
import * as yup from 'yup';

import { getRequest, postRequest, putRequest } from '../../../services/httpService';

export type AnesthesiaType = 'LOCAL_ANESTHESIA' | 'SEDATION' | 'GENERAL_ANESTHESIA' | 'SPINAL_ANESTHESIA' | 'EPIDURAL_ANESTHESIA';
export type SiteType = 'PERITUBAL' | 'TUBO_OVARIAN' | 'PARA_OVARIAN' | 'POUCH_OF_DOUGLAS_POD' | 'ABDOMEN' | 'OTHER';
export type AdhesionType = 'DENSE' | 'FILMSY' | 'NO_ADHESION';
export type EndometriosisGrade = 'MINIMAL' | 'MILD' | 'MODERATE';
export type UterusType = 'SMALL' | 'FIBROID' | 'NORMAL' | 'ADENOMYOTIC' | 'BULKY';
export type SizeType = 'NORMAL' | 'SMALL' | 'BULKY' | 'PRESENCE_OF_FIBROID_MYOMA' | 'ADENOMYOSIS';
export type ShapeType = 'NORMAL' | 'DISTORTED';
export type PositionType = 'NORMAL' | 'ABNORMAL';
export type MobilityType = 'NORMAL' | 'RESTRICTED';
export type AssociatedProcedureType = 'TUBAL_RECANALISATION' | 'MYOMECTOMY';
export type UndersurfaceLiverType = 'NORMAL' | 'FITZ_HUGH' | 'CURTIS_SYNDROME';
export type EBHpeType = 'PROLIFERATIVE' | 'SECRETORY' | 'ENDOMETRITIS' | 'OTHER';

// Interface for Laparoscopy Type
export interface LaparoscopyType {
  id?: number | null;
  patient_procedure?: number | null;
  date: string;
  indication: string;
  cycle_day: string;
  procedure_duration: string;

  //Anesthesia Details
  anesthesia_type: AnesthesiaType | null;
  anesthesia_details_remark: string;
  preanesthetic_checkup: string;

  //Patency Of Falloplan Tube
  right_tube_status: string;
  left_tube_status: string;

  //Right Ovary
  right_ovary_status: string;
  right_ovary_details: string;

  //Left Ovary
  left_ovary_status: string;
  left_ovary_details: string;
  
  //Other Details
  adhesion_site: SiteType | null;
  adhesion_grade: AdhesionType | null;
  remark:string | null;
  endometriosis_grade: EndometriosisGrade | null;

  uterus_status: UterusType | null;
  uterus_size: SizeType | null;
  uterus_shape: ShapeType | null;
  uterus_position: PositionType | null;
  uterus_mobility: MobilityType | null;
  associated_procedures: AssociatedProcedureType | null;
  uterus_remark: string | null;
  pouch_of_douglas_remark: string;
  undersurface_of_liver: UndersurfaceLiverType | null;
  eb_hpe: EBHpeType | null;
  done_by: number | null;
  associated_doctor: number | null;
  uploaded_image: string | null;
  eb_hpe_remark: string | null;
  image_path_url: string | null;
}

// Default initial values
export const initialLaparoscopy: LaparoscopyType = {
  id: null,
  date: '',
  patient_procedure:null,
  indication: '',
  cycle_day: '',
  procedure_duration: '',
  anesthesia_type: null,
  anesthesia_details_remark: '',
  preanesthetic_checkup: '',
  right_tube_status: '',
  left_tube_status: '',
  right_ovary_status: '',
  right_ovary_details: '',
  left_ovary_status: '',
  left_ovary_details: '',
  adhesion_site: null,
  adhesion_grade: null,
  remark:null,
  endometriosis_grade: null,
  uterus_status: null,
  uterus_size: null,
  uterus_shape: null,
  uterus_position: null,
  uterus_mobility: null,
  associated_procedures: null,
  uterus_remark: null,
  pouch_of_douglas_remark: '',
  undersurface_of_liver: null,
  eb_hpe: null,
  done_by: null,
  associated_doctor: null,
  uploaded_image: null,
  eb_hpe_remark:'',
  image_path_url: null
};

export const anesthesiaItems: { value: AnesthesiaType; label: string }[] = [
  { value: 'LOCAL_ANESTHESIA', label: 'Local Anesthesia' },
  { value: 'SEDATION', label: 'Sedation' },
  { value: 'GENERAL_ANESTHESIA', label: 'General Anesthesia' },
  { value: 'SPINAL_ANESTHESIA', label: 'Spinal Anesthesia' },
  { value: 'EPIDURAL_ANESTHESIA', label: 'Epidural Anesthesia' },
];
  
export const siteItems: { value: SiteType; label: string }[] = [
  { value: 'PERITUBAL', label: 'Peritubal' },
  { value: 'TUBO_OVARIAN', label: 'Tubo-Ovarian' },
  { value: 'PARA_OVARIAN', label: 'Para-Ovarian' },
  { value: 'POUCH_OF_DOUGLAS_POD', label: 'Pouch of Douglas (POD)' },
  { value: 'ABDOMEN', label: 'Abdomen' },
  { value: 'OTHER', label: 'Other' },
];
  
export const adhesionItems: { value: AdhesionType; label: string }[] = [
  { value: 'DENSE', label: 'Dense' },
  { value: 'FILMSY', label: 'Filmsy' },
  { value: 'NO_ADHESION', label: 'No Adhesion' },
];
  
export const endometriosisGradeItems: { value: EndometriosisGrade; label: string }[] = [
  { value: 'MINIMAL', label: 'Minimal' },
  { value: 'MILD', label: 'Mild' },
  { value: 'MODERATE', label: 'Moderate' },
];
  
export const uterusItems: { value: UterusType; label: string }[] = [
  { value: 'SMALL', label: 'Small' },
  { value: 'FIBROID', label: 'Fibroid' },
  { value: 'NORMAL', label: 'Normal' },
  { value: 'ADENOMYOTIC', label: 'Adenomyotic' },
  { value: 'BULKY', label: 'Bulky' },
];
  
export const uterusSizeItems: { value: SizeType; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'SMALL', label: 'Small' },
  { value: 'BULKY', label: 'Bulky' },
  { value: 'PRESENCE_OF_FIBROID_MYOMA', label: 'Presence of fibroid (Myoma)' },
  { value: 'ADENOMYOSIS', label: 'Adenomyosis' },
];
  
export const shapeItems: { value: ShapeType; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'DISTORTED', label: 'Distorted' },
];
  
export const positionItems: { value: PositionType; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'ABNORMAL', label: 'Abnormal' },
];
  
export const mobilityItems: { value: MobilityType; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'RESTRICTED', label: 'Restricted' },
];
  
export const associatedProcedureItems: { value: AssociatedProcedureType; label: string }[] = [
  { value: 'TUBAL_RECANALISATION', label: 'Tubal Recanalisation' },
  { value: 'MYOMECTOMY', label: 'Myomectomy' },
];
  
export const liverUndersurfaceItems: { value: UndersurfaceLiverType; label: string }[] = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'FITZ_HUGH', label: 'Fitz Hugh' },
  { value: 'CURTIS_SYNDROME', label: 'Curtis Syndrome' },
];
  
export const ebHpeItems: { value: EBHpeType; label: string }[] = [
  { value: 'PROLIFERATIVE', label: 'Proliferative' },
  { value: 'SECRETORY', label: 'Secretory' },
  { value: 'ENDOMETRITIS', label: 'Endometritis' },
  { value: 'OTHER', label: 'Other' },
];
  
// Validation schema for Laparoscopy with only mandatory fields
export const LaparoscopyValidationSchema = yup.object({
  date: yup.string().required('Date is required'), 
  indication: yup.string().required('Indication is required'),
  cycle_day: yup.string().matches(/^\d+$/, 'Day of Cycle must be a number').required('Day of Cycle is required'), // Mandatory
  procedure_duration: yup.string().matches(/^\d+$/, 'Duration must be a number').nullable(),
  anesthesia_type: yup.string().nullable().oneOf([
    'LOCAL_ANESTHESIA', 'SEDATION', 'GENERAL_ANESTHESIA', 'SPINAL_ANESTHESIA', 'EPIDURAL_ANESTHESIA'
  ]),
  preanesthetic_checkup: yup.string(),
  right_tube_status: yup.string(),
  left_tube_status: yup.string(),
  right_ovary_status: yup.string(),
  right_ovary_details: yup.string(),
  left_ovary_status: yup.string(),
  left_ovary_details: yup.string(),
  adhesion_site: yup.string().nullable().oneOf(['PERITUBAL', 'TUBO_OVARIAN', 'PARA_OVARIAN', 'POUCH_OF_DOUGLAS_POD', 'ABDOMEN', 'OTHER']),
  adhesion_grade: yup.string().nullable().oneOf(['DENSE', 'FILMSY', 'NO_ADHESION']),
  remark: yup.string().nullable(),
  endometriosis_grade: yup.string().nullable().oneOf(['MINIMAL', 'MILD', 'MODERATE']),
  uterus_status: yup.string().nullable().oneOf(['SMALL', 'FIBROID', 'NORMAL', 'ADENOMYOTIC', 'BULKY']),
  uterus_size: yup.string().nullable().oneOf(['NORMAL', 'SMALL', 'BULKY', 'PRESENCE_OF_FIBROID_MYOMA', 'ADENOMYOSIS']),
  uterus_shape: yup.string().nullable().oneOf(['NORMAL', 'DISTORTED']),
  uterus_position: yup.string().nullable().oneOf(['NORMAL', 'ABNORMAL']),
  uterus_mobility: yup.string().nullable().oneOf(['NORMAL', 'RESTRICTED']),
  associated_procedures: yup.string().nullable().oneOf(['TUBAL_RECANALISATION', 'MYOMECTOMY']),  
  uterus_remark: yup.string().nullable(),
  pouch_of_douglas_remark: yup.string(),
  undersurface_of_liver: yup.string().nullable().oneOf(['NORMAL', 'FITZ_HUGH', 'CURTIS_SYNDROME']),
  eb_hpe: yup.string().nullable().oneOf(['PROLIFERATIVE', 'SECRETORY', 'ENDOMETRITIS', 'OTHER']),
  done_by: yup.number().nullable(),
  associated_doctor: yup.number().required('Associated Doctor is required'),
  eb_hpe_remark: yup.string()
});

// URL generation function for laparoscopy
export function generateLaparoscopyURL(id: number): string {
  return `/laparoscopy/patient-procedure/${id}`;
}
  
// Function to get laparoscopy
export function getLaparoscopy(id: number): Promise<LaparoscopyType> {
  return getRequest(generateLaparoscopyURL(id), { responseType: 'json' });
}

export function addLaparoscopy(data: LaparoscopyType): Promise<LaparoscopyType> {
  const url = 'laparoscopy/';  
  return postRequest(url, data, { responseType: 'json' });
}

// Function to update laparoscopy
export function updateLaparoscopy(id: number, data: LaparoscopyType): Promise<LaparoscopyType> {
  const url = `/laparoscopy/${id}`;
  return putRequest(url, data, { responseType: 'json' });
}
export interface OocyteSource {
  id: number;
  type: string;
  last_name: string;
  photo_url: string | null;
  first_name: string;
  medical_record_no: string | null;
}

export interface DonorInfo {
  recipient: string | null;
  sperm_source: OocyteSource[]; 
  oocyte_source: OocyteSource[];
}

export interface Service {
  id: number;
  name: string;
  code: string;
  description: string;
  service_type: string;
}

export interface LaparoscopyDetailsEnum {
  id: number;
  services: Service[];
  procedure_notes: string;
  created_on: string;
  doctor: number;
  doctor_name: string;
  indication: string | null;
  donor_info: DonorInfo;
  laparoscopy_status: boolean;
  hysteroscopy_status: boolean;
}

export function getLaparoscopyList(id: number): Promise<LaparoscopyDetailsEnum> {
  const url = `patient-procedure/?patient=${id}&search=Laparoscopy`;
  return getRequest(url, { responseType: 'json' });
}

