import { Autocomplete, Avatar, AvatarGroup, Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormHelperText, Grid2, Paper, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, ThemeProvider, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { theme } from '../../Medical_History_Forms/themeprovider';
import styles from './createCycle.module.css';
import { addProcedures, cycleRelatedMandatoryProcedures, FormErrors, IndicationMenuItems, PatientProcedureCreate } from './createCycle-helper';
import { ProcedureMaster, ServiceMaster } from '../CycleList/cyclelist-helper';
import { useAlert } from '../../alertProvider';
import cart from '../../../assets/SVG/shopping-cart.svg';
import  CloseCircle  from '../../../assets/SVG/closecircle.svg';
import closeCircleRed from '../../../assets/SVG/close-circle.svg';
import addCircle from '../../../assets/SVG/add-black.svg';
import addedTick from '../../../assets/SVG/tick-circle.svg';
import { getTreatmentServices } from '../Procedure/proceduresHelper';
import { getDoctors, User } from '../../Diagnosis/diagnosisHelper';
import { ConfirmationModal } from '../../confirmationModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from '../../loaderProvider/loader';
import LinkOocyteDonor from './LinkOocyteDonor';
import { putLinkOocyteDonor } from './LinkOocyteDonorHelper';
import DefaultImageMale from '../../../assets/SVG/Default Profile - Male.svg';
import DefaultImageFemale from '../../../assets/SVG/Default Profile - Female.svg';
import { patientDetailsObservable } from '../../../services/sharedService';
import { Patientv2 , Donor} from '../../../types';

type OnDataType = (value: boolean) => void;

const CreateCycle = ({cycleDetails, onClose }: {cycleDetails: ProcedureMaster, onClose: OnDataType }) => {
  const onDataRef = useRef<OnDataType | null>(null);

  const [occytechecked, setOccyteChecked] = useState({
    self: false,
    donor: false,
    partner:false
  });
  const [spermchecked, setSpermchecked] = useState({
    self: false,
    donor: false,
  });

  const { addAlert } = useAlert();
  const [openLinkDialog, setopenLinkDialog] =useState(false);
  const [dialogTitle, setdialogTitle] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cycleType, setCycleType] = useState('ivf/icsi Cycle'); 
  const steps = [`Cycle Details (${cycleDetails.name  })`, 'Add Procedure'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [CycleRelatedProcedures, setCycleRelatedProcedures] = useState<any[]>([]);
  const [doctorError, setDoctorError] = useState(false); 
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedDoctorid , setSelectedDoctorId] = useState<number | null>(null);
  const [doctors, setDoctors] = useState<User[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const { id } = useParams<{ id: string }>(); 
  
  const [selectedProcedures , SetSelectedProcedures] = useState<ServiceMaster[]>([]);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>('self'); 
  const { showLoader, hideLoader } = useLoader();
  const [showOverlay, setShowOverlay] = useState(false);
  const [donorData, setDonorData] = useState<{ donorIds: Donor[]; dialogTitle: string } | null>(null);
  const [spermdonorData, setspermDonorData] = useState<{ donorIds: Donor[]; dialogTitle: string } | null>(null);
  const [OocytedonorData, setOocyteDonorData] = useState<{ donorIds: Donor[]; dialogTitle: string } | null>(null);
  const [errors, setErrors] = useState<FormErrors | null>({});
  const [PrevDonorInfo, setPrevDonorInfo] = useState<Donor[]>([]);
  const navigate = useNavigate(); 
  const [searchQuery, setSearchQuery] = useState<string>(''); // State for search input
  const [patientDetails, setpatientDetails] = useState<Patientv2 | Donor | null>(null);
  const [showAll, setShowAll] = useState(false); // State to toggle between showing 10 or all items
  
  const handleOpenModal=()=>{
    if (!selectedDoctorid) {
      setDoctorError(true); // Set error if no doctor is selected
      setShowOverlay(true);
      return;
    }

    setOpenModal(true);
  };


  const [formValues, setFormValues] = useState({
    indication: '',
    notes: '',
  });


  const handleCloseDialog = () => {
    setopenLinkDialog(false);
  };

  const handleAddProcedure = useCallback(
    (order: ServiceMaster) => {
      // const isOrderAlreadyAdded = selectedProcedures.some(
      //   (service) => service.id === order.id
      // );
  
      // if (isOrderAlreadyAdded) {
      //   addAlert('This service is already added.', 'info');
      // } else {
      SetSelectedProcedures((prev) => [...prev, order]);
      // }
    },
    []
  );
  

  useEffect(()=>{
    if (cycleDetails.name === '(OI) Cycle') {
      setActiveStep(1);
    }

    if (cycleDetails.name === 'IUI') {
      setSelectedValue(null);
    }
    getTreatmentServices(`?procedure=${cycleDetails.id}`).then((data) => {
      const fetchedProcedures = data.objects;

      // Find the matching mandatory procedure
      const matchedMandatoryProcedure = cycleRelatedMandatoryProcedures.find(
        (mandatoryProcedure) => mandatoryProcedure.procedureName === cycleDetails.name
      );

      if (matchedMandatoryProcedure) {
        // Filter the fetched procedures to match the services in the matched mandatory procedure
        const filteredProcedures = fetchedProcedures.filter((fetchedProcedure) =>
          matchedMandatoryProcedure.services.some(
            (service) =>
              service.serviceName === fetchedProcedure.name || service.code === fetchedProcedure.code
          )
        );
        filteredProcedures.forEach((procedure) => {
          SetSelectedProcedures((prev) => [...prev, procedure]);
        });

        // setCycleRelatedProcedures(filteredProcedures);
      }

      setCycleRelatedProcedures(data.objects);    
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching data:', error);
    });

    getDoctors().then((res) => {
      setDoctors(res.objects);
    });
  },[cycleDetails.id, cycleDetails.name]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchChange = (event:any) => {
    setSearchQuery(event.target.value);
  };

  // Filter CycleRelatedProcedures based on search query
  const filteredProcedures = CycleRelatedProcedures.filter((cycle) =>
    cycle.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  const displayedProcedures = showAll
    ? filteredProcedures
    : filteredProcedures.slice(0, 10);

  useEffect(() => {
    const subscription = patientDetailsObservable.subscribe((value) => {
      setpatientDetails(value);
      // eslint-disable-next-line no-console
      console.log(value);
      
    });
  
    return () => subscription.unsubscribe();
  }, []);
  
  useEffect(()=>{
    if (patientDetails && patientDetails.patient_type === 'OOCYTE_DONOR') {
      setOccyteChecked({
        self: false,
        donor: true,
        partner:false
      });
      const transformedDonor: Donor = {
        id: patientDetails.id,
        first_name: patientDetails.first_name,
        last_name: patientDetails.last_name,
        medical_record_no: patientDetails.medical_record_no,
        profile_url: patientDetails.photo_url || undefined, // Map photo_url to profile_url
        mobile_number: '',
      };
      
      // Update the donorData state
      setDonorData((prev) => {
        if (!prev) {
          return {
            donorIds: [transformedDonor],
            dialogTitle: 'Link Oocyte Donor', 
          };
        }
      
        return {
          ...prev,
          donorIds: [...prev.donorIds, transformedDonor],
        };
      });
      
    }
  }, [patientDetails]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };




  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    
    const isChecked = event.target.value;
    
    setSelectedValue(isChecked);
 
  
    // Call handleOpenLinkDialog when checkbox is checked
    if (isChecked === 'donor' ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOpenLinkDialog('Link Surrogate');
    }
    else{
      setOocyteDonorData(null);
    }
  };
  const handleCloseModal = () => {setOpenModal(false);};

  const handleSave = () => {
    const newErrors:FormErrors = {};
    if (patientDetails&&patientDetails.patient_type !== 'OOCYTE_DONOR') {
      if (cycleDetails.name !== 'IUI' && !occytechecked.self && !occytechecked.donor && !occytechecked.partner)
        newErrors.occytechecked = 'Select at least one oocyte source';
      if (!spermchecked.self && !spermchecked.donor)
        newErrors.spermchecked = 'Select at least one sperm source';
      if (cycleDetails.name !== 'IUI' && !selectedValue) newErrors.recipient = 'Recipient is required';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setActiveStep(1);
    }
  };

  const extractDonorIds = (data: { donorIds: { id: number }[] } | null): number[] => {
    return data?.donorIds?.map((donor) => donor.id) || [];
  };
  

  const handleFinalSave=()=>{
    showLoader();
  
    const data :PatientProcedureCreate ={
      patient:patientDetails &&patientDetails.patient_type === 'OOCYTE_DONOR' ? patientDetails.id : Number(id),
      procedures:Number(cycleDetails.id),
      services: selectedProcedures
        .map((procedure) => procedure.id) // Extract IDs
        .filter((id): id is number => id !== undefined),
      indication:formValues.indication,
      doctor:selectedDoctorid?selectedDoctorid  :0,
      oocyte_source_self:occytechecked.self,
      oocyte_partner_self:occytechecked.partner,
      sperm_source_self:spermchecked.self,
      recipient_self:selectedValue === 'self',
      procedure_notes:formValues.notes
    };

    const donorIds = extractDonorIds(donorData); 
    const spermDonorIds = extractDonorIds(spermdonorData);
    const oocyteDonorIds = extractDonorIds(OocytedonorData); 

    // Combine all IDs into a single array (if needed)
    const allDonorIds = [...donorIds, ...spermDonorIds, ...oocyteDonorIds];

    const Data = {
      donor_ids: allDonorIds,
    };
    
    putLinkOocyteDonor(Number(id), Data)
      .then(response => {
        // eslint-disable-next-line no-console
        console.log(`Donors link Successfully, ${response}`);
        addProcedures(data)
          .then((res) => {
            // eslint-disable-next-line no-console
            console.log(res);
            
            addAlert('Procedure Added Successfully', 'success');
            navigate(`/clinical/patients/treatment-plan/${id}?view=procedure_list`);
          })
          .catch((error) => {
            // Handle errors
          // eslint-disable-next-line no-console
            console.error('An error occurred:', error);
            addAlert('An error occurred:', 'error');
          });

    
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        
        addAlert('Error linking donors. Please try again.', 'error'); 
        hideLoader();
        return;
      }).finally(() => {
        hideLoader();
          
      });

 
    
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOocyteCheckboxChange = (name: any) => (event: { target: { checked: any; }; }) => {
    const isChecked = event.target.checked;

    // Update the state
    setOccyteChecked({
      ...occytechecked,
      [name]: isChecked,
    });
  
    // Call handleOpenLinkDialog when checkbox is checked
    if (isChecked && name === 'donor') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOpenLinkDialog('Link Oocyte Donor');
    }
    if (!isChecked && name === 'donor') {
      setDonorData(null);
    } 
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSpermCheckboxChange = (name: any) => (event: { target: { checked: any; }; }) => {

    const isChecked = event.target.checked;

    // Update the state
    setSpermchecked({
      ...spermchecked,
      [name]: isChecked,
    });
  
    // Call handleOpenLinkDialog when checkbox is checked
    if (isChecked && name === 'donor') {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleOpenLinkDialog('Link Sperm Donor');
    }
    if (!isChecked && name === 'donor') {
      setspermDonorData(null);
    } 

  };
  useEffect(() => {
    onDataRef.current = onClose;
  }, [onClose]);

  const onBack = () => {
    // eslint-disable-next-line no-console
    console.log('onback called');
    if (onDataRef.current) {
      onDataRef.current(true);
    }
  };

  const handleOpenLinkDialog = (title:string)=>{
    setdialogTitle(title);

    if (title === 'Link Oocyte Donor' && donorData && donorData.donorIds) {
      setPrevDonorInfo(donorData.donorIds);
    } else if (title === 'Link Sperm Donor' && spermdonorData && spermdonorData.donorIds) {
      setPrevDonorInfo(spermdonorData.donorIds);
    } else if (title === 'Link Surrogate' && OocytedonorData && OocytedonorData.donorIds) {
      setPrevDonorInfo(OocytedonorData.donorIds);
    } else {
      setPrevDonorInfo([]); // Clear previous info if no matching data is found
    }

    setopenLinkDialog(true);
  };





  const handleToggleOverlay = () => {
    setShowOverlay((prev) => !prev);
  };

  const handleRemoveService = (index:number) => {
    SetSelectedProcedures((prev) => {
      const updatedServices = prev.filter((_, i) => i !== index);
      return updatedServices;
    });
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  

  function handleDoctorChange( value: number): void {
    setSelectedDoctorId(value);
    setDoctorError(false);
  }

 
  const handleCloseLinkDialog = (data?: { donorIds: Donor[]; dialogTitle: string }) => {

    
    if (data) {
    
      if (data.dialogTitle === 'Link Oocyte Donor') {
        setDonorData({
          donorIds: data.donorIds,
          dialogTitle: 'Link Oocyte Donor',
        });

        if (data.donorIds.length === 0) {
          setOccyteChecked({
            ...occytechecked,
            donor: false
          });
        }
      }
      if (data.dialogTitle === 'Link Sperm Donor') {
        setspermDonorData({
          donorIds: data.donorIds,
          dialogTitle: 'Link Sperm Donor',
        });

        if (data.donorIds.length === 0) {
          setSpermchecked({
            ...spermchecked,
            donor: false
          });
        }
      }
      if (data.dialogTitle === 'Link Surrogate') {
        setOocyteDonorData({
          donorIds: data.donorIds,
          dialogTitle: 'Link Surrogate',
        });

        if (data.donorIds.length === 0) {
          setSelectedValue('self');
        }
      }
    }
    setopenLinkDialog(false);
  };
  
  
  


  return (
    <>
      {cycleDetails.name !== '(OI) Cycle' && (
        <Grid2 m={2} container>
          <Grid2 size={4}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                padding: 2,
                backgroundColor: '#fff',
                borderRadius: 3,
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                sx={{
                  width: '100%',
                  '& .MuiStepConnector-line': {
                    borderTopWidth: 1.5,
                    borderColor: '#E0E0E0',
                  },
                }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        '& .MuiStepIcon-root': {
                          color: index <= activeStep ? '#FFEFEB' : '#BDBDBD', // Active/Inactive step colors
                          backgroundColor: 'transparent',
                        },
                        '& .MuiStepIcon-root.Mui-completed': {
                          color: '#016A1C', // Override the default completed color
                        },
                        '& .MuiStepIcon-root.Mui-active': {
                          color: '#FFEFEB', // Custom color for the active step
                        },
                        '& .MuiStepIcon-text': {
                          fill: index === activeStep ? '#E17E61' : '#FFF',
                        },
                        '& .MuiStepLabel-label': {
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          fontWeight: 600,
                          color: index < activeStep 
                            ? '#016A1C' // Color for steps before the active step
                            : index === activeStep 
                              ? '#E17E61' // Color for the active step
                              : '#BDBDBD', // Color for steps after the active stepd
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid2>
        </Grid2>

      )
      }
      <ThemeProvider theme={theme}>

        {activeStep === 0 ? (
          <div>
            {cycleType === 'Natural Cycle' ? (
              <div>
                <Box sx={{ padding: 2, borderRadius: '8px', width: '100%' }}>
                  {/* Title and Radio Buttons */}
                  <Grid2 container alignItems="center" spacing={2}>
                    <Grid2 size={8}>
                      <Typography variant="subtitle1">
                        <strong>Cycle Details</strong> (Natural Cycle)
                      </Typography>
                    </Grid2>
                    <Grid2 size={4} sx={{ textAlign: 'right' }}>
                      <RadioGroup
                        row
                        // value={radioValue}
                        // onChange={handleRadioChange}
                        sx={{ display: 'inline-flex' }}
                      >
                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                      </RadioGroup>
                    </Grid2>
                  </Grid2>

                  {/* Notes TextField */}
                  <Box sx={{ marginTop: 2 }}>
                
                    <TextField
                      placeholder="Type Here"
                      variant="outlined"
                      label="Notes"
                      fullWidth
                      multiline
                      rows={3}
                    />
                  </Box>

                  <Grid2 size={12}>
                    <hr></hr>
                  </Grid2>
                  <Grid2 size={12} display={'flex'} justifyContent={'end'}>
                    <Grid2 size={12} my={2} display={'flex'} justifyContent={'end'}>
                      <Button
                        type="button"
                        onClick={onBack}
                        sx={{
                          width: '7.5rem',
                          height: '2.875rem',
                          border: '1px solid #505050',
                          backgroundColor: 'white',
                          color: '#505050',
                          fontFamily: 'Montserrat, sans-serif',
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '22px',
                          textAlign: 'left',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          borderRadius: '10px',
                          marginRight: '20px',
                          textTransform: 'none',

                          '&:hover': {
                            backgroundColor: '#f5f5f5', // Slight hover effect for better UX
                          },
                        }}
                      >
                    Cancel
                      </Button>
                      <Button sx={{
                        height: '2.875rem',
                        minWidth:'7.5rem',

                        gap: '0.625rem',
                        borderRadius: '0.625rem',
                        backgroundColor: '#505050',
                        color: 'white',
                        opacity: 1,
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',

                        transition: 'opacity 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#505050', 
                          opacity: 0.9, 
                        },
                      }}variant="contained" onClick={() => {
                      // handleOpenModal1('savebtn');
                      } }>
                    Save
                      </Button>
                    </Grid2>
                  </Grid2>
                </Box>
              </div>
            ) : (
       
              <>
                <Grid2 mx={3} spacing={2} container>

                  <Grid2 size={{xl:4, md:6}} mt={2}>
                    <Box
                      sx={{
                        backgroundColor: '#F8F8F8',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                        borderRadius: '15px'
                      }} alignItems="center" gap={2}>
                      <span>Oocyte Source:</span>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={occytechecked.self}
                            disabled={cycleDetails.name === 'IUI'}
                            onChange={handleOocyteCheckboxChange('self')}
                            sx={{
                              color: '#BDBDBD',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              },
                              '&.Mui-disabled': {
                                opacity: 0.5, // Fades the checkbox when disabled
                                cursor: 'not-allowed', // Makes the cursor not-allowed
                              },
                            }}
                          />
                        }
                        label="Self"
                        sx={{
                          margin: 0,
                          opacity: cycleDetails.name === 'IUI' ? 0.5 : 1, // Fades label when disabled
                          cursor: cycleDetails.name === 'IUI' ? 'not-allowed' : 'pointer', // Makes cursor not-allowed when disabled
                        }}
                      />
                      {cycleDetails.name === 'Reciprocal IVF Cycle' &&(
                        <FormControlLabel
                          control={<Checkbox
                            checked={occytechecked.partner}
                            onChange={handleOocyteCheckboxChange('partner')}
                            sx={{
                              color: '#BDBDBD',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              }
                            }} />}
                          label="Partner"
                          sx={{ margin: 0 }} />
                      )
                      }

                      <Box display={'flex'} alignItems={'center'}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={occytechecked.donor}
                              disabled={cycleDetails.name === 'IUI'}
                              onChange={handleOocyteCheckboxChange('donor')}
                              sx={{
                                color: '#BDBDBD',
                                '&.Mui-checked': {
                                  color: '#4CAF50',
                                },
                                '&.Mui-disabled': {
                                  opacity: 0.5, // Fades the checkbox when disabled
                                  cursor: 'not-allowed', // Prevents interaction
                                },
                              }}
                            />
                          }
                          label=""
                          sx={{ margin: 0 }}
                        />
                        <p
                          style={{
                            color: '#5A8AEA',
                            fontWeight: 600,
                            cursor: cycleDetails.name === 'IUI' ? 'not-allowed' : 'pointer', // Prevents interaction when disabled
                            opacity: cycleDetails.name === 'IUI' ? 0.5 : 1, // Fades text when disabled
                            pointerEvents: cycleDetails.name === 'IUI' ? 'none' : 'auto', // Disables clicks when disabled
                          }}
                          // onClick={() => { handleOpenLinkDialog('Link Oocyte Donor'); } }
                        >
    Donor
                        </p>
                      </Box>

                      <AvatarGroup max={3}
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 30,
                            fontSize: '0.75rem',
                          },
                        }}>
                        {donorData && donorData.dialogTitle === 'Link Oocyte Donor' && donorData.donorIds.map((donor, index) => (
                          <Avatar
                            key={index}
                            sx={{
                              width: 30,
                              height: 30,
                              margin: '0 5px',
                              backgroundColor: 'gray', 
                            }}
                          >
                            <img style={{width:'40px'}} src={donor.profile_url ? donor.profile_url:DefaultImageFemale} alt="profileView" />
                          </Avatar>
                        ))}
                      </AvatarGroup>


                    </Box>
                    {errors?.occytechecked && <FormHelperText error>{errors.occytechecked}</FormHelperText>}
                  </Grid2>
                  <Grid2 size={{xl:4, md:6}} mt={2}>
                    <Box
                      sx={{
                        backgroundColor: '#F8F8F8',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px',
                        borderRadius: '15px'
                      }} alignItems="center" gap={2}>
                      <span>Sperm Source:</span>


                      <FormControlLabel
                        control={<Checkbox
                          checked={spermchecked.self}
                          disabled={patientDetails?.patient_type === 'OOCYTE_DONOR' || (patientDetails?.patient_type === 'Patient-Single' && patientDetails?.gender === 'FEMALE') || cycleDetails.name === 'Reciprocal IVF Cycle'}

                          onChange={handleSpermCheckboxChange('self')}
                         
                          sx={{
                            color: '#BDBDBD',
                            '&.Mui-checked': {
                              color: '#4CAF50',
                            },
                            '&.Mui-disabled': {
                              opacity: 0.5, 
                              cursor: 'not-allowed',
                            },
                          }}
                    
                        />}

                        label={patientDetails?.gender === 'FEMALE' ? 'Partner':'Self'}
                        sx={{
                          margin: 0,
                          opacity:
                            patientDetails?.patient_type === 'OOCYTE_DONOR' ||
                            (patientDetails?.patient_type === 'Patient-Single' && patientDetails?.gender === 'FEMALE') ||
                            cycleDetails.name === 'Reciprocal IVF Cycle'
                              ? 0.5
                              : 1, 
                          cursor:
                            patientDetails?.patient_type === 'OOCYTE_DONOR' ||
                            (patientDetails?.patient_type === 'Patient-Single' && patientDetails?.gender === 'FEMALE') ||
                            cycleDetails.name === 'Reciprocal IVF Cycle'
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                      />

                      <Box display={'flex'} alignItems={'center'}>
                        <FormControlLabel
                          control={<Checkbox
                            checked={spermchecked.donor}
                            onChange={handleSpermCheckboxChange('donor')}
                            sx={{
                              color: '#BDBDBD',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              },
                            }} />}
                          disabled={patientDetails?.patient_type === 'OOCYTE_DONOR'}
                          label=""
                          sx={{
                            color: '#BDBDBD',
                            '&.Mui-checked': {
                              color: '#4CAF50',
                            },
                            '&.Mui-disabled': {
                              opacity: 0.5, 
                              cursor: 'not-allowed',
                            },
                          }}
                        />
                        <p  style={{
                          color: '#5A8AEA',
                          fontWeight: 600,
                          cursor: patientDetails?.patient_type === 'OOCYTE_DONOR' ? 'not-allowed' : 'pointer',
                          opacity: patientDetails?.patient_type === 'OOCYTE_DONOR' ? 0.5 : 1, 
                        }} 
                        >Donor</p>
                      </Box>
                      <AvatarGroup max={3}
                        sx={{
                          '& .MuiAvatar-root': {
                            width: 30,
                            height: 30,
                            fontSize: '0.75rem',
                          },
                        }}>
                        {spermdonorData && spermdonorData.dialogTitle === 'Link Sperm Donor' && spermdonorData.donorIds.map((donor, index) => (
                          <Avatar
                            key={index}
                            sx={{
                              width: 30,
                              height: 30,
                              margin: '0 5px',
                              backgroundColor: 'gray', 
                            }}
                          >
                            <img style={{width:'40px'}} src={donor.profile_url ? donor.profile_url:DefaultImageMale} alt="profileView" />

                          </Avatar>
                        ))}
                      </AvatarGroup>

                    </Box>
                    {errors?.spermchecked && <FormHelperText error>{errors.spermchecked}</FormHelperText>}
                  </Grid2>
                  {cycleDetails.name !== 'Cryopreservation'?(

                    <Grid2 size={{xl:4, md:6}} mt={2}>
                      <Box
                        sx={{
                          backgroundColor: '#F8F8F8',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '10px',
                          borderRadius: '15px'
                        }} alignItems="center" gap={2}>
                        <span>Recipient:</span>
                        <RadioGroup row defaultValue="self" value={selectedValue} onChange={handleRadioChange} >

                          <FormControlLabel
                            value="self"
                            control={
                              <Radio
                                disabled={patientDetails?.patient_type === 'OOCYTE_DONOR' || cycleDetails.name === 'IUI'}
                                sx={{
                                  '&.Mui-disabled': {
                                    opacity: 0.5, // Fades the radio button when disabled
                                    cursor: 'not-allowed', // Prevents interaction
                                  },
                                }}
                              />
                            }
                            label="Self"
                            sx={{
                              opacity: patientDetails?.patient_type === 'OOCYTE_DONOR' || cycleDetails.name === 'IUI' ? 0.5 : 1, // Fades label when disabled
                              cursor: patientDetails?.patient_type === 'OOCYTE_DONOR' || cycleDetails.name === 'IUI' ? 'not-allowed' : 'pointer', // Prevents interaction when disabled
                            }}
                          />

                          <Box display={'flex'} alignItems={'center'}>

                            <FormControlLabel
                              value="donor"
                              control={
                                <Radio
                                  disabled={
                                    patientDetails?.patient_type === 'OOCYTE_DONOR' ||
        cycleDetails.name === 'Reciprocal IVF Cycle' ||
        cycleDetails.name === 'IUI'
                                  }
                                  sx={{
                                    color: '#BDBDBD',
                                    '&.Mui-checked': {
                                      color: '#4CAF50',
                                    },
                                    '&.Mui-disabled': {
                                      opacity: 0.5, // Fades the radio button when disabled
                                      cursor: 'not-allowed', // Disables interaction
                                    },
                                  }}
                                />
                              }
                              label=""
                              sx={{
                                margin: 0,
                                opacity:
      patientDetails?.patient_type === 'OOCYTE_DONOR' ||
      cycleDetails.name === 'Reciprocal IVF Cycle' ||
      cycleDetails.name === 'IUI'
        ? 0.5
        : 1,
                                cursor:
      patientDetails?.patient_type === 'OOCYTE_DONOR' ||
      cycleDetails.name === 'Reciprocal IVF Cycle' ||
      cycleDetails.name === 'IUI'
        ? 'not-allowed'
        : 'pointer',
                              }}
                            />

                            <p  style={{
                              color: '#5A8AEA',
                              fontWeight: 600,
                              cursor:
        patientDetails?.patient_type === 'OOCYTE_DONOR' ||
        cycleDetails.name === 'Reciprocal IVF Cycle' ||
        cycleDetails.name === 'IUI'
          ? 'context-menu'
          : 'pointer',
                              opacity:
        patientDetails?.patient_type === 'OOCYTE_DONOR' ||
        cycleDetails.name === 'Reciprocal IVF Cycle' ||
        cycleDetails.name === 'IUI'
          ? 0.5
          : 1,
                            }} 
                            >Surrogate</p>
                          </Box>
                        </RadioGroup>
                        <AvatarGroup max={3}
                          sx={{
                            '& .MuiAvatar-root': {
                              width: 30, // Width for all avatars including the extra icon
                              height: 30, // Height for all avatars including the extra icon
                              fontSize: '0.75rem', // Adjust font size for the "+2" icon
                            },
                          }}>

                          {OocytedonorData && OocytedonorData.dialogTitle === 'Link Surrogate' && OocytedonorData.donorIds.map((donor, index) => (
                            <Avatar
                              key={index}
                              sx={{
                                width: 30,
                                height: 30,
                                margin: '0 5px',
                                backgroundColor: 'gray', 
                              }}
                            >
                              <img style={{width:'40px'}} src={donor.profile_url ? donor.profile_url:DefaultImageFemale} alt="profileView" />

                            </Avatar>
                          ))}
                        </AvatarGroup>

                      </Box>

                    </Grid2>
                  ):(
                    <Grid2 size={{xl:4, md:6}} mt={2}></Grid2>
                  )}
                  <Grid2 size={3} mt={2}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        options={IndicationMenuItems}
                        getOptionLabel={(option) => option.label || ''} // Display the label for each option
                        value={IndicationMenuItems.find((item) => item.value === formValues.indication) || null} // Sync with formValues.indication
                        onChange={(event, newValue) => {
                          handleSelectChange({ target: { name: 'indication', value: newValue?.value || '' } });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Indication"
                            placeholder="Select Indication"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: Boolean(formValues.indication || params.inputProps.value), // Shrink if there's a value or user input
                            }}
                            fullWidth
                          />
                        )}
                        isOptionEqualToValue={(option, value) => option.value === value?.value} // Ensure proper equality check
                      />
                      <FormHelperText>
                        {/* Add any validation messages here */}
                      </FormHelperText>
                    </FormControl>
                    {errors?.indication && <FormHelperText error>{errors.indication}</FormHelperText>}

                  </Grid2>
                  <Grid2 size={3} mt={2}>
                    <TextField
                      label="Notes"
                      multiline
                      variant="outlined"
                      fullWidth
                      name="notes"
                      value={formValues.notes}
                      onChange={handleInputChange}
                      placeholder="Type here"
                    />

                    {errors?.notes && <FormHelperText error>{errors.notes}</FormHelperText>}

                  </Grid2>

                  <Grid2 size={12}>
                    <hr></hr>
                  </Grid2>
                  <Grid2 size={12} display={'flex'} justifyContent={'end'}>
                    <Grid2 size={12} my={2} display={'flex'} justifyContent={'end'}>
                      <Button
                        type="button"
                        onClick={onBack}

                        sx={{
                          width: '7.5rem',
                          height: '2.875rem',
                          border: '1px solid #505050',
                          backgroundColor: 'white',
                          color: '#505050',
                          fontFamily: 'Montserrat, sans-serif',
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '22px',
                          textAlign: 'left',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          borderRadius: '10px',
                          marginRight: '20px',
                          textTransform: 'none',

                          '&:hover': {
                            backgroundColor: '#f5f5f5', // Slight hover effect for better UX
                          },
                        }}
                      >
                    Cancel
                      </Button>
                      <Button sx={{
                        height: '2.875rem',
                        minWidth:'7.5rem',
                        gap: '0.625rem',
                        borderRadius: '0.625rem',
                        backgroundColor: '#505050',
                        color: 'white',
                        opacity: 1,
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '22px',

                        transition: 'opacity 0.3s ease',
                        '&:hover': {
                          backgroundColor: '#505050', 
                          opacity: 0.9, 
                        },
                      }}variant="contained" onClick={handleSave}>
                    Add Procedures
                      </Button>
                    </Grid2>
                  </Grid2>
                </Grid2>
          
                <Dialog open={openLinkDialog}
                  onClose={handleCloseDialog}
                  PaperProps={{
                    style: {
                      maxWidth: '1000px', // Set the desired width
                      minWidth: '700px',
                      borderRadius: '25px', // Set the desired border radius
                    },
                  }}
                >
                  <LinkOocyteDonor
                    dialogTitle={dialogTitle}
                    prevDonorInfo={PrevDonorInfo.length > 0 ? PrevDonorInfo : undefined} // Pass PrevDonorInfo if available
                    onClose={(data) => {
                      if (data && data.donorIds) {
                        handleCloseLinkDialog({ donorIds: data.donorIds, dialogTitle: dialogTitle });
                      } else {
                        handleCloseLinkDialog({ donorIds: [], dialogTitle: dialogTitle });
                      }
                    }}
                  />
                </Dialog>
              </>  
            )}
          </div>
        
        ) : activeStep === 1 ? (
          <div>
            <Box>
              <Grid2 container sx={{ width: '100%' }}>

                {/* Search Field */}
                <Grid2 offset={6} size={4} justifyContent="flex-end"  sx={{
                  alignSelf: 'flex-end', // Align this specific item to the bottom of the container
                }} >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Search"
                    placeholder="Search by Procedure, Services, code"
                    size="small"
                    sx={{
      
                      '& .MuiInputBase-root': {
                        height: 46, // Adjust height here
                      },
                    }}

                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Grid2>

                {/* Cart Button */}
                <Grid2 size={2}>
                  <div   style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', marginRight:'15px' }}>
                    {/* Notification Badge and Cart Button */}
                    <div>
                      <span
                        style={{
                          position: 'relative',
                          top: '25px',
                          left: '35px',
                          width: '20px',
                          height: '20px',
                          backgroundColor: '#f00',
                          color: '#fff',
                          borderRadius: '50%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          zIndex: 1111,
                        }}
                      >
                        {selectedProcedures.length}
                      </span>
                      <Button
                        onClick={handleToggleOverlay}
                        style={{
                          width: '55px',
                          height: '53px',
                          border: 'solid 1px #E2E3E5',
                          borderRadius: '15px',
                          padding: '0px',
                          zIndex: 1000,
                          backgroundColor: '#FFF',
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
                        }}
                      >
                        <img src={cart} alt="Cart Icon" />
                      </Button>
                    </div>

                    {/* Overlay */}
                    {showOverlay && (
                      <Paper
                        className={styles.overlayFadeIn}
                        elevation={4}
                        style={{
                          position: 'absolute',
                          top: '85px',
                          right: '0',
                          width: '400px',
                          backgroundColor: '#E2E3E5',
                          borderRadius: '10px',
                          padding: '16px',
                          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
                          zIndex: 2000,
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '20px',
                            width: '0',
                            height: '0',
                            borderLeft: '10px solid transparent',
                            borderRight: '10px solid transparent',
                            borderBottom: '10px solid #E2E3E5',
                            zIndex: 2001,
                          }}
                        ></div>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: '10px',
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Montserrat',
                              fontSize: '18px',
                              fontWeight: 700,
                            }}
                          >
      Orders ({selectedProcedures.length})
                          </Typography>
                          <img src={CloseCircle} alt="close"  onClick={handleToggleOverlay}/>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: '#FFF',
                            borderRadius: '10px',
                            padding: '10px',
                            maxHeight: '250px',
                            overflow:'auto'
                          }}
                        >
                          {selectedProcedures.length > 0 ? (
                            <Box>

                              {selectedProcedures.length > 0 && (
                                <Grid2 size={12}  sx={{
                                  padding:'16px'
                                }}  >
                                  <Autocomplete
                                    options={doctors}
                                    getOptionLabel={(option) => `Dr. ${option.first_name} ${option.last_name}`}
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        handleDoctorChange(newValue.id); // Passes the doctor ID
                                      } else {
                                        handleDoctorChange(-1); // Pass a default ID or handle null as needed
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Doctor"
                                        variant="outlined"
                                        error={doctorError} // Show error state
                                        helperText={doctorError ? 'Doctor is required' : ''} // Error message
                                        fullWidth
                                      />
                                    )}
                                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                            
                                  />
                                </Grid2>
                              )}
                              {selectedProcedures.map((procedure, index) => (
                                <Box
                                  key={index}
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  py={2}
                                  px={1}
                                  pr={0}
                                  borderRadius="8px"
                                  border="1px solid grey"
                                  boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                  bgcolor="#fff"
                                  m={2}
                                >
                                  <Grid2 container spacing={2}>
                                    <Grid2 size={10} display="flex" justifyContent="start" alignItems="center">
                                      <p>{procedure.name}</p>
                                      <p style={{ paddingLeft: '10px' }}>({procedure.code})</p>
                                    </Grid2>
                                    <Grid2  size={2} display="flex" justifyContent="end"> 
                                      <Button onClick={() => handleRemoveService(index)}>
                                        <img
                                          src={closeCircleRed}
                                          alt="Close Icon"
                                          style={{ width: '20px', height: '20px' }}
                                        />
                                      </Button>
                                    </Grid2>
                                  </Grid2>
                                </Box>
                              ))}
                            </Box>
                          ) : (
                            <Typography style={{ fontSize: '14px', color: '#888' }}>
        No procedures selected.
                            </Typography>
                          )}
                        </Box>
                        <Box>
                          <div className="d-flex justify-content-end">
                            <button
                              onClick={handleOpenModal}
                              style={{
                                padding: '5px 10px',
                                border: 'none',
                                borderRadius: '7px',
                                fontSize: 'larger',
                                fontWeight: '700',
                                marginTop: '15px',
                                backgroundColor: '#505050',
                                color: '#FFF',
                              }}
                              disabled={selectedProcedures.length === 0}
                            >
Proceed
                            </button>
                          </div>
                        </Box>
                      </Paper>
                    )}
                  </div>
                </Grid2>

              </Grid2>

              {filteredProcedures.length > 10 &&(

                <Grid2 container spacing={2} my={1} mt={4} mx={3} display={'flex'} justifyContent={'flex-end'}>

                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '20.3px',
                      textAlign: 'left',
                      textDecorationLine: 'underline',
                      textDecorationStyle: 'solid',
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      color:'#5A8AEA',
                      cursor:'pointer'
                    }}

                    onClick={() => setShowAll(!showAll)} 
                  >
                    {showAll ? 'Show Less' : 'View All'}
                  </p>
                </Grid2>
              )}

              <Grid2 
                container 
                spacing={2} 
                m={2}
              >
                {displayedProcedures.length === 0 &&searchQuery.length>0 ? (
                  <Grid2
                    size={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <p style={{ fontSize: '18px', color: '#666', fontWeight: 600 }}>
      No procedures found for the searched term.
                    </p>
                  </Grid2>
                ) : (
                  displayedProcedures.map((cycle, index) => {
                    const isOrderAdded = selectedProcedures.some((procedure) => procedure.id === cycle.id);
                    const delay = `${index * 0.06}s`;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const isHovered = hoveredButton === cycle.title;

                    return (
                      <Grid2
                        key={index}
                        size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2.4 }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <Paper
                          elevation={3}
                          style={{ animationDelay: delay }}
                          className={`${styles.card} fadein`}
                          sx={{
                            padding:'10px',
                            textAlign: 'center',
                            borderRadius: 2,
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                        >
                          <Grid2 container sx={{ flexGrow: 1 }}>
                            {/* <Grid2 size={2}>
                            <img src={PriscriptionSVG} alt="Cycle" />
                          </Grid2> */}
                            <Grid2 size={9}>
                              <Box pl={1}>
                                <Tooltip title={`(${cycle.code}) ${cycle.name}`} arrow>
                                  <p
                                    style={{
                                      fontFamily: 'Nunito',
                                      fontSize: '14px',
                                      fontWeight: 700,
                                      lineHeight: '24.55px',
                                      textAlign: 'left',
                                      textUnderlinePosition: 'from-font',
                                      textDecorationSkipInk: 'none',
                                      display: '-webkit-box',
                                      WebkitLineClamp: 2,
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '200px',
                                      cursor: 'pointer',
                                    }}
                                  >
  ({cycle.code}) {cycle.name}
                                  </p>
                                </Tooltip>
                              </Box>
                            </Grid2>
                            {/* <Grid2 size={12}>
                            <hr style={{ width: '100%' }} />
                          </Grid2> */}
                            <Grid2 size={3} display="flex" justifyContent="flex-end" alignItems="center">
                              <button
                                onClick={() => handleAddProcedure(cycle)}
                                onMouseEnter={() => setHoveredButton(cycle.title)}
                                onMouseLeave={() => setHoveredButton(null)}
                                style={{
                                  padding: '5px 10px',
                                  border: 'none',
                                  borderRadius: '7px',
                                  fontSize: '16px',
                                  fontWeight: '700',
                                  backgroundColor: isOrderAdded
                                    ? '#DEEFE1'
                                    : '#F4F4F4',
                                  color: isOrderAdded ? '#47B35F' : '#232323',
                                  cursor: isOrderAdded ? 'not-allowed' : 'pointer',
                                  width: 'auto',
                                  height:'45px'
                                }}
                                disabled={isOrderAdded}
                              >
                                {!isOrderAdded ? (
                                  <img
                                    src={addCircle}
                                    alt="Add Icon"
                                    style={{ width: '22px', height: '22px' }}
                                  />
                                ) : (
                                  <img
                                    src={addedTick}
                                    alt="Added Icon"
                                    style={{ width: '22px', height: '22px' }}
                                  />
                                )}
                              </button>
                            </Grid2>
                          </Grid2>
                        </Paper>
                      </Grid2>
                    );
                  })
                )}
              </Grid2>

              <Grid2 size={12}>
                <hr></hr>
              </Grid2>
              <Grid2 size={12} my={2} display={'flex'} justifyContent={'end'}>
                <Button
                  type="button"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    cycleDetails.name !== '(OI) Cycle' ? setActiveStep(0) : onBack();
                  }}

                  sx={{
                    width: '7.5rem',
                    height: '2.875rem',
                    border: '1px solid #505050',
                    backgroundColor: 'white',
                    color: '#505050',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '22px',
                    textAlign: 'left',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    borderRadius: '10px',
                    marginRight: '20px',
                    textTransform: 'none',

                    '&:hover': {
                      backgroundColor: '#f5f5f5', // Slight hover effect for better UX
                    },
                  }}
                >
                    Cancel
                </Button>
                <Button sx={{
                  height: '2.875rem',
                  gap: '0.625rem',
                  borderRadius: '0.625rem',
                  backgroundColor: '#505050',
                  color: 'white',
                  opacity: 1,
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '22px',
                
                  transition: 'opacity 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#505050', 
                    opacity: 0.9, 
                  },
                }}
                disabled={selectedProcedures.length === 0}
                variant="contained" onClick={() => {
                  handleOpenModal();
                } }>
                    Mark as done
                </Button>
              </Grid2>
            </Box>
          </div>
        ):''}       

        <ConfirmationModal
          open={openModal}
          handleClose={handleCloseModal}
          onConfirm={handleFinalSave}
          type={'save'}
          title='Place Order'
          contentMessage='Are you sure you want to prescribe selected procedure?'
        /> 
      </ThemeProvider></>

  );
};

export default CreateCycle;