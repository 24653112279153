/* eslint-disable no-console */
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Button, Grid2, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Box, Container, CircularProgress, TextField} from '@mui/material';
import styles from './../oocyteCryo/cryoStorage.module.css';
import { CryoStorageDetail, getEmbryoStorage,  } from '../cryoStorage.helpers';
import StatusChip from '../../../components/customTableComponent/statusChip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmbryoMaterialDetails from './embryoMaterialDetails';
import { CryotechUnit, fetchCanes, fetchCanisters, fetchCryotechs, fetchEmbryoUnits, fetchGoblets, fetchTankData, gradientData, StorageUnit } from '../../Embryology/Embryo_Culture/embryoCulture.helpers';
import { useClinic } from '../../../components/ClinicProvider';
import TankRadio from '../TankRadio';
import CanisterRadio from '../CanisterRadio';
import StorageUnitRadio from '../StorageUnitRadio';
import CryotechComponent from '../CryotechComponent';
import GobletUnit from '../GobletUnit';
import SearchFilterUnits from '../SearchFilterUnits';
import StorageDetails from '../StorageDetails';
import debounce from 'lodash/debounce'; 


const EmbryoStorage = () => {
  const [cryoStorageDetails, setCryoStorageDetails] = useState<CryoStorageDetail[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<number | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<number | null>(null);
  const [tankStoragePlan, setTankStoragePlan]= useState<StorageUnit[]>([]);
  const [selectedTank, setSelectedTank] = useState<number | null>(null);
  const [canisters, setCanisters] = useState<StorageUnit[]>([]);
  const [selectedCanister, setSelectedCanister] = useState<number | null>(null);
  const [canes, setCanes] = useState<StorageUnit[]>([]);
  const [selectedCane, setSelectedCane] = useState<number | null>(null);
  const [goblets, setGoblets] = useState<StorageUnit[]>([]);
  const [selectedGoblet, setSelectedGoblet] = useState<number | null>(null);
  const [cryotechs, setCryotechs] = useState<CryotechUnit[]>([]);
  const { ClinicId } = useClinic();
  const [embryocount, setEmbryocount] = useState(0);
  const [showMaterialSection, setShowMaterialSection] = useState(false);
  const [showTankSection, setShowTankSection] = useState(false);
  const [loadingStorage, setLoadingStorage] = useState(false);
  const [embryoUnits, setEmbryoUnits] = useState<unknown[]>([]); 
  const handleMaterialClick = (cycleID: number, PID?: number) => {
    if (PID) setSelectedPatient(PID);
    setSelectedMaterial(cycleID);
    setShowMaterialSection(true); // Toggle the state
    setShowTankSection(false); // Hide tank section
    setCanisters([]);
    setCanes([]); 
    setGoblets([]); 
    setCryotechs([]);
    setSelectedTank(null);
    setSelectedCanister(null);
    setSelectedCane(null);
    setSelectedGoblet(null);
  };

  const handleTankClick = () => {
    setShowTankSection((prev) => !prev);
    setShowMaterialSection(false); // Hide material section
    setEmbryoUnits([]); 
  };
  
  const latestQuery = useRef(''); // Track latest search query
  const [searchQuery, setSearchQuery] = useState('');

  
  const fetchStorageData = useCallback((search = '') => {
    latestQuery.current = search;
    setLoadingStorage(true);
    getEmbryoStorage(search)
      .then((response) => {
        if (search !== latestQuery.current) return; // Ignore outdated responses
        setCryoStorageDetails(response.objects);
        setEmbryocount(response.objects.length);
      })
      .catch((error) => {
        console.error('Error fetching storage data:', error);
        setCryoStorageDetails([]);
      })
      .finally(() => {
        setLoadingStorage(false);
      });
  }, []); // ✅ Stable reference

  const debouncedFetch = useMemo(() => {
    return debounce((search?: string) => {
      fetchStorageData(search);
    }, 300);
  }, [fetchStorageData]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchQuery(value);
      debouncedFetch(value);
    },
    [debouncedFetch]
  );

  // Fetch data on mount or search query change
  useEffect(() => {
    fetchStorageData(searchQuery);
  }, [searchQuery, fetchStorageData]);


  // Function to close the material section
  const closeMaterialSection = () => {
    setSelectedMaterial(null);
    setShowTankSection(false);
    setShowMaterialSection(false); // Hide material section
    setCanisters([]);
    setCanes([]); 
    setGoblets([]); 
    setCryotechs([]);
    setSelectedTank(null);
    setSelectedCanister(null);
    setSelectedCane(null);
    setSelectedGoblet(null);
  };

  const loadTankStoragePlan = useCallback(() => {
    fetchTankData(Number(ClinicId), 'Embryo')
      .then((res) => {
        const tanks = res.objects || [];
        setTankStoragePlan(tanks);
      })
      .catch((error) => {
        console.error('Error fetching tank storage plan:', error);
      });
  }, [ClinicId]);
  
  const loadCanisters = useCallback((tankId: number) => {
    fetchCanisters(tankId, 1)
      .then((response) => {
        setCanisters(response);
      })
      .catch((error) => {
        console.error('Error fetching canisters:', error);
      })
      .finally(() => {
      });
  }, []);

  
  const loadCanes = useCallback((tankId: number, canisterId?: number) => {
    fetchCanes(tankId, 2, canisterId??0)
      .then((response) => {
        // Use toSorted() to avoid mutating the original array
        const sortedCanes = response.toSorted((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') ?? '0', 10);
          const numB = parseInt(b.name?.replace(/\D/g, '') ?? '0', 10);
          return numA - numB;
        });

        setCanes(sortedCanes);
      })
      .catch((error) => {
        console.error('Error fetching canes:', error);
      })
      .finally(() => {
      });
  }, []);
    
    
  const loadGoblets = useCallback((tankId: number, caneId: number) => {
    fetchGoblets(tankId, 3, caneId)
      .then((response) => {
        // Use toSorted() to create a new sorted array without modifying the original
        const sortedGoblets = response.toSorted((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') ?? '0', 10);
          const numB = parseInt(b.name?.replace(/\D/g, '') ?? '0', 10);
          return numA - numB;
        });
        setGoblets(sortedGoblets);
      })
      .catch((error) => {
        console.error('Error fetching goblets:', error);
      })
      .finally(() => {
      });
  }, []);

  
  const loadCryotechs = useCallback((tankId: number, gobletId: number) => {
    fetchCryotechs(tankId, 4, gobletId)
      .then((response) => {
        const sortedCryotechs = response.toSorted((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') || '0');
          const numB = parseInt(b.name?.replace(/\D/g, '') || '0');
          return numA - numB;
        });
        
        setCryotechs(sortedCryotechs);
      })
      .catch((error) => {
        console.error('Error fetching cryotechs:', error);
      })
      .finally(() => {
      });
  }, []);
    
  useEffect(() => {
    loadTankStoragePlan();
  }, [loadTankStoragePlan]);

  const handleTankChange = (tankId: string) => {
    setSelectedTank(Number(tankId));
    loadCanisters(Number(tankId)); 
    setCanisters([]);
    setCanes([]); 
    setGoblets([]); 
    setCryotechs([]);
    setSelectedCanister(null);
    setSelectedCane(null);
    setSelectedGoblet(null);
  };

  const handleCanisterChange = (canisterId: number) => {
    setSelectedCanister(canisterId);
    if (selectedTank && canisterId) {
      loadCanes(selectedTank, canisterId);
    }
    
    setGoblets([]); 
    setSelectedCane(null);
    setSelectedGoblet(null);
    setCryotechs([]);

  };
  
  const handleCaneChange = (caneID: number) => {
    setSelectedCane(caneID);
    if (selectedTank && caneID) {
      loadGoblets(selectedTank, caneID);
    }
    setSelectedGoblet(null);
    setCryotechs([]);
  };

  
  const handleGobletChange = (gobletID: number) => {
    setSelectedGoblet(gobletID);
    if (selectedTank && gobletID) {
      loadCryotechs(selectedTank, gobletID);
    }
    setCryotechs([]); 
  };

  const processResults = (data: unknown[], close: boolean = false) => {    
    if (close) {
      setEmbryoUnits([]); 
    } else {
      setEmbryoUnits(data); 
    }
  };
  
  return (
    <div className={styles.container}>
      <div className="container-fluid">
        <Grid2 container spacing={2} mt={1}  columns={12}>
          {/* Left Side: Radio Buttons */}
          <Grid2
            size={{ xs: 6, sm: showMaterialSection || showTankSection ? 4 : 8, md: showMaterialSection || showTankSection ? 4 : 8 }}
            className="d-flex "
          >
            <Typography variant="h6" sx={{ marginLeft: 2 }}>
             Patient List
            </Typography>
          </Grid2>

          {/* Middle: Tank Button */}
          <Grid2 
            size={{ xs: 3, sm: showMaterialSection || showTankSection ? 3 : 4, md: showMaterialSection || showTankSection ? 3 : 4 }}
            className="d-flex justify-content-end " >
            <Button variant="contained" color="primary" sx={{ fontWeight: 'bold',  height: '38px'}} size="small" onClick={handleTankClick} disabled={showTankSection} >
                Tank 
            </Button>
            <TextField
              label="Search by ID, Name"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ width: '100%',  marginLeft: 2  }} 
              size="small"
            />
          </Grid2>

          {/* Right Side: Materials Section */}
          {showMaterialSection && (
            <Grid2
              size={{ xs: 4.7, sm: 4.7, md: 4.7 }}
              className="d-flex justify-content-start"
              sx={{ ml: 2, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Materials
              </Typography>

              <IconButton onClick={closeMaterialSection} color="secondary" size='medium' >
                <HighlightOffIcon />
              </IconButton>
            </Grid2>            
          )}

          {showTankSection && (
            <Grid2
              size={{ xs: 4.7, sm: 4.7, md: 4.7 }}
              className="d-flex justify-content-start"
              sx={{ ml: 2, display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 1 }}>
                              All Tanks
                <Box
                  sx={{
                    backgroundColor: '#FDECEC',
                    borderRadius: '50%',
                    padding: '4px 10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {tankStoragePlan.length}
                </Box>
              </Typography>
              <IconButton onClick={closeMaterialSection} color="secondary">
                <HighlightOffIcon />
              </IconButton>
            </Grid2>
          )}

          <Grid2 size={{ xs: showMaterialSection || showTankSection ? 7 : 12, sm: showMaterialSection || showTankSection ? 7 : 12, md: showMaterialSection || showTankSection ? 7 : 12 }}>
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <TableContainer sx={{ border: '1px solid rgb(240, 237, 237)', borderRadius: '10px', fontWeight: 'bold',  }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        height: '2rem',
                        background: 'rgba(250, 250, 250, 1)',
                        border: 'none',
                        '& .MuiTableCell-root': {
                          padding: '0.6rem 0.6rem',
                        },
                      }}
                    >
                      {['MRN', 'Patient Name', 'Billed', 'Material', 'Media'].map((title, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontFamily: 'Nunito, sans-serif',
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            lineHeight: '1.45',
                            textAlign: 'left',
                            color: 'rgba(35, 35, 35, 1)',
                            padding: '0.3rem 0.6rem', // Adjust padding
                          }}
                        >
                          {title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
    
                  <TableBody>
                    {loadingStorage ? (
                    // ✅ Show Loader inside TableBody
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
                            <CircularProgress color="secondary" size={50}/>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : cryoStorageDetails.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography variant="h6" color="textSecondary">
                            No data found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      cryoStorageDetails.map((storage, index) => (
                        <TableRow key={index}>
                          <TableCell>{storage.patient_mrn || '-'}</TableCell>
                          <TableCell>{storage.patient_name }  {storage.patient_last_name || '-'}</TableCell>
                          {/* <TableCell>{storage.cycle_number || '-'}</TableCell> */}
                          <TableCell>
                            <StatusChip value="Pending" height={20} />
                          </TableCell>
                          <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleMaterialClick(storage.cycle, storage?.patient_id || undefined)}>
                            <span
                              className={`${styles.pointerData} ${
                                selectedMaterial === storage.cycle ? styles.highlight : ''
                              }`}
                            >
                              {storage.material_count || '-'}
                            </span>
                          </TableCell>
                          <TableCell>{storage.cryo_media || '-'}</TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer> 
            </div>
          </Grid2>
          
          {/* Right Side: Materials Section */} 
          {showMaterialSection && (
            <Grid2 size={{ xs: 12, sm: 5, md: 5 }} sx={{ marginTop: 0 }}>
              <EmbryoMaterialDetails selectedCycleID={selectedPatient}   />
            </Grid2>          
          )}         
          
          {showTankSection && (
            <Grid2 size={{ xs: 12, sm: 5, md: 5 }} sx={{ marginTop: 0 }}>
              <Box 
                sx={{ 
                  width: '95%',  
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  margin: '0 auto',  
                  marginBottom: '15px'
                }}
              >
                <SearchFilterUnits fetchData={fetchEmbryoUnits} onResults={processResults} />
              </Box>


              <Container sx={{ mt: 4 }}>
                {embryoUnits.length > 0 && 
                  embryoUnits.map((unit, index) => (
                    <Box key={index}>
                      <StorageDetails
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        locationDetails={(unit as any)?.location_details ?? { parents: [] }}
                      />
                    </Box>
                  ))
                }
              </Container>
    
              {embryoUnits.length <= 0 && (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: 1.5,
                      width: 'auto',
                      height: 'auto',
                      margin: '5px auto',
                      paddingX: '5px',
                      alignItems: 'start',
                    }}
                  >
                    <TankRadio
                      tanks={tankStoragePlan}
                      selectedTank={selectedTank}
                      onChange={handleTankChange}
                    />
                    <CanisterRadio
                      canisters={canisters}
                      selectedCanister={selectedCanister}
                      onChange={handleCanisterChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: 1.5,
                      width: 'auto',
                      margin: '5px auto',
                      paddingX: '5px',
                      alignItems: 'start',
                    }}
                  >
                    <StorageUnitRadio
                      units={canes}
                      selectedUnit={selectedCane}
                      onChange={handleCaneChange}
                      type="cane"
                    />
                    <GobletUnit
                      units={goblets}
                      selectedUnit={selectedGoblet}
                      onChange={handleGobletChange}
                      type="goblet"
                    />
                  </Box>
                  <CryotechComponent cryotechs={cryotechs} gradientData={gradientData} />
                </>
              )}

            </Grid2>
          )}          
        </Grid2>
        
        
        <Box sx={{ mt: 3, mb: 3, borderBottom: '1px solid #ddd' }} />

        <Grid2 container spacing={2} alignItems="center" className="mt-4" >
          {/* Total Count */}
          <Grid2 size={{ xs: 12, sm: 3 , md: 3 }} className="d-flex justify-content-start" sx={{ marginBottom: 2 }}>
            <span className={styles.searchby}>Total Count: {embryocount}</span>
          </Grid2>

          {/* Status Indicators */}
          <Grid2 size={{ xs: 12, sm: 9, md: 9 }} className="d-flex justify-content-end" sx={{ marginBottom: 2 }}>
            {[
              { color: '#47B35F', label: 'Complete', count: 0 },
              { color: '#B0A588', label: 'Billing Due', count: 0 },
              { color: '#9B3434', label: 'Overdue', count: 0 },
              { color: '#EFAE78', label: 'Near Expiry', count: 0 },
              { color: '#F25B5B', label: 'Expired', count: 0 },
            ].map((item, index) => (
              <div key={index} className="d-flex align-items-center" style={{ marginRight: '16px' }}>
                <div className={styles.circle} style={{ backgroundColor: item.color }}></div>
                <span className={styles.completeText} style={{ marginLeft: '8px' }}>
                  {item.label} {item.count}
                </span>
              </div>
            ))}
          </Grid2>
        </Grid2>

      </div>
    </div>
  );
};

export default  EmbryoStorage; 

