/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useMemo, useState} from 'react';
import { TextField, Grid, Tooltip, IconButton, Button } from '@mui/material';
import addCircle from '../../../assets/SVG/add-black.svg';
import deleteBtn from '../../../assets/SVG/trash.svg';
import editBtn from '../../../assets/SVG/PencilSimple.svg';
import styles from './DrugsHome.module.css';
import InfoIcon from '@mui/icons-material/Info';
import { ColumnConfig, GroupConfig, TableRowData } from '../../../types';
import { useLoader } from '../../loaderProvider/loader';
import AddDrugs from './AddDrugs';
import EditableTable from '../../customTableComponent';
import { deletePatientDrugs, getPatientDrugs, Prescription } from './DrugsHomeHelper';
import { useParams } from 'react-router-dom';
import { ConfirmationModal } from '../../confirmationModal';
import { useAlert } from '../../alertProvider';
import { convertUnderscoreToTitle } from '../../../utils/commonUtils';
import AddIcon from '@mui/icons-material/AddCircleOutline';


const columns: ColumnConfig[] = [
  { field: 'drugName', headerName: 'Drug Name', type: 'text', editable: false },
  { field: 'genericName', headerName: 'Generic Name', type: 'text', editable: false },
  { field: 'strength', headerName: 'Strength', type: 'text', editable: false },
  { field: 'UOM', headerName: 'UOM', type: 'text', editable: false },
  { field: 'route', headerName: 'Route', type: 'text', editable: false },
  { field: 'frequency', headerName: 'Frequency', type: 'text', editable: false },
  { field: 'instructions', headerName: 'Instructions', type: 'text', editable: false },
  { field: 'days', headerName: 'Day(s)', type: 'text', editable: false },
  { field: 'qty', headerName: 'Qty', type: 'text', editable: false },
  { field: 'notes', headerName: 'Notes', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'action', headerName: 'Action', type: 'text', editable: false },
];

const groupConfig: GroupConfig[] = [
  { fields: ['drugName'], type: 'column' },
  { fields: ['genericName'], type: 'column' },
  { fields: ['strength'], type: 'column' },
  { fields: ['UOM'], type: 'column' },
  { fields: ['route'], type: 'column' },
  { fields: ['frequency'], type: 'column' },
  { fields: ['instructions'], type: 'column' },
  { fields: ['days'], type: 'column' },
  { fields: ['qty'], type: 'column' },
  { fields: ['notes'], type: 'column' },
  { fields: ['doctor'], type: 'column' },
  { fields: ['action'], type: 'column' },
];

const DrugsHome: React.FC = () => {
  const [data, setData] = useState<TableRowData[]>([]);
  const [dataSize, setDataSize] = useState<number>();
  const [showAddDiagnosis, setShowAddDiagnosis] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { showLoader, hideLoader } = useLoader();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [selectedDrugId, setSelectedDrugId] = useState<number | null>(null);
  const { id } = useParams<{ id: string }>(); 
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 
  const { addAlert } = useAlert();
  const [isDrugDeleted, setIsDrugDeleted] = useState(false);


 
  const handleDelete = (id: number) => {
    setSelectedDrugId(id);
    setOpenConfirmDialog(true);
    // Implement delete logic here
  };


  const handleEdit = (rowData: Prescription) => {
    console.log('Editing row:', rowData);
    setSelectedData(rowData);
    setShowAddDiagnosis(true);
    // Implement edit logic here
  };


  const handleSubmit =()=>{
    showLoader();

    console.log('Deleting row with ID:', selectedDrugId);
    if (selectedDrugId) {
      deletePatientDrugs(selectedDrugId.toString()).then((res)=>{
        addAlert('Drug deleted Successfully', 'success');
        setIsDrugDeleted(true);
      })
        .catch((error) => {
          console.error('Error deleting selected Drug', error);
          addAlert('Error deleting selected Drug', 'error');
          
        })
        .finally(() => {
          hideLoader();
        });
    }
  };

  useEffect(() => {
    showLoader();
    getPatientDrugs(`?patient=${id}`)
      .then((res) => { 
        console.log(res);
        
        const tableData: TableRowData[] = res.objects.map((item) => {
          const createdOnDate = item.created_on ? new Date(item.created_on) : null;
          const isDisabled = createdOnDate
            ? (new Date().getTime() - createdOnDate.getTime()) / (1000 * 60 * 60) > 24
            : false;
        
          return {
            id: item.id,
            drugName: item.drug.brand_name,
            genericName:
              item.drug.molecule.length > 13 ? (
                <Tooltip title={item.drug.molecule}>
                  <span
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      maxWidth: '150px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.drug.molecule.substring(0, 13)}...
                  </span>
                </Tooltip>
              ) : (
                item.drug.molecule
              ),
            strength: item.drug.strength ? item.drug.strength : '-',
            UOM: item.drug.stock_uom_code,
            route: item.drug_route_code,
            frequency: item.frequency,
            instructions: item.instructions
              ? item.instructions.length > 13
                ? (
                  <Tooltip title={item.instructions}>
                    <span
                      style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        maxWidth: '150px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.instructions.substring(0, 13)}...
                    </span>
                  </Tooltip>
                )
                : convertUnderscoreToTitle(item.instructions)
              : '-',
            days: item.no_of_days,
            qty: item.quantity,
            action: (
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem',cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
                {/* Edit Button */}
                <Tooltip title={isDisabled ? 'Cannot edit past 24 hours' : 'Edit'}>
                  <span>
                    <IconButton
                      onClick={() => !isDisabled && handleEdit(item)}
                      disabled={isDisabled}
                      style={{
                        color: isDisabled ? '#B0B0B0' : '#E17C64',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                      }}
                      size="small"
                    >
                      <img src={editBtn} alt="edit" />
                    </IconButton>
                  </span>
                </Tooltip>
        
                {/* Delete Button */}
                <Tooltip title={isDisabled ? 'Cannot delete past 24 hours' : 'Delete'}>
                  <span>
                    <IconButton
                      onClick={() => !isDisabled && handleDelete(item.id)}
                      disabled={isDisabled}
                      style={{
                        color: isDisabled ? '#B0B0B0' : '#FF0000',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                      }}
                      size="small"
                    >
                      <img src={deleteBtn} alt="delete" />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            ),
            doctor: `Dr. ${item.user_first_name}`,
            notes: item.notes
              ? item.notes.length > 13
                ? (
                  <span
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      maxWidth: '200px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Tooltip title={item.notes}>
                      <span>
                        <InfoIcon fontSize="small" />
                      </span>
                    </Tooltip>{' '}
                    &nbsp;
                    {item.notes.substring(0, 45)}...
                  </span>
                )
                : (
                  <span>{item.notes}</span>
                )
              : '-',
          };
        });
        
        setDataSize(res.meta.total_count);
        setData(tableData);
        
      })
      .catch((error) => {
        console.error('Error fetching patient diagnosis:', error);
      })
      .finally(() => {
        hideLoader();
        setIsDrugDeleted(false); 
      });
    // eslint-disable-next-line
  }, [id, isDrugDeleted, showAddDiagnosis, showLoader, hideLoader,]);


  const filteredData = useMemo(
    () => data.filter(
      (row) =>
        row.drugName.toLowerCase().includes(searchTerm.toLowerCase())
    ),
    [searchTerm, data]
  );

  const handleOnChange = (id: number, field: string, value: string) => {

  };
  


  const handleAddClick = () => {
    setSelectedData(null);
    setShowAddDiagnosis(true);
  };

  const handleBackClick = () => {
    setShowAddDiagnosis(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  return (
    <div className={`${styles.home} mt-4 p-3`} style={{ display: 'flex', flexDirection: 'column' }}>
      {showAddDiagnosis ? (
        <AddDrugs onBackClick={handleBackClick} data={selectedData} />
      ) : (
        <>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
              <TextField
                id="outlined-basic"
                label="Search Medicine"
                placeholder='Search by Drug Name, Generic Name'
                value={searchTerm}
                onChange={handleSearchChange}
                variant="outlined" fullWidth />
            </Grid>
            <Grid item xs />
            <Grid item xs={12} sm="auto">

              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: 'none', fontWeight: 'bold', width: '100%' }}
                startIcon={<AddIcon />}
                onClick={handleAddClick} 
                style={  {padding: '5px 25px', border: 'none', borderRadius: '7px', fontSize:'larger', fontWeight:'700'}}
                
              >
            Add
              </Button>
              {/* <button onClick={handleAddClick}  src={addCircle} alt='add'/>   ADD
              </button> */}
            </Grid>
          </Grid>

          <div className="mt-4" style={{ flexGrow: 1 }}>


            {filteredData.length === 0 ? (
              <div style={{textAlign:'center', fontSize:'18px'}}>No Drugs prescribed, click add to Prescribe Drugs</div>
            ) : (
              <EditableTable
                columns={columns}
                data={filteredData}
                actionType="none"
                // onAction={handleAction}
                onChange={handleOnChange}
                groupConfig={groupConfig}
                dataSize={dataSize}
              />
            )}
           
          </div>

          <ConfirmationModal
            open={openConfirmDialog}
            handleClose={()=>{setOpenConfirmDialog(false);}}
            onConfirm={() => {
              handleSubmit();
            }}
            type="save"
            title="Delete Drug"
            contentMessage='Are you sure you want to delete prescribed Drug?'
          />
        </>
      )}
    </div>
  );
};

export default DrugsHome;
