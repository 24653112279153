import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context data
interface MultilingualContextType {
    currentLanguage: string;
    setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

// Define the props for the provider component
interface MultilingualProviderProps {
    language: string;
    children?: ReactNode;
}

// Create the context
const MultilingualContext = createContext<MultilingualContextType | undefined>(undefined);

// Create a provider component
export const MultilingualProvider: React.FC<MultilingualProviderProps> = ({ language, children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(language);

  const contextValue = React.useMemo(
    () => ({ currentLanguage, setLanguage: setCurrentLanguage }),
    [currentLanguage]
  );

  return (
    <MultilingualContext.Provider value={contextValue}>
      {children}
    </MultilingualContext.Provider>
  );
};

// Custom hook to use the MultilingualContext
export const useMultilingualContext = (): MultilingualContextType => {
  const context = useContext(MultilingualContext);
  if (!context) {
    throw new Error('useMultilingualContext must be used within a MultilingualProvider');
  }
  return context;
};
