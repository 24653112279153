/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import styles from './appointmentModal.module.css';
import { ReactComponent as More } from '../../../src/assets/SVG/close-circle-greay.svg';
import { useAlert } from '../alertProvider';
import { ConfirmationModal } from '../confirmationModal';
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Tabs, Tab, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, InputAdornment, Autocomplete, FormHelperText, CircularProgress, FormLabel, RadioGroup, Radio, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ReactComponent as SearchIcon } from '../../../src/assets/SVG/Search-Icon.svg'; // Ensure correct path and use ReactComponent for SVG
import {
  fetchPatientList,
  getPatientDetails,
  appointmentReasons,
  useDebounce,
  postBookAppointment,
  getGender,
  postPatientPartnerInfo,
  getWorkingHours,
  getcountrycode,
  getRooms,
  getOTtables,
  departmentsByClinic,
  getUsersByClinic,
  getUserDetailsById,
  clinic,
  getUsersByClinicAndTime
} from '../../services/apiService';
import {
  Patient,
  Patientv2,
  AppointmentReasons,
  userListRoleWise,
  AppointmentFormErrorsType,
  bookSlotPatientData,
  Gender,
  AppointmentModalProps,
  ValidationErrors1,
  DepartmentDetail,
  UserBasic
} from '../../types';
import PatientDetails from './PatientDetails/patientDetails';
import PreviousVisits from './PreviousVisits/previousHistory';
import theme from '../../utils/theme';
import { convertDateToISOWithOffset, formatDateToISOWithOffsetFilter } from '../../services/time';
import { useClinic } from '../ClinicProvider';
import { LabeledWithAsterisk, RequiredLabel, timeDifference, toTitleCase, validateEmail } from '../../utils/commonUtils';
import registrationTypesEnum from '../../mocks/registrationTypesEnum';
import { date } from 'yup';


export interface PatientData {
  firstName: string;
  lastName: string;
  gender: string;
  mobile: string;
  dob: string;
  email: string;
  mobile_country_code?: string,
}

export interface PartnerData extends PatientData {
  isPartnerSelected: boolean;
}


export default function AppointmentModal({
  resource,
  open,
  onClose,
  slotDate,
  slotDepartment,
  departmentName = 'Admin',
  departmentTabs, 
  handleTabChange,
}: AppointmentModalProps) {
  const convertToDate = (date: Date | string): Date => {
    return typeof date === 'string' ? new Date(date) : date;
  };

  const formattedSlotDate = convertToDate(slotDate || new Date());
  const [appointmentDate, setAppointmentDate] = useState<Date>(formattedSlotDate || new Date());

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatTime = (date: Date): string => {
    return date.toTimeString().split(':').slice(0, 2).join(':');
  };

  const addMinutes = (date: Date, minutes: number): Date => {
    return new Date(date.getTime() + minutes * 60000);
  };
  const [timeFrom, setTimeFrom] = useState<string>(formatTime(formattedSlotDate));
  const [timeTo, setTimeTo] = useState<string>(formatTime(addMinutes(formattedSlotDate, 15)));

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validates only one "@" and no spaces

  const [selectedDepartment, setSelectedDepartment] = useState(slotDepartment || '');
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isPatientSelected, setIsPatientSelected] = useState(false);
  const [patientSearchTerm, setPatientSearchTerm] = useState('');
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedPatientDetails, setSelectedPatientDetails] = useState<Patientv2 | null>(null);
  const [departmentList, setDepartmentList] = useState<DepartmentDetail[]>([]);
  const [appointmentReasonsList, setAppointmentReasonsList] = useState<AppointmentReasons[]>([]);
  const [roleList, setRoleList] = useState<UserBasic[]>([]);
  const [availableRoleList, setAvailableRoleList] = useState<UserBasic[]>([]);
  // const [finalRoleList, setFinalRoleList] = useState<UserBasic[]>([]);
  const [selectedAppointmentReason, setSelectedAppointmentReason] = useState<number | undefined>(undefined);
  const [selectedPersonnel, setSelectedPersonnel] = useState<UserBasic | undefined>(undefined);
  // const [inputValue, setInputValue] = useState('');
  // const debouncedInputValue = useDebounce(inputValue, 300); // Debounce for 300ms

  useEffect(() => {
    if (resource){
      const id = Number(resource);
      if (!Number.isNaN(id)) {
        getUserDetailsById(Number(resource)).then((user: UserBasic) => setSelectedPersonnel(user));
      }
    }
  }, [resource]);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const currentDate = new Date().toISOString().split('T')[0];
  const [remark, setRemark] = useState('');
  const [genders, setGenders] = useState<Gender[]>([]);
  const [checkboxState, setCheckboxState] = useState({
    patient: true,
    partner: false,
    oocyteDonor: false,
    spermDonor: false,
    surrogate: false
  });

  const [patientType, setPatientType] = useState('PATIENT_SINGLE');
  const [formErrors, setFormErrors] = useState<AppointmentFormErrorsType>({
    department: '', personnel: '', appointmentReason: '', appointmentDate: '',
    timeFrom: '', timeTo: '', remark: '', room:'', table:'  '
  });

  const [countryCodes, setCountryCodes] = useState<any[]>([]);
  const [filteredCountryCodes, setFilteredCountryCodes] = useState<any[]>([]);
  const [room, setRoom] = useState<any[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<number | undefined>(undefined);
  const [table, setTable] = useState<any[]>([]);
  const [selectedTable, setSelectedTable] = useState<number | undefined>(undefined);

  const requiredFields: (keyof AppointmentFormErrorsType)[] = ['department','personnel','appointmentReason',
    'appointmentDate','timeFrom','timeTo'];

  const isSurgeryDepartment = departmentList.some(department => department.id === slotDepartment && department.name === 'Surgery');
  if (isSurgeryDepartment) {
    departmentName = 'Surgery';
    requiredFields.push('room', 'table');
  }

  const debouncedSearchTerm = useDebounce(patientSearchTerm, 300);

  const validateForm = () => {
    setIsFormSubmitted(true);
    const newErrors: AppointmentFormErrorsType = {};
    requiredFields.forEach((field) => {
      const fieldValue = {
        department: selectedDepartment,
        personnel: selectedPersonnel,
        appointmentReason: selectedAppointmentReason,
        appointmentDate: appointmentDate,
        timeFrom: timeFrom,
        timeTo: timeTo,
      }[field];
      if (!fieldValue) {
        if (departmentName === 'Surgery' && (field === 'room' || field === 'table')) {
          return;
        }
        newErrors[field] = `${field} is required`;
      }
    });
    if (departmentName === 'Surgery') {
      if (!selectedRoom) {
        newErrors['room'] = 'Room is required for Surgery department';
      }
      if (!selectedTable) {
        newErrors['table'] = 'Table is required for Surgery department';
      }
    }

    if (tabIndex === 0 && !isPatientSelected) {
      newErrors['patient'] = 'Please select a patient';
    }

    if (!selectedPersonnel || selectedPersonnel.id === -1) {
      newErrors['personnel'] = 'Please select a personnel';
    }

    if (timeFrom && timeTo && timeTo <= timeFrom) {
      newErrors['timeTo'] = 'Time To must be greater than Time From';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const bookAppointment = (patientData: any, patientId: number | undefined) => {
    const startDateTime = convertDateToISOWithOffset( new Date(`${formatDate(appointmentDate)} ${timeFrom}:00`));
    const endDateTime = convertDateToISOWithOffset( new Date(`${formatDate(appointmentDate)} ${timeTo}:00`));
    const payload = {
      user: selectedPersonnel?.id,
      department: selectedDepartment,
      appointment_date: formatDate(appointmentDate),
      start_datetime: startDateTime,
      end_datetime: endDateTime,
      appointment_reason: selectedAppointmentReason,
      ...(Object.keys(patientData || {}).length === 0
        ? { patient: patientId }
        : {
          patient_data: {
            clinic_id: ClinicId,
            first_name: patientData?.first_name,
            last_name: patientData?.last_name,
            middle_name: null,
            date_of_birth: patientData?.date_of_birth,
            blood_group: null,
            gender: patientData?.gender,
            mobile_number: patientData?.mobile_number,
            mobile_number_country_code: patientData?.mobile_number_country_code,
            patient_type: patientData?.patient_type,
            alternate_email: patientData?.alternate_email,
          },
        }),
      ...(patientData?.partner?.first_name && {
        partner_data: {
          clinic_id: ClinicId,
          first_name: patientData.partner.first_name,
          last_name: patientData.partner.last_name,
          middle_name: null,
          date_of_birth: patientData.partner.date_of_birth,
          blood_group: null,
          gender: patientData.partner.gender,
          mobile_number: patientData.partner.mobile_number,
          mobile_number_country_code: patientData.partner.mobile_number_country_code,
          alternate_email: patientData?.alternate_email,
          // patient_type: patientData.partner.patient_type
        },
      }),
      remark: remark,
      room: null,
      table: null,
    };
    
    

    postBookAppointment(payload)
      .then((response) => {
        addAlert('Appointment booked successfully!', 'success');
        onClose();
      })
      .catch((error) => {
        if (error.response) {
          const validationErrors = error.response.data.errors;
          validationErrors.forEach((errorDetail: { detail: any; }) => {
            const errorMessage = errorDetail.detail;
            addAlert(errorMessage, 'error');
          });
        } else {
          addAlert('Network error. Please check your connection and try again.', 'error');
        }
      });
  };

  const [newPatientData, setNewPatientData] = useState<bookSlotPatientData>({
    firstName: '',
    lastName: '',
    gender: 'FEMALE',
    mobile: '',
    dob: '',
    email: '',
    mobile_country_code: '',
  });

  const [partnerData, setPartnerData] = useState<PartnerData>({
    firstName: '',
    lastName: '',
    gender: '',
    mobile: '',
    dob: '',
    email: '',
    mobile_country_code: '',
    isPartnerSelected: false, // Manage partner checkbox
  });
  const [errors, setErrors] = useState<ValidationErrors1>({});
  const resetFormFields = () => {
    setPartnerData({
      firstName: '',
      lastName: '',
      gender: 'MALE',
      mobile_country_code: '',
      mobile: '',
      dob: '',
      email: '',
      isPartnerSelected: false,
    });
    setNewPatientData({
      firstName: '',
      lastName: '',
      gender: 'FEMALE',
      mobile_country_code: '',
      mobile: '',
      dob: '',
      email: '',
    });
    setErrors({});
    setFormErrors({
      personnel: '',
      appointmentReason: '',
      remark: ''
    });
    setSelectedPersonnel(undefined);
    setSelectedAppointmentReason(undefined);
    setSelectedRoom(undefined);
    setSelectedTable(undefined);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedPatientDetails(null);
    setTabIndex(newValue);
    resetFormFields();
  };

  const fetchDepartments = useCallback(() => {
    departmentsByClinic(ClinicId)
      .then((data) => {
        // Filter out the departments that are not in the departmentTabs
        const filterDepartmentList: DepartmentDetail[] = (departmentTabs || []).filter((department) => data.objects.some((dept) => dept.id === department.id)); 
        setDepartmentList(filterDepartmentList);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
  }, [ClinicId, departmentTabs]);


  const fetchDepartmentsRooms = () => {
    getRooms()
      .then((data) => {
        setRoom(data.objects);
      })
      .catch((error) => {
        console.error('Error fetching departments room:', error);
      });
  };

  const fetchOTtables = (roomID = '') => {
    const query = roomID ? `roomID=${roomID}` : '';
    getOTtables(query)
      .then((data) => {
        setTable(data.objects);
      })
      .catch((error) => {
        console.error('Error fetching OT tables:', error);
      });
  };

  const fetchAppointmentReasons = useCallback(() => {
    appointmentReasons({ clinicId: Number(ClinicId), departmentId: Number(selectedDepartment) })
      .then((data) => {
        setAppointmentReasonsList(data.objects);
      })
      .catch((error) => {
        console.error('Error fetching appointment reasons:', error);
      });
  }, [ClinicId, selectedDepartment]);

  const fetchPersonnelByRole = useCallback(() => {
    getUsersByClinic(ClinicId, selectedDepartment.toString())
      .then((res: any) => {
        const personnels = res?.objects ?? [];
        setRoleList(personnels);
      })
      .catch((error) => {
        console.error('Error fetching working hours:', error);
      });

  }, [selectedDepartment, ClinicId]);


  const fetchPatientDetails = (id: string) => {
    getPatientDetails(id)
      .then((details) => {
        setSelectedPatientDetails(details);
        if (details.partner) {
          setCheckboxState({
            patient: true, // Patient is selected
            partner: true, // Check partner if present
            oocyteDonor: false,
            spermDonor: false,
            surrogate: false
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching patient details:', error);
      });
  };


  

  

  const validateFields = () => {
    const  formErrors1: ValidationErrors1 = {};
    if (!newPatientData.firstName) formErrors1.firstName = 'First Name is required';
    if (!newPatientData.lastName) formErrors1.lastName = 'Last Name is required';
    if (!newPatientData.gender) formErrors1.gender = 'Gender is required';
    if (!newPatientData.mobile) formErrors1.mobile = 'Mobile is required';
    if (!newPatientData.dob) formErrors1.dob = 'Date of Birth is required';
    if (!newPatientData.email) formErrors1.email = 'Email is required';
    if (!newPatientData.mobile_country_code) formErrors1.mobile_country_code = 'mobile_country_code is required';
    if (!newPatientData.email) {
      formErrors1.email = 'Email is required';
    } else if (!validateEmail(newPatientData.email)) {
      formErrors1.email = 'Invalid email format';
    }

    if (checkboxState.partner) {
      if (!partnerData.firstName) formErrors1.partnerFirstName = 'Partner First Name is required';
      if (!partnerData.lastName) formErrors1.partnerLastName = 'Partner Last Name is required';
      if (!partnerData.gender) formErrors1.partnerGender = 'Partner Gender is required';
      if (!partnerData.mobile) formErrors1.partnerMobile = 'Partner Mobile is required';
      if (!partnerData.dob) formErrors1.partnerDob = 'Partner Date of Birth is required';
      if (!partnerData.email) formErrors1.partnerEmail = 'Partner Email is required';
      if (!partnerData.mobile_country_code) formErrors1.partner_mobile_country_code = 'Mobile Country Code is required';

      if (!partnerData.email) {
        formErrors1.partnerEmail = 'Email is required';
      } else if (!validateEmail(partnerData.email)) {
        formErrors1.partnerEmail = 'Invalid email format';
      }
    }


    setErrors(formErrors1);

    return Object.keys(formErrors1).length === 0;
  };

  useEffect(() => {
    const updatedDate = convertToDate(slotDate || new Date());
    setAppointmentDate(updatedDate);
    setTimeFrom(formatTime(updatedDate));
    setTimeTo(formatTime(addMinutes(updatedDate, 15)));
  }, [slotDate]);

  useEffect(() => {
    setSelectedDepartment(slotDepartment || '');
  }, [slotDepartment]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      const query = `limit=50&search=${debouncedSearchTerm}`;
      fetchPatientList(query, ClinicId)
        .then((response) => {
          setPatients(response.objects as Patient[]);
          setError('');
        })
        .catch((err) => {
          console.error('Error fetching patients:', err);
          setError('Failed to fetch patients');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setPatients([]);
    }
  }, [debouncedSearchTerm, ClinicId]);

  const fetchGender = () => {
    getGender()
      .then((res) => {
        setGenders(res.objects);
      })
      .catch((error) => {
        console.error('Error fetching gender data:', error);
      });
  };
  
  const fetchCountryCodes = () => {
    getcountrycode()
      .then((res) => {
        setCountryCodes(res.data);
        setFilteredCountryCodes(res.data);
      })
      .catch((error) => {
        console.error('Error fetching country code data:', error);
      });
  };  


  const handleInputChange = (event: any, value: string, reason: string) => {
    if (reason === 'input') {
      const filtered = countryCodes.filter(country =>
        country.name.toLowerCase().startsWith(value.toLowerCase()) ||
        country.dial_code.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCountryCodes(filtered);
    }
  };

  useEffect(() => {
    if (open) {
      fetchDepartments();
      fetchAppointmentReasons();
      fetchPersonnelByRole();
      fetchGender();
      fetchCountryCodes();
    }
  }, [open, fetchPersonnelByRole, fetchDepartments, fetchAppointmentReasons]);

  const fetchPersonnelByRoleAndTime = useCallback(() => {
    const startDateTime = formatDateToISOWithOffsetFilter( new Date(`${formatDate(appointmentDate)} ${timeFrom}:00`));
    const endDateTime = formatDateToISOWithOffsetFilter( new Date(`${formatDate(appointmentDate)} ${timeTo}:00`));
    getUsersByClinicAndTime(ClinicId, selectedDepartment.toString(), startDateTime, endDateTime)
      .then((res: any) => {
        const personnels = res?.objects ?? [];
        setAvailableRoleList(personnels);
      })
      .catch((error) => {
        console.error('Error fetching working hours:', error);
      });
  // eslint-disable-next-line
  }, [selectedDepartment, ClinicId]);

  useEffect(() => {
    if (open) {
      fetchPersonnelByRoleAndTime();
    }
    // eslint-disable-next-line
  }, [timeFrom, timeTo, selectedDepartment]);

  useEffect(() => {
    if (open) {
      setSelectedPatientDetails(null);
      setSelectedPersonnel(undefined);
      setIsPatientSelected(false);
      setIsFormSubmitted(false);
      setSelectedAppointmentReason(undefined);
      setRemark('');
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      resetFormFields();
    }
  }, [open]);

  const updateTimeToBasedOnReason = (value: string, selectedAppointmentReason: number | undefined) => {
    if (selectedAppointmentReason) {
      const reason = appointmentReasonsList.find(({ id }) => id === selectedAppointmentReason);
      if (reason) {
        const timeFromDate = new Date(`${new Date().toISOString().split('T')[0]}T${value}:00`);
        const timeToFormatted = new Date(timeFromDate.getTime() + reason.default_duration * 60000)
          .toTimeString()
          .slice(0, 5);
        setTimeTo(timeToFormatted);
      }
    }
  };

  const handleChange = (field: string, value: any) => {


    if (field === 'remark') {
      setRemark(value);
      return; // Exit early for remark, avoid unnecessary updates
    }

    switch (field) {
    case 'department':
      setSelectedDepartment(value);

      // Clear selected personnel when department changes
      setSelectedPersonnel(undefined);

      if (formErrors.department) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          department: '',
        }));
      }
      if(handleTabChange){
        const departmentTabObject = departmentList.find((department) => department.id === value);
        handleTabChange(departmentTabObject);
      }
      break;
    case 'personnel':
      setSelectedPersonnel(value);
      if (formErrors.personnel) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          personnel: '',
        }));
      }
      break;
    case 'appointmentReason':
      setSelectedAppointmentReason(value);
      if (formErrors.appointmentReason) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          appointmentReason: '',
        }));
      }
      break;
    case 'appointmentDate':
      // setAppointmentDate(value);
      setAppointmentDate(new Date(value)); // Convert 'YYYY-MM-DD' string to Date

      if (formErrors.appointmentDate) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          appointmentDate: '',
        }));
      }
      break;
    case 'timeFrom':
      setTimeFrom(value);
      updateTimeToBasedOnReason(value, selectedAppointmentReason);

      if (formErrors.timeFrom) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeFrom: '',
        }));
      }
      break;
    case 'timeTo':
      setTimeTo(value);
      if (formErrors.timeTo) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeTo: '',
        }));
      }
      break;

    case 'room':
      setSelectedRoom(value);
      fetchOTtables(value);
      if (formErrors.room) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          room: '',
        }));
      }
      break;
    case 'table':
      setSelectedTable(value);
      if (formErrors.table) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          table: '',
        }));
      }
      break;
    default:
      break;
    }
  };

  const handleCheckboxChange = (event: { target: { name: string; checked: boolean; }; }) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState: any) => {
      const newState = { ...prevState, [name]: checked };

      if (name === 'patient' && checked) {
        // Clear other options when "Patient" is selected
        return {
          patient: true,
          partner: prevState.partner, // Keep partner selected if it was already selected
          spermDonor: false,
          oocyteDonor: false,
          surrogate: false,
        };
      } else if (name === 'partner' && checked) {
        // Ensure "Patient" remains selected if "Partner" is selected
        return {
          ...newState,
          patient: true,
        };
      } else if (checked) {
        // Uncheck "Patient" and "Partner" if any donor option is selected
        return {
          patient: false,
          partner: false,
          [name]: true,
        };
      }

      return newState;
    });
  };


  const updatePatientType = useCallback(() => {
    const { patient, partner, oocyteDonor, spermDonor, surrogate } = checkboxState;
    if (patient && partner) {
      setPatientType(registrationTypesEnum[1].value);
    } else if (oocyteDonor) {
      setPatientType(registrationTypesEnum[3].value);
    } else if (spermDonor) {
      setPatientType(registrationTypesEnum[2].value);
    } else if (surrogate) {
      setPatientType(registrationTypesEnum[4].value);
    } else if (partner){
      setPatientType(registrationTypesEnum[0].value);
    } else {
      setPatientType(registrationTypesEnum[1].value);
    }
  }, [checkboxState]);

  useEffect(() => {
    updatePatientType();
  }, [updatePatientType]);

  useEffect(() => {
    if(departmentName === 'Surgery'){
      fetchDepartmentsRooms();
    }

  }, [departmentName]);

  useEffect(() => {
    if (newPatientData.gender === 'FEMALE' || newPatientData.gender === 'MALE') {
      setPartnerData((prevData) => ({
        ...prevData,
        gender: newPatientData.gender === 'FEMALE' ? 'MALE' : 'FEMALE',
      }));
    }
  }, [newPatientData.gender]);

  const handlePatientOrPartnerChange = <T extends bookSlotPatientData>(
    field: keyof T,
    value: string,
    isPartner: boolean
  ) => {

    // Email Validation
    if (field === 'email') {
      if (/\s/.test(value)) {
        return; // Stop further execution if a space is entered
      }
    }


    // Update the correct state
    if (isPartner) {
      setPartnerData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    } else {
      setNewPatientData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }

    if (errors[field as keyof ValidationErrors1]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field as keyof ValidationErrors1]: '', // Clear error for this field
      }));
    }
  };


  const handleOpenConfirmationModal = () => {
    let isValid = true;

    if (tabIndex === 1) {
      // Validate new patient fields
      const newPatientValid = validateFields(); // Validate fields specific to new patient
      isValid = isValid && newPatientValid; // Combine validity with existing checks
    }

    // Always validate the overall form regardless of tabIndex
    const formValid = validateForm(); // Validate the general form
    isValid = isValid && formValid; // Combine validity with new patient checks
    const timeDiff = timeDifference(timeFrom, timeTo);

    if (timeDiff < 5 || timeDiff > 300) {
      addAlert('Time slot range should be in between 5 mins to 300 mins (5 hours)', 'error');
    } else if(isValid) {
      setOpenConfirmationModal(true);
    } else {
      console.error('Form has errors!', errors); // Updated to use errors state for logging
    }
  };

  const handleSaveAppointment = () => {
    const payload = {
      first_name: newPatientData.firstName,
      last_name: newPatientData.lastName,
      gender: newPatientData.gender,
      mobile_number: newPatientData.mobile,
      date_of_birth: newPatientData.dob,
      patient_type: patientType,
      // additional_info: {
      alternate_email: newPatientData.email,
      clinic:ClinicId,
      mobile_number_country_code: newPatientData.mobile_country_code,
      reason_for_visit: selectedAppointmentReason,
      // },

      ...(checkboxState.partner && {
        partner: {
          first_name: partnerData.firstName,
          last_name: partnerData.lastName,
          date_of_birth: partnerData.dob,
          gender: partnerData.gender,
          alternate_email: partnerData.email,
          clinic:ClinicId,
          mobile_number: partnerData.mobile,
          mobile_number_country_code: partnerData.mobile_country_code,
        },
      }),
    };
    if (tabIndex === 1) {
      bookAppointment(payload, 0);
    } else if (tabIndex === 0) {
      bookAppointment({}, selectedPatientDetails?.id);
    }
  };

  const getPatientName = () => {
    if (tabIndex === 1 && newPatientData.firstName && newPatientData.lastName) {
      return `${newPatientData.firstName} ${newPatientData.lastName}`;
    }
    if (selectedPatientDetails?.first_name && selectedPatientDetails?.last_name) {
      return `${selectedPatientDetails.first_name} ${selectedPatientDetails.last_name}`;
    }
    return 'Patient';
  };

  const get21YearsAgoDate = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    // return new Date(today.getFullYear() - 21, today.getMonth(), today.getDate()); removed this validation as per discuss with sonali
  };

  const defaultDate = formatDate(get21YearsAgoDate());


  


  const handleClosePopup = () => {
    // setIsPopupOpen(false);
    setSelectedPatientDetails(null);
    setAppointmentDate(new Date());
    setTimeFrom(formatTime(new Date()));
    setTimeTo(formatTime(addMinutes(new Date(), 15)));
    setOpenConfirmationModal(false);
    resetFormFields();
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        handleClosePopup();
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth maxWidth='lg'
      PaperProps={{  sx: {  borderRadius: '12px', width: '55%',  minWidth: '700px',},  }}
    >
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1px' }}>
          <span>Appointment</span>
          <IconButton
            edge='end'
            color="inherit"
            onClick={onClose}
            aria-label="close"
            className={styles.floatEnd}
          >
            <More />
          </IconButton>
        </div>
        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '0px' }}>
          {tabIndex === 0
            ? 'Fill below details to book an appointment for an existing patient.'
            : 'Fill below details to book a new appointment.'}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <div className="px-0">
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            aria-label="registration tabs"
            variant="fullWidth"
            style={{ borderBottom: '2px solid #f2f2f2', fontWeight: '800' }}
            TabIndicatorProps={{ sx: { backgroundColor: 'rgba(225, 126, 97, 1)', height: '2px' } }}
            sx={{
              '& .MuiTab-root': {
                fontFamily: 'Montserrat',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '22px',
                textAlign: 'left',
                textTransform: 'none',
                width: '100%',
                color: 'rgba(141, 152, 162, 1)',
              },
              '& .Mui-selected': {
                color: '#E17E61',
                fontWeight: '800',
              },
              mb: 2,
            }}
          >
            <Tab label="Existing Patient" />
            <Tab label="New Patient" />
          </Tabs>


          <Grid container alignItems='center' spacing={2} sx={{ mb: 3}}>
            <Grid item xs={tabIndex === 1 ? 12 : 6}>
              {/* Patient Radio Button */}
              <FormControl>
                <RadioGroup>
                  <FormControlLabel
                    value="PATIENT"
                    control={
                      <Radio
                        name="patient"
                        checked={checkboxState.patient}
                        onChange={handleCheckboxChange}
                        sx={{
                          color: '#232323',
                          '&.Mui-checked': {
                            color: '#4CAF50',
                          },
                        }}
                      />
                    }
                    label="Patient"
                  />

                </RadioGroup>
              </FormControl>

              {(checkboxState.patient && tabIndex === 1) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="partner"
                      checked={checkboxState.partner}
                      onChange={handleCheckboxChange}
                      sx={{
                        color: '#232323',
                        '&.Mui-checked': {
                          color: '#4CAF50',
                        },
                      }}
                    />
                  }
                  label="Partner"
                />
              )}

              {tabIndex === 1 && (
                <FormControl>
                  <RadioGroup>
                    <Stack direction="row">
                      {/* Sperm Donor Radio Button */}
                      <FormControlLabel
                        value="SPERM_DONOR"
                        control={
                          <Radio
                            name="spermDonor"
                            checked={checkboxState.spermDonor}
                            onChange={handleCheckboxChange}
                            sx={{
                              color: '#232323',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              },
                            }}
                          />
                        }
                        label="Sperm Donor"
                      />
                      {/* Oocyte Donor Radio Button */}
                      <FormControlLabel
                        value="OOCYTE_DONOR"
                        control={
                          <Radio
                            name="oocyteDonor"
                            checked={checkboxState.oocyteDonor}
                            onChange={handleCheckboxChange}
                            sx={{
                              color: '#232323',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              },
                            }}
                          />
                        }
                        label="Oocyte Donor"
                      />
                      {/* Surrogate Radio Button */}
                      <FormControlLabel
                        value="SURROGATE"
                        control={
                          <Radio
                            name="surrogate"
                            checked={checkboxState.surrogate}
                            onChange={handleCheckboxChange}
                            sx={{
                              color: '#232323',
                              '&.Mui-checked': {
                                color: '#4CAF50',
                              },
                            }}
                          />
                        }
                        label="Surrogate"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>


            {tabIndex === 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  options={patients} // Use the patients fetched from the API
                  getOptionLabel={(option) => `${option.medical_record_no} | ${option.first_name} ${option.last_name}`.trim()}
                  onInputChange={(event, newInputValue) => {
                    setPatientSearchTerm(newInputValue); // Update the search term, triggering the useEffect to fetch data
                  }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      fetchPatientDetails(String(newValue.id));
                      setIsPatientSelected(true);
                    } else {
                      setIsPatientSelected(false);
                      setPatients([]);
                    }
                  }}
                  noOptionsText="No patients found"
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={LabeledWithAsterisk('Select Patient', true)}	
                      placeholder="Search Patient"
                      variant="outlined"
                      sx={{ height: '48px', '& .MuiOutlinedInput-root': { height: '48px' } }}
                      error={isFormSubmitted && !isPatientSelected}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ height: '90%', width: '90%' }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>

          <ThemeProvider theme={theme}>
            <div className={styles.basicInfoContainer}>
              <Grid container spacing={2}>
                {tabIndex === 1 && (
                  <>
                    {/* New Patient Fields */}
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label={LabeledWithAsterisk('First Name', true)}	
                        value={newPatientData.firstName}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Only update state if the value contains alphabetic characters
                          if (/^[a-zA-Z]*$/.test(value) || value === '') {
                            handlePatientOrPartnerChange('firstName', value, false);
                          }
                        }}
                        error={!!errors.firstName}  // Use errors instead of formErrors1
                        // helperText={errors.firstName} // Use errors instead of formErrors1
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label={LabeledWithAsterisk('Last Name', true)}	
                        value={newPatientData.lastName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[a-zA-Z]*$/.test(value) || value === '') {
                            handlePatientOrPartnerChange('lastName', value, false); // false for new patient
                          }
                        }}
                        error={!!errors.lastName}  // Use errors instead of formErrors1
                        // helperText={errors.lastName} // Use errors instead of formErrors1
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth size="small" error={!!errors.gender}>
                        <InputLabel id="gender-label">{LabeledWithAsterisk('Sex Assigned at Birth', true)}	</InputLabel>
                        <Select
                          labelId="gender-label"                          
                          label={LabeledWithAsterisk('Sex Assigned at Birth', true)}	
                          value={newPatientData.gender}
                          onChange={(e) => handlePatientOrPartnerChange('gender', e.target.value, false)} // false for new patient
                        >
                          {genders.map((gender) => (
                            <MenuItem key={gender.name} value={gender.name}>
                              {toTitleCase(gender.name)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <FormControl fullWidth variant="outlined" error={!!errors.mobile}>
                            <Autocomplete
                              options={filteredCountryCodes}
                              getOptionLabel={(option) => newPatientData.mobile_country_code === option.dial_code ? option.dial_code : `${option.name} (${option.dial_code})`} // Show only dial_code when selected, name + dial_code in dropdown
                              value={filteredCountryCodes.find(country => country.dial_code === newPatientData.mobile_country_code) || null} // Use filteredCountryCodes for value
                              onChange={(event, newValue) => {
                                handlePatientOrPartnerChange('mobile_country_code', newValue?.dial_code || '', false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={LabeledWithAsterisk('Country Code', true)}	
                                  variant="outlined"
                                  autoComplete='off'
                                  error={!!errors.mobile_country_code}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: null,
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} style={{ fontSize: '0.8rem' }}>
                                  {`${option.name} (${option.dial_code})`}
                                </li>
                              )}
                              onInputChange={handleInputChange} // Use the new input change handler
                              isOptionEqualToValue={(option, value) => option.dial_code === value.dial_code && option.name === value.name} // Match both name and dial_code
                              sx={{ width: '100%', height: '56px',
                                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                  width: 'auto',
                                  minWidth: '3.5rem',
                                  maxWidth: '4rem' }
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            size="small"                            
                            label={LabeledWithAsterisk('Mobile #', true)}	
                            value={newPatientData.mobile}
                            // onChange={(e) => handlePatientOrPartnerChange('mobile', e.target.value, false)}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value) || value === '') {
                                handlePatientOrPartnerChange('mobile', value, false);
                              }
                            }}
                            error={!!errors.mobile}
                            type="tel"
                            inputProps={{ maxLength: 11 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"                        
                        label={LabeledWithAsterisk('Date of Birth', true)}	
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={newPatientData.dob}
                        onChange={(e) => handlePatientOrPartnerChange('dob', e.target.value, false)}
                        error={!!errors.dob}  // Use errors instead of formErrors
                        inputProps={{
                          max: defaultDate,
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label={LabeledWithAsterisk('Email ID', true)}	
                        value={newPatientData.email}
                        onChange={(e) => handlePatientOrPartnerChange('email', e.target.value, false)}
                        error={!!errors.email}
                        // helperText={errors.email} // Use errors instead of formErrors1
                      />
                    </Grid>

                    {/* Partner Fields */}

                    {/* Conditionally Render Partner Fields */}
                    {checkboxState.partner && (
                      <>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size="small"
                            id="partnerFirstName"
                            label={LabeledWithAsterisk('Partner First Name', true)}	
                            variant="outlined"
                            value={partnerData.firstName}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[a-zA-Z]*$/.test(value) || value === '') {
                                handlePatientOrPartnerChange('firstName', value, true); // true for partner
                              }
                            }}

                            error={!!errors.partnerFirstName}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size="small"
                            id="partnerLastName"
                            label={RequiredLabel('Partner Last Name', true)}
                            variant="outlined"
                            value={partnerData.lastName}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^[a-zA-Z]*$/.test(value) || value === '') {
                                handlePatientOrPartnerChange('lastName', value, true); // true for partner
                              }
                            }}
                            error={!!errors.partnerLastName}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <FormControl fullWidth size="small" error={!!errors.partnerGender}> {/* Show error state */}
                            <InputLabel id="partner-gender-label">{RequiredLabel('Sex Assigned at Birth', true)}</InputLabel>
                            <Select
                              labelId="partner-gender-label"
                              id="partnerGender"
                              label={RequiredLabel('Sex Assigned at Birth', true)}
                              value={partnerData.gender}
                              onChange={(e) => handlePatientOrPartnerChange('gender', e.target.value, true)} // true for partner
                            >
                              {genders.map((gender) => (
                                <MenuItem key={gender.name} value={gender.name}>
                                  {toTitleCase(gender.name)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                              <FormControl fullWidth variant="outlined" error={!!errors.partnerMobile}>
                                <Autocomplete
                                  options={filteredCountryCodes} // Use the filtered country codes for partner as well
                                  getOptionLabel={(option) => partnerData.mobile_country_code === option.dial_code ? option.dial_code : `${option.name} (${option.dial_code})`} // Show only dial_code when selected, name + dial_code in dropdown
                                  value={filteredCountryCodes.find(country => country.dial_code === partnerData.mobile_country_code) || null} // Use filteredCountryCodes for value
                                  onChange={(event, newValue) => {
                                    handlePatientOrPartnerChange('mobile_country_code', newValue?.dial_code || '', true); // Set true for partner
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={RequiredLabel('Country Code', true)}
                                      autoComplete='off'
                                      variant="outlined"
                                      error={!!errors.partner_mobile_country_code}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: null, // Remove the dropdown arrow icon
                                      }}
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li {...props} style={{ fontSize: '0.8rem' }}>
                                      {`${option.name} (${option.dial_code})`}
                                    </li>
                                  )}
                                  onInputChange={handleInputChange} // Use the new input change handler
                                  isOptionEqualToValue={(option, value) => option.dial_code === value.dial_code && option.name === value.name} // Match both name and dial_code
                                  sx={{ width: '100%', height: '56px' }}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={7}>
                              <TextField
                                fullWidth
                                size="small"
                                id="partnerMobile"
                                label={RequiredLabel('Mobile #', true)}
                                variant="outlined"
                                value={partnerData.mobile}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value) || value === '') { // Allow only digits and empty string
                                    handlePatientOrPartnerChange('mobile', value, true); // Set true for partner
                                  }
                                }}
                                error={!!errors.partnerMobile}
                                type="tel"
                                inputProps={{ maxLength: 11 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size="small"
                            id="partnerDob"
                            label={RequiredLabel('Date of Birth', true)}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            value={partnerData.dob}
                            onChange={(e) => handlePatientOrPartnerChange('dob', e.target.value, true)}
                            error={!!errors.partnerDob}
                            inputProps={{
                              max: defaultDate,
                            }}
                          />
                        </Grid>

                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            size="small"
                            id="partnerEmail"                            
                            label={RequiredLabel('Email ID', true)}
                            variant="outlined"
                            value={partnerData.email}
                            onChange={(e) => handlePatientOrPartnerChange('email', e.target.value, true)}
                            error={!!errors.partnerEmail}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}

                {tabIndex === 0 && isPatientSelected && selectedPatientDetails && (
                  <PatientDetails selectedPatientDetails={selectedPatientDetails} />
                )}


                {/* Common Fields */}
                <Grid item xs={6} sm={4} lg={4}>
                  <FormControl fullWidth size="small" error={!!formErrors.department}>
                    <InputLabel id="department-label">{RequiredLabel('Department', true)}</InputLabel>
                    <Select
                      labelId="department-label"
                      id="department-select"
                      value={selectedDepartment}
                      onChange={(e) => handleChange('department', e.target.value)}
                      label={RequiredLabel('Department', true)}
                      disabled={departmentList.length === 0}
                      IconComponent={slotDepartment ? () => null : undefined}
                    >
                      {departmentList.map((department) => (
                        <MenuItem key={department.id} value={department.id}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={4} lg={4}>
                  <FormControl fullWidth size="small" error={!!formErrors.personnel}>
                    {/* <InputLabel>Personnel *</InputLabel> */}
                    <Autocomplete
                      options={roleList}
                      getOptionLabel={(option: UserBasic) => `${option.first_name} ${option.last_name}`}
                      value={selectedPersonnel || null}
                      onChange={(event, personnelNewValue: UserBasic | null) => {
                        setSelectedPersonnel(personnelNewValue || undefined);
                        handleChange('personnel', personnelNewValue);
                      }}
                      renderOption={(props, option) => {
                        const isAvailable = availableRoleList.some((user) => user.id === option.id);
    
                        return (
                          <li {...props} key={option.id}>
                            <Typography
                              style={{
                                color: isAvailable ? 'green' : 'red',
                                fontWeight: isAvailable ? 'bold' : 'normal',
                              }}
                            >
                              {option.first_name} {option.last_name}
                            </Typography>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          disabled={!(timeFrom && timeTo)}
                          label={
                            departmentName === 'Admin'
                              ? RequiredLabel('Personnel', true)
                              : departmentName === 'Pathology'
                                ? RequiredLabel('Pathologist', true)
                                : ['Surgery', 'Ultrasound', 'Counselor'].includes(departmentName)
                                  ? RequiredLabel('Doctor Name', true)
                                  : RequiredLabel('Doctor Name', true)
                          }
                          variant="outlined"
                          error={!!formErrors.personnel}
                        />
                      )}
                      disabled={!selectedDepartment || !timeFrom || !timeTo}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={4} lg={4}>
                  <FormControl fullWidth size="small" error={!!formErrors.appointmentReason}>
                    <Autocomplete
                      options={appointmentReasonsList}
                      getOptionLabel={(option) => option.reason}
                      value={appointmentReasonsList.find(reason => reason.id === selectedAppointmentReason) || undefined}
                      onChange={(event, newValue) => {
                        handleChange('appointmentReason', newValue ? newValue.id : undefined);
                      }}
                      disabled={selectedPersonnel?.first_name.length ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Appointment Reason', true)}
                          variant="outlined"
                          error={!!formErrors.appointmentReason}
                        />
                      )}
                      size="small"
                      disableClearable
                      noOptionsText="No options"
                    />
                  </FormControl>
                </Grid>

                {departmentName === 'Surgery' && (
                  <>
                    <Grid item xs={6} sm={4}>
                      <FormControl fullWidth size="small" error={!!formErrors.room}>
                        <InputLabel id="room-label">{RequiredLabel('Room', true)}</InputLabel>
                        <Select
                          labelId="room-label"
                          id="room-select"
                          value={selectedRoom || ''}
                          onChange={(e) => handleChange('room', Number(e.target.value))}                          
                          label={RequiredLabel('Room', true)}
                        >
                          {room.map((roomItem) => (
                            <MenuItem key={roomItem.id} value={roomItem.id}>
                              {roomItem.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      <FormControl fullWidth size="small" error={!!formErrors.table && (selectedRoom !== undefined)}>
                        <InputLabel id="table-label">{RequiredLabel('Table', true)}</InputLabel>
                        <Select
                          labelId="table-label"
                          id="table-select"
                          value={selectedTable || ''}
                          onChange={(e) => handleChange('table', e.target.value)}
                          label={RequiredLabel('Table', true)}
                          disabled={!selectedRoom}
                          className={!selectedRoom ? 'disabled-select' : ''}
                        >
                          {table.map((tableItem) => (
                            <MenuItem key={tableItem.id} value={tableItem.id}>
                              {tableItem.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {/* <FormHelperText>{formErrors.table}</FormHelperText> */}
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={6} sm={4} lg={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="appointmentDate"
                    label={RequiredLabel('Date', true)}
                    type="date"
                    name="appointmentDate"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={formatDate(appointmentDate)}
                    onChange={(e) => handleChange('appointmentDate', e.target.value)}
                    error={!!formErrors.appointmentDate}
                    InputProps={{
                      inputProps: {
                        min: currentDate,
                      },
                    }}
                  />
                </Grid>

                {/* Time From */}
                <Grid item xs={6} sm={4} lg={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="timeFrom"
                    label={RequiredLabel('Time From', true)}
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={timeFrom}
                    onChange={(e) => handleChange('timeFrom', e.target.value)}
                    error={!!formErrors.timeFrom}
                  />
                </Grid>

                {/* Time To */}
                <Grid item xs={6} sm={4} lg={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="timeTo"
                    label={RequiredLabel('Time To', true)}
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    value={timeTo}
                    onChange={(e) => handleChange('timeTo', e.target.value)}
                    error={!!formErrors.timeTo}
                    helperText={formErrors.timeTo}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small" error={!!formErrors.remark}>
                    <TextField
                      id="remark"
                      label="Remark"
                      variant="outlined"
                      value={remark}
                      onChange={(e) => handleChange('remark', e.target.value)}
                      multiline
                      rows={3}
                      InputProps={{
                        style: { resize: 'none' },
                        inputProps: { maxLength: 2000 },
                      }}
                      placeholder="Type Here"
                    />
                  </FormControl>
                </Grid>
                {tabIndex === 0 && selectedPatientDetails && (
                  <PreviousVisits patientId={selectedPatientDetails.id} />
                )}
              </Grid>

            </div>
          </ThemeProvider>
        </div>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <button
          className={`${styles.save}`}
          onClick={handleOpenConfirmationModal}
          style={{
            fontFamily: 'Montserrat',
            width: '920px',
            height: '46px',
            borderRadius: '12px',
            padding: '12px 40px',
            gap: '10px',
            backgroundColor: '#232323',
            color: !selectedPersonnel || availableRoleList.some((user) => user.id === selectedPersonnel?.id) ? 'white': 'red'
          }}
        > {!selectedPersonnel || availableRoleList.some((user) => user.id === selectedPersonnel?.id) ? 'Book Appointment': 'User not available, force book appointment?'}
        </button>
      </DialogActions>

      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        onConfirm={handleSaveAppointment}
        type="save"
        title={availableRoleList.some((user) => user.id === selectedPersonnel?.id) ? 'Create Appointment': 'Force Book Appointment'}
        contentMessage={availableRoleList.some((user) => user.id === selectedPersonnel?.id) ? `Are you sure you want to create an appointment for ${getPatientName()}?`: `Are you sure you want to force book an appointment for ${getPatientName()}?`}
      />
    </Dialog>
  );
}
