import React from 'react';
import {
  Box,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  LinearProgress,
} from '@mui/material';
import './TankRadio.css';
import { StorageUnit } from '../Embryology/Embryo_Culture/embryoCulture.helpers';
import { toTitleCase } from '../../utils/commonUtils';

import canisterNotAvailable from '../../assets/SVG/Canister.png';

interface CanisterRadioProps {
  canisters: StorageUnit[];
  selectedCanister: number | null;  
  onChange: (value: number) => void; 
}

const CanisterRadio: React.FC<CanisterRadioProps> = ({ canisters, selectedCanister, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const canisterId = Number(event.target.value);
    onChange(canisterId);
  };

  return (
    <FormControl component="fieldset" className="tank-radio-container">
      <Typography 
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '16px',
          marginBottom: '8px',
          textAlign: 'left',
          color: '#333',
        }}
      >
        Canister
      </Typography>
      
      <RadioGroup value={selectedCanister !== null ? String(selectedCanister) : ''} onChange={handleChange}>
        {canisters.length ? (
          canisters.map((canister) => {
            const isSelected = selectedCanister === canister.id;
            return (
              <Box 
                key={canister.id} 
                className={`tank-item ${isSelected ? 'selected-tank' : 'unselected-tank'}`} 
                sx={{
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, width 0.3s ease-in-out',
                  transform: isSelected ? 'scale(1.05)' : 'scale(1)',
                  boxShadow: isSelected ? '0px 6px 15px rgba(0, 0, 0, 0.15)' : 'none',
                  width: isSelected ? '92%' : '100%', // Reduce width for selected tank
                  margin: isSelected ? '0.5rem auto' : '0',
                }}
              >
                {/* Wrap the Radio and Name */}
                <Box 
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem', // Space between radio and text
                  }}
                >
                  <Radio 
                    value={String(canister.id)} 
                    className={`radio-button ${isSelected ? 'selected-radio' : ''}`} 
                    sx={{
                      color: isSelected ? '#222' : '#999', // Outer circle color
                      '&.Mui-checked': {
                        color: '#fb6f92', // Inner circle color
                      },
                    }}
                  />
                  <Typography 
                    className="tank-name"   
                    sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '17.07px',
                      letterSpacing: '0px',    
                      color: isSelected ? '#000' : '#999',
                      transition: 'color 0.3s ease-in-out',
                    }}
                  >
                    {toTitleCase(canister.name)}
                  </Typography>
                  <Typography 
                    className="tank-percentage"
                    sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      fontSize: '10px',
                      lineHeight: '12.19px',
                      letterSpacing: '0%',
                      textAlign: 'right',
                      marginLeft: 'auto',
                      color: '#999',
                    }}
                  >
                    {((canister.occupancy ?? 0).toFixed(2))}%
                  </Typography>
                </Box>

                {/* Progress Bar in Separate Box */}
                <Box sx={{ marginTop: '4px' }}>
                  <LinearProgress
                    variant="determinate"
                    value={canister?.occupancy ?? 0}
                    className={`progress-bar ${isSelected ? 'selected-bar' : ''} ${
                      (canister.occupancy ?? 0) >= 100 ? 'red-bar' : 'green-bar'
                    }`}
                    sx={{
                      transition: 'width 0.5s ease-in-out',
                      backgroundColor: canister.occupancy === 100 && !isSelected ? '#F25B5B' : 'transparent', 
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: canister.occupancy === 100 
                          ? (!isSelected ? '#D84040' : '#FF0000') 
                          : '#4caf50',
                      },
                    }}

                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <img src={canisterNotAvailable} alt="No Canister Available" style={{ width: '280px', height: '240px' }} />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default CanisterRadio;
