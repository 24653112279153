/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { spermCryoStorageDetail, getSpermMaterialsDetails } from '../cryoStorage.helpers';
import { capitalizeTitle, getDateInDDMMYYYY } from '../../../utils/commonUtils';
import PopoverPanel from './popoverPanel';
import EditableDateCell from '../editableDateCell';

interface MaterialDetailsProps {
    selectedCycleID: number | null;
  }
  
const SpermStorageTable: React.FC<MaterialDetailsProps> = ({ selectedCycleID }) => {
  const [selectedCycleDetails, setSelectedCycleDetails] = useState<spermCryoStorageDetail[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  const fetchData = (cycleID: number) => {
    setLoading(true);
    setError(null);
    getSpermMaterialsDetails(cycleID)
      .then((data) => {
        setSelectedCycleDetails(data || null);
      })
      .catch((err) => {
        console.error('Error fetching material details:', err);
        setError('Failed to fetch material details');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedCycleID !== null) {
      fetchData(selectedCycleID);
    }
  }, [selectedCycleID]);

  const handleDateChange = (newDate: string) => {
    console.log('New Date:', newDate);
    fetchData(selectedCycleID!); 
  };


  if (loading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>{error}</p>;
  }
  
  // Gradient color mapping for circles
  const gradientMap: Record<string, string> = {
    White: 'radial-gradient(circle, #FFFFFF 0%, #DCDCDC 100%)',
    Blue: 'radial-gradient(circle, #5C8CDB 0%, #BDD6FF 80%)',
    Brown: 'radial-gradient(circle, #8B5A2B 0%, #F5E6DA 100%)',
    Green: 'radial-gradient(circle, #4CAF50 0%, #DFF5E0 100%)',
    Orange: 'radial-gradient(circle, #E78457 0%, #FFDCCC 100%)',
    Red: 'radial-gradient(circle, #E74C3C 0%, #FFE5E0 100%)',
    Yellow: 'radial-gradient(circle, #DEC122 0%, #FFFCE9 100%)',
  };

  // Function to render a colored circle based on gradient map
  const GradientCircle: React.FC<{ color?: string }> = ({ color }) => {
    if (!color || !gradientMap[color]) return null;

    return (
      <span
        style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          background: gradientMap[color],
          marginLeft: '2px',
          marginRight: '6px',
          boxShadow: 'inset 1px 1px 3px rgba(0,0,0,0.2), 0 3px 6px rgba(0,0,0,0.1)',
          border: '1px solid rgba(255,255,255,0.7)',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      ></span>
    );
  };


  // Function to format location details for the provided response
  const formatLocationDetails = (locationDetails: any): JSX.Element => {
    if (!locationDetails || !locationDetails.parents) {
      return <Typography variant="body2">N/A</Typography>;
    }
  
    const parts = locationDetails.parents.map((p: any) => p.name);
    
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '200px',
          maxWidth: '100%',
        }}
      >
        {/* First Line: Tank & Canister */}
        <Typography variant="body2">
          {parts[0] || ''} {parts[1] ? ` | ${parts[1]}` : ''}
        </Typography>
  
        {/* Second Line: Cane & Vials (always together) */}
        <Typography variant="body2">
          {parts[2] || ''} {parts[3] ? `| ${parts[3]}` : ''} {locationDetails.name ? `| ${capitalizeTitle(locationDetails.name)}` : ''}
        </Typography>

        {/* Colored Circle if needed */}
        <GradientCircle color={locationDetails.color || ''} />
      </Box>
    );
  };
  
  return (
    <div >
      <TableContainer sx={{ border: '1px solid rgb(240, 237, 237)', borderRadius: '10px' ,  overflowX: 'auto',}} >
        <Table >
          <TableHead>
            <TableRow
              sx={{
                height: '2rem',
                background: 'rgba(250, 250, 250, 1)',
                border: 'none',
                '& .MuiTableCell-root': {
                  padding: '0.6rem 0.6rem',
                  minWidth: '50%',
                },
              }}
            >
              {['Sperm No.', 'Quality of Sperm',  'Freezing Details', 'Freeze Date', 'Age', 'Renewal Date', 'Location'].map((title, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.45',
                    textAlign: 'left',
                    color: 'rgba(35, 35, 35, 1)',
                    padding: '0.3rem 0.6rem',
                    verticalAlign: 'top',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedCycleDetails ? (
              selectedCycleDetails.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="h6" color="textSecondary">
                        No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                selectedCycleDetails.map((storage, index) => (
                  <TableRow key={index} >
                    <TableCell>{storage.sample_id || '-'}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {/* Conditionally render PopoverPanel and Type text */}
                      {storage.type_of_sperm ? (
                        <>
                          <PopoverPanel content={storage}/>&nbsp;
                          Type - {capitalizeTitle(storage.type_of_sperm)}
                        </>
                      ) : (
                        ' -' 
                      )}
                    </TableCell>


                    <TableCell>
                      <div> No : {storage.id}</div>
                      <div>{storage.cryo_date ? getDateInDDMMYYYY(storage.cryo_date) : '-'}</div>
                    </TableCell>                    
                    
                    <TableCell>{storage.cryo_date ? getDateInDDMMYYYY(storage.cryo_date) : '-'}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Day 0</TableCell>
                    {/* <TableCell>{storage.cryo_date ? getDateInDDMMYYYY(storage.cryo_date) : '-'}</TableCell> */}

                    <TableCell>
                      <EditableDateCell cryo_date={storage.renewal_date} itemId={storage.id} type='semen' onDateChange={handleDateChange} />
                    </TableCell>

                    <TableCell sx={{ 
                      wordWrap: 'break-word', 
                      whiteSpace: 'normal', 
                      maxWidth: '200px' 
                    }}>
                      {formatLocationDetails(storage.location_details) || '-'}
                    </TableCell>

                  </TableRow>
                ))
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h6" color="textSecondary">
                        Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> 
    </div>
  );
};

export default SpermStorageTable;
