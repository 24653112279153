import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
interface StorageUnit {
    id: number | null;
    reference_id: string | null;
    plan: number;
    level: number;
    name: string;
    parent: number | null;
    occupancy: number;
    hierarchy: string;
    specimen: unknown | null;
    is_occupied: boolean;
    is_leaf: boolean;
    color: string | null;
}

interface StorageDetailsProps {
    locationDetails: {
        id?: number;
        name?: string;
        occupancy?: number | null;
        parents: StorageUnit[];
    } | null;
}

// Define hierarchy order
const hierarchyOrder = ['Tank', 'Canister', 'Cane'];

const SemenStorageDetails: React.FC<StorageDetailsProps> = ({ locationDetails }) => {

  if (!locationDetails || !locationDetails.parents) {
    return <Typography>No storage details available.</Typography>;
  }

  // Sort parents based on predefined hierarchy order
  const sortedUnits = [...locationDetails.parents].sort((a, b) => a.level - b.level);

  // Function to determine capacity color
  const getCapacityColor = (occupancy: number) => {
    if (occupancy <= 30) return '#27ae60'; // Green for low occupancy
    if (occupancy <= 70) return '#f39c12'; // Orange for medium
    return '#e74c3c'; // Red for high occupancy
  };

  return (
    <><Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'flex-start',
        padding: 2,
      }}
    >
      {/* Right Side: Card Section */}
      <Box
        sx={{
          flex: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
        }}
      >
        {sortedUnits.map((unit, index) => {
          return (
            <Card
              key={index}
              sx={{
                borderRadius: 3,
                boxShadow: 2,
                backgroundColor: '#F7F7F7',
                minWidth: 160,
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 1.5, paddingBottom: 0, marginBottom: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#555' }}>
                    {hierarchyOrder[index]} :
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 700, color: '#000' }}>
                    {unit?.name}
                  </Typography>
                </Box>

                <Typography sx={{ fontSize: 10, fontWeight: 500, color: '#777' }}>
                    Capacity:{' '}
                  <span style={{ color: getCapacityColor(unit.occupancy), fontWeight: 600 }}>
                    {unit.occupancy ? unit.occupancy.toFixed(2) + '%' : '0%'}
                  </span>
                </Typography>
              </CardContent>
            </Card>
          );
        })} 
        <Card
          sx={{
            borderRadius: 3,
            boxShadow: 2,
            backgroundColor: '#F7F7F7',
            minWidth: 160,
          }}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 1.2, paddingBottom: 0, marginBottom: 0 }}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#555' }}>
                Vials :
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 700, color: '#000' }}>
                {locationDetails?.name}
              </Typography>
            </Box>

            <Typography sx={{ fontSize: 10, fontWeight: 500, color: '#777' }}>
              Capacity:{' '}
              <span style={{ color: getCapacityColor(locationDetails?.occupancy ?? 0), fontWeight: 600 }}>
                {locationDetails?.occupancy ? locationDetails?.occupancy.toFixed(2) + '%' : '0%'}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box><Box sx={{ height: '1px', backgroundColor: '#ddd', my: 2 }} /></>
  );
};  

export default SemenStorageDetails;
