import React, { createContext, useContext, useState } from 'react';
import { CycleData } from '../Surgery/oocyteRetrieval/OocyteRetrival.helper'; // Import CycleData type

interface CycleContextType {
  cycleId: number | null;
  selectedCycle: CycleData | null;
  setCycleId: (id: number | null) => void;
  setSelectedCycle: (cycle: CycleData | null) => void;
}

// Create context with default values
const CycleContext = createContext<CycleContextType | undefined>(undefined);

// Provider Component
export const CycleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cycleId, setCycleId] = useState<number | null>(null);
  const [selectedCycle, setSelectedCycle] = useState<CycleData | null>(null);

  return (
    <CycleContext.Provider value={{ cycleId, selectedCycle, setCycleId, setSelectedCycle }}>
      {children}
    </CycleContext.Provider>
  );
};

// Custom hook to use the context
export const useCycle = () => {
  const context = useContext(CycleContext);
  if (!context) {
    throw new Error('useCycle must be used within a CycleProvider');
  }
  return context;
};
