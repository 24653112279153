/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid2 from '@mui/material/Grid2'; 
import IconButton from '@mui/material/IconButton';
import { ReactComponent as More } from '../../../assets/SVG/close-circle-greay.svg';
import { RequiredLabel } from '../../../utils/commonUtils';
import styles from './embryoCulture.module.css';
import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { useFormik } from 'formik';
import { cryoPurposeItems, fetchCanes, fetchCanisters, fetchGoblets, fetchTankData, FreezeDialogValues, freezeValidationSchema, gradientData, initialValuesCryo, StorageUnit } from './embryoCulture.helpers';
import { getUsersByClinicId } from '../../../services/apiService';
import { useClinic } from '../../../components/ClinicProvider';
import { mediaItems } from '../Occyte-Grading/oocyteGrading.helper';
import TankSelect from './TankSelect';
import CanisterSelect from './CanisterSelect';
import CaneSelect from './CaneSelect';
import GobletSelect from './GobletSelect';
import CryotechSelect from './cryotechSelect';

interface FreezeActionDialogProps {
  open: boolean; 
  onClose: () => void;
  values: FreezeDialogValues;
  onSave: (formValues: FreezeDialogValues) => void;
  selectedEmbryos: number[];
}

// Define a constant for roles to avoid magic strings
const ROLES = ['DOCTOR', 'EMBRYOLOGIST', 'NURSE'] as const;
type Role = typeof ROLES[number];
interface Clinician {
  id: number; 
  first_name: string;
  last_name: string;
  role: Role;  
}

const FreezeActionDialog: React.FC<FreezeActionDialogProps> = ({ open, onClose, values, onSave, selectedEmbryos }) => {
    
  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(values);
  const formik = useFormik({
    initialValues: freezeValues,    
    validationSchema: freezeValidationSchema,
    onSubmit: (formValues) => {
      console.log('Saved Values:', formValues);
      onSave(formValues);
    },
    enableReinitialize: true,
  });
  
  useEffect(() => {
    console.log('selectedEmbryos', selectedEmbryos);
  }, [selectedEmbryos]);

  const { ClinicId } = useClinic();
   
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });

  const [tankStoragePlan, setTankStoragePlan]= useState<StorageUnit[]>([]);
  const [selectedTank, setSelectedTank] = useState<number | null>(null);

  const [canisters, setCanisters] = useState<StorageUnit[]>([]);
  const [selectedCanister, setSelectedCanister] = useState<number | null>(null);
  const [loadingCanisters, setLoadingCanisters] = useState(false);

  const [canes, setCanes] = useState<StorageUnit[]>([]); 
  const [selectedCane, setSelectedCane] = useState<number | null>(null);
  const [loadingCanes, setLoadingCanes] = useState(false);

  const [goblets, setGoblets] = useState<StorageUnit[]>([]);
  const [selectedGoblet, setSelectedGoblet] = useState<number | null>(null);
  const [loadingGoblets, setLoadingGoblets] = useState(false);

  
  const [cryotechs, setCryotechs] = useState<StorageUnit[]>([]);
  const [selectedCryotech, setSelectedCryotech] = useState<number | null>(null);
  const [loadingCryotechs, setLoadingCryotechs] = useState(false);

  const [selectedGobletColor, setSelectedGobletColor] = useState<string | null>(null);
  const [isGobletColorDisabled, setIsGobletColorDisabled] = useState(false);

  const [selectedCryotechColor, setSelectedCryotechColor] = useState<string | null>(null);
  // const [isCryotechColorDisabled, setIsCryotechColorDisabled] = useState(false);
  

  const fetchCliniciansByRole = useCallback((role: Role) => {
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {        
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
      });
  }, [ClinicId]); 
    
  useEffect(() => {
    if (open) {
      ROLES.forEach(fetchCliniciansByRole); 
      setFreezeValues(initialValuesCryo);
    }
  }, [open, fetchCliniciansByRole]);


  const loadTankStoragePlan = useCallback(() => {
    fetchTankData(Number(ClinicId), 'Embryo')
      .then((res) => {
        const tanks = res.objects || [];
        setTankStoragePlan(tanks);
      })
      .catch((error) => {
        console.error('Error fetching tank storage plan:', error);
      });
  }, [ClinicId]);

  useEffect(() => {
    loadTankStoragePlan();
  }, [loadTankStoragePlan]);
 

  const loadCanisters = useCallback((tankId: number) => {
    setLoadingCanisters(true); // Show loader
    fetchCanisters(tankId, 1)
      .then((response) => {
        console.log('Fetched canisters:', response);
        setCanisters(response);
      })
      .catch((error) => {
        console.error('Error fetching canisters:', error);
      })
      .finally(() => {
        setLoadingCanisters(false); // Hide loader
      });
  }, []);


  const loadCanes = useCallback((tankId: number, canisterId?: number) => {
    setLoadingCanes(true);
    fetchCanes(tankId, 2, canisterId??0)
      .then((response) => {
        console.log('Fetched canes:', response);
        console.log('canisterId:', canisterId);
        // Sort canes safely, handling null or undefined names
        const sortedCanes = response.sort((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') || '0');
          const numB = parseInt(b.name?.replace(/\D/g, '') || '0');
          return numA - numB;
        });

        setCanes(sortedCanes);
        setCanes(sortedCanes);
      })
      .catch((error) => {
        console.error('Error fetching canes:', error);
      })
      .finally(() => {
        setLoadingCanes(false);
      });
  }, []);

  const loadGoblets = useCallback((tankId: number, caneId: number) => {
    setLoadingGoblets(true);
    fetchGoblets(tankId, 3, caneId)
      .then((response) => {
        console.log('Fetched goblets:', response);
        const sortedGoblets = response.sort((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') || '0');
          const numB = parseInt(b.name?.replace(/\D/g, '') || '0');
          return numA - numB;
        });
        setGoblets(sortedGoblets);
      })
      .catch((error) => {
        console.error('Error fetching goblets:', error);
      })
      .finally(() => {
        setLoadingGoblets(false);
      });
  }, []);
  
  const loadCryotechs = useCallback((tankId: number, gobletId: number) => {
    setLoadingCryotechs(true);
    fetchGoblets(tankId, 4, gobletId)
      .then((response) => {
        console.log('Fetched cryotechs:', response);
        const sortedCryotechs = response.sort((a, b) => {
          const numA = parseInt(a.name?.replace(/\D/g, '') || '0');
          const numB = parseInt(b.name?.replace(/\D/g, '') || '0');
          return numA - numB;
        });
        setCryotechs(sortedCryotechs);
      })
      .catch((error) => {
        console.error('Error fetching cryotechs:', error);
      })
      .finally(() => {
        setLoadingCryotechs(false);
      });
  }, []);
  
  const handleTankChange = (value: string) => {
    const newTankId = Number(value) || null;
    console.log('Tank ID changed:', newTankId);
    setSelectedTank(newTankId);

    formik.setFieldValue('tank', newTankId);
    formik.setFieldTouched('tank', false, false);
    if (newTankId) {
      loadCanisters(newTankId);
      setCanisters([]);
      setCanes([]); 
      setSelectedCanister(null);
      setSelectedCane(null);
      setSelectedGoblet(null);
      setCryotechs([]);
      setSelectedCryotech(null);

      formik.setFieldValue('canister', null);
    } else {
      setCanisters([]);
      setCanes([]);
    }
  };

  const handleCanisterChange = (value: string) => {
    const canisterId = Number(value);
    console.log('Selected Canister:', canisterId);
    setSelectedCanister(canisterId);

    formik.setFieldValue('canister', canisterId);
    formik.setFieldTouched('canister', false, false);

    if (canisterId && selectedTank) {
      loadCanes(selectedTank, canisterId);
      setSelectedCane(null); 
      setSelectedGoblet(null);
      setSelectedCryotech(null);
      formik.setFieldValue('cane', null);
    }
  };


  const handleCaneChange = (value: string) => {
    const caneId = Number(value);
    console.log('Selected Cane:', caneId);
    setSelectedCane(caneId);
  
    formik.setFieldValue('cane', caneId);
    formik.setFieldTouched('cane', false, false);

    if (caneId && selectedTank) {
      loadGoblets(selectedTank, caneId);
      setSelectedGoblet(null); 
      setSelectedCryotech(null);
      formik.setFieldValue('goblet', null);
    }
  };
  

  const handleGobletChange = (value: string) => {
    const gobletId = Number(value);

    const selectedGobletObj = goblets.find((g) => g.id === gobletId);
    
    setSelectedGoblet(gobletId);
  
    formik.setFieldValue('goblet', gobletId);
    formik.setFieldTouched('goblet', false, false);

    if(selectedGobletObj){
      setSelectedGobletColor(selectedGobletObj.color || null);
      setIsGobletColorDisabled(!!selectedGobletObj.color);

      formik.setFieldValue('goblet_color', selectedGobletObj.color);
      formik.setFieldTouched('goblet_color', false);
  
      formik.setFieldError('goblet_color', '');
    }
    
    if (gobletId && selectedTank) {
      loadCryotechs(selectedTank, gobletId);
      setSelectedCryotech(null);
      formik.setFieldValue('cryotech', null);      
    }
  };

  const handleCryotechChange = (value: string) => {
    const cryotechID = Number(value);
    console.log('Selected cryotechID:', cryotechID);
    
    formik.setFieldValue('cryotech', cryotechID);
    formik.setFieldTouched('cryotech', false, false);

    setSelectedCryotech(cryotechID);    
  };
  
  const handleGobletColorChange = (event: SelectChangeEvent<string>) => {
    const selectedColor = event.target.value;
    console.log('Selected Goblet Color:', selectedColor);
    setSelectedGobletColor(selectedColor);
    setIsGobletColorDisabled(false);

    formik.setFieldValue('goblet_color', selectedColor);
    formik.setFieldTouched('goblet_color', false);

    // if (selectedTank && selectedGoblet !== null) {
    //   storeGobletColor(selectedTank, selectedGoblet, selectedColor)
    //     .then(() => {
    //       console.log('Goblet color successfully stored.');
    //     })
    //     .catch((error) => {
    //       console.error('Error storing goblet color:', error);
    //     });
    // } else {
    //   console.warn('Tank or Goblet is not selected.');
    // }
    
  };

  const handleCryotechColorChange = (event: SelectChangeEvent<string>) => {
    const selectedColor = event.target.value;
    console.log('Selected Cryotech Color:', selectedColor);
    setSelectedCryotechColor(selectedColor);
    // setIsCryotechColorDisabled(false);
  
    // Update Formik's field value for cryotech_color and clear touched state
    formik.setFieldValue('cryotech_color', selectedColor);
    formik.setFieldTouched('cryotech_color', false);
  
  };
  
  
  return (    
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose}     
        fullWidth maxWidth='lg'
        PaperProps={{  sx: {  borderRadius: '12px', width: '55%',  minWidth: '700px',},  }}>
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>Action
              <span style={{ display: 'flex', gap: '8px', marginTop: '5px' }}>
                {selectedEmbryos.map((num) => (
                  <span
                    key={num}
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      backgroundColor: '#47B35F',
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: 400,
                    }}
                  >
                    {num}
                  </span>
                ))}
              </span>
            </div>

            <IconButton
              edge='end'
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <More />
            </IconButton>
          </div>
        </DialogTitle>


        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <TextField
                  name="cryo_preserved_date"
                  type="date"
                  label={RequiredLabel('Cryo Preserved Date', true)}
                  fullWidth
                  value={formik.values.cryo_preserved_date || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.cryo_preserved_date && Boolean(formik.errors.cryo_preserved_date)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid2>
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_preserved_by"
                  options={clinicianLists['DOCTOR'] || []}
                  getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                  value={
                    clinicianLists['DOCTOR'].find(
                      (doctor) => doctor.id === formik.values.cryo_preserved_by // Use formik.values directly here
                    ) || null
                  }
                  onChange={(_, value) => {
                    // Manually update Formik's field value with the selected doctor's id
                    formik.setFieldValue('cryo_preserved_by', value?.id || '');  // Update with the clinician's ID
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={RequiredLabel('Cryo Preserved By', true)}

                      error={formik.touched.cryo_preserved_by && Boolean(formik.errors.cryo_preserved_by)}
                    />
                  )}
                />
              </Grid2>

                     
              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_purpose"
                  options={cryoPurposeItems}  
                  getOptionLabel={(option) => option.label}
                  
                  value={cryoPurposeItems.find(option => option.value === formik.values.cryo_purpose) || null}
                  onChange={(_, value) => {
                    formik.setFieldValue('cryo_purpose', value?.value || '');  // Update with the clinician's ID
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cryo Purpose"
                      error={formik.touched.cryo_purpose && Boolean(formik.errors.cryo_purpose)}
                    />
                  )}
                />
              </Grid2> 

              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="cryo_media"
                  options={mediaItems}  
                  getOptionLabel={(option) => option.label}
                  
                  value={mediaItems.find(option => option.value === formik.values.cryo_media) || null}
                  onChange={(_, value) => {
                    formik.setFieldValue('cryo_media', value?.value || '');  
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Media"
                      error={formik.touched.cryo_media && Boolean(formik.errors.cryo_media)}
                    />
                  )}
                />
              </Grid2> 

              <Grid2 size={{ xs: 4, sm: 4, md: 4 }}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  id="is_screened"
                  label="Screening Status"
                  value={formik.values.is_screened === true ? true : formik.values.is_screened === false ? false : ''}
                  onChange={(e) => {
                    formik.setFieldValue('is_screened', e.target.value === 'true' ? true : false);
                  }}
                  error={formik.touched.is_screened && Boolean(formik.errors.is_screened)}
                  helperText={formik.touched.is_screened && formik.errors.is_screened}
                >
                  <MenuItem value="true">Yes</MenuItem> 
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              </Grid2>
            </Grid2>
              

            <Grid2 container spacing={2} mt={3}>
              <Grid2 size={{ xs: 12}}>
                <Typography variant="subtitle1" component="div">
                Location
                </Typography>
              </Grid2>

              <Grid2 size={{ xs: 6, sm: 6, md: 6 }}>
                <TankSelect
                  tanks={tankStoragePlan}
                  selectedTank={selectedTank}
                  onChange={handleTankChange}
                  label="Tank"

                  hasError={formik.touched.tank && Boolean(formik.errors.tank)}
                />
              </Grid2>

              <Grid2 size={{ xs: 6, sm: 6, md: 6 }}>
                <CanisterSelect
                  canisters={canisters}
                  selectedCanister={selectedCanister}
                  onChange={handleCanisterChange}
                  label="Canister"
                  loading={loadingCanisters}
                  hasError={formik.touched.canister && Boolean(formik.errors.canister)}
                />
              </Grid2>
                  
              <Grid2 size={{ xs: 6, sm: 6, md: 6 }}>
                <CaneSelect
                  canes={canes}
                  selectedCane={selectedCane}
                  onChange={handleCaneChange}
                  label="Cane"
                  loading={loadingCanes}
                  hasError={formik.touched.cane && Boolean(formik.errors.cane)}
                />
              </Grid2>

              <Grid2 size={{ xs: 6, sm: 6, md: 6 }}>
                <GobletSelect
                  goblets={goblets}
                  selectedGoblet={selectedGoblet}
                  onChange={handleGobletChange}
                  label="Goblet"
                  loading={loadingGoblets}
                  hasError={formik.touched.goblet && Boolean(formik.errors.goblet)}
                />
              </Grid2>


              <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                <FormControl fullWidth variant="outlined" error={formik.touched.goblet_color && Boolean(formik.errors.goblet_color)}>
                  <InputLabel id="gradient-select-label">{RequiredLabel('Goblet Color', true)}</InputLabel>
                  <Select labelId="gradient-select-label" label="Goblet Color" 
                    value={selectedGobletColor || ''} 
                    disabled={isGobletColorDisabled}                    
                    onChange={handleGobletColorChange}
                    sx={{
                      height: '48px',
                      '& .MuiSelect-select': { 
                        display: 'flex',
                        alignItems: 'center', 
                      },
                    }}>
                    {gradientData.gradients.map((gradient) => (
                      <MenuItem key={gradient.name} value={gradient.name} sx={{ height: '48px' }}>                        
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <span
                            style={{
                              width: '16px',
                              height: '16px',
                              borderRadius: '50%',
                              background: gradient.background,
                              marginRight: '12px',
                              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
                            }}
                          ></span>
                          <Typography
                            sx={{
                              fontFamily: 'Montserrat, sans-serif',
                              fontWeight: 700,
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center', // Vertically center the text
                            }}
                          >
                            {gradient.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
              
              <Grid2 size={{ xs: 6, sm: 6, md: 6 }}>
                <CryotechSelect
                  cryotechs={cryotechs}
                  selectedCryotech={selectedCryotech}
                  onChange={handleCryotechChange}
                  label="Cryotech" 
                  loading={loadingCryotechs}
                  hasError={formik.touched.cryotech && Boolean(formik.errors.cryotech)}
                />
              </Grid2>

              <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}>
                <FormControl fullWidth variant="outlined" error={formik.touched.cryotech_color && Boolean(formik.errors.cryotech_color)}>
                  <InputLabel id="gradient-select-label">{RequiredLabel('Cryotech Color', true)}</InputLabel>
                  <Select
                    labelId="gradient-select-label"
                    label={RequiredLabel('Cryotech Color', true)}          
                    value={selectedCryotechColor || ''}                                         
                    onChange={handleCryotechColorChange}
                    sx={{
                      height: '48px',
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center', 
                      },
                    }}
                  >
                    {gradientData.gradients.map((gradient) => (
                      <MenuItem key={gradient.name} value={gradient.name} sx={{ height: '48px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                          <span
                            style={{
                              width: '16px',
                              height: '16px',
                              borderRadius: '50%',
                              background: gradient.background,
                              marginRight: '12px',
                              boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
                            }}
                          ></span>
                          <Typography
                            sx={{
                              fontFamily: 'Montserrat, sans-serif',
                              fontWeight: 700,
                              fontSize: '12px',
                              display: 'flex',
                              alignItems: 'center', 
                            }}
                          >
                            {gradient.name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>

            </Grid2>
          </DialogContent>
          <DialogActions>
            <button type="button" onClick={onClose} className={styles.resetButton}>
              Cancel
            </button>
            <button type="submit" className={`${styles.customButton}`}>
              <strong>Save</strong>
            </button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
};

export default FreezeActionDialog; 

