/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import styles from './LinkOocyteDonor.module.css';
import { ReactComponent as More } from '../../../assets/SVG/close-circle-greay.svg';
import { Checkbox } from '@mui/material';
import { useLoader } from '../../loaderProvider/loader';
import { Donor, getLinkOocyteDonor, putLinkOocyteDonor } from './LinkOocyteDonorHelper';
import { ConfirmationModal } from '../../confirmationModal';
import { usePatientContext } from '../../SubPatinetProvider';
import { useAlert } from '../../alertProvider';

interface LinkOocyteDonorProps {
  dialogTitle: string;
  prevDonorInfo?: Donor[] |null; 
  onClose: (data?: { donorIds: Donor[] }) => void; 
}
const LinkOocyteDonor: React.FC<LinkOocyteDonorProps> = ({ dialogTitle,prevDonorInfo, onClose }) => {
  const { showLoader, hideLoader } = useLoader();
  const [donors, setDonors] = useState<Donor[]>([]);
  const [filteredDonors, setFilteredDonors] = useState<Donor[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedDonors, setSelectedDonors] = useState<Donor[]>([]);
  const [cancelDialog, setCancelDialog] = useState<boolean>(false); 
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (prevDonorInfo) {
      // eslint-disable-next-line no-console
      console.log('Previous Donor Info:', prevDonorInfo);
      // Handle previous donor info here
    }
  }, [prevDonorInfo]);
  useEffect(() => {
    showLoader();

    const patientType =
      dialogTitle === 'Link Surrogate'
        ? 'SURROGATE'
        : dialogTitle === 'Link Sperm Donor'
          ? 'SPERM_DONOR'
          : dialogTitle === 'Link Oocyte Donor'
            ? 'OOCYTE_DONOR'
            : '';

    getLinkOocyteDonor(patientType)
      .then((response) => {
        setDonors(response.objects);
        setFilteredDonors(response.objects);


        if (prevDonorInfo && prevDonorInfo.length > 0) {
          setSelectedDonors(prevDonorInfo);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [dialogTitle, showLoader, hideLoader, prevDonorInfo]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query === '') {
      setFilteredDonors(donors);
    } else {
      const filteredData = donors.filter((donor) => {
        const fullName = `${donor.first_name} ${donor.last_name}`.toLowerCase();
        const searchQueryLower = query.toLowerCase();
        return (
          (fullName && fullName.includes(searchQueryLower)) ||
          (donor.id && donor.id.toString().includes(searchQueryLower)) ||
          (donor.mobile_number && donor.mobile_number.includes(searchQueryLower))
        );
      });
      setFilteredDonors(filteredData);
    }
  };

  const handleCheckboxChange = (donor: Donor) => {
    setSelectedDonors((prevSelected) =>
      prevSelected.find((d) => d.id === donor.id)
        ? prevSelected.filter((d) => d.id !== donor.id)
        : [...prevSelected, donor]
    );
  };

  const handleClearAll = () => {
    setCancelDialog(true); 
  };

  const handleConfirmClearAll = () => {
    setSelectedDonors([]);
    setCancelDialog(false); 
  };

  const handleCancelClearAll = () => {
    setCancelDialog(false); 
  };

  const handleSubmit = () => {

    const donorID= {
      donorIds: selectedDonors,
      dialogTitle:dialogTitle,
    };
    onClose(donorID); 
   
  };
  
  const getEyeColorBg = (eyeColor?: string): string => {
    const colorMap: { [key: string]: string } = {
      BLUE: '#A2CFFE',
      GREEN: '#6A8E5C',
      BROWN: '#4E3629',
      GRAY: '#778899',
      AMBER: '#926F34',
      BLACK: '#1C1C1C',
    };
  
    return colorMap[eyeColor?.toUpperCase() || ''] || '';  
  };

  const getHairColorBg = (hairColor?: string): string => {
    const colorMap: { [key: string]: string } = {
      BLONDE: '#FBF6D9',
      BROWN: '#3d2314',
      RED: '#B06500',
      BLACK: '#2C2C2C',
    };
  
    return colorMap[hairColor?.toUpperCase() || ''] || ''; 
  };

  const getSkinToneBg = (skinTone?: string): string => {
    const skinToneMap: { [key: string]: string } = {
      FAIR: '#F1C6A1',
      LIGHT: '#F5D0A9',
      MEDIUM: '#D09A6E',
      OLIVE: '#808000',
      'LIGHT BROWN': '#C68E17',
      'DARK BROWN': '#3E1C41',
      EBONY: '#2A1A1F',
      ROSY: '#F1A7C1',
    };
  
    return skinToneMap[skinTone?.toUpperCase() || ''] || ''; 
  };
  
  return (
    <div className={`${styles.container} mt-4 p-0`}>
      <div className="px-4">
        <div className="d-flex justify-content-between">
          <span className={styles.titleText}>{dialogTitle}</span>
          <More
            className={styles.floatEnd}
            onClick={() =>
              onClose(selectedDonors.length  > 0 && prevDonorInfo && prevDonorInfo?.length >0 ? { donorIds: selectedDonors } : undefined)

            }
          />
        </div>

        <div>
          <p className={styles.textmuted}>Select a donor to link to the patient</p>
        </div>
      </div>

      <hr />

      <div className="px-4">
        <div className="row gx-0">
          <div className={'row gx-0'}>
            <input
              type="text"
              name="name"
              className={styles.inputField}
              placeholder="Search by Donor Name, Donor ID or Contact"
              value={searchQuery}
              onChange={handleSearchChange} 
            />
          </div>
        </div>
        <div className="row gx-0 mt-4">
          <div className={styles.tableContainer}>
            <table className={styles.customTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>Donor Name</th>
                  <th>Contact</th>
                  <th>ID</th>
                  <th>Eye Color</th>
                  <th>Hair Color</th>
                  <th>Skin Color</th>
                  <th>Blood Group</th>
                </tr>
              </thead>
              <tbody>
                {filteredDonors.map((donor) => (
                  <tr key={donor.id}>
                    <td>
                      <Checkbox
                        checked={selectedDonors.some((d) => d.id === donor.id)}
                        onChange={() => handleCheckboxChange(donor)}
                        sx={{
                          '&.Mui-checked': {
                            color: 'green',
                          },
                        }}
                      />
                    </td>
                    <td>{donor.first_name} {donor.last_name}</td>
                    <td>
                      {donor.mobile_number ? `(${donor.mobile_number_country_code || ''}) ${donor.mobile_number}` : '-'}
                    </td>
                    <td>{donor.medical_record_no || '-'}</td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        {/* Red circle with dynamic background color */}
                        <div
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            backgroundColor: getEyeColorBg(donor.eye_color), // Dynamically set background color
                          }}
                        ></div>
                        {/* Eye color text */}
                        {donor.eye_color ? donor.eye_color : '-'}
                      </div>
                    </td>
         
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        {/* Circle with dynamic background color */}
                        <div
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            backgroundColor: getHairColorBg(donor.hair_color_as_adult), // Handle undefined here
                          }}
                        ></div>
            
                        {donor.hair_color_as_adult ? donor.hair_color_as_adult : '-'}
                      </div>
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        {/* Circle with dynamic background color */}
                        <div
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            backgroundColor: getSkinToneBg(donor.skin_tone || ''), // Handle undefined or null here
                          }}
                        ></div>
                        {/* Skin tone text */}
                        {donor.skin_tone || '-'} 
                      </div>
                    </td>
                    <td>{donor.blood_group || '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row gx-0 mt-4">
            <div className={`${styles.buttonDiv} col-md-12 p-0 mt-5`}>
              <button
                className={`${styles.cancel} ${styles.small}`}
                onClick={handleClearAll} 
              >
                Clear All
              </button>
              <button
                className={`${styles.save}`}
                style={{
                  marginBottom: '20px',
                }}
                onClick={() => setOpenModal(true)} 
              >
  Link Donor
              </button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={cancelDialog}
        handleClose={handleCancelClearAll} 
        onConfirm={handleConfirmClearAll} 
        title="Clear election"
        contentMessage="Are you sure you want to clear all selected donors?"
        type={'warning'}
      />

      <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(false)} 
        onConfirm={handleSubmit} 
        title="Link Donor"
        contentMessage="Are you sure you want to link this donor?"
        type={'warning'}
      />
    </div>
  );
};

export default LinkOocyteDonor;
