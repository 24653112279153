/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { getLogs,getLogsCount,LogsData, mapLogsData, updatedLabels } from './LogsComponentHelper';
import { ReactComponent as INFO } from '../../assets/SVG/MaterialInfoCircle.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './CryoThawingComponent.module.css';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as CryobackgroundImg } from '../../assets/SVG/Cryobackgroundimg.svg';
import { useLoader } from '../loaderProvider/loader';
import { usePatientContext } from '../SubPatinetProvider';
interface LogsComponentProps {
  label: string;
}
 
const LogsComponent: React.FC<LogsComponentProps> = ({ label }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | SVGSVGElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<'Thawed' | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); 
  const [data, setData] = useState<LogsData[]>([]);
  const [dynamicLabels, setDynamicLabels] = useState<any[]>([]);
  const { showLoader, hideLoader } = useLoader();
  const { patientId } = usePatientContext();
  const [totalCryopreserved, setTotalCryopreserved] = useState<number>(0);
  const [totalThawed, setTotalThawed] = useState<number>(0);
  const [totalCultured, setTotalCultured] = useState<number>(0);
  const [totalTransferred, setTotalTransferred] = useState<number>(0);
  const [totalResearched, setTotalResearched] = useState<number>(0);
  const [totalDiscarded, setTotalDiscarded] = useState<number>(0);
  const [totalSurvived, setTotalSurvived] = useState<number>(0);
  const [remainingBalance, setRemainingBalance] = useState<number>(0);
  const handlePopoverOpen = ( 
    event: React.MouseEvent<SVGSVGElement | HTMLElement>,
    content: 'Thawed',
    index: number 
  ) => {
    setPopoverAnchorEl(event.currentTarget as HTMLElement | SVGSVGElement);
    setPopoverContent(content);
    const item = data[index];
    const labels = updatedLabels(item); 
    setDynamicLabels(labels); 
  
  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
    setPopoverContent(null);
  };

  const handleOpenDialog = (planInfo: string) => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    showLoader();
    getLogs(patientId, label)
      .then((fetchedData) => {
        const mappedData = Array.isArray(fetchedData) ? mapLogsData(fetchedData) : [];
        let totalCryo = 0;
        let totalThaw = 0;
        let totalCulture = 0;
        let totalTransfer = 0;
        let totalResearch = 0;
        let totalDiscard = 0;
        let totalSurvival = 0;
        let totalBalance = 0;
        mappedData.forEach((item) => {
          totalCryo += item.cryo_count || 0;
          totalThaw += item.thaw_count || 0;
          totalCulture += item.culture_count || 0;
          totalTransfer += item.transfer_count || 0;
          totalResearch += item.research_count || 0;
          totalDiscard += item.discard_count || 0;
          totalSurvival += item.survival_count || 0;
          totalBalance += item.balance || 0;
        });
        setData(mappedData);
        setTotalThawed(totalThaw);
        setTotalCultured(totalCulture);
        setTotalTransferred(totalTransfer);
        setTotalResearched(totalResearch);
        setTotalDiscarded(totalDiscard);
        setTotalSurvived(totalSurvival);
        // setRemainingBalance(totalBalance);
        setErrorMessage(null);
      })
      .catch((error) => {
        const errorDetail = error?.errors?.[0]?.detail || 'An unknown error occurred while fetching the data.';
        console.error('Error fetching logs data:', error);
        setErrorMessage(errorDetail);
      })
      .finally(() => {
        hideLoader();
      });
  
    getLogsCount(patientId, label.toLowerCase())
      .then((response) => {
        try {
          const totalCryoPreserved = response?.total_count || 0;
          setTotalCryopreserved(totalCryoPreserved);
  
          // Use the latest values from state inside useEffect to update the balance
          setRemainingBalance((prevRemainingBalance) => 
            totalCryoPreserved - ((totalThawed || 0) + (totalDiscarded || 0))
          );
  
        } catch (error) {
          setTotalCryopreserved(0);
        }
      })
      .catch((error) => {
        console.error('Error fetching logs:', error);
      });
  
  }, [label, patientId, showLoader, hideLoader, totalThawed, totalDiscarded]);
  
  
  return (
    <div className={styles.oocyteContainer}>
      {data.length > 0 ? (
        <>
          <table className={styles.fullWidthTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Cycle Reference</th>
                <th>#Cryopreserved</th>
                <th>#Thawed</th>
                <th>#Survived</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.cryo_date ? item.cryo_date : '-'}</td>
                  <td>{item.cycle_number ? item.cycle_number : '-'}</td>
                  <td>{item.cryo_count ? item.cryo_count : '-'}</td>
                  <td>
                    <INFO
                      style={{ marginRight: '8px', cursor: 'pointer' }}
                      onClick={(e) => handlePopoverOpen(e, 'Thawed', index)}
                    />
                    {item.thaw_count !== null ? item.thaw_count : '-'}
                  </td>
                  <td>{item.survival_count !== null ? item.survival_count : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr className={styles.divider} />
          <Grid container spacing={2} className="mt-4" justifyContent="space-between" wrap="wrap">
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Cryopreserved: <span className={styles.value}>{totalCryopreserved}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
                Total Thawed: <span className={styles.value}>{totalThawed}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Transferred: <span className={styles.value}>{totalTransferred}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Cultured: <span className={styles.value}>{totalCultured}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Researched: <span className={styles.value}>{totalResearched}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Discarded: <span className={styles.value}>{totalDiscarded}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Total Survived: <span className={styles.value}>{totalSurvived}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={1.5}>
              <div className={styles.griditem}>
              Remaining Balance: <span className={styles.value}>{remainingBalance}</span>
              </div>
            </Grid>
          </Grid>

          {/* Popover for Material Information only */}
          <Popover
            id="dynamic-popover"
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
              <Typography sx={{
                flexGrow: 1,
                fontFamily: 'Montserrat',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '24.38px',
                textAlign: 'left',
                color: '#232323',
              }}>
                Thawed <span className={styles.thawsubinfo}>(#Thaw : )</span>
              </Typography>
              <More onClick={handleClosePopover} style={{ cursor: 'pointer', marginLeft: '25px' }} />
            </Box>
            <hr className={styles.divider} />
            {/* Material information content */}
            {popoverContent === 'Thawed' && dynamicLabels.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <Typography sx={{
                  flexGrow: 1,
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '19.5px',
                  textAlign: 'left',
                  color: '#737373',
                }}>
                  {item.label}
                </Typography>
                <Typography sx={{
                  ml: 2,
                  fontFamily: 'Nunito',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '21.82px',
                  textAlign: 'left',
                  color: '#232323',
                }}>
                  {item.value}
                </Typography>
              </Box>
            ))}
          </Popover>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <CryobackgroundImg />
        </div>
      )}
    </div>
  );

};

export default LogsComponent;
