// convert local date time to backend
export function convertDateToISOWithOffset(date: Date): string {
  const timeZoneOffsetInMinutes = date.getTimezoneOffset();
  const localTime = new Date(date.getTime() - timeZoneOffsetInMinutes * 60000);
  
  // Format the date to ISO string without the Z
  const isoStringWithoutZ = localTime.toISOString().replace('Z', '');
  
  // Calculate the local time zone offset in hours and minutes
  const sign = timeZoneOffsetInMinutes > 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(timeZoneOffsetInMinutes) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(timeZoneOffsetInMinutes) % 60)
    .toString()
    .padStart(2, '0');
  // Return the ISO string with the local time zone offset
  return `${isoStringWithoutZ}${sign}${hours}:${minutes}`;
}
export function formatDateToISOWithOffsetFilter(date: Date): string {
  const timeZoneOffsetInMinutes = date.getTimezoneOffset(); // Get local timezone offset
  const localTime = new Date(date.getTime() - timeZoneOffsetInMinutes * 60000);
  
  // Format date to 'YYYY-MM-DDTHH:mm:ss.SSS' (without Z)
  const isoStringWithoutZ = localTime.toISOString().slice(0, -1); 

  // Get timezone offset in ±HH:mm format
  const sign = timeZoneOffsetInMinutes > 0 ? '-' : '+'; // Offset direction
  const hours = Math.floor(Math.abs(timeZoneOffsetInMinutes) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(timeZoneOffsetInMinutes) % 60)
    .toString()
    .padStart(2, '0');

  return `${isoStringWithoutZ}${sign}${hours}:${minutes}`;
}


// convert backend date time to Local UI date time
export function convertToLocalDateTime(utcDateTimeString: string): Date {
  // Create a Date object from the UTC datetime string.
  const utcDate = new Date(utcDateTimeString);
  
  // Convert the UTC date to local time.
  const localDate = new Date(utcDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
  
  return localDate;
}

export function convertUTCToLocalTime(utcTime: string) {
  // Split the UTC time string into hours, minutes, and seconds
  const [hours, minutes, seconds] = utcTime.split(':').map(Number);

  // Get the current date in UTC
  const now = new Date();

  // Create a new Date object in UTC with the same date and specified time
  const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hours, minutes, seconds));

  // Convert to local time by simply instantiating a new Date from the UTC date
  const localDate = new Date(utcDate);

  // Format the local time in hh:mm:ss
  const localHours = localDate.getHours().toString().padStart(2, '0');
  const localMinutes = localDate.getMinutes().toString().padStart(2, '0');
  const localSeconds = localDate.getSeconds().toString().padStart(2, '0');

  return `${localHours}:${localMinutes}:${localSeconds}`;
}

export function convertUTCToLocalTimeDate(utcDate: Date): string {
  // Ensure the input is a Date object
  if (!(utcDate instanceof Date)) {
    throw new Error('Invalid date object');
  }

  // Create a local date from the UTC date
  const localDate = new Date(utcDate);

  // Extract hours, minutes, and seconds in local time
  const localHours = localDate.getHours().toString().padStart(2, '0');
  const localMinutes = localDate.getMinutes().toString().padStart(2, '0');
  const localSeconds = localDate.getSeconds().toString().padStart(2, '0');

  return `${localHours}:${localMinutes}:${localSeconds}`;
}

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatDateToLocalYYYYMMDD(date: Date): string {
  const year = date.getFullYear(); // Get local year
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get local month (0-indexed)
  const day = date.getDate().toString().padStart(2, '0'); // Get local day of the month
  return `${year}-${month}-${day}`;
}

export function convertISOToTime(isoString: string, isSuffix: boolean) {
  const date = new Date(isoString);

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Determine AM or PM suffix
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight

  // Return in "HH:MMam/pm" format
  if(isSuffix){
    return `${hours.toString().padStart(2, '0')}:${minutes} ${ampm}`;
  } else {
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  }
}

export const formatDateToDisplay = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Get the local time zone abbreviation
  const timeZoneName = date.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];

  // Combine the date, time, and time zone for human readability
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${timeZoneName}`;
};

export function formatDateToDDMMYYYY(dateString: string | number | Date) {
  const date = new Date(dateString);
  
  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const convertToUTCFormat = (localTime: string): string => {
  const [hours, minutes] = localTime.split(':').map(Number);

  // Create a Date object with today's date and the time in local time
  const now = new Date();
  now.setHours(hours, minutes, 0, 0);

  // Convert to UTC and format as hh:mm:ss.uuuuuu
  const utcHours = now.getUTCHours().toString().padStart(2, '0');
  const utcMinutes = now.getUTCMinutes().toString().padStart(2, '0');
  const utcSeconds = now.getUTCSeconds().toString().padStart(2, '0');
  const utcMilliseconds = now.getUTCMilliseconds().toString().padStart(6, '0');

  return `${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds}`;
};


export function convertUTCToDesiredTimezone(utcDateString: string, desiredTimezoneOffset: number = 0): string {
  // Check if input is a string
  if (typeof utcDateString !== 'string') {
    throw new Error('Invalid input. UTC date string must be a string.');
  }

  // Check if desired timezone offset is a number
  if (typeof desiredTimezoneOffset !== 'number') {
    throw new Error('Invalid desired timezone offset. It must be a number.');
  }

  // Parse the UTC date string
  const utcDate = new Date(utcDateString);
  if (isNaN(utcDate.getTime())) {
    throw new Error('Invalid UTC date string.');
  }

  // Calculate the desired timezone date
  const desiredDate = new Date(utcDate.getTime() + (desiredTimezoneOffset * 60 * 1000));

  // Extract hours, minutes, and seconds in desired timezone
  const hours = desiredDate.getHours();
  const minutes = desiredDate.getMinutes();

  // Determine AM/PM
  const amPm = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const displayHours = hours % 12 === 0 ? 12 : hours % 12;

  // Format the time
  const formattedTime = `${displayHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;

  return formattedTime;
}

export function getNumberOfDays(start: Date | string | object, end: Date | string | object): {days: number | string, timeOfDay: string} {
  const startDate = new Date(start as string | number | Date);
  const endDate = new Date(end as string | number | Date);
  
  // Calculate the difference in milliseconds
  const differenceInMs = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1).toString();
  const newISTstartDate = new Date(startDate.setMinutes(startDate.getMinutes() + 330));
  // Convert milliseconds to days
  return {days : differenceInMs , timeOfDay: newISTstartDate.toTimeString().split(' ')[0].slice(0, 5) };
}