/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Autocomplete, Box, Grid2, IconButton, MenuItem, TextField, ThemeProvider } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { theme } from '../../../theme';
import HPIBack from '../../../assets/SVG/HPIBack.svg';
import { getDoctors, User } from '../../Diagnosis/diagnosisHelper';
import { DrugRouteMenuItems, FrequencyMenuItems, getdrug, getRoute, initialPatientDrugsCreateValues, InstructionsMenuItems, PatientDrugsCreate, PatientDrugsCreateValidationSchema, postdrug, PostPatientDrugs, putdrug, RouteData } from './DrugsHomeHelper';
import { usePatientContext } from '../../SubPatinetProvider';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import { patientDetailsObservable } from '../../../services/sharedService';
import { Field, FieldArray, FieldProps, Form, Formik, FormikErrors } from 'formik';
import { RequiredLabel } from '../../../utils/commonUtils';
import { ConfirmationModal } from '../../confirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';


interface AddDrugsProps {
    onBackClick: () => void;
    data: any; // This is the prop you're passing (the selected data)
  }

const AddDrugs: React.FC<AddDrugsProps> = ({ onBackClick, data }) => {

  const [inputValue, setInputValue] = useState(''); // State for input value
  const [drug, setdrug] = useState<any[]>([]);
  const [selecteddrug, setSelecteddrug] = useState(null);
  const [doctors, setDoctors] = useState<User[]>([]);
  const [initialValues, setInitialValues] = useState<{ drug: PatientDrugsCreate[] }>({ drug: [initialPatientDrugsCreateValues],  });
  const { patientId } = usePatientContext();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false); 
  const [contentMessage , setcontentMessage]=useState('');
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [patientDetails, setpatientDetails] = useState<any >(null);
  const { id } = useParams<{ id: string }>(); 
  const [drugRoutes, setDrugRoutes] = useState<{ label: string; value: string }[]>([]);
 
  

  useEffect(() => {
    const subscription = patientDetailsObservable.subscribe((value) => {
      setpatientDetails(value);
    });
    
    return () => subscription.unsubscribe();
  }, []);
  useEffect(() => {
    getDoctors().then((res) => {
      setDoctors(res.objects);
    });
  }, []);

  const handleSelectionChange = async (
    value: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<{ drug: PatientDrugsCreate[]; }>>,
    values: { drug: any[] }
  ) => {
    if (value) {
      const filtereddrug = values.drug.filter((drug) => drug.drug);
  
      const isDuplicate = filtereddrug.some((drug) => drug.drug === value.id);
  
      if (isDuplicate) {
        addAlert('This drug has already been added.', 'info');
        return;
      }
  
      await setFieldValue('drug', [
        ...filtereddrug,
        {
          patient: id,
          drug: value.id,
          drug_name: value.molecule,
          drug_brandName: value.brand_name,
          drug_UOM:value.stock_uom,
          drug_strength:value.strength,
          frequency: '',

          instructions: null, 
          no_of_days: null, 
          quantity: null, 
          notes: '', 
          user: null, 
          drug_route: null,
        },
      ]);
    }
  };
  
  

  const onBack = () => {
    onBackClick();
  };

  const handleSearch = (searchValue: string) => {
    
    if (searchValue.length>0) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const query = `?search=${searchValue}`;
        getdrug(query).then((res) => {
          setdrug(res.objects);
        });
      }, 300);
    }
  };

  const handleSubmit = (values: any, { setSubmitting, resetForm }: any) => {
    showLoader();
    const convertedData:PostPatientDrugs[] = values.drug.map((test:any) => {
      
      return {
        patient: test.patient,       
        drug: test.drug,   
        frequency: test.frequency,         
        instructions: test.instructions,  
        no_of_days:test.no_of_days,
        quantity:test.quantity,
        notes: test.notes || '',
        drug_route:test.drug_route || '',
        user:  test.user,
      };
    });
    
    if (data) {
      Promise.all(convertedData.map(drugs => putdrug(data.id, drugs)))
        .then(() => {
          addAlert('drug Updated successfully.', 'success');
          onBackClick();
        
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.error('An error occurred:', error);
          addAlert('An error occurred while Updating the drug. Please try again.', 'error');
        })
        .finally(()=>{
          hideLoader();
        });
    } else {
      Promise.all(convertedData.map(data => postdrug(data)))
        .then(() => {
          addAlert('drug added successfully.', 'success');
          onBackClick();
        
        })
        .catch((error) => {
        // eslint-disable-next-line no-console
          console.error('An error occurred:', error);
          addAlert('An error occurred while saving the drug. Please try again.', 'error');
        })
        .finally(()=>{
          hideLoader();
        });
    }
    
    
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  useEffect(() => {
    if (data) {
      const doctor = doctors.find(doc => doc.first_name === data.user_first_name);
      setInitialValues({
        drug: [{
          id: data.id,
          drug: data.drug.id, 
          drug_name: data.drug.molecule,
          drug_brandName: data.drug.brand_name,
          drug_strength: data.drug.strength,
          drug_UOM: data.drug.stock_uom_code,
          frequency: data.frequency, 

          instructions: data.instructions,
          no_of_days: data.no_of_days, 
          quantity: data.quantity, 
          notes: data.notes, 
          user: doctor ? doctor.id : null, 
          patient: data.patient, 
          drug_route: data.drug.route_code, 
        }],
      });
    }
  }, [data, patientId, doctors]);

  const [missingFields, setMissingFields] = useState<{ [index: number]: string[] }>({});

  const handleOpenModal = (values: any) => {

    let allFieldsFilled = true;
    const updatedMissingFields: { [index: number]: string[] } = {};
    if (Array.isArray(values.drug)) {
      values.drug.forEach((row: any, index: number) => {
        const missingFields: string[] = [];
    
        if (!row.drug_name) missingFields.push('drug_name');
        if (!row.drug_brandName) missingFields.push('drug_brandName');
        if (!row.frequency) missingFields.push('frequency');
        if (!row.instructions) missingFields.push('instructions');
        if (!row.quantity) missingFields.push('quantity');
        if (!row.no_of_days) missingFields.push('no_of_days');
        if (!row.user) missingFields.push('user');
        if (!row.drug_route) missingFields.push('drug_route');
        if (missingFields.length > 0) {
          updatedMissingFields[index] = missingFields;
          allFieldsFilled = false;
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('values.drug is undefined or not an array');
      allFieldsFilled = false;
    }
    setMissingFields(updatedMissingFields);
  
    if (allFieldsFilled) {
      if (data) {
        setcontentMessage('Are you sure you want to Update order for Selected drugs?');

      } else {
        setcontentMessage('Are you sure you want to Place Order for selected drugs?');
        
      }
      setOpenConfirmDialog(true);
      
    }
  };
  
  useEffect(() => {
    getRoute()
      .then((response) => {
        setDrugRoutes(
          response?.objects.map((route: RouteData) => ({
            label: route.description, 
            value: route.code,       
          }))
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching routes:', error);
      });
  }, []);
    
  const calculateQuantity = (frequency: string | null, days?: number | null): number => {
    if (!frequency || !days) return 0;
  
    // Convert "1/2" to 0.5, split numbers by "-"
    const doses = frequency.split('-').map((dose) => (dose === '1/2' ? 0.5 : parseInt(dose, 10) || 0));
  
    // Sum up the doses in a day
    const totalPerDay = doses.reduce((sum, val) => sum + val, 0);
  
    // Multiply by the number of days
    return totalPerDay * days;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true} 
      validationSchema={PatientDrugsCreateValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        dirty,
        touched,
        errors,
        setFieldTouched,
        submitForm,
        validateForm,
      }) => {
        return (
          <>
            <ThemeProvider theme={{theme}}>
              <div>
                <Grid2 container spacing={2}>
                  <Grid2 size={5} display="flex" justifyContent="start">
                    <IconButton onClick={onBack}>
                      <img src={HPIBack} alt="Back" style={{ width: '2.5rem', height: '2.5rem' }} />
                    </IconButton>
                  </Grid2>
                </Grid2>
              </div>
              <Form  onSubmit={(e) => {
                e.preventDefault();
              }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid2 container spacing={2}>
                    <Grid2 size={7}> {/* Occupies 6 out of 12 columns */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start', // Aligns to the start
                          marginBottom: 5,
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          fullWidth
                          disabled={data}
                          open={drug.length > 0  && inputValue.length > 0} 
                          options={inputValue.trim() ? drug : []}
                          value={selecteddrug} // Bind the selected value
                          inputValue={inputValue} // Bind the input value
                          onChange={(e, value) => {
                            handleSelectionChange(value, setFieldValue, values);
                            setInputValue('');
                          }}
                          onInputChange={(e, value) => {
                            setInputValue(value); // Update input value state
                            handleSearch(value);
                          }}
                          getOptionLabel={(option) => `${option.name || ''}`}
                          renderOption={(props, option) => (
                            <li {...props}>
                              {option.name}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Medicine"
                              placeholder="Search by Drug Name, Generic Name"
                            />
                          )}
                          noOptionsText="No related option related to searched term"
                        />
                      </Box>
                    </Grid2>
                  </Grid2>
                  {/* Dynamic Fields for Diagnoses */}
                  <FieldArray
                    name="drug"
                    render={({ remove }) => (
                      <>
                        {values.drug.map((row, index) => (
                          <Grid2 container spacing={2} marginBottom={3} key={index} alignItems="center">

                            
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <TextField
                                value={row.drug_brandName !== null ? row.drug_brandName  : ''}
                                variant="outlined"
                                name='drug_brandName'
                                size="small"
                                fullWidth
                                disabled
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={RequiredLabel('Drug Name', true)}
                                error={!!missingFields[index]?.includes('drug_brandName') && !row.drug_brandName} 
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '&.Mui-error': {
                                      '& fieldset': {
                                        borderColor: 'red', 
                                      },
                                    },
                                  },
                                }}
                              />

                            </Grid2>
                           
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                      
                              <TextField
                                value={row.drug_name !== null ? row.drug_name  : ''}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={RequiredLabel('Generic Name', true)}
                                error={!!missingFields[index]?.includes('drug_name') && !row.drug_name}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '&.Mui-error': {
                                      '& fieldset': {
                                        borderColor: 'red', 
                                      },
                                    },
                                  },
                                }}
                              />

                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}}>

                              <TextField
                                value={row.drug_strength !== null ? row.drug_strength  : null}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled
                                InputLabelProps={{ shrink: row.drug_brandName ? true: false }}
                                label={RequiredLabel('Drug Strength', false)}
                                error={!!missingFields[index]?.includes('drug_strength') && !row.drug_strength} 
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '&.Mui-error': {
                                      '& fieldset': {
                                        borderColor: 'red', 
                                      },
                                    },
                                  },
                                }}
                              />
  
                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}}>

                              <TextField
                                value={row.drug_UOM !== null ? row.drug_UOM  : ''}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled
                                
                                label={RequiredLabel('UOM', false)}
                                error={!!missingFields[index]?.includes('drug_UOM') && !row.drug_UOM} 
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    '&.Mui-error': {
                                      '& fieldset': {
                                        borderColor: 'red', 
                                      },
                                    },
                                  },
                                }}
                              />
                           
                            </Grid2>

                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].drug_route`}
                                render={({ field }: FieldProps) => (
                                  <Autocomplete
                                    options={drugRoutes} // Dynamically fetched options
                                    getOptionLabel={(option) => option.label}
                                    value={drugRoutes.find((item) => item.value === row?.drug_route) || null}
                                    onChange={(event, newValue) => {
                                      setFieldValue(`drug[${index}].drug_route`, newValue ? newValue.value : '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        {...field}
                                        label={RequiredLabel('Route', true)}
                                        variant="outlined"
                                        
                                        size="small"
                                        fullWidth
                                        error={!!missingFields[index]?.includes('drug_route') && !row.drug_route}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '&.Mui-error': {
                                              '& fieldset': {
                                                borderColor: 'red',
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid2>                            
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].frequency`}
                                render={({ field }: FieldProps) => (
                                  <Autocomplete
                                    options={FrequencyMenuItems}
                                    getOptionLabel={(option) => option.label}
                                    value={FrequencyMenuItems.find((item) => item.value === row.frequency) || null}
                                    onChange={(event, newValue) => {
                                      const selectedFrequency = newValue ? newValue.value : '';
                                      setFieldValue(`drug[${index}].frequency`, selectedFrequency);
                            
                                      // Recalculate quantity when frequency changes
                                      const calculatedQty = calculateQuantity(selectedFrequency, row.no_of_days);
                                      setFieldValue(`drug[${index}].quantity`, calculatedQty);
                                    }}


                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        {...field}
                                        label={RequiredLabel('Frequency', true)}
                                        variant="outlined"
                                        
                                        size="small"
                                        fullWidth
                                        error={!!missingFields[index]?.includes('frequency') && !row.frequency}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '&.Mui-error': {
                                              '& fieldset': {
                                                borderColor: 'red',
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />

                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].instructions`}
                                render={({ field }: FieldProps) => (
                                  <Autocomplete
                                    options={InstructionsMenuItems}
                                    getOptionLabel={(option) => option.label} // Map the options to their labels
                                    value={InstructionsMenuItems.find((item) => item.value === row.instructions) || null}
                                    onChange={(event, newValue) => {
                                      setFieldValue(`drug[${index}].instructions`, newValue ? newValue.value : '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        {...field}
                                        label={RequiredLabel('Instructions', true)}
                                        variant="outlined"
                                        
                                        size="small"
                                        fullWidth
                                        error={!!missingFields[index]?.includes('instructions') && !row.instructions}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '&.Mui-error': {
                                              '& fieldset': {
                                                borderColor: 'red',
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />

                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].no_of_days`}
                                as={TextField}
                                label={RequiredLabel('Day(s)', true)}

                                type="number"
                                variant="outlined"
                                fullWidth
                                
                                size="small"
                                value={row.no_of_days !== null ? row.no_of_days : ''}
                                error={!!missingFields[index]?.includes('no_of_days')&& !row.no_of_days} 
                                onKeyUp={(e:any) => {
                                  const days = e.target.value ? parseInt(e.target.value, 10) : 0;
                                  setFieldValue(`drug[${index}].no_of_days`, days);
                            
                                  // Recalculate quantity when days change
                                  const calculatedQty = calculateQuantity(row.frequency, days);
                                  setFieldValue(`drug[${index}].quantity`, calculatedQty);
                                }}
                              />
                            </Grid2>

                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].quantity`}
                                as={TextField}
                                label={RequiredLabel('Qty', true)}
                                type="number"
                                variant="outlined"
                                fullWidth
                                
                                size="small"
                                value={row.quantity !== null ? row.quantity : ''}
                                error={!!missingFields[index]?.includes('quantity')&& !row.quantity} 

                              />
                            </Grid2>


                           

                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].user`}
                                render={({ field }: FieldProps) => (
                                  <Autocomplete
                                    options={doctors}
                                    getOptionLabel={(option) => `Dr. ${option.first_name} ${option.last_name}`}
                                    value={doctors.find((doctor) => doctor.id === row.user) || null}
                                    onChange={(event, newValue) => {
                                      setFieldValue(`drug[${index}].user`, newValue ? newValue.id : '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        {...field}
                                        label={RequiredLabel('Doctor', true)}
                                        variant="outlined"
                                        
                                        size="small"
                                        fullWidth
                                        error={!!missingFields[index]?.includes('user') && !row.user}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '&.Mui-error': {
                                              '& fieldset': {
                                                borderColor: 'red',
                                              },
                                            },
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />

                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}}>
                              <Field
                                name={`drug[${index}].notes`}
                                as={TextField}
                                label="Notes"
                                variant="outlined"
                                fullWidth
                                
                                size="small"
                                value={row.notes !== null ? row.notes : ''}
                              
                              />
                            </Grid2>
                            <Grid2 size={{xl:2, lg:3, md:4}} sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                              <IconButton  onClick={() => {
                                if (values.drug.length === 1 && values.drug[index]?.drug_brandName) {
                                  setFieldValue('drug', [
                                    {
                                      patient: id,
                                      drug: null,
                                      drug_name: null,
                                      drug_brandName: null,
                                      drug_UOM:null,
                                      drug_strength:null,
                                      frequency: '',
                            
                                      instructions: null, 
                                      no_of_days: null, 
                                      quantity: null, 
                                      notes: '', 
                                      user: null, 
                                      drug_route: null,
                                    },
                                  ]);
                                } else if (values.drug.length > 1) {
                                  remove(index);
                                } else {
                                  addAlert('At least one disease history record must remain.', 'warning');
                                }
                              }}
                              disabled={data}
                              color="error"
                              sx={{ textTransform: 'none' }}>
                                <DeleteIcon />
                              </IconButton>
                            </Grid2>

                            {index < values.drug.length - 1 && (
                              <Grid2 size={12}>
                                <hr />
                              </Grid2>
                            )}
                          </Grid2>
                          
                        ))}
                      </>
                    )} /> 
                </Box>
                {/* Submit Button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                  <button
                    onClick={() => handleOpenModal(values)}
                    style={{
                      padding: '8px 10px',
                      border: 'none',
                      borderRadius: '7px',
                      fontSize: '16px',
                      fontWeight: '700',
                      backgroundColor:'#505050',
                      color:'#FFFFFF',
                      width: '130px'
                    }}
                  >
                    {data ? 'Update Order' : 'Place Order'}
                  </button>
                </Box>
              </Form>
              <ConfirmationModal
                open={openConfirmDialog}
                handleClose={handleCloseConfirmDialog}
                onConfirm={() => {
                  handleSubmit();
                }}
                type="save"
                title="Place Order"
                contentMessage={contentMessage}
              />
            </ThemeProvider></>
        );
      }}
    </Formik>
  );

};

export default AddDrugs;