/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import { Accordion, AccordionSummary, AccordionDetails, Button, Dialog, DialogContent, Typography, Box, TextField, Autocomplete, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CycleData, getCycle, getStim, OptionTypeNode, Stim } from './helper.stim';
import { convertToLocalDateTime, formatDateToYYYYMMDD } from '../../services/time';
import { AxiosError } from 'axios';
import { useAlert } from '../../components/alertProvider';
import { usePatientContext } from '../../components/SubPatinetProvider';
import { useCycle } from '../../components/cycleContext/CycleContext';
import CreateNewCycle from '../../components/CycleOverViewList/CreateNewCycle';
import TerminateCycleConfirmation from '../../components/CycleOverViewList/TerminateCycleConfirmation';
import { Cycle, getcycleList } from '../../components/CycleOverViewList/CycleOverviewListHelper';
import { navigateTo } from '../../services/nevigationService';

interface CycleSelectorProps {
    onCycleChange: (event: React.SyntheticEvent | null, value: number | null) => void;
    patient: number
}


const CycleSelector: React.FC<CycleSelectorProps> = ({onCycleChange, patient}) => {

  const [stim, setStim] = useState<OptionTypeNode[] | null>();
  const [selectedStim, setSelectedStim] = useState<Stim | null>();
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>();
  const [medicationProtocols, setMedicationProtocols] = useState<{ [key: string]: string[] }>();
  const [cycle, setStselectedCycle] = useState<OptionTypeNode | null>();
  const [cyclesData, setCyclesData] = useState<Cycle[]>([]); 
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [isCreateCycleDialogOpen, setIsCreateCycleDialogOpen] = useState<boolean>(false);
  const [isTerminateCycleDialogOpen, setIsTerminateCycleDialogOpen] = useState<boolean>(false);
  const [selectedIdTerminate, setSelectedIdTerminate] = useState<string | null>(null);
  const [currCycleStatus, setCurrCycleStatus ] = useState<string | undefined>(undefined);

  const {addAlert} = useAlert();
  const { patientId } = usePatientContext(); 
  const { setCycleId } = useCycle();

  useEffect(() => {
    getCycle(patient).then((value: CycleData[]) => {
      const options: OptionTypeNode[] = value.filter((data: CycleData) => 
        data.stimulation_chart !== null && data.stimulation_chart).map((data: CycleData) => 
        ({label: data.cycle_number.toString(), 
          labelNode: 
          <Typography component={'span'}>{data.cycle_number} |
            <Chip color={data.status === 'ACTIVE' ? 'success':'error'} variant='outlined' label={data.status === 'ACTIVE' ? 'Active':'Inactive'} sx={{ml:1}} />
          </Typography>, 
          value: data.stimulation_chart.toString(), 
          status: data.status}));
      setStim(options);
  
      value.forEach((cycle: CycleData) => {
        
        if (cycle.status === 'ACTIVE') {
          setSelectedStimChart(cycle.stimulation_chart);
          setStselectedCycle({
            label: `${cycle.cycle_number} | `,
            value: cycle.stimulation_chart.toString(),
            status: cycle.status,
          });
        }
        // Add medication protocol to the list of protocols for the cycle
        if (cycle.medication_protocol) {
          setMedicationProtocols((prevState) => {
            const medProtoValues = new Set([
              ...(prevState?.[cycle.id] ?? []),
              cycle.medication_protocol,
              
            ]);
            return {
              ...prevState,
              [cycle.id]: Array.from(medProtoValues),
            };
          });
        }
      });
    }).catch((error: AxiosError) => {
      setSelectedStim(null);
      setSelectedStimChart(null);
      addAlert(error.response?.statusText || 'An error occurred', 'error');
    });
  }, [patient, addAlert, refreshPage]);
  


  useEffect(() => {
    if (selectedStimChart) {
      // Pass the selectedStimChart id (not the full object) to getStim
      getStim(selectedStimChart).then((stim: Stim) => setSelectedStim(stim));
      onCycleChange(null, selectedStimChart);  // Pass id to onCycleChange
    } else {
      onCycleChange(null, null);
    }
  }, [selectedStimChart, onCycleChange]);
  const handleChange = (event: React.SyntheticEvent, value: OptionTypeNode | null) => {
    if (value) {
 
      setCycleId(Number(value.value));     
      setSelectedStimChart(parseInt(value.value));
      onCycleChange(event, parseInt(value.value));
      setStselectedCycle(value);
      setCurrCycleStatus(value.status);
    }
  };
  useEffect(() => {
    return () => {
      setCycleId(null); 
    };
  }, [setCycleId]);

  const cycleDetails = selectedStim && [
    { label: 'Cycle Name', value: selectedStim.cycle.cycle_name },
    { label: 'Cycle No.', value: selectedStim.cycle.cycle_number },
    {
      label: 'Medication Protocols',
      value:
        medicationProtocols?.[selectedStim.cycle.id]?.join(', ') ??
        selectedStim.cycle.medication_protocol ??
        'N/A',
    },
    { label: 'Cycle Provider', value: selectedStim.cycle.provider_name },
    { label: 'Cycle Co-ordinator', value: selectedStim.cycle.coordinator_name },
    {
      label: 'Cycle Start date',
      value: formatDateToYYYYMMDD(
        convertToLocalDateTime(selectedStim.cycle.cycle_start_date)
      ),
    },
    {
      label: 'LMP date',
      value: selectedStim.cycle.lmp_date
        ? formatDateToYYYYMMDD(convertToLocalDateTime(selectedStim.cycle.lmp_date))
        : 'N/A',
    },
    { label: 'Remarks', value: selectedStim?.cycle?.remarks !== '' ? selectedStim?.cycle?.remarks ?? 'N/A' : 'N/A' },
  ];

  useEffect(() => {
    getcycleList(String(patientId))
      .then((data: Cycle[]) => { 
        setCyclesData(data);  
         
      })
      .catch((error) => {
        if (error?.response?.data?.errors && Array.isArray(error.response.data.errors)) {
          const errorMessage = error.response.data.errors.map((err: { detail: string }) => err.detail).join(', ');
          if (error.response.status !== 404) {
            addAlert(errorMessage, 'error');
          }
          setCyclesData([]); 
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching cycle data:', error);
          addAlert('Error fetching cycle data', 'error');
        }
      });
  }, [addAlert, patientId, isCreateCycleDialogOpen, isTerminateCycleDialogOpen]);
    
  const handleCloseDialog = () => {
    setIsCreateCycleDialogOpen(false);
    setRefreshPage(!refreshPage);
  };
  const handleTerminateCloseDialog = () => {
    setIsTerminateCycleDialogOpen(false);
    setRefreshPage(!refreshPage);
  };

  const buttonStyle = (cycleStatus: boolean) => ({
    backgroundColor: cycleStatus ? '#fff' : '#505050',
    height: '56px',
    minWidth: '228px',
    color: cycleStatus ?'#505050' : '#fff',
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing:' 0%',
    ml:2,
    '&:disabled': {
      color:'#fff',
      cursor: 'not-allowed',
      opacity: 0.5,
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    }
  });

  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="medium" />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ display: 'flex', alignItems: 'center' }} // Ensure proper alignment
        >
          <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center" p="0.5rem">
            <Typography fontFamily="Montserrat" fontWeight={600} fontSize="18px" variant="h6">
      Cycle Details
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem' }}>
              <Button
                color="info"
                variant="text"
                onClick={() => navigateTo(`/clinical/patients/cycle-overview/${patientId}`)}
                sx={{ '&:hover': { backgroundColor: 'inherit' }, mr: 2 }}
              >
                <Typography
                  sx={{ textDecoration: 'underline' }}
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="16px"
                  variant="h6"
                >
          Cycle Overview
                </Typography>
              </Button>
              {stim && (
                <Autocomplete
                  value={cycle ?? undefined}
                  disablePortal
                  options={stim}
                  onChange={handleChange}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => ''} // Label of the option to show in the input field
                  isOptionEqualToValue={(option, value) => option.value === value.value} // Value comparison between options and selected value
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.labelNode} {/* This shows the Chip inside the dropdown */}
                    </li>
                  )}
                  renderInput={(params) => {
                    // Access the selected option directly from the `cycle` state, not from `params.inputProps.value`
                    const selectedOption = cycle ? stim.find((item) => item.value === cycle.value) : null;
                    return (
                      <TextField
                        {...params}
                        label="Cycle No."                        
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedOption ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {selectedOption.labelNode} {/* This shows the Chip inside the input field */}
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              {stim?.[0]?.labelNode} {/* Default option */}
                            </Box>
                          ),
                        }}
                      />
                    );
                  }}
                  popupIcon={<ExpandMoreIcon fontSize='medium'/>}
                  disableClearable={true}
                />
              )}
              {selectedStim?.cycle.status === 'ACTIVE' ? 
                <Button
                  variant="outlined"                  
                  sx={buttonStyle(true)}
                  className={Styles.terminateCylceButton}  
                  onClick={()=>{
                    setIsTerminateCycleDialogOpen(true);
                    setSelectedIdTerminate(selectedStim.cycle.id.toString());
                  }}    
                >
                  {'Terminate Cycle'}
                </Button> :
                <Button
                  variant="outlined"                   
                  sx={buttonStyle(false)}
                  onClick={()=>setIsCreateCycleDialogOpen(true)}           
                  disabled={cyclesData.some(cycle => cycle.status === 'ACTIVE') }
                >
                  {'Create New Cycle'}
                </Button>}
            </Box>
          </Box>          
        </AccordionSummary>
        {selectedStim && <AccordionDetails>
          <Box className={Styles.detailsContainer}>
            {cycleDetails?.map(({ label, value }) => (
              <Box key={label} className={Styles.info}>
                <Typography color="secondary.main">{label}</Typography>
                <Typography color="primary.main" fontWeight={600} sx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{value}</Typography>
              </Box>
            ))}
          </Box>
        </AccordionDetails>}
      </Accordion>

      {/* Dialog to show CreateNewCycle component */}
      <Dialog
        open={isCreateCycleDialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '60rem',
            maxWidth: '70vw',
            padding: '1rem',
          },
        }}
        aria-labelledby="create-cycle-dialog-title"
      >
        <DialogContent
          className="p-0"
          style={{
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <CreateNewCycle onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>     

      {/* Dialog to show Terminate Cycle component */}
      <Dialog
        open={isTerminateCycleDialogOpen}
        onClose={handleTerminateCloseDialog}
        PaperProps={{
          style: {
            borderRadius: '20px',
            width: '60rem',
            maxWidth: '70vw',
            padding: '1rem',
          },
        }}
        aria-labelledby="terminate-cycle-dialog-title"
      >
        <DialogContent
          className="p-0"
          style={{
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <TerminateCycleConfirmation onClose={handleTerminateCloseDialog} cycleId={selectedIdTerminate} />
        </DialogContent>
      </Dialog> 
    </Box>
  );
};

export default CycleSelector;