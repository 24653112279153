import React, { useEffect, useState } from 'react'; 
import {  Dialog,  DialogContent,  DialogTitle,  DialogActions,  Button,  Typography,  IconButton,  Avatar,  List,  ListItem,  ListItemAvatar,  ListItemText,  Box, Snackbar, Alert,} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ReactComponent as DeleteIcon } from '../../../assets/SVG/trash.svg';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import EmbryoImageLightbox from './EmbryoImageLightbox';

interface EmbryoMultiImageUploadDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedEmbryos: number[];
  onUploadComplete: (uploadedFiles: { embryo_no: number; base64: string }[]) => void;
}

interface FileUploadEntry {
  id: string;
  file: string | null;
  fileName: string;
  fileSize: string;
}

const EmbryoMultiImageUploadDialog: React.FC<EmbryoMultiImageUploadDialogProps> = ({
  isOpen,
  onClose,
  selectedEmbryos,
  onUploadComplete
}) => {
  const [fileUploads, setFileUploads] = useState<FileUploadEntry[]>([]);  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lightboxData, setLightboxData] = useState<{ imageUrl: string | null; embryo_no: number | null }>({ imageUrl: null, embryo_no: null });

  useEffect(() => {
    setFileUploads(
      selectedEmbryos.map((num) => ({
        id: num.toString(), 
        file: null,
        fileName: '', 
        fileSize: '',
      }))
    );
  }, [selectedEmbryos]);

  const handleFileChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setFileUploads((prevUploads) =>
        prevUploads.map((upload) =>
          upload.id === id
            ? {
              ...upload,
              file: reader.result as string,
              fileName: file.name,
              fileSize: (file.size / 1024).toFixed(2) + ' KB',
            }
            : upload
        )
      );
    };
    reader.readAsDataURL(file);
  };

  const handleClose = (_event: object, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };
 

  const handleMultipleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
  
    const updatedUploads = [...fileUploads];
  
    Array.from(files).forEach((file) => {
      const match = file.name.match(/(\d+)/); // Extract number from filename
  
      if (match) {
        const embryo_no = parseInt(match[1], 10); // Extract the actual embryo number
  
        // Find the corresponding index in selectedEmbryos
        const embryoIndex = selectedEmbryos.findIndex(num => num === embryo_no);
  
        if (embryoIndex !== -1) {
          const reader = new FileReader();
          reader.onloadend = () => {
            updatedUploads[embryoIndex] = {
              id: String(selectedEmbryos[embryoIndex]), // Convert number to string
              file: reader.result as string | null, // Ensure file is a string or null
              fileName: file.name,
              fileSize: (file.size / 1024).toFixed(2) + ' KB',
            };
            setFileUploads([...updatedUploads]);
          };
          reader.readAsDataURL(file);
        }
      }
    });
  };
  
  
  const handleSave = () => {
    const uploadedFiles = fileUploads
      .filter((upload) => upload.file !== null)
      .map((upload) => ({
        embryo_no: parseInt(upload.id, 10),
        base64: upload.file as string,
      }));

    onUploadComplete(uploadedFiles);
    onClose();
  };

  const handleDeleteFile = (uploadId: string) => {
    setFileUploads((prev) =>
      prev.map((item) =>
        item.id === uploadId ? { ...item, file: null, fileName: '', fileSize: '' } : item
      )
    );
  };

  const handleButtonClick = () => {
    setOpenSnackbar(true);
  };
  
  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" PaperProps={{ sx: { borderRadius: '15px', width: '80%', maxWidth: '800px' } }} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ml: 1, pr: 4, mt: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          Upload Embryo Images
          <Box sx={{ display: 'flex', gap: 1 }}>
            {selectedEmbryos.map((num, index) => {
              const isUploaded = fileUploads[index]?.file;
              return (
                <Box
                  key={index}
                  sx={{
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    backgroundColor: isUploaded ? '#4CAF50' : '#EFAE78',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                >
                  {num}
                </Box>
              );
            })}
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, mt: 1 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {selectedEmbryos.length === 0 ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ height: 200 }}>
            <Avatar sx={{ bgcolor: '#EDEDED', width: 80, height: 80 }}>
              <UploadFileIcon sx={{ color: '#999', fontSize: 40 }} />
            </Avatar>
            <Typography variant="h6" sx={{ mt: 2, color: '#777' }}>
        Please select embryos
            </Typography>
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                component="label"
                startIcon={<FileUploadOutlinedIcon />}
                sx={{
                  background: '#444',
                  borderRadius: '10px',
                  padding: '8px 16px',
                  color: '#fff',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
                onClick={handleButtonClick}
              >
                Bulk Upload
                <input type="file" accept="image/*" multiple hidden onChange={handleMultipleFileUpload} />
              </Button>

              <Snackbar
                open={openSnackbar}
                autoHideDuration={700}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert severity="info" onClose={() => setOpenSnackbar(false)}>
                  Filename should contain Embryo No. for bulk upload.
                </Alert>
              </Snackbar>
            </Box>

            <List>
              {fileUploads.map((upload, index) => (
                <ListItem key={upload.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #ddd', borderRadius: '12px', mb: 1, p: 2, transition: '0.3s' }}>
                  <Typography variant="body1" sx={{ minWidth: 100, fontWeight: 'bold' }}>
              🟡 Embryo {selectedEmbryos[index]}
                  </Typography>
                  <ListItemAvatar sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 80 }}>
                    {upload.file ? (
                      <Avatar
                        src={upload.file}
                        sx={{ width: 45, height: 45, borderRadius: '10px', cursor: 'pointer' }}
                        onClick={() => setLightboxData({ imageUrl: upload.file!, embryo_no: selectedEmbryos[index] })}
                      />
                    ) : (
                      <Avatar sx={{ bgcolor: '#EDEDED', width: 45, height: 45 }}>
                        <UploadFileIcon sx={{ color: '#999' }} />
                      </Avatar>
                    )}
                  </ListItemAvatar>
                  <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                    <ListItemText
                      primary={<Typography sx={{ fontWeight: 500 }}>{upload.fileName || 'Choose a file'}</Typography>}
                      secondary={upload.fileSize && <Typography sx={{ color: '#777' }}>{upload.fileSize}</Typography>}
                      sx={{ flex: 1 }}
                    />
                  </Box>
                  <label htmlFor={`file-input-${upload.id}`} style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(upload.id, e)} style={{ display: 'none' }} id={`file-input-${upload.id}`} />
                    <Button variant="contained" component="span" sx={{ background: '#E17E61', borderRadius: '10px', padding: '8px 16px', color: '#fff', fontWeight: 'bold', textTransform: 'none' }}>
                      <FileUploadOutlinedIcon sx={{ mr: 1, fontSize: 18 }} /> Upload
                    </Button>
                  </label>
                  {upload.file && (
                    <IconButton onClick={() => handleDeleteFile(upload.id)} sx={{ ml: 1, color: 'red' }}>
                      <DeleteIcon style={{ width: 20, height: 20 }} />
                    </IconButton>
                  )}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={selectedEmbryos.length === 0 || fileUploads.every(upload => !upload.file)} // Disable if no embryos are selected OR no files uploaded
          sx={{
            background: '#444',
            mr: 3,
            color: '#fff',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:disabled': {
              background: '#e0e0e0', // Lighter grey when disabled
              color: '#888', // Slightly faded text
              cursor: 'not-allowed',
            },
            mb:2
          }}
        >
        Save
        </Button>
      </DialogActions>      
      
      {lightboxData.imageUrl && (
        <EmbryoImageLightbox 
          imageUrl={lightboxData.imageUrl} 
          embryoNumber={lightboxData.embryo_no} 
          onClose={() => setLightboxData({ imageUrl: null, embryo_no: null })} 
        />
      )}
    </Dialog>
  );
};

export default EmbryoMultiImageUploadDialog;
