import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Slide,
  SlideProps,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Zoom In Alternative
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; // Zoom Out Alternative

import './EmbryoImageLightbox.css';

interface EmbryoImageLightboxProps {
  imageUrl: string | null;
  embryoNumber?: number | null;
  onClose: () => void;
  label?: string;
}

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EmbryoImageLightbox: React.FC<EmbryoImageLightboxProps> = ({ imageUrl, embryoNumber, onClose, label = 'Embryo' }) => {
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (imageUrl) {
      setLoading(true);
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => setLoading(false);
    }
  }, [imageUrl]);

  const handleZoom = (direction: 'in' | 'out') => {
    setZoom((prevZoom) => {
      const newZoom = direction === 'in' ? prevZoom * 1.2 : prevZoom / 1.2;
      return Math.min(Math.max(newZoom, 1), 3); // Limit zoom range
    });
  };

  return (
    <Dialog
      open={Boolean(imageUrl)}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      TransitionComponent={Transition}
      className="embryo-dialog"
      aria-labelledby="embryo-dialog-title"
      aria-describedby="embryo-dialog-description"
    >
      {/* Header */}
      <DialogTitle id="embryo-dialog-title" className="dialog-title">
        {embryoNumber !== undefined && <span className="embryo-number">{label} #{embryoNumber}</span>}
        <IconButton onClick={onClose} size="small" aria-label="Close" className="close-button">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Image Display */}
      <DialogContent className="dialog-content">
        {loading ? (
          <CircularProgress className="loading-indicator" />
        ) : (
          <img
            src={imageUrl || ''}
            alt={`Embryo ${embryoNumber}`}
            className="embryo-image"
            style={{ transform: `scale(${zoom})` }}
          />
        )}
      </DialogContent>


      <DialogActions className="dialog-actions" sx={{ justifyContent: 'center' }}>
        <Tooltip title="Zoom Out" arrow>
          <IconButton onClick={() => handleZoom('out')} aria-label="Zoom Out" className="zoom-btn" sx={{ color: 'white' }}>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoom In" arrow>
          <IconButton onClick={() => handleZoom('in')} aria-label="Zoom In" className="zoom-btn" sx={{ color: 'white' }}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Close" arrow>
          <Button onClick={onClose} variant="contained" className="close-btn">
      Close
          </Button>
        </Tooltip>
      </DialogActions>


    </Dialog>
  );
};

export default EmbryoImageLightbox;
