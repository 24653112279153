import React from 'react';
import { ReactComponent as PrescribedUnavailableImage } from '../../assets/SVG/PrescribedUnavailable.svg';
import Grid2 from '@mui/material/Grid2';

interface Props {
  message?: string;
}

const PrescribedUnavailable: React.FC<Props> = ({ message = 'No surgery has been prescribed yet' }) => {
  return (
    <Grid2 container justifyContent="center" alignItems="center">
      <Grid2>
        <PrescribedUnavailableImage style={{ width: '600px', height: '400px', marginTop: '64px' }} />
        <p style={{ textAlign: 'center', fontWeight: 600 }}>
          {message}
        </p>
      </Grid2>
    </Grid2>
  );
};

export default PrescribedUnavailable;
