/* eslint-disable no-console */
import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modals.module.css';
import TrashIcon from '../../assets/SVG/modal-trash.svg';
import CopyIcon from '../../assets/SVG/modal-copy.svg';
import NoteIcon from '../../assets/SVG/modal-note.svg';
import DeleteIcon from '../../assets/SVG/delete-notification.svg';
import EditIcon from '../../assets/SVG/modal-edit.svg';
const MultiPopupModals: React.FC = () => {
  const [scheduleEventModalOpen, setScheduleEventModalOpen] = useState<boolean>(false);
  const [drugModalOpen, setDrugModalOpen] = useState<boolean>(false);
  const [hormonesModalOpen, setHormonesModalOpen] = useState<boolean>(false);
  const [delHormonesModalOpen, setDelHormonesModalOpen] = useState<boolean>(false);
  const [editHormonesModalOpen, setEditHormonesModalOpen] = useState<boolean>(false);
  const [scheduleFormData, setScheduleFormData] = useState({
    event: '',
    date: '',
    fromTime: '',
    toTime: '',
    doctor: '',
    embryologist: '',
    operationTheater: '',
    OTtable: '',
    remark: '',
  });

  const [drugFormData, setDrugFormData] = useState({
    drugName: '',
    dosage: '',
    days: '',
  });

  const [hormonesFormData, setHormonesFormData] = useState({
    hormoneValue: '',
    unit: '',
  });

  const [delHormonesFormData, setDelHormonesFormData] = useState({
    hormoneValue: '',
    unit: '',
  });
  // Handlers for opening and closing modals
  const handleScheduleModalOpen = () => setScheduleEventModalOpen(true);
  const handleScheduleModalClose = () => setScheduleEventModalOpen(false);

  const handleDrugModalOpen = () => setDrugModalOpen(true);
  const handleDrugModalClose = () => setDrugModalOpen(false);

  const handleHormonesModalOpen = () => setHormonesModalOpen(true);
  const handleHormonesModalClose = () => setHormonesModalOpen(false);

  const handleDelHormonesModalOpen = () => setDelHormonesModalOpen(true);
  const handleDelHormonesModalClose = () => setDelHormonesModalOpen(false);

  const handleEditHormonesModalOpen = () => setEditHormonesModalOpen(true);
  const handleEditHormonesModalClose = () => setEditHormonesModalOpen(false);
  // Handlers for form input changes
  const handleScheduleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setScheduleFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDrugChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setDrugFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleHormonesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setHormonesFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleDelHormonesChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setDelHormonesFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Submit handlers
  const handleScheduleSubmit = () => {
    console.log('Schedule Event Form Data:', scheduleFormData);
    handleScheduleModalClose(); // Close modal
  };

  const handleDrugSubmit = () => {
    console.log('Drug Form Data:', drugFormData);
    handleDrugModalClose(); // Close modal
  };
  const handleHormonesSubmit = () => {
    console.log('Drug Form Data:', hormonesFormData);
    handleHormonesModalClose(); // Close modal
  };
  const handleDelHormonesSubmit = () => {
    console.log('Drug Form Data:', delHormonesFormData);
    handleDelHormonesModalClose(); // Close modal
  };

  return (
    <div className="p-4">
      {/* Buttons to open modals */}
      <Typography variant="h6" gutterBottom>
        Scheduled OPU Event
      </Typography>
      <Button variant="contained" onClick={handleScheduleModalOpen}>
        Schedule Event
      </Button>
      <br />
      <br />
      <Typography variant="h6" gutterBottom>
        Drug (Trigger and Stim)
      </Typography>
      <Button variant="contained" onClick={handleDrugModalOpen}>
        Add Drug
      </Button>
      <br />
      <br />
      <Typography variant="h6" gutterBottom>
        Hormones Add Delete
      </Typography>
      <Button variant="contained" onClick={handleHormonesModalOpen}>
        Add Hormones
      </Button>
      &nbsp;
      <Button variant="contained" onClick={handleDelHormonesModalOpen}>
        Delete Hormones
      </Button>
      &nbsp;
      <Button variant="contained" onClick={handleEditHormonesModalOpen}>
        Edit Hormones
      </Button>

      {/* Schedule Event Modal */}
      <Modal open={scheduleEventModalOpen} onClose={handleScheduleModalClose}>
        <Box className={styles.modal}>
          <Box className={styles.modalHeader}>
            <Typography variant="h3" gutterBottom>
              Schedule Event
            </Typography>
            <IconButton aria-label="close" onClick={handleScheduleModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                label={
                  <>
                    Schedule <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="event"
                value={scheduleFormData.event}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              >
                <MenuItem value="Oocyte Retrieval">Oocyte Retrieval</MenuItem>
                <MenuItem value="Embryo Transfer (ET)">Embryo Transfer (ET)</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date"
                name="date"
                type="date"
                value={scheduleFormData.date}
                onChange={handleScheduleChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    From Time <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="fromTime"
                type="time"
                value={scheduleFormData.fromTime}
                onChange={handleScheduleChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    To Time <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="toTime"
                type="time"
                value={scheduleFormData.toTime}
                onChange={handleScheduleChange}
                InputLabelProps={{ shrink: true }}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    Doctor <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="doctor"
                value={scheduleFormData.doctor}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Embryologist"
                name="embryologist"
                value={scheduleFormData.embryologist}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    Operation Theater <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="operationTheater"
                value={scheduleFormData.operationTheater}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <>
                    OT Table <span style={{ color: 'red' }}>*</span>
                  </>
                }
                name="OTtable"
                value={scheduleFormData.OTtable}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Remark"
                name="remark"
                rows={3}
                value={scheduleFormData.remark}
                onChange={handleScheduleChange}
                margin="normal"
                className="mt-0"
              />
            </Grid>
          </Grid>
          <Box className={styles.modalActions}>
            <Button variant="outlined" onClick={handleScheduleModalClose} className={styles.modalCancelBtn}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleScheduleSubmit} className={styles.modalSaveBtn}>
              Confirm Schedule
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Drug Modal */}
      <Modal open={drugModalOpen} onClose={handleDrugModalClose}>
        <Box className={styles.modal}>
          <Box className={styles.modalHeader}>
            <Typography variant="h3" gutterBottom>
              Stim Drug on 29/10/2024 (ARGIPRIME)
            </Typography>
            <Grid>
              <IconButton aria-label="Trash">
                <img src={TrashIcon} alt="Trash Icon" className={styles.popupIcon} />
              </IconButton>
              <IconButton aria-label="copy">
                <img src={CopyIcon} alt="Copy Icon" className={styles.popupIcon} />
              </IconButton>
              <IconButton aria-label="Note">
                <img src={NoteIcon} alt="Note Icon" className={styles.popupIcon} />
              </IconButton>
            </Grid>
          </Box>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Dose *"
                name="drugName"
                placeholder="Add Stim Drug Dose"
                value={drugFormData.drugName}
                onChange={handleDrugChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Drug Time *"
                name="dosage"
                type="time"
                value={drugFormData.dosage}
                onChange={handleDrugChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="#Days *"
                name="day"
                value={drugFormData.days}
                onChange={handleDrugChange}
                margin="normal"
                type="number"
              />
            </Grid>
            <Grid item xs={12} >
              <div className={styles.deleteNote}>
                <Grid item xs={1} md={1} >
                  <span className={styles.deleteNoteImg}> <img src={DeleteIcon} alt="Delete Icon" /></span>
                </Grid>
                <Grid item xs={11} md={11}>
                  <h5>Delete</h5>
                  <p>Are you sure you want to delete drug?</p>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Box className={styles.modalActions} style={{ display: 'flex', justifyContent: 'right' }}>
            <Button variant="contained" onClick={handleDrugSubmit} className={styles.buttonSmall}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Hormones Modal */}
      <Modal open={hormonesModalOpen} onClose={handleHormonesModalClose}>
        <Box className={`${styles.modal} ${styles.modalSmall}`}>
          <Box className={styles.modalHeader}>
            <Typography variant="h3" gutterBottom>
              Hormones
            </Typography>
          </Box>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Hormone Value <span style={{ color: 'red' }}> * </span>
                </>}
                name="hormoneValue"
                placeholder="Hormone Value"
                value={hormonesFormData.hormoneValue}
                onChange={handleHormonesChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}

              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Unit <span style={{ color: 'red' }}> * </span>
                </>}
                name="unit"
                type="text"
                value={hormonesFormData.unit}
                onChange={handleHormonesChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>


          </Grid>
          <Box className={styles.modalActions}>
            <Button variant="outlined" onClick={handleHormonesModalClose} className={styles.modalCancelBtn}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleHormonesSubmit} className={styles.modalSaveBtn}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Hormones Modal Delete */}
      <Modal open={delHormonesModalOpen} onClose={handleDelHormonesModalClose}>
        <Box className={`${styles.modal} ${styles.modalSmall}`}>
          <Box className={styles.modalHeader}>
            <Typography variant="h3" gutterBottom>
              Hormones
            </Typography>
          </Box>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Hormone Value <span style={{ color: 'red' }}> * </span>
                </>}
                name="hormoneValue"
                placeholder="Hormone Value"
                value={hormonesFormData.hormoneValue}
                onChange={handleDelHormonesChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Unit <span style={{ color: 'red' }}> * </span>
                </>}
                name="unit"
                type="text"
                value={hormonesFormData.unit}
                onChange={handleDelHormonesChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} >
              <div className={styles.deleteNote}>
                <Grid item xs={2} md={2} >
                  <span className={styles.deleteNoteImg}> <img src={DeleteIcon} alt="Delete Icon" /></span>
                </Grid>
                <Grid item xs={10} md={10}>
                  <h5>Delete</h5>
                  <p>Are you sure you want to delete hormone value?</p>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Box className={styles.modalActions}>
            <Button variant="outlined" onClick={handleDelHormonesModalClose} className={styles.modalCancelBtn}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDelHormonesSubmit} className={styles.modalSaveBtn}>
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Hormones Modal Delete */}
      <Modal open={editHormonesModalOpen} onClose={handleEditHormonesModalClose}>
        <Box className={`${styles.modal} ${styles.modalSmall}`}>
          <Box className={styles.modalHeader}>
            <Typography variant="h3" gutterBottom>
              Hormones
            </Typography>

            <Grid>
              <IconButton aria-label="Trash">
                <img src={TrashIcon} alt="Trash Icon" className={styles.popupIcon} />
              </IconButton>
              <IconButton aria-label="edit">
                <img src={EditIcon} alt="Edit Icon" className={styles.popupIcon} />
              </IconButton>
            </Grid>
          </Box>
          <Grid container spacing={2} className={styles.modalBody}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Hormone Value <span style={{ color: 'red' }}> * </span>
                </>}
                name="hormoneValue"
                placeholder="Hormone Value"
                value="325"
                onChange={handleDelHormonesChange}
                margin="normal"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>
                  Unit <span style={{ color: 'red' }}> * </span>
                </>}
                name="unit"
                type="text"
                value="mlu/ml"
                onChange={handleDelHormonesChange}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>
          <Box className={styles.modalActions}>
            <Button variant="outlined" onClick={handleDelHormonesSubmit} className={styles.modalCancelBtn}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDelHormonesSubmit} className={styles.modalSaveBtn}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

    </div >
  );
};

export default MultiPopupModals;
