import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import './TankSelect.css';
import { StorageUnit } from './embryoCulture.helpers';
import { RequiredLabel } from '../../../utils/commonUtils';

interface CaneSelectProps {
  canes: StorageUnit[];
  selectedCane: number | null;
  onChange: (value: string) => void;
  label?: string;
  loading: boolean;
  hasError?: boolean;
}

const CaneSelect: React.FC<CaneSelectProps> = ({
  canes,
  selectedCane,
  onChange,
  label = 'Cane',
  loading,
  hasError = false,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const caneId = event.target.value;
    // eslint-disable-next-line no-console
    console.log('Selected Cane ID:', caneId);
    onChange(caneId);
  };

  return (
    <FormControl fullWidth className="tank-select-container" error={hasError}>
      <InputLabel shrink={selectedCane !== null}>{RequiredLabel(label, true)}</InputLabel>
      <Select
        value={selectedCane !== null ? String(selectedCane) : ''}
        onChange={handleChange}
        label={RequiredLabel(label, true)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Restricts the height of the dropdown
              overflowY: 'auto', // Enables vertical scrolling
            },
          },
        }}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} /> &nbsp; Loading...
          </MenuItem>
        ) : canes.length ? (
          canes.map((cane) => (
            <MenuItem key={cane.id} value={cane.id}>
              <Box className="tank-item">
                <Typography className="tank-item-header" sx={{ 
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700,
                  fontSize: '12px',
                }}>
                  {cane.name}
                  <span className="tank-progress-text">
                    {(cane.occupancy ?? 0).toFixed(2)}%
                  </span>
                </Typography>
                <Box className="progress-bar-container">
                  <LinearProgress
                    variant="determinate"
                    value={cane.occupancy ?? 0}
                    className="progress-bar"
                    sx={{
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor:'68B62B'
                      },
                    }}
                  />
                </Box>
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No canes available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CaneSelect;
