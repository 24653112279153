import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from '../../../assets/SVG/MaterialInfoCircle.svg';
import Popover from '@mui/material/Popover';

interface OverlayContent {
    title: string;
    details: { label: string; value: string }[];
}

interface OverlayIconPanelProps {
    content: OverlayContent;
}

const OverlayIconPanel: React.FC<OverlayIconPanelProps> = ({ content }) => {
  const [anchorEl, setAnchorEl] = useState<SVGElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<SVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <InfoIcon
        style={{ cursor: 'pointer', color: '#555' }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        sx={{ pointerEvents: 'none' }} // Prevents flickering
      >
        <div
          style={{
            padding: '16px',
            background: 'white',
            color: '#232323',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            fontSize: '0.9rem',
            textAlign: 'left',
            maxWidth: '600px',
          }}
        >
          <div
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 700,
              marginBottom: '8px',
              fontSize: '20px',
              borderBottom: '1px solid #ddd',
              paddingBottom: '4px',
            }}
          >
            {content.title}
          </div>
          {content.details.map((detail, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px',
              }}
            >
              <span style={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: '16px', color: '#737373' }}>{detail.label} &nbsp;</span>
              <span style={{ fontFamily: 'Nunito', fontWeight: 500, fontSize: '16px', color: '#232323' }}>{detail.value}</span>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
};

export default OverlayIconPanel;
