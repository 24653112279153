/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import DeleteIcon from '../../../assets/SVG/trash.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './embryoCulture.module.css';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import imageIcon from '../../../assets/SVG/gallery_icon.svg';
import { deleteEmbryo } from './dayZeroHelper';

import { Close as CloseIcon } from '@mui/icons-material';
import { capitalizeTitle } from '../../../utils/commonUtils';
import { useAlert } from '../../../components/alertProvider';

type Column = {
  label: string;
  field: string;
  editable: boolean; 
};

type Row = {
  [key: string]: string | number | undefined;
};

interface EditableTableProps {
  columns: Column[];
  rows: Row[];
  isDeletable: boolean;
  onDelete?: (id: number | string) => void; 
}

const DetailsTable: React.FC<EditableTableProps> = ({
  columns,
  rows,
  isDeletable,
  onDelete
}) => {
  const [data, setData] = useState(rows);
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null); 
  const [oocyteNumber, setOocyteNumber] = useState<string | number | null>(null);
  const { addAlert } = useAlert();


  const [showAll, setShowAll] = useState<boolean>(false); 
  useEffect(()=>{
    setData(rows);    
  },[rows]);

  
  const handleViewMore = () => {
    setShowAll(!showAll); // Toggle the showAll state
  };


  const handleDeleteRow = (id: number) => {
    deleteEmbryo(id)
      .then(() => {        
        // Remove the row with the matching id
        const updatedRows = data.filter((row) => row.id !== id);
        setData(updatedRows);
        // Call the parent-provided onDelete callback if it exists
        if (onDelete) {
          onDelete(id);
        }
      })
      .catch((error) => {
        addAlert(error.response.data.detail, 'error');
        console.error('Failed to delete the embryo:', error);
      });
  };
  

  const openImagePreview = (src: string, oocyte: string | number) => {
    setImageSrc(src);
    setOocyteNumber(oocyte);
    setOpen(true);
  };

  const closeImagePreview = () => {
    setOpen(false);
    setImageSrc(null);
    setOocyteNumber(null);
  };

  
  // Gradient color mapping for circles
  const gradientMap: Record<string, string> = {
    White: 'radial-gradient(circle, #FFFFFF 0%, #DCDCDC 100%)',
    Blue: 'radial-gradient(circle, #5C8CDB 0%, #BDD6FF 80%)',
    Brown: 'radial-gradient(circle, #8B5A2B 0%, #F5E6DA 100%)',
    Green: 'radial-gradient(circle, #4CAF50 0%, #DFF5E0 100%)',
    Orange: 'radial-gradient(circle, #E78457 0%, #FFDCCC 100%)',
    Red: 'radial-gradient(circle, #E74C3C 0%, #FFE5E0 100%)',
    Yellow: 'radial-gradient(circle, #DEC122 0%, #FFFCE9 100%)',
  };
  
  // Function to render a colored circle based on gradient map
  const GradientCircle: React.FC<{ color?: string }> = ({ color }) => {
    if (!color || !gradientMap[color]) return null;
  
    return (
      <span
        style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          background: gradientMap[color],
          marginLeft: '2px',
          marginRight: '6px',
          boxShadow: 'inset 1px 1px 3px rgba(0,0,0,0.2), 0 3px 6px rgba(0,0,0,0.1)',
          border: '1px solid rgba(255,255,255,0.7)',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      ></span>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatLocationDetails = (locationDetails: any): JSX.Element => {
    if (!locationDetails || !locationDetails.parents) {
      return <span>N/A</span>;
    }
  
    const hierarchy = locationDetails.parents
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((p: any) => !p.name.startsWith('Goblet'))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((p: any) => p.name)
      .join(' | ');
  
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const goblet = locationDetails.parents.find((p: any) => p.name.startsWith('Goblet '));
    const cryotec = locationDetails;
    
    return (
      <span>
        {hierarchy}
        {goblet && (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', ml: 1 }}>
            <Typography variant="body2" component="span" sx={{ fontSize: '16px', mr: 1 }}>
              | {capitalizeTitle(goblet.name)}
            </Typography>
            <GradientCircle color={goblet.color || ''} />
          </Box>
        )}
        {cryotec && (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', ml: 1 }}>
            <Typography variant="body2" component="span" sx={{ fontSize: '16px', mr: 1 }}>
              | {capitalizeTitle(cryotec.name)}
            </Typography>
            <GradientCircle color={cryotec.color || ''} />
          </Box>
        )}
      </span>
    );
  };
  
  return (
    <><Table sx={{ width: '100%', borderCollapse: 'collapse' }}>
      <TableHead sx={{ backgroundColor: '#FAFAFA' }}>
        <TableRow>
          {columns.map((col, index) => (
            <TableCell key={index} align="left" sx={{ fontSize: '16px', fontFamily: 'Nunito', fontWeight: 600 }}>
              {col.label}
            </TableCell>
          ))}
          {isDeletable && (
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>

            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length === 0 ? (
          <TableRow>
            <TableCell
              colSpan={columns.length + (isDeletable ? 1 : 0)}
              align="center"
              sx={{ fontSize: '16px', fontFamily: 'Nunito', fontWeight: 500 }}
            >
        No Embryo Data to Show
            </TableCell>
          </TableRow>
        ) : (
          (showAll ? data : data.slice(0, 4)).map((row, rowIndex) => ( // Show limited rows if `showAll` is false
            <TableRow key={rowIndex}>
              {columns.map((col, colIndex) => (
                <TableCell
                  key={colIndex}
                  align="left"
                  sx={{ fontSize: '16px', fontFamily: 'Nunito', fontWeight: 500 }}
                >
                  {col.field === 'image' ? (
                    row[col.field] ? (
                      <Tooltip title="View image" arrow>
                        <img
                          src={row[col.field] as string}
                          alt=""
                          style={{ width: '32px', height: '30px', cursor: 'pointer', borderRadius: '25%' }}
                          onClick={() => {
                            if (row[col.field] && row.embryoNo !== undefined) {
                              openImagePreview(row[col.field] as string, row.embryoNo);
                            }
                          }}
                          onError={(e) => {
                            (e.target as HTMLImageElement).style.display = 'none';
                            (e.target as HTMLImageElement).parentElement?.appendChild(
                              document.createElement('span')
                            );
                          }}
                        />
                      </Tooltip>
                    ) : (
                      '-'
                    )
                  ) : col.field === 'location_details' ? (
                    formatLocationDetails(row[col.field])
                  ) : (
                    row[col.field]
                  )}
                </TableCell>
              ))}
              {isDeletable && (
                <TableCell align="center">
                  <IconButton
                    onClick={() => handleDeleteRow(row.id as number)}
                    color="error"
                    title="Delete Row"
                  >
                    <img src={DeleteIcon} alt="DeleteIcon" />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))
        )}
      </TableBody>

    </Table>
    {data.length > 4 && ( // Show "View More" button only if data length is greater than 4
      <Box display="flex" justifyContent="center" mt={1}>
        <Button
          variant="outlined"
          onClick={handleViewMore}
          size="small" // Smaller button
          sx={{ padding: '4px 10px' }} // Adjust padding for a smaller size
        >
          {showAll ? 'Show Less' : 'View More'}
        </Button>
      </Box>
    )}
    {/* Dialog for image preview */}
    <Dialog
      open={open}
      onClose={closeImagePreview}
      maxWidth={false}
      sx={{
        width: '600px', 
        height: '600px', 
        margin: 'auto',
        '& .MuiPaper-root': { 
          width: '600px',
          height: '600px', 
          overflow: 'hidden', 
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Embryo</span>
          <span
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              backgroundColor: '#47B35F',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 400,
              marginLeft: '8px', 
            }}
          >
            {oocyteNumber}
          </span>
        </div>
        <IconButton
          onClick={closeImagePreview}
          color="primary"
          sx={{
            position: 'absolute',
            top: 10,
            right: 4,
            padding: '8px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100% - 56px)', 
          overflow: 'auto', 
        }}
      >
        {imageSrc && (
          <img
            src={imageSrc}
            alt="Large Preview"
            style={{
              maxWidth: '100%', 
              maxHeight: '100%',
              objectFit: 'contain',
              display: 'block', 
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px', 
          justifyContent: 'center', 
        }}
      >
        <Button onClick={closeImagePreview} color="primary" sx={{ textTransform: 'capitalize' }}>
      Close
        </Button>
      </DialogActions>
    </Dialog>


    </>
  );
};

export default DetailsTable;
