/* eslint-disable @typescript-eslint/no-explicit-any */
import { getRequest } from '../../services/httpService';
import { convertUnderscoreToTitle } from '../../utils/commonUtils';

export const updatedLabels = (item: any) => [
  { label: 'Grade', value: item.grade || '-' },
  { label: 'Protocol', value: item.oocyteId || '-' },
  { label: 'Screening Status', value: item.is_screened ? 'YES' : 'NO' },
  { label: 'Insemination', value: item.insemination_technique || '-' },  
  { label: 'Oocyte Source', value: convertUnderscoreToTitle(item.oocyte_source|| '-') },
  { label: 'Oocyte Source Name', value: item.oocyte_source_name|| '-' },
  { label: 'Sperm Source', 
    value: item.sperm_source_type === 'Patient-Single' ? 'Partner' : convertUnderscoreToTitle(item.sperm_source_type) || '-'
  },
]; 


export const embryMaterialLabel = (item: any) => [
  { label: 'Day', value: item.day || '-' },
  { label: 'Protocol', value: item.oocyteId || '-' },
  { label: 'Oocyte Source', value: convertUnderscoreToTitle(item.oocyte_source)|| '-' },
  { label: 'Sperm Source',
    value: item.sperm_source_type === 'Patient-Single' ? 'Partner' : convertUnderscoreToTitle(item.sperm_source_type) || '-'
  },
  { label: 'Insemination', value: item.insemination_technique || '-' },  
  { label: 'Screening Status', value: item.is_screened ? 'YES' : 'NO' },
  { label: 'PGT Outcome', value: item.pgt_results || '-' },
  { label: 'Oocyte Source Name', value: item.oocyte_source_name|| '-' },
];
  


const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

const getFormattedDate = (offsetDays: number) => {
  const date = new Date();
  date.setDate(date.getDate() + offsetDays);
  return date.toLocaleDateString('en-GB'); // Format: DD/MM/YYYY
};

export const billing = [
  { label: 'Bill No.', value: getRandomNumber(1000, 9999).toString() },
  { label: 'Plan', value: ['Culture', 'Extended Culture', 'Freezing'][getRandomNumber(0, 2)] },
  { label: 'Charges', value: `$${getRandomNumber(100, 500)}` },
  { label: 'Due', value: `$${getRandomNumber(20, 100)}` },
  { label: 'Last Billed Date', value: getFormattedDate(-30) }, 
  { label: 'Next Billed On', value: getFormattedDate(30) },  
];

export interface CryopreservedData { 
  id: number | null;
  cycle: number | null;
  remarks: string | null;
  oocyte_number: number | null;
  grade: string | null;
  location: string | null;
  disposition: string | null;
  files: string | null;
  cryo_preserved_date: string | null;
  cryo_preserved_by: number | null;
  cryo_purpose: string | null;
  cryo_media: string | null;
  is_screened: boolean | null;
  disposition_plan: string | null;
  disposition_plan_type: string | null;
  disposition_date: string | null;
  disposition_time: string | null;
  cryo_preserved_by_name: string | null;
  disposition_recipient: number | null;
  disposition_recipient_name: string | null;
  billing: string | null; 
  embryo_number: number | null;
  location_details?: LocationDetails; 
  day: number | null;
}

export interface LocationDetails {
  id: number;
  reference_id: string;
  plan: number;
  parents: Array<{
    id: number;
    reference_id: string | null;
    plan: number;
    level: number;
    name: string;
    parent: number | null;
    occupancy: number;
    hierarchy: string;
    specimen: string | null;
    is_occupied: boolean;
    is_leaf: boolean;
    color: string | null;
  }>;
  level: number;
  name: string;
  parent: number;
  occupancy: number;
  hierarchy: string;
  specimen: string;
  is_occupied: boolean;
  color: string;
}


export function getCryopreservedUrl(id: number, label: string): string {
  const sanitizedLabel = label.toLowerCase(); 
  return `/cryo-storage/${sanitizedLabel}/patient/${id}/?status=FREEZE`;
}

export function getCryopreserved(id: number, label: string): Promise<CryopreservedData> {
  return getRequest(getCryopreservedUrl(id, label));
}


export function getRecipients(donorId: number): Promise<any> { 
  const url = `/patients/?donors=${donorId}`;
  return getRequest(url);
}


