
import { deleteRequest, getRequest, postRequest } from '../../../services/httpService';
import { ColumnConfig, GroupConfig } from '../../../types';
import { PaginatedServiceMasterList } from '../CycleList/cyclelist-helper';

export interface proceduresListType {
  id: number;
  services: ServiceType[];
  procedures: string;
  procedures_type: string;
  procedure_notes: string | null;
  advised_date?: string | null;
  doctor?: number;
  doctor_name?: string;
  indication?: string;
  donor_info: {
    recipient: Patient;
    sperm_source: Patient[];
    oocyte_source: Patient[];
  };
  created_on: string | null;
  procedure_id?:string
}

export interface Patient {
  id: number;
  last_name?: string;
  photo_url?: string;
  first_name?: string;
  type:string;
  medical_record_no?: string;
}

export const proceduresList: proceduresListType[] = [];


export const proceduresList2: ProcedureDataType[] = [];



export const columns: ColumnConfig[] = [
  { field: 'advised_date', headerName: 'Advised Date', type: 'text', editable: false, hideLabel: true },
  { field: 'procedures_type', headerName: 'Procedure Type', type: 'text', editable: false, hideLabel: true },
  { field: 'service_code', headerName: 'Service Code', type: 'text', editable: false, hideLabel: true },
  { field: 'service_name', headerName: 'Service Name', type: 'text', editable: false, hideLabel: true },
  { field: 'procedure_notes', headerName: 'Notes', type: 'text', editable: false, hideLabel: true },
];

export const groupConfig: GroupConfig[] = [
  { fields: ['advised_date'], type: 'row' },
  { fields: ['procedures_type'], type: 'column', defaultHeaderGroupname: 'Procedure Type' },
  { fields: ['service_code'], type: 'column', defaultHeaderGroupname: 'Service Code' },
  { fields: ['service_name'], type: 'column', defaultHeaderGroupname: 'Service Name' },
  { fields: ['procedure_notes'], type: 'column', defaultHeaderGroupname: 'Procedure Notes' },
];




export const procedureColumns: ColumnConfig[] = [
  { field: 'code', headerName: 'Service Code', type: 'text', editable: false, hideLabel: true },
  { field: 'name', headerName: 'Service Name', type: 'text', editable: false, hideLabel: true },
  { field: 'description', headerName: 'Notes', type: 'text', editable: false, hideLabel: true },
  { field: 'action', headerName: 'Action', type: 'text', editable: false, hideLabel: false },
];

export const procedureGroupConfig: GroupConfig[] = [
  { fields: ['code'], type: 'column', defaultHeaderGroupname: 'Service Code' },
  { fields: ['name'], type: 'column', defaultHeaderGroupname: 'Service Name' },
  { fields: ['description'], type: 'column' },
  { fields: ['action'], type: 'column' },
];


export function getTreatmentServices(query:string):Promise<PaginatedServiceMasterList>{
  return getRequest(`/treatment-plan-services/${query}`);
}

export function deleteCycle(query:number):Promise<null>{
  return deleteRequest(`/patient-procedure/${query}/`);
}
export function deleteService(query:number, serviceId:number):Promise<null>{
  return deleteRequest(`/patient-procedure/${query}/service/${serviceId}/`);
}

export function handleAddMoreServices(query:number, serviceId:number):Promise<null>{
  return postRequest(`/patient-procedure/${query}/service/${serviceId}/add/`,{});
}
export interface ServiceType {
  id: number;
  name: string;
  code: string;
  description: string;
  service_type: string;
}

export interface ProcedureDataType {
  id: number;
  services: ServiceType[];
  procedures: string;
  procedures_type: string;
  procedure_notes: string | null;
  advised_date?: string | null;
  doctor?: number;
  doctor_name?: string;
  indication: string;
  donor_info: {
    recipient: string;
    sperm_source: string[];
    oocyte_source: string[];
  };
  created_on: string | null;
}


