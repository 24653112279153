import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  LinearProgress,
  SelectChangeEvent,
  CircularProgress,
} from '@mui/material';
import './TankSelect.css';
import { StorageUnit } from './embryoCulture.helpers';
import { capitalizeTitle, RequiredLabel } from '../../../utils/commonUtils';

interface CanisterSelectProps {
  canisters: StorageUnit[];
  selectedCanister: number | null;
  onChange: (value: string) => void;
  label?: string;
  loading: boolean;
  hasError?: boolean;
}

const CanisterSelect: React.FC<CanisterSelectProps> = ({
  canisters,
  selectedCanister,
  onChange,
  label = 'Canister',
  loading,
  hasError = false,
}) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const canisterId = event.target.value;
    // eslint-disable-next-line no-console
    console.log('Selected Canister ID:', canisterId);
    onChange(canisterId);
  };

  return (
    <FormControl fullWidth className="tank-select-container"  error={hasError} >
      <InputLabel shrink={selectedCanister !== null}>{RequiredLabel(label, true)}</InputLabel>
      <Select
        value={selectedCanister !== null ? String(selectedCanister) : ''}
        onChange={handleChange}
        label={RequiredLabel(label, true)}
      >
        {loading ? (
          <MenuItem disabled>
            <CircularProgress size={20} />   &nbsp; Loading...
          </MenuItem>
        ) : canisters.length ? (
          canisters.map((canister) => (
            <MenuItem key={canister.id} value={canister.id}>
              <Box className="tank-item">
                <Typography className="tank-item-header" sx={{ 
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700,
                  fontSize: '12px',
                }}>
                  { capitalizeTitle(canister.name || '')}
                  <span className="tank-progress-text">{canister.occupancy?.toFixed(2) ?? '0.00'}%</span>
                </Typography>
                <Box className="progress-bar-container">
                  <LinearProgress
                    variant="determinate"
                    value={canister.occupancy ?? 0}
                    className="progress-bar"
                    sx={{
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor:'68B62B'
                      },
                    }}
                  />
                </Box>
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No canisters available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CanisterSelect;
