import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../locales/en/translationEn.json';
import translationHi from '../locales/hi/translationHi.json';

//Creating object with the variables of imported translation files
const resources =  {
  en: { translation: translationEn },
  hi: { translation: translationHi },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') ?? 'en', // Default language
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;
