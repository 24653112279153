import React from 'react';
import { gradientData } from '../pages/Embryology/Embryo_Culture/embryoCulture.helpers';

/**
 * Validates an email address format.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
export const validateEmail = (email: string | null | undefined): boolean => {
  if (!email || email.trim() === '') {
    return false; // Return false if email is empty, null, or undefined
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};


/**
 * Convert a string to title case.
 * @param {string} str - The input string.
 * @returns {string} - The title cased string.
 */
export const toTitleCase = (str: string | null | undefined): string => {
  // If the string is null or undefined, return an empty string
  if (str == null || str.trim() === '') {
    return '';
  }

  // Convert to title case
  return str
    .toLowerCase()
    .split(' ')
    .filter(word => word.length > 0) // Filter out any empty words from multiple spaces
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * Trim whitespace from both ends of a string.
 * @param {string | null | undefined} str - The input string (can also be null or undefined).
 * @returns {string} - The trimmed string, or an empty string if the input is null or undefined.
 */
export const trimString = (str: string | null | undefined): string => {
  // Return empty string if the input is null or undefined
  return str ? str.trim() : '';
};


/**
 * Check if a string is a valid email format.
 * @param {string} email - The email string to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Checks if the given date is in the past compared to the current date and time.
 * 
 * @param date - The date to check. It can be a valid Date object or a date string.
 * @returns True if the date is in the past, otherwise false.
 * @throws Error if the provided date is invalid.
 */
export const isDateInPast = (date: Date | string): boolean => {
  let parsedDate: Date;

  if (typeof date === 'string') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = date;
  } else {
    throw new Error('Invalid date provided. Must be a Date object or a date string.');
  }

  const now = new Date();
  return parsedDate.getTime() < now.getTime();
};

export const timeDifference = (startTime: string, endTime: string) => {
  const start = new Date(`1970-01-01T${startTime}:00`);
  const end = new Date(`1970-01-01T${endTime}:00`);
  const diffInMinutes = (Number(end) - Number(start)) / (1000 * 60);

  return diffInMinutes;
};


export const getMaxDateForMonthInput = () => {
  return new Date().toISOString().slice(0, 7); // Returns the current year and month in YYYY-MM format
};

 

/**
   * Get today's date in YYYY-MM-DD format (for date input max).
   * @returns The current date in YYYY-MM-DD format.
   */
export const getMaxDate = ()=> {
  return new Date().toISOString().split('T')[0]; // Max date is today's date
};

// Checking if the appointment date has passed or not
export const checkDatePassed = (givenDateStr: string) => {
  if (!givenDateStr) return false;
  
  let givenDate;
    
  if (givenDateStr.includes('-') && givenDateStr.split('-')[0].length === 2) {
    const [day, month, year] = givenDateStr.split('-').map(Number);
    givenDate = new Date(year, month - 1, day);
  } else {
    givenDate = new Date(givenDateStr);
  }
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  return givenDate <= today;
};

/**
 * Format a date input to 'dd-mm-yyyy'.
 * @param {Date | string} dateInput - The date input to format.
 * @returns {string} The formatted date string in 'dd-mm-yyyy' format.
 */
export const getDateInDDMMYYYY = (dateInput: Date | string): string => {
  if (!dateInput) return '';

  let date;

  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === 'string') {
    date = new Date(dateInput);
  } else {
    return dateInput; // Return
  }

  if (isNaN(date.getTime())) return dateInput.toString(); // Return the input as-is

  return date.toLocaleDateString('en-GB');
};


export const RequiredLabel = (label: string, isRequired: boolean = true) => (
  <>
    {label} {isRequired && <span style={{ color: 'red' }}> *</span>}
  </>
);

export const textareaStyles = () => {
  return {
    width: '100%',
    Height: '30px',
    padding: '8px',
    borderRadius: '12px',
    border: '1px solid #ccc',
    resize: 'vertical' as const, // Ensure proper type for 'resize'
    minHeight: '30px',
    maxHeight: '120px',
    '&::placeholder': {
      textAlign: 'left',  // Ensure placeholder is left-aligned
    },
    '&:focus::placeholder': {
      opacity: 0.7,  // You can adjust the opacity or styling when focused
    },
  };
};

export const LabeledWithAsterisk = (label: string, isRequired: boolean = true) => (
  <span>
    {label} {isRequired && <span style={{ color: 'red' }}>*</span>}
  </span>
);


export const getFieldStylesAndDisabled = (condition: boolean, backgroundColor: string = '#fafafa',  opacity: number = 0.9) => ({
  disabled: !condition,
  sx: {
    backgroundColor: !condition ? backgroundColor : 'inherit',
    opacity: !condition ? opacity : 1,
  },
});



export const tabStyles = {
  borderBottom: '2px solid #f2f2f2',
  fontWeight: '800',
  '& .MuiTabs-indicator': {
    backgroundColor: 'rgba(225, 126, 97, 1)', // Tab indicator color
    height: '2px',
  },
  '& .MuiTab-root': {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    color: 'rgba(141, 152, 162, 1)', // Default color for unselected tabs
  },
  '& .Mui-selected': {
    color: '#E17E61', // Selected tab color
    fontWeight: '800', // Bold font for the selected tab
  },
  mb: 2,
  mt: 4,
};

export const sectionTitleStyle = {
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '23.2px',
  textAlign: 'left',
  color: '#9E9E9E'
};


export const infoTextStyle = {
  fontFamily: 'Nunito, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '21.82px',
  textAlign: 'left',
  color: '#232323'
};

export const capitalizeTitle = (text :string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// Function to sanitize time values
export const sanitizeTime = (time : string | null) => {
  if (time === null) {
    return time;
  }

  // Check if the time is in the format HH:mm:ss
  const timePattern = /^\d{2}:\d{2}(:\d{2})?$/;
  
  // If it matches the HH:mm format (without seconds), append ":00"
  if (timePattern.test(time)) {
    return time.endsWith(':00') ? time : `${time}:00`;
  }
  
  return time;
};

/**
 * Utility function to format a date into DD-MM-YYYY
 * @param {string | Date} dateInput - The ISO string or Date object
 * @returns {string} Formatted date as DD-MM-YYYY
 */
export const convertDateToDDMMYYYY = (dateInput: string | Date): string => {
  const date = new Date(dateInput);

  // Validate the date
  if (isNaN(date.getTime())) {
    // eslint-disable-next-line no-console
    console.error('Invalid date input:', dateInput);
    return 'Invalid Date';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

/**
 * Converts a string like 'OTHER_FACTOR' into a human-readable format like 'Other Factor'.
 * Handles null and undefined inputs by returning an empty string.
 * 
 * @param inputString - The input string to convert
 * @returns Converted string in the desired format
 */
export function convertUnderscoreToTitle(inputString: string | null | undefined): string {
  if (inputString == null) {
    return ''; // Return an empty string for null or undefined
  }

  return inputString
    .toLowerCase() // Convert all letters to lowercase
    .split('_') // Split the string by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' '); // Join the words back with spaces
}

export const isWithin24Hours = (createdOn: string | undefined | null): boolean => {
  if (!createdOn) {
    return false;
  }

  const createdDate = new Date(createdOn);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifferenceMs = currentDate.getTime() - createdDate.getTime();

  if (timeDifferenceMs <= 0) {
    return false;
  }

  // Convert to hours
  const timeDifferenceInHours = timeDifferenceMs / (1000 * 60 * 60);

  return timeDifferenceInHours <= 24;
};


// Function to get gradient based on color
export const getGradient = (color: string | null) => {
  const gradient = gradientData.gradients.find((g) => g.name === color);
  return gradient ? gradient.background : 'radial-gradient(circle, #FFFFFF 0%, #DCDCDC 100%)'; // Default White
};

export const extractTime = (isoString: string | null | undefined, format12Hour = false): string => {
  if (!isoString) return '';

  const date = new Date(isoString);
  return date.toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: format12Hour 
  });
};
