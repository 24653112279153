import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, LinearProgress } from '@mui/material';
import CryotechComponent from './CryotechComponent';
import { CryotechUnit, gradientData } from '../Embryology/Embryo_Culture/embryoCulture.helpers';
import { getCryotechDetails } from './cryoStorage.helpers';

interface StorageUnit {
    id: number | null;
    reference_id: string | null;
    plan: number;
    level: number;
    name: string;
    parent: number | null;
    occupancy: number;
    hierarchy: string;
    specimen: unknown | null;
    is_occupied: boolean;
    is_leaf: boolean;
    color: string | null;
}

interface StorageDetailsProps {
    locationDetails: {
        id?: number;
        parents: StorageUnit[];
    } | null;
}

// Define hierarchy order
const hierarchyOrder = ['Tank', 'Canister', 'Cane', 'Goblet'];

const StorageDetails: React.FC<StorageDetailsProps> = ({ locationDetails }) => {
  const [cryotechs, setCryotechs] = useState<CryotechUnit[]>([]);


  useEffect(() => {  
    if (locationDetails?.id) {
      getCryotechDetails(locationDetails.id)
        .then((data) => {
          if (Array.isArray(data)) {
            setCryotechs(data);
          } else if (data && typeof data === 'object') {
            setCryotechs([data]); 
          } else {
            setCryotechs([]); 
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('Error fetching Cryotech details:', error))
        .finally(() => {
          // eslint-disable-next-line no-console
          console.log('Cryotech details fetch operation completed.');
        });
    }
  }, [locationDetails?.id]);
  

  if (!locationDetails || !locationDetails.parents) {
    return <Typography>No storage details available.</Typography>;
  }

  // Sort parents based on predefined hierarchy order
  const sortedUnits = locationDetails.parents.sort((a, b) => {
    return hierarchyOrder.indexOf(a.hierarchy) - hierarchyOrder.indexOf(b.hierarchy);
  });

  // Function to determine capacity color
  const getCapacityColor = (occupancy: number) => {
    if (occupancy <= 30) return '#27ae60'; // Green for low occupancy
    if (occupancy <= 70) return '#f39c12'; // Orange for medium
    return '#e74c3c'; // Red for high occupancy
  };


  // Function to get radial gradient based on color
  const getRadialGradient = (color: string) => {
    switch (color.toLowerCase()) {
    case 'pink':
      return 'radial-gradient(50% 50% at 49.77% 50%, #EF88E9 0%, #FFF0FE 100%)';
    case 'green':
      return 'radial-gradient(50% 50% at 49.77% 50%, #32CD32 0%, #C8FACC 100%)';
    case 'blue':
      return 'radial-gradient(50% 50% at 49.77% 50%, #1E90FF 0%, #D6E6FF 100%)';
    case 'red':
      return 'radial-gradient(50% 50% at 49.77% 50%, #FF6347 0%, #FFD6D6 100%)';
    case 'yellow':
      return 'radial-gradient(50% 50% at 49.77% 50%, #FFD700 0%, #FFF8DC 100%)';      
    case 'white':
      return 'radial-gradient(50% 50% at 49.77% 50%, #FFFFFF 0%, #DCDCDC 100%)';
    case 'brown':
      return 'radial-gradient(50% 50% at 49.77% 50%, #8B5A2B 0%, #F5E6DA 100%)';
    case 'orange':
      return 'radial-gradient(50% 50% at 49.77% 50%, #E78457 0%, #FFDCCC 100%)';
    default:
      return 'radial-gradient(50% 50% at 49.77% 50%, #A9A9A9 0%, #D3D3D3 100%)'; 
    }
  };

  return (
    <><Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        alignItems: 'flex-start',
        padding: 2,
      }}
    >
      {/* Left Side: Cryotech Section */}
      <Box sx={{ flex: 1, minWidth: '100px' }}>
        <CryotechComponent cryotechs={cryotechs} gradientData={gradientData} />
      </Box>

      {/* Right Side: Card Section */}
      <Box
        sx={{
          flex: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
        }}
      >
        {sortedUnits.map((unit, index) => {
          const isGoblet = unit.level === 3;
          return (
            <Card
              key={index}
              sx={{
                borderRadius: 3,
                boxShadow: 2,
                backgroundColor: '#F7F7F7',
                minWidth: 160,
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: 1.2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: 12, fontWeight: 600, color: '#555' }}>
                    {hierarchyOrder[index]} :
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 700, color: '#000' }}>
                    {unit?.name}
                  </Typography>
                </Box>
                {!isGoblet && (
                  <Typography sx={{ fontSize: 10, fontWeight: 500, color: '#777' }}>
                    Capacity:{' '}
                    <span style={{ color: getCapacityColor(unit.occupancy), fontWeight: 600 }}>
                      {unit.occupancy ? unit.occupancy.toFixed(2) + '%' : '0%'}
                    </span>
                  </Typography>
                )}
                {isGoblet && (
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    sx={{
                      height: 5,
                      borderRadius: 2,
                      marginTop: 1,
                      backgroundColor: '#eee',
                      '& .MuiLinearProgress-bar': {
                        background: getRadialGradient(unit.color ?? 'default'),
                      },
                    }} />
                )}
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
    <Box sx={{ height: '1px', backgroundColor: '#ddd', my: 2 }} /></>
  );
};  

export default StorageDetails;
