/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import { getStim, getTestResults, Stim, TestResult } from './helper.stim';
import { convertToLocalDateTime } from '../../services/time';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartDataset,
  ChartData,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export interface Hormonal {
  patientId: number;
  stim: number;
}

type DatasetType = ChartDataset<'line'> | ChartDataset<'bar'>;

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const Hormonalgraph: React.FC<Hormonal> = ({ patientId, stim }) => {
  const [firstDate, setFirstDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedStim, setSelectedStim] = useState<Stim | null>(null);
  const [hormones, setHormones] = useState<TestResult[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [dataset, setDataset] = useState<ChartDataset<'line' | 'bar', number[]>[]>([]);
  const [selectedHormone, setSelectedHormone] = useState<'Progesterone'| 'Estradiol'| 'FSH'| 'LH'>('Progesterone');
  const testsnames: string[] = ['Progesterone', 'Estradiol', 'FSH', 'LH'];

  function daysFromFirstDate(date: Date) {
    if (firstDate) {
      return (
        Math.ceil((date.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)) +
        1
      );
    }
    return (
      Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) +
      1
    );
  }

  useEffect(() => {

    if (!patientId || !firstDate) return;
    getTestResults(patientId)
      .then((results: TestResult[]) =>
        results.filter(
          (result: TestResult) =>
            convertToLocalDateTime(result.date) >= firstDate &&
            (!endDate || convertToLocalDateTime(result.date) <= endDate)
        )
      )
      .then((filteredResults) => setHormones(filteredResults))
       
      .catch((error) => console.error('Failed to fetch test results:', error));
  }, [firstDate, endDate, patientId]);

  useEffect(() => {
    if (!hormones.length) return;

    // Generate labels based on days from first date
    const lab = Array.from(new Set(
      hormones.map((result) => daysFromFirstDate(new Date(result.date)).toString())
    )).sort((a, b) => Number(a) - Number(b)); 
    setLabels(lab);

    const testNames = Array.from(new Set(hormones.map((result) => result.laboratory_test.name)));

    // const datasets: ChartDataset<'line' | 'bar', number[]>[] = testNames.filter((testName) => testName.toUpperCase() === selectedHormone.toUpperCase()).map((testName, index) => ({
    const datasets: ChartDataset<'line' | 'bar', number[]>[] = testNames.map((testName, index) => ({
      type: 'bar', // ✅ Explicitly cast type
      label: testName,
      borderColor: index % 2 === 0 ? 'rgb(255, 99, 132)' : 'rgb(75, 192, 192)',
      borderWidth: 2,
      fill: index % 2 !== 0,
      data: labels.map((label) => {
        const test = hormones.find(
          (result) => daysFromFirstDate(new Date(result.date)).toString() === label 
        );
        return test?.results ?? null;
      }).filter((value): value is number => value !== null),
    }));
    
    setDataset(datasets);
    // eslint-disable-next-line
  }, [hormones, selectedHormone]);

  
  useEffect(() => {
    if (!stim) return;
    getStim(stim)
      .then((stimData) => setSelectedStim(stimData))
       
      .catch((error) => console.error('Failed to fetch Stim data:', error));
  }, [stim]);

  useEffect(() => {
    if (!selectedStim?.cycle) return;

    if (selectedStim.cycle.cycle_start_date) {
      setFirstDate(convertToLocalDateTime(selectedStim.cycle.cycle_start_date));
    }

    if (selectedStim.cycle.cycle_end_date) {
      setEndDate(convertToLocalDateTime(selectedStim.cycle.cycle_end_date));
    }
  }, [selectedStim]);

  const data: ChartData<'bar' | 'line', number[], string> = {
    labels,
    datasets: dataset,
  };

  useEffect(() => {
    console.log('running', labels, dataset);
  }, [labels, dataset]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedHormone((event.target as HTMLInputElement).value.toString() as 'Progesterone'| 'Estradiol'| 'FSH'| 'LH');
  };

  return (
    (dataset && labels) ? (
      <div className={Styles.hormonal}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Hormones</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleRadioChange}
          >
            <FormControlLabel value="Progesterone" control={<Radio />} label="Progesterone" />
            <FormControlLabel value="Estradiol" control={<Radio />} label="Estradiol" />
            <FormControlLabel value="FSH" control={<Radio />} label="FSH" />
            <FormControlLabel value="LH" control={<Radio />} label="LH" />
          </RadioGroup>
        </FormControl>
        <Chart type="line" data={data} />
      </div>
    ) : 'No test found'
  );
};

export default Hormonalgraph;