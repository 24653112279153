/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { ColumnConfig, GroupConfig, TableRowData } from '../../types';
import styles from './Andrology.module.css';
import EditableTable from '../../components/customTableComponent/index';
import { ReactComponent as Pending } from '../../assets/SVG/pendingLab.svg';
import { ReactComponent as Complete } from '../../assets/SVG/complete.svg';
import ReusableCustombtn from '../../components/ResuableCustombtnComponent/index';
import { useAlert } from '../../components/alertProvider';
import axios from 'axios';
import { useLoader } from '../../components/loaderProvider/loader';
import { navigateTo } from '../../services/nevigationService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePatientContext } from '../../components/patinetProvider';
import { usePatientContext as useSubPatientContext } from '../../components/SubPatinetProvider';
import { useClinic } from '../../components/ClinicProvider';
import { Grid } from '@mui/material';
import { getAndrology, mapPatientData, PatientData, PatientRecord, } from './AndrologyHelper';

export default function Andrology() {
  const [patientData, setPatientData] = useState<PatientData[]>([]);
  const [dataSize, setDataSize] = useState<number | undefined>();
  const [selectedValue, setSelectedValue] = useState('patient');
  const [inputValue, setInputValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [trigger, setTrigger] = useState(false);
  const { ClinicId } = useClinic();
  const [urlParams] = useSearchParams();
  const { addPatient } = usePatientContext();
  const { addSubPatient } = useSubPatientContext();
  const [currentPageoffset, setCurrentPagePageoffset] = useState(1);
  const itemsPerPagePageoffset = 10;
  const [tableData, setTableData] = useState<TableRowData[]>([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputChange = (value: any) => {
    setInputValue(value);
  };

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
    setCurrentPagePageoffset(1);
  };


  const groupConfig: GroupConfig[] = [
    { fields: ['patientName', 'MRN',], type: 'column', defaultHeaderGroupname: 'Patient' },
    { fields: ['partnerName', 'partnerMRN',], type: 'column', defaultHeaderGroupname: 'Partner' },
    { fields: ['procedure'], type: 'row' },
    { fields: ['doctor'], type: 'row' },
    { fields: ['status'], type: 'row' },
  ];
  const columns: ColumnConfig[] = [
    { field: 'partnerName', headerName: 'Partner', type: 'text', editable: false },
    { field: 'patientName', headerName: 'Patient', type: 'text', editable: false },
    { field: 'MRN', headerName: 'MRN', type: 'text', editable: false },
    { field: 'partnerMRN', headerName: 'MRN', type: 'text', editable: false },
    { field: 'procedure', headerName: 'Procedure', type: 'text', editable: false },
    { field: 'doctor', headerName: 'Andrologist Name', type: 'text', editable: false },
    { field: 'status', headerName: 'Status', type: 'text', editable: false },
  ];

  const handleDateChange = (start: string, end: string) => {
    setStartDate(start);
    setEndDate(end);
  };
  const [selectedStatus, setSelectedStatus] = useState<boolean | undefined>(undefined);


  const handleStatusClick = (statusType: 'complete' | 'pending') => {
    if (statusType === 'complete') {
      setSelectedStatus(true);
    } else if (statusType === 'pending') {
      setSelectedStatus(false);
    } else {
      setSelectedStatus(undefined);
    }
  };



  const handlePageChange = (newPage: number) => {
    setCurrentPagePageoffset(newPage);
  };
  const handlePageChangeSSO = (newPage: number) => {
    setCurrentPagePageoffset(newPage);
  };
  useEffect(() => {
    showLoader();
    const fetchData = async () => {
      try {
        // const offset = currentPageoffset; 
        let offset;
        if (currentPageoffset === 1) {
          offset = (itemsPerPagePageoffset * (currentPageoffset - 1));
        } else {
          offset = (itemsPerPagePageoffset * (currentPageoffset - 1)) + 1;

        }
        const params = {
          search: inputValue || '',
          startDate: startDate || '',
          endDate: endDate || '',
          selectedValue: selectedValue || '',
          itemsPerPage: itemsPerPagePageoffset,
          currentPage: currentPageoffset,
          ClinicId: ClinicId || '',
          offset: offset,
          status: selectedStatus,
        };
        console.log(selectedStatus);

        const response = await getAndrology(params);
        const transformedData = mapPatientData(response.objects);
        setCompleteCount(response.meta.total_completed);
        setPendingCount(response.meta.total_pending);
        let completeCount = 0;
        let pendingCount = 0;
        const tableRowData = transformedData.flatMap((data: PatientData, index: number) =>
          data.objects.map((item: PatientRecord) => {
            const { patient, doctor_name, service, status } = item;
            const partner = patient.partner;
            const createdDate = new Date(patient.created_on);
            const totalCount = response.meta.total_count;
            setDataSize(totalCount);
            if (status) {
              completeCount++;
            } else {
              pendingCount++;
            }
            return {
              id: index + 1,
              patient_id: patient.id,
              patientName: `${patient.first_name} ${patient.last_name} | ${patient.age}`,
              MRN: `${patient.medical_record_no} |${patient.gender__name}`,
              age: patient.age?.toString() || '-',
              gender: patient.gender__name || '-',
              date: `${createdDate.getUTCDate().toString().padStart(2, '0')}/${(createdDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${createdDate.getUTCFullYear()}`,
              procedure: service || '-',
              reasonOfVisit: patient.reason_for_visit || '-',
              partnerName: partner ? `${partner.first_name} ${partner.last_name} | ${partner.age}` : '-',
              partnerMRN: `${partner?.medical_record_no} |${partner?.gender__name}`,
              partnerage: partner ? partner.age?.toString() || '' : '',
              partnergender: partner ? partner.gender__name || '' : '',
              doctor: doctor_name || '-',
              firstvisit: service || '-',
              status: status ? <Complete /> : <Pending />,
            };
          })
        );

        setTableData(tableRowData);

        hideLoader();
      } catch (error) {
        hideLoader();
        console.error('Error fetching patient data:', error);
      }
    };

    fetchData();
  }, [
    inputValue,
    startDate,
    endDate,
    selectedValue,
    itemsPerPagePageoffset,
    currentPageoffset,
    ClinicId,
    showLoader,
    hideLoader,
    selectedStatus
  ]);



  const typeFromUrl = useMemo(() => {
    return urlParams.get('patient_type') || 'patient';
  }, [urlParams]);

  useEffect(() => {
    setSelectedValue(typeFromUrl);
  }, [typeFromUrl]);




  const handleOnChange = (id: number, field: string, value: string) => {
    console.log('On Change:', { id, field, value });
  };


  const handleAction = (id: number) => {
    console.log('Action Triggered for ID:', id);
  };

  const navigateToFn = (id: number) => {
    const selectedData = tableData.find((item) => item.id === id);

    if (!selectedData) {
      console.log('Data not found');
      return;
    }

    const { procedure, patient_id } = selectedData;

    // Define routes based on procedure type
    const routes: Record<string, string> = {
      'Semen Processing': `/lab/andrology/semen-processing/${patient_id}`,
      'Semen Freezing': `/lab/andrology/semen-freezing/${patient_id}`,
      'Semen Thawing': `/lab/andrology/semen-thawing/${patient_id}`,
      'IUI': `/lab/andrology/IUI/${patient_id}`,
    };

    const route = routes[procedure] || `/lab/andrology/semen-analysis/${patient_id}`;

    navigate(route);
  };


  const [patientId, setPatientId] = useState<string | undefined>(undefined);


  const getAllRowData = (row: TableRowData) => {
    addPatient(row.patient_id);
    return new Promise<void>((resolve) => {
      setPatientId(row.patient_id.toString());
      addSubPatient(row.patient_id);
      resolve();
    })
      .then(() => {
        sessionStorage.setItem('prevRoute', '/lab/andrology');
        // navigateTo(`/lab/andrology/semen-analysis/${row.patient_id}`);
      });
  };
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setTableKey(prevKey => prevKey + 1);
  }, [selectedValue]);
  return (
    <div className={styles.container}>
      <div className='container-fluid'>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6} className={'d-flex align-items-center'}>
            <span className={styles.searchby}>Search By:</span>
            <div className='ms-3 flex-grow-1'>
              <ReusableCustombtn
                type={['radio']}
                radioOptions={[
                  { name: 'Patient', value: 'patient' },
                  { name: 'Sperm Donor', value: 'SPERM_DONOR' },
                ]}
                selectedValue={selectedValue}
                onRadioChange={handleRadioChange}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={6} className={`${styles.mediaQueryCss} d-flex align-items-center`}>
            <ReusableCustombtn
              type={['input', 'date']}
              onDateChange={(start: string, end: string) => handleDateChange(start, end)}
              inputLabel='Search Patient'
              inputPlaceholder='Search by name, MRN'
              dateFromLabel='Date From'
              dateToLabel='Date To'
              onInputChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </div>
      <div className='mt-4'>
        <EditableTable
          key={tableKey}
          columns={columns}
          data={tableData}
          actionType='none'
          onAction={handleAction}
          onChange={handleOnChange}
          groupConfig={groupConfig}
          onRowClick={(id: number) => navigateToFn(id)}
          dataSize={dataSize}
          onPageChange={handlePageChange}
          getAllRowData={getAllRowData}
        />

      </div>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={6} className="d-flex align-items-center mt-4">
        <span className={styles.searchby} onClick={() => setSelectedStatus(undefined)}>Total Count : {dataSize}</span>
        <div className="d-flex justify-content-end align-items-center w-100 mt-4">
          <div className="d-flex align-items-center" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={() => handleStatusClick('complete')}>
            <div className={styles.circle} style={{ backgroundColor: '#47B35F' }}></div>
            <span className={`${styles.completeText}`} style={{ marginLeft: '8px' }}>
              Complete {completeCount}
            </span>
          </div>
          <div className="d-flex align-items-center" style={{ marginLeft: '16px', cursor: 'pointer' }} onClick={() => handleStatusClick('pending')}>
            <div className={styles.circle} style={{ backgroundColor: '#EFAE78' }}></div>
            <span className={`${styles.completeText}`} style={{ marginLeft: '8px' }}>
              Pending {pendingCount}
            </span>
          </div>

        </div>
      </Grid>
    </div>
  );
}
