import moment from 'moment-timezone';
import { AppointmentStatus } from '../../types';
import { getRequest } from '../../services/httpService';

export interface DateRange {
  date: string; // Day in 'yyyy-MM-dd' format (UTC)
  start: Date;
  end: Date;
}

export function splitDateRangeIntoDailyGroups(startDate: Date, endDate: Date): DateRange[] {
  const groups: DateRange[] = [];
  let currentStart = startDate;

  while (moment(currentStart).isBefore(endDate) || moment(currentStart).isSame(endDate, 'day')) {
    // Define the end of the current day at 23:59:59
    const endOfDay = moment(currentStart).endOf('day').toDate();

    // Determine the end time for the current day's group
    const groupEnd = moment.min(moment(endOfDay), moment(endDate)).toDate();

    // Only add a new group if currentStart is strictly before endDate or it ends exactly at the end of the previous day
    if (moment(currentStart).isBefore(endDate) || moment(currentStart).isSame(endDate, 'day')) {
      // Exclude endDate from creating a group if it's exactly midnight
      if (!(moment(groupEnd).isSame(endDate) && moment(groupEnd).hours() === 0 && moment(groupEnd).minutes() === 0)) {
        groups.push({
          date: moment(currentStart).utc().format('YYYY-MM-DD'), // UTC formatted day as a string
          start: currentStart,
          end: groupEnd,
        });
      }
    }

    // Move to the next day starting at 00:00:00
    currentStart = moment(currentStart).add(1, 'days').startOf('day').toDate();
  }

  return groups;
}

export interface OutOfOfficeRequest {
  user: number;
  start_datetime: string;
  end_datetime: string;
  type: string;
  remark: string;
  appointment_date: string;
  room: null;
  table: null;
}

export interface Status {
  status: AppointmentStatus;
  count: string;
}

export function getStatus(start_date: string, end_date: string, department: string | null | undefined, user: string | null | undefined): Promise<Status[]> {
  if (department && user) {
    return getRequest<Status[]>(`appointments/status?start_date=${start_date}&end_date=${end_date}&type=APPOINTMENT&department=${department}`);
  }
  if (department) {
    return getRequest<Status[]>(`appointments/status?start_date=${start_date}&end_date=${end_date}&type=APPOINTMENT&department=${department}`);
  }
  if (user) {
    return getRequest<Status[]>(`appointments/status?start_date=${start_date}&end_date=${end_date}&type=APPOINTMENT`);
  }
  return getRequest<Status[]>(`appointments/status?start_date=${start_date}&end_date=${end_date}&type=APPOINTMENT`);
}