import { Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import OocyteComponent from './OocyteComponent';

const CryoThawingComponent = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  return (
    <div>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="registration tabs"
        variant="fullWidth"
        style={{ borderBottom: '2px solid #f2f2f2', fontWeight: '800' }}
        TabIndicatorProps={{
          sx: {
            backgroundColor: 'rgba(225, 126, 97, 1)',
            height: '2px', 
          },
        }}
        sx={{
          '& .MuiTab-root': {
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '22px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
            color: 'rgba(141, 152, 162, 1)',
          },
          '& .Mui-selected': {
            color: '#E17E61',
            fontWeight: '800',
          },
        }}
      >
        <Tab label="Oocyte" />
        <Tab label="Embryo" />
      </Tabs>

      {value === 0 && (
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          <OocyteComponent label={'Oocyte'} />
        </Typography>
      )}
      {value === 1 && (
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          <OocyteComponent label={'Embryo'} />
        </Typography>
      )}
    </div>
  );
};

export default CryoThawingComponent;
