/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import OocyteSelector from './occyteSelector';
import DetailsTable from './detailsTable';
import { action945Items, cleavageItems, cultureDishConfigItems, EmbryoDetail, EmbryoFinilizeUpdateType, EmbryoOocyteFilledStatus, FreezeDialogValues, incubatorItems, initialValuesCryo, pbItems, PnItems, source_list, storeGobletColor, UpdateEmbryo } from './embryoCulture.helpers';
import { Autocomplete, Box, Button, Grid2, TextField, ThemeProvider, Typography } from '@mui/material';
import styles from './embryoCulture.module.css';
import { theme } from '../../../components/Medical_History_Forms/themeprovider';
import { Form } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, getIn } from 'formik';
import { convertUnderscoreToTitle, RequiredLabel } from '../../../utils/commonUtils';
import { Clinician, Role, ROLES } from '../Occyte-Grading/oocyteGrading.helper';
import { useClinic } from '../../../components/ClinicProvider';
import { useAlert } from '../../../components/alertProvider';
import { useLoader } from '../../../components/loaderProvider/loader';
import { getUsersByClinicId } from '../../../services/apiService';
import { ConfirmationModal } from '../../../components/confirmationModal';
import { columns, CombinedValidationSchema, EmbryoCultureType, EmbryoUpdateDetailsType, getEmbryoCultureDetails,  initialEmbryoCultureValues, initialEmbryoUpdateValues, updateEmbryoDetails, updateEmbryoSummary, updateEmbryoSummaryDetails } from './dayFirst.helpers';
import { AxiosError } from 'axios';
import GradingUnavailable from '../Occyte-Grading/oocyteGradingUnavailable';
import EmbryoCultureViewMode from './ViewMode';
import FreezeActionDialog from './freezeActionDialog';
import OverlayIconPanel from './overlay';
import EmbryoFileUpload from './EmbryoFileUpload';
import EmbryoMultiImageUploadDialog from './EmbryoMultiImageUploadDialog';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface cycleProp {
  initialCycleId: number;
}

const DayFirst:React.FC<cycleProp> = ({ initialCycleId }) => {  
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [file, setFile] = useState<File | null>(null); 
  const [imagePathUrl, setImagePathUrl] = useState<string | null>(null);
  const [selectedEmbryos, setSelectedEmbryos] = useState<number[]>([]);
  const [selectedSource, setselectedSource] = useState<string>('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [embryosFound, setEmbryosFound] = useState<boolean>(true); 
  const [embryoCulture, setEmbryoCulture] = useState<EmbryoCultureType>(initialEmbryoCultureValues);
  const [embryoDetails, setEmbryoDetails] = useState<EmbryoUpdateDetailsType>(initialEmbryoUpdateValues);
  const [hitSubmitbtn, setHitSubmitbtn] = useState(false);
  const [actionStatus, setActionStatus] = useState<'DRAFT' | 'FINALIZED'>('DRAFT');
  const [showViewMode, setShowViewMode] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<{ embryo_no: number; base64: string }[]>([]);
  const [isEmbryoUploadDialogOpen, setEmbryoUploadDialogOpen] = useState(false); // State to control Embryo upload dialog visibility
  
  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });
  const [allSourceEmbryos, setAllSourceEmbryos] = useState<UpdateEmbryo[]>([]);
  
  const [allEmbryos, setAllEmbryos] = useState<EmbryoDetail[]>([]);
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableData, setTableData] = useState<any[]>([]);
  const [isFinalized, setIsFinalized] = useState(false);

  const [isTableDeletable, setIsTableDeletable] = useState(false);
  
  const [freezeValues, setFreezeValues] = useState<FreezeDialogValues>(initialValuesCryo);
  const [isFreezeDialogOpen, setFreezeDialogOpen] = useState(false);

    
  const [areAllOocytesFilled, setAreAllOocytesFilled] = useState(false);
  const [isFinalizeConfirmDialogOpen, setFinalizeConfirmDialogOpen] = useState(false);
  
  const [selectedCycleId, setSelectedCycleId] = useState<number>(initialCycleId);
  const [sourceData, setSourceData] = useState<source_list[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(null);
  const [selectedThawId, setSelectedThawId] = useState<number | null>(null);
  
  const fetchEmbryoDetails = useCallback(
    (cycle_id: number,  sourceID?: number, thawId?:number | null) => {
      showLoader();
      if (cycle_id) {
        getEmbryoCultureDetails(cycle_id, sourceID, thawId) 
          .then((data) => {
            
            if (data.embryo && data.embryo.length === 0) {
              setEmbryosFound(false); 
            } else {
              setEmbryosFound(true); 
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const finalizedEmbryo = data.embryo.find((embryo: any) => embryo.status === 'FINALIZED');
    
            // If a finalized embryo is found, set state to true
            if (finalizedEmbryo) {
              setIsFinalized(true);
            }


            // const finalizedItem = data.embryo?.find((item: EmbryoUpdateDetailsType) => item.status === 'FINALIZED');            
            // setIsTableDeletable(!finalizedItem);

            const embryoDetails = data.embryo
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((detail: any) => ({
                embryo_number: detail.embryo_number,
                isFilled: detail.action !== null,
              }))
              .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
            setAllEmbryos(embryoDetails);
            const dayOneEntry = data?.details.find((item: { day: number; }) => item.day === 1);
            setEmbryoCulture((prevEmbryoCulture) => ({
              ...prevEmbryoCulture,  
              ...dayOneEntry,        
            }));

                        
            // if (embryoDetails.length >= 1) {
            //   const isAllFilled = embryoDetails.every((embryo: EmbryoOocyteFilledStatus) => embryo.isFilled);
                    
            //   if (!isAllFilled) {
            //     setAreAllOocytesFilled(false);
            //   } else {
            //     setAreAllOocytesFilled(true);
            //   }
            // } else {
            //   setAreAllOocytesFilled(false);
            // }
            
          })
          .catch((error) => {
            console.error('Error fetching Embryo Details:', error);
          })
          .finally(() => {
            hideLoader();
          });
      }
    },
    [showLoader, hideLoader]
  );
  const fetchSourceList = useCallback((isdeletetriggered?:boolean)=>{
    getEmbryoCultureDetails(selectedCycleId)
      .then((data) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        processEmbryoData(data,selectedSourceId, selectedThawId );
        // Check if source_list exists before updating state
        if (data && Array.isArray(data.source_list)) {
          setSourceData(data.source_list);
          // Set the first object's source as the selectedSourceId if sourceData is not empty
          if (data.source_list.length > 0) {
  
            const firstSourceId = data.source_list[0].source;
            const FirstThawId = data.source_list[0].thaw_cycle;
            setselectedSource(data.source_list[0].name);
            setSelectedSourceId(firstSourceId);
            setSelectedThawId(FirstThawId);
            // Call fetchEmbryoDetails with the first source ID
            fetchEmbryoDetails(selectedCycleId, firstSourceId, FirstThawId);
          }
          setAllSourceEmbryos(data.embryo);
  
          const finalizedItem = data.embryo?.every((item: EmbryoUpdateDetailsType) => item.status === 'FINALIZED');            
          setIsTableDeletable(finalizedItem);

          if (!isdeletetriggered) {
            
            const showViewMode = data.embryo?.some((item: EmbryoUpdateDetailsType) => item.action !== null);
            setShowViewMode(showViewMode);
                    
          } else {
            setShowViewMode(false);
          }
          const embryoDetails = data.embryo
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((detail: any) => ({
              embryo_number: detail.embryo_number,
              isFilled: detail.action !== null,
            }))
            .sort((a: { embryo_number: number; }, b: { embryo_number: number; }) => a.embryo_number - b.embryo_number);
                          
          if (embryoDetails.length >= 1) {
            const isAllFilled = embryoDetails.every((embryo: EmbryoOocyteFilledStatus) => embryo.isFilled);
                      
            if (!isAllFilled) {
              setAreAllOocytesFilled(false);
            } else {
              setAreAllOocytesFilled(true);
            }
          } else {
            setAreAllOocytesFilled(false);
          }            
        }
      })
      .catch((error) => {
        console.error('Error fetching source list:', error);
      });
    // eslint-disable-next-line
  },[]);

  
  useEffect(() => {
    fetchSourceList();
    // eslint-disable-next-line
  }, [selectedCycleId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processEmbryoData = (data:any, selectedSourceId:number |null |undefined, selectedThawId:number |null |undefined) => {

    if (data.details && Array.isArray(data.details)&& !selectedSourceId && !selectedThawId) {
      const mappedRows = data.embryo
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .filter((item: any) => item.action !== null).sort((a: any, b: any) => a.embryo_number - b.embryo_number).map((item: any) => {
          const freezeContent = item.action === 'FREEZE'
            ? {
              title: 'Action Information',
              details: [
                { label: 'Cryo Preserved date', value: item.cryo_preserved_date || '-' },
                { label: 'Cryo Preserved By', value: item.cryo_preserved_by_name || '-' },
                { label: 'Cryo Purpose', value: convertUnderscoreToTitle(item.cryo_purpose) || '-' },
                { label: 'Media', value: convertUnderscoreToTitle(item.cryo_media) || '-' },
                { label: 'Screening Status', value: item.is_screened  ?'Yes':'No'},
              ],
            }
            : null;
          
            
          return{
            
            id: item.id,            
            image: item.files[0].file,
            embryoNo: item.embryo_no,
            pn: item?.pn || '-',
            pb: item?.pb || '-',
            cleavage: convertUnderscoreToTitle(item?.cleavage) || '-',
            action: item.action === 'FREEZE' && freezeContent ?( 
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OverlayIconPanel content={freezeContent} />
                <span style={{ marginLeft: '5px' }}>{convertUnderscoreToTitle(item.action)}</span>
              </div>): convertUnderscoreToTitle(item.action),
            freezeLocation: '-',
            location_details:item.location_details,
          };
        });

      setTableData(mappedRows);
    }
  };
  
  // Handle dropdown change using SelectChangeEvent type
  const handleSourceChange = (event: string) => {
    const selectedSOurceName = event;
    const selectedSourceItem = sourceData.find(item => item.name === selectedSOurceName) || null;
    // Log the selected source item
    setselectedSource(selectedSOurceName); // Update selectedSourceId as well
  
    const cycle_ID = selectedSourceItem?.cycle ?? 0; 
    setSelectedCycleId(cycle_ID);
    setSelectedSourceId(selectedSourceItem?.source ?? 0);
    setSelectedThawId(selectedSourceItem?.thaw_cycle ?? null);
  
    if (selectedSourceItem) {
      fetchEmbryoDetails(cycle_ID, selectedSourceItem.source, selectedSourceItem.thaw_cycle); 
    } 
  };
    
  const handleSelectionChange = (newSelectedOocytes: number[], newDropdownValue: string) => {    
    setSelectedEmbryos(newSelectedOocytes);
    if (selectedSource && selectedSource !== newDropdownValue) {      
      setselectedSource(newDropdownValue);
      handleSourceChange(newDropdownValue);
    };
  };
  
  // useEffect(() => {
  //   fetchEmbryoDetails(selectedCycleId);
  // }, [fetchEmbryoDetails,selectedCycleId]);  
  
  // Wrap fetchCliniciansByRole in useCallback
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {         
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here
  
  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); 
  


  // Utility function to sanitize EmbryoCulture values
  const sanitizeEmbryoCulture = (values: EmbryoCultureType): EmbryoCultureType => {
    const sanitizedValues = { ...values };

    if (sanitizedValues.time && sanitizedValues.time.trim().length === 5) {
      sanitizedValues.time = `${sanitizedValues.time}:00`;
    }

    sanitizedValues.day = 1; 
    sanitizedValues.cycle = selectedCycleId; 

    return sanitizedValues;
  };

  const sanitizeEmbryoDetails = (values: EmbryoUpdateDetailsType): EmbryoUpdateDetailsType => {
    const sanitizedValues = { ...values };
    sanitizedValues.cycle = selectedCycleId; 
    sanitizedValues.status = 'FINALIZED'; 

    if(sanitizedValues.action === 'FREEZE'){
 
      sanitizedValues.cryo_preserved_date = freezeValues.cryo_preserved_date || sanitizedValues.cryo_preserved_date;
      sanitizedValues.cryo_preserved_by = freezeValues.cryo_preserved_by || sanitizedValues.cryo_preserved_by;
      sanitizedValues.cryo_purpose = freezeValues.cryo_purpose || sanitizedValues.cryo_purpose;
      sanitizedValues.cryo_media = freezeValues.cryo_media || sanitizedValues.cryo_media;
      sanitizedValues.is_screened = freezeValues.is_screened || sanitizedValues.is_screened;
      sanitizedValues.locations = freezeValues.cryotech ?? null;
    }  
    sanitizedValues.source = selectedSourceId;
    sanitizedValues.thaw_cycle = selectedThawId;   

    // ✅ Add the uploaded files in the required format
    if (uploadedFiles.length > 0) {
      // ✅ Add the uploaded files in the required format
      sanitizedValues.files = uploadedFiles.map(file => ({
        embryo_no: file.embryo_no,
        file: file.base64.replace(/^data:image\/\w+;base64,/, '') // Remove prefix
      }));
    }
    
    return sanitizedValues;
  };

  const handleSubmit = (
    values: { embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType },
    { setSubmitting, setErrors, resetForm }: FormikHelpers<{ embryoCulture: EmbryoCultureType; embryoDetails: EmbryoUpdateDetailsType }>
  ) => {
    showLoader();
  
    const sanitizedEmbryoCulture = sanitizeEmbryoCulture(values.embryoCulture);
    const sanitizedEmbryoDetails = sanitizeEmbryoDetails(values.embryoDetails); 
  
    Promise.all([
      updateEmbryoDetails(values.embryoCulture.id ?? 0, sanitizedEmbryoCulture),
      updateEmbryoSummaryDetails(selectedCycleId, sanitizedEmbryoDetails),
    ])
      .then(([updatedEmbryoCulture, updatedEmbryoDetails]) => {
        addAlert('Embryo details saved successfully', 'success');

             
        if(sanitizedEmbryoDetails.action === 'FREEZE'){
          if (freezeValues.tank && freezeValues.goblet !== null) {
            if (freezeValues.tank != null && freezeValues.goblet != null && freezeValues.goblet_color) {
              storeGobletColor(freezeValues.tank, freezeValues.goblet, freezeValues.goblet_color)
                .then((response) => {
                  console.log('Goblet color successfully stored.', response);
                })
                .catch((error) => {
                  console.error('Error storing goblet color:', error);
                });
            } else {
              console.error('Tank, Goblet, or Goblet Color is missing.');
            }        
          } else {
            console.warn('Tank or Goblet is not selected.');
          }

          if (freezeValues.tank && freezeValues.cryotech !== null) {
            if (freezeValues.tank != null && freezeValues.cryotech != null && freezeValues.cryotech_color) {
              storeGobletColor(freezeValues.tank, freezeValues.cryotech, freezeValues.cryotech_color)
                .then((response) => {
                  console.log('Cryotech color successfully stored.', response);
                })
                .catch((error) => {
                  console.error('Error storing cryotech color:', error);
                });
            } else {
              console.error('Tank, cryotech, or cryotech Color is missing.');
            }        
          } else {
            console.warn('Tank or cryotech is not selected.');
          }
        }

        // Reset form with sanitized values
        resetForm({
          values: {
            embryoCulture: sanitizedEmbryoCulture,
            embryoDetails: sanitizedEmbryoDetails,
          },
        });
  
        fetchEmbryoDetails(selectedCycleId,  selectedSourceId ?? undefined, selectedThawId); 
        fetchSourceList(); 
      })
      .catch((error: unknown) => {
        // Handle errors
        if (error instanceof AxiosError) {
          if (error.response?.data?.type === 'validation_error') {
            const apiErrors = error.response.data.errors;
  
            const formikErrors: FormikErrors<{
              embryoCulture: EmbryoCultureType;
              embryoDetails: EmbryoUpdateDetailsType;
            }> = {
              embryoCulture: {} as FormikErrors<EmbryoCultureType>,
              embryoDetails: {} as FormikErrors<EmbryoUpdateDetailsType>,
            };
  
            apiErrors.forEach((err: { attr: string; detail: string }) => {
              if (err.attr.startsWith('embryoCulture')) {
                const fieldKey = err.attr.replace('embryoCulture.', '');
                formikErrors.embryoCulture![fieldKey as keyof EmbryoCultureType] = err.detail;
              } else if (err.attr.startsWith('embryoDetails')) {
                const fieldKey = err.attr.replace('embryoDetails.', '');
                formikErrors.embryoDetails![fieldKey as keyof EmbryoUpdateDetailsType] = err.detail;
              }
            });
  
            setErrors(formikErrors);
          } else {
            console.error('Unexpected error occurred:', error);
          }
        } else {
          console.error('Error occurred while saving embryo details:', error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleDelete = (id: number | string) => {
    setTableData((prevRows) => prevRows.filter((row) => row.id !== id));
    
    // fetchEmbryoDetails(selectedCycleId,  selectedSourceId ?? undefined, selectedThawId); 
    fetchSourceList(true);
  };

  
  const saveFinalize = () => {
    const finalizePromises = sourceData.map((source) => {
      // Filter embryos that match the current source
      const filteredEmbryos = allSourceEmbryos
        .filter((embryo) => embryo.source === source.source && embryo.thaw_cycle === source.thaw_cycle)
        .map((embryo) => embryo.embryo_number);

      const payload: EmbryoFinilizeUpdateType = {
        embryos: filteredEmbryos,  
        cycle: selectedCycleId as number,
        day: 1, 
        status: 'FINALIZED',
        source:source.source,
        thaw_cycle:source.thaw_cycle
      };
      return updateEmbryoSummary(selectedCycleId, payload)
        .then(() => {
          addAlert(`Embryo details for source ${source.name} saved successfully`, 'success');
        })
        .catch((error) => {
          console.error(`Error finalizing embryo summary for source ${source.name}:`, error);
        });
    }); 
    Promise.all(finalizePromises).then(() => {
      fetchSourceList();
    }); 

  };
  

  const handleFreezeDialogSave = (formValues: FreezeDialogValues) => {
    setFreezeValues(formValues); 
    
    setFreezeDialogOpen(false);
  };

  if (showViewMode) {
    return (
      <div >
        <EmbryoCultureViewMode data={embryoCulture} tableData={tableData} isFinalized ={isTableDeletable} tablecolumns= {columns} day={1} setIsEditMode={setShowViewMode}/>
      </div>
    );
  };

  
  const handleUploadComplete = (files: { embryo_no: number; base64: string }[]) => {
    setUploadedFiles(files);  
  };
  
  return (    
    <ThemeProvider theme={theme}>
      {allEmbryos.length ===0 ? (
      // If no embryos are found, show 
        <GradingUnavailable  message="No embryos available for the selected day." />
      ) : (
        <Formik
          initialValues={{ embryoCulture, embryoDetails }} 
          validationSchema={CombinedValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            setFieldValue,
            submitForm,
            validateForm,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldTouched
          }) => {
            return (
              <Form>
                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{xs:12, sm:3, md:3}}>
                    <TextField
                      name="date"
                      type="date"
                      label={RequiredLabel('Fert Check Date', true)}                      
                      value={
                        getIn(values, 'embryoCulture.opu_date')
                          ? new Date(
                            new Date(getIn(values, 'embryoCulture.opu_date')).getTime() + 24 * 60 * 60 * 1000
                          )
                            .toISOString()
                            .split('T')[0]
                          : new Date().toISOString().split('T')[0]
                      }
                      disabled
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      defaultValue={new Date().toISOString().split('T')[0]}       
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <TextField
                      name="embryoCulture.time"
                      type="time"
                      label={RequiredLabel('Fert Check Time', true)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={getIn(values, 'embryoCulture.time') || ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.time') &&
                      !!getIn(errors, 'embryoCulture.time')
                      }
                    />
                  </Grid2>

                 
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="embryologist"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => `${option.first_name || ''} ${option.last_name || ''}`}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.embryologist
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.embryologist', value?.id || ''); // Update Formik's value for 'embryoCulture.embryologist'
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Embryologist', true)} // Your label function
                          error={
                            !!getIn(touched, 'embryoCulture.embryologist') &&
                          !!getIn(errors, 'embryoCulture.embryologist')
                          }
                        />
                      )}
                    />
                  </Grid2>
                    
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="witness"
                      options={clinicianLists['EMBRYOLOGIST'] || []}
                      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                      value={clinicianLists['EMBRYOLOGIST'].find(
                        (doctor) => doctor.id === values.embryoCulture?.witness
                      ) || null}
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.witness', value?.id || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Witness', true)}
                          error={
                            !!getIn(touched, 'embryoCulture.witness') &&
                          !!getIn(errors, 'embryoCulture.witness')
                          }
                          helperText={
                            getIn(touched, 'embryoCulture.witness') && getIn(errors, 'embryoCulture.witness')
                              ? getIn(errors, 'embryoCulture.witness') === 'Witness Embryologist name is required'
                                ? '': getIn(errors, 'embryoCulture.witness') : ''
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={incubatorItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        incubatorItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.incubator')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.incubator', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Incubator', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.incubator') &&
                          !!getIn(errors, 'embryoCulture.incubator')
                          }
                        />
                      )}
                    />
                  </Grid2>
      
                  <Grid2 size={{ xs: 12, sm: 3, md: 3 }}>
                    <TextField
                      name="remarks"
                      label={RequiredLabel('Remark', false)}
                      fullWidth
                      multiline
                      rows={1}
                      placeholder="Enter remark"
                      value={values.embryoCulture?.remarks || ''}
                      onChange={(e) => {
                        setFieldValue('embryoCulture.remarks', e.target.value || '');
                      }}
                      onBlur={handleBlur}
                      error={
                        !!getIn(touched, 'embryoCulture.remarks') &&
                      !!getIn(errors, 'embryoCulture.remarks')
                      }
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cultureDishConfigItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cultureDishConfigItems.find(
                          (option) => option.value === getIn(values, 'embryoCulture.culture_dish_config')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoCulture.culture_dish_config', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Culture Dish Configuration', false)}
                          error={
                            !!getIn(touched, 'embryoCulture.culture_dish_config') &&
                          !!getIn(errors, 'embryoCulture.culture_dish_config')
                          }
                        />
                      )}
                    />
                  </Grid2>              
                </Grid2>   
            
                <div>
                  <OocyteSelector
                    title="Embryo No."
                    sourceList={sourceData}
                    selectedsource={selectedSource}
                    totalOocytes={allEmbryos}
                    onSelectionChange={handleSelectionChange}
                    hitSubmitbtn={hitSubmitbtn}
                  />
                </div>

                <Grid2 container spacing={2} marginTop={2}>
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={PnItems}
                      getOptionLabel={(option) => option.label}
                      value={ PnItems.find((option) => option.value === getIn(values, 'embryoDetails.pn')) || null }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pn', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PN', true)}
                          error={
                            !!getIn(touched, 'embryoDetails.pn') &&
                          !!getIn(errors, 'embryoDetails.pn')
                          }
                        />
                      )}
                    />
                  </Grid2>

                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={pbItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        pbItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.pb')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.pb', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('PB', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.pb') &&
                          !!getIn(errors, 'embryoDetails.pb')
                          }
                        />
                      )}
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={cleavageItems}
                      getOptionLabel={(option) => option.label}
                      value={
                        cleavageItems.find(
                          (option) => option.value === getIn(values, 'embryoDetails.cleavage')
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.cleavage', value?.value || '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Cleavage', false)}
                          error={
                            !!getIn(touched, 'embryoDetails.cleavage') &&
                          !!getIn(errors, 'embryoDetails.cleavage')
                          }
                        />
                      )}
                    />
                  </Grid2>
                
                  <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} >
                    <Autocomplete
                      options={action945Items}
                      getOptionLabel={(option) => option.label}
                      value={
                        action945Items.find(
                          (option) => option.value === getIn(values, 'embryoDetails.action')
                        ) || null
                      }                      
                      onChange={(_, value) => {
                        setFieldValue('embryoDetails.action', value?.value || '');
                        if (value?.value === 'FREEZE') {
                          setFreezeDialogOpen(true);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={RequiredLabel('Action', true)}
                          error={
                            !!getIn(touched, 'embryoDetails.action') &&
                          !!getIn(errors, 'embryoDetails.action')
                          }
                        />
                      )}
                    />
                  </Grid2>

                  
                  <Grid2 size={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                    {/* <EmbryoFileUpload selectedEmbryos={selectedEmbryos} onFileUpload={setFile} imagePathUrl={imagePathUrl} cycle_id={selectedCycleId || null} day={1}/> */}
                    
                    <Button
                      variant="outlined"
                      component="span"
                      onClick={() => setEmbryoUploadDialogOpen(true)} // Open dialog on click
                      style={{
                        width: '10rem',
                        height: '36px',
                        padding: '12px 40px',
                        gap: '8px',
                        borderRadius: '10px',
                        background: 'rgba(243, 243, 243, 1)',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FileUploadOutlinedIcon style={{ width: '20px', marginRight: '8px', color: 'black' }} />
                      <Typography
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '22px',
                          textAlign: 'left',
                          color: 'rgba(80, 80, 80, 1)',
                          textTransform: 'capitalize',
                        }}
                      >
                        Upload
                      </Typography>
                    </Button>

                    {/* Upload Dialog */}
                    <EmbryoMultiImageUploadDialog 
                      isOpen={isEmbryoUploadDialogOpen} 
                      onClose={() => setEmbryoUploadDialogOpen(false)} 
                      selectedEmbryos={selectedEmbryos} 
                      onUploadComplete={handleUploadComplete} 
                    />
                  </Grid2>
      
                  {/* Freeze Action Dialog */}
                  <FreezeActionDialog
                    open={isFreezeDialogOpen}
                    onClose={() => {
                      setFieldValue('embryoDetails.action', ''); 
                      setFreezeDialogOpen(false);  
                    }}
                    values={freezeValues}
                    onSave={handleFreezeDialogSave}                    
                    selectedEmbryos={selectedEmbryos} 
                  />
                  
                </Grid2>
      
                <div >
                  <p style={{fontFamily:'Montserrat', fontSize:'20px', fontWeight:700, padding:'1rem'}}>Details</p>
                  <DetailsTable columns={columns} rows={tableData} isDeletable={!showViewMode}   onDelete={handleDelete}/>
                </div>
                <div>
                  <Box sx={{display:'flex', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', justifyContent:'flex-start', marginTop:'1rem'}}>
                      <button
                        type="button"
                        className={styles.resetButton}
                        // onClick={() => setOpenResetConfirmDialog(true)} 
                      >
                      Cancel
                      </button>
                    </Box>

                    <Box className={`${styles.customButtonDiv}`}  >  

                      {/* {!areAllOocytesFilled ? (
                        <button
                          type="button"
                          className={styles.resetButton}
                          style={{width:'10rem',
                            cursor : areAllOocytesFilled ? 'pointer':'not-allowed'
                          }}
                          disabled={!areAllOocytesFilled}
                          onClick={async () => {
                            // Ensure setFieldValue completes before proceeding
                            await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                            setActionStatus('FINALIZED');
                            setHitSubmitbtn(true);
                            validateForm().then((formErrors) => {
                              // Mark fields with validation errors as touched
                              Object.entries(formErrors).forEach(([namespace, fields]) => {
                                if (fields && typeof fields === 'object') {
                                  Object.keys(fields).forEach((field) => {
                                    setFieldTouched(`${namespace}.${field}`, true, true);
                                  });
                                }
                              });
                    
                              if (Object.keys(formErrors).length === 0) {
                                setOpenConfirmDialog(true);
                              } else {
                                console.log('Validation errors:', formErrors);
                              }
                            });
                          }}
                        >
                      Save & Finalize
                        </button>
                      ) : (
                        <button
                          type="button"
                          style={{ width: '10rem' }}
                          className={styles.resetButton}                          
                          onClick={() => setFinalizeConfirmDialogOpen(true)}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            {'Save & Finalized'}
                          </span>
                        </button>
                      )} */}

                      <button
                        type="button"
                        onClick={async () => {
                    
                          // Ensure setFieldValue completes before proceeding
                          await setFieldValue('embryoDetails.embryos', selectedEmbryos);
                    
                          setActionStatus('DRAFT');
                          setHitSubmitbtn(true);
                    
                          validateForm().then((formErrors) => {
                          // Mark fields with validation errors as touched
                            Object.entries(formErrors).forEach(([namespace, fields]) => {
                              if (fields && typeof fields === 'object') {
                                Object.keys(fields).forEach((field) => {
                                  setFieldTouched(`${namespace}.${field}`, true, true);
                                });
                              }
                            });
                    
                            if (Object.keys(formErrors).length === 0) {
                              setOpenConfirmDialog(true);
                            } else {
                              console.log('Validation errors:', formErrors);
                            }
                          });
                        }}
                    
                        className={`${styles.customButton}`}
                      >
                        <span style={{ fontWeight: 'bold' }}>
                          Save
                        </span>
                      </button>                 
                    </Box>
                  </Box>
                </div>
                  
                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={
                    actionStatus === 'FINALIZED'
                      ? 'Are you sure you want to finalize day1 details?'
                      : 'Are you sure you want to save day1 details?'
                  }
                />
                
                <ConfirmationModal
                  open={isFinalizeConfirmDialogOpen}
                  handleClose={() => setFinalizeConfirmDialogOpen(false)}
                  onConfirm={() => {
                    setFinalizeConfirmDialogOpen(false);
                    saveFinalize();
                  }} 
                  type="save"
                  title="Embryo Culture"
                  contentMessage={ 'Are you sure you want to finalize day 1 details?'
                  }
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </ThemeProvider>
  );
};

export default DayFirst;
