import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { Patientv2 } from '../../types';

// Define the shape of the context data
interface PatientContextType {
  patientId: number;
  addPatient: (id: number) => void;
  removePatient: () => void;
  getPatient: () => number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMainPatientDetails: (details: any) => void;
  MainpatientDetails: Patientv2 | null;

}

// Define the props for the provider component
interface PatientProviderProps {
  children: ReactNode;
}

// Create the context
const PatientContext = createContext<PatientContextType | undefined>(undefined);

// Create a provider component
export const PatientProvider: React.FC<PatientProviderProps> = ({ children }) => {
  const [patientId, setPatientId] = useState<number>(-1); // Default value indicating no patient
  const [MainpatientDetails, setPatientDetailsState] = useState<Patientv2 | null>(null); 
  // Add a patient by setting the patientId
  const addPatient = useCallback((id: number): void => {
    if (id > 0) { // Ensure the patient ID is valid (positive integer)
      setPatientId(id);
    } else {
      // eslint-disable-next-line no-console, quotes
      console.error("Invalid patient ID. Must be a positive number.");
    }
  }, []);

  // Remove the patient by resetting the patientId to its default state
  const removePatient = useCallback((): void => {
    setPatientId(-1); // Reset to default value
    setPatientDetailsState(null); 
  }, []);

  // Get the current patientId
  const getPatient = useCallback((): number => {
    return patientId;
  }, [patientId]);


  // Set patient details
  const setMainPatientDetails = useCallback((details: Patientv2): void => {
    setPatientDetailsState(details);
  }, []);


  return (
    <PatientContext.Provider value={{ patientId, addPatient, removePatient, getPatient, setMainPatientDetails, MainpatientDetails }}>
      {children}
    </PatientContext.Provider>
  );
};

// Custom hook to use the PatientContext
export const usePatientContext = (): PatientContextType => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error('usePatientContext must be used within a PatientProvider');
  }
  return context;
};
