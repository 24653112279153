import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import StatusChip from '../../customTableComponent/statusChip';

import EditButton from '../../../assets/SVG/editPencil.svg';
import viewIcon from '../../../assets/SVG/eye_view.svg';
import { getHysteroscopyList } from './hysteroscopy.helper';
import PrescribedUnavailable from '../prescribedUnavailable';
import { usePatientContext } from '../../SubPatinetProvider';

// Mock API response
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mockLaparoscopyDetailsAPI = (patientId: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        results: [
          {
            id: 1,
            services: [
              {
                id: 1,
                name: 'Laparoscopy Service',
                code: 'LAP001',
                description: 'Diagnostic laparoscopy procedure',
                service_type: 'CYCLE_RELATED', 
              },
            ],
            procedures: 'Diagnostic',
            procedures_type: 'Laparoscopy',
            procedure_notes: 'Procedure completed successfully.',
            created_on: '2024-12-18T10:18:43.131Z',
            doctor: 101,
            doctor_name: 'Dr. John Doe',
            indication: 'TUBAL_FACTOR',
            donor_info: 'None',
            laparoscopy_status: 'Completed',
            hysteroscopy_status: 'Not Required',
          },
        ],
      });
    }, 1000); // Simulate network delay
  });

const HysteroscopyList = ({
  onEdit,
  onView,
}: {
  onEdit: (id: number) => void;
  onView: (id: number) => void;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [laparoscopyDetails, setLaparoscopyDetails] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const { patientId } = usePatientContext();
  
  useEffect(() => {
    // Fetch laparoscopy details
    getHysteroscopyList(patientId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        setLaparoscopyDetails(response.objects);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching laparoscopy details:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [patientId]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
      </div>
    );
  }

  
  if (!laparoscopyDetails.length) {
    return <PrescribedUnavailable message="No hysteroscopy has been prescribed yet" />; // Show PrescribedUnavailable when no data is available
  }
  
  
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              height: '2rem',
              background: 'rgba(250, 250, 250, 1)',
              '& .MuiTableCell-root': {
                padding: '0.6rem 0.6rem',
              },
            }}
          >
            {['Date of Procedure', 'Procedure ID', 'Surgeon Name', 'Status', ''].map(
              (title, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.45',
                    textAlign: 'left',
                    color: 'rgba(35, 35, 35, 1)',
                  }}
                >
                  {title}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {laparoscopyDetails.map((laparoscopy) => (
            <TableRow key={laparoscopy.id}>
              <TableCell>
                {new Date(laparoscopy.created_on).toLocaleDateString('en-US')}
              </TableCell>
              <TableCell>{laparoscopy?.services[0]?.code}</TableCell>
              <TableCell>{laparoscopy.doctor_name}</TableCell>
              <TableCell>
                <StatusChip
                  value={laparoscopy.hysteroscopy_status ? 'Completed' : 'Pending'}
                />
              </TableCell>
              
              <TableCell>
                {laparoscopy.hysteroscopy_status ? (
                  <Tooltip title="View Details">
                    <img 
                      src={viewIcon} 
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      alt="View Icon" 
                      onClick={() => onView(laparoscopy.id)} 
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Details">
                    <img 
                      src={EditButton} 
                      style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      alt="Edit Icon" 
                      onClick={() => onEdit(laparoscopy.id)} 
                    />
                  </Tooltip>
                )}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HysteroscopyList;
