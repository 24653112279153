
import { getRequest, putRequest } from '../../services/httpService';
import { CryotechUnit } from '../Embryology/Embryo_Culture/embryoCulture.helpers';

export interface CryoStorageDetail {
    id: number;
    cycle: number;
    oocyte_number: number;
    grade: string;
    location: string | null;
    disposition: string;
    files: string | null;
    cryo_preserved_date: string | null;
    cryo_preserved_by: number;
    cryo_purpose: string;
    cryo_media: string | null;
    is_screened: boolean;
    cycle_number: string| null;
    patient_name: string;
    patient_mrn: string;
    patient_type: string;
    cryo_preserved_by_name: string;
    material_count?: number | null;
    created_on?: string | null;
    age?: string | null;
    renewalDate?: string | null;
    embryo_number?: string | null;
    patient_last_name?: string | null;   
    renewal_date?: string ;    
    patient_id?: number | null;      
    day?: number | null;        
    location_details?: LocationDetails; 
  }
   
export interface Meta {
    limit: number;
    next: string | null;
    offset: number;
    previous: string | null;
    total_count: number;
  }
  
export interface spermCryoStorageDetail {
    id: number;
    cycle: number;
    cryo_date: string;
    location: number;
    media_volume: string;
    semen_volume: string;
    expiry_date: string | null;
    remark: string | null;
    thaw_date: string | null;
    thaw_time: string | null;
    is_survived: boolean | null;
    plan: string | null;
    doctor: string | null;
    witness: string | null;
    reason: string | null;
    thaw_remark: string | null;
    recipient: string | null;
    status: string;
    cycle_number: string;
    patient_name: string;
    patient_mrn: string;
    cryo_preserved_by_name: string;
    sample_id: number;
    type_of_sperm: string;
    volume: number;
    sperm_concentration_million_per_ml: number;
    total_sperm_count: number;
    all_progressive: number;
    total_motility: number;
    immotile_percent: number;
    non_progressive_percent: number;
    cryo_media: string | null;
    material_count?: number | null;
    renewal_date?: string ;
    embryo_number?: string | null;  
    patient_last_name?: string | null; 
    patient_id ?: number | null;
    day?: number | null;        
    location_details?: LocationDetails; 
    
  }
  

export interface spermCryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: spermCryoStorageDetail[];
  }
  
  
  
export interface CryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: CryoStorageDetail[];
  }
  
  
export function getOocyteStorage(search?: string): Promise<CryoStorageResponse> {
  const url = search 
    ? `cryo-storage/oocyte/?limit=15&search=${encodeURIComponent(search)}` 
    : 'cryo-storage/oocyte/?limit=15';

  return getRequest(url);
}
    
export function getDonorOocyteStorage(search?: string): Promise<CryoStorageResponse> {
  const url = search 
    ? `cryo-storage/oocyte/?patient_type=OOCYTE_DONOR&limit=15&search=${encodeURIComponent(search)}` 
    : 'cryo-storage/oocyte/?patient_type=OOCYTE_DONOR&limit=15';
  return getRequest(url);
}
    
export function getEmbryoStorage(search?: string): Promise<CryoStorageResponse> {
  const url = search
    ? `cryo-storage/embryo/?limit=15&search=${encodeURIComponent(search)}`
    : 'cryo-storage/embryo/?limit=15';
  return getRequest(url);
}

export function getSpermStorage(search?: string): Promise<spermCryoStorageResponse> {
  const url = search
    ? `cryo-storage/semen?search=${encodeURIComponent(search)}`
    : 'cryo-storage/semen';
  return getRequest(url);
}

export function getDonorSpermStorage(): Promise<spermCryoStorageResponse> {
  const url = 'cryo-storage/?patient_type=SPERM_DONOR';
  return getRequest(url); 
}

export interface CryoStorageResponse {
    meta: {
      limit: number;
      next: string | null;
      offset: number;
      previous: string | null;
      total_count: number;
    };
    objects: CryoStorageDetail[];
  }
  

  
// export function getMaterialsDetails(cycleID : number ): Promise<CryoStorageDetail[]> {
//   const url = `cryo-storage/oocyte/materials/cycle/${cycleID}/`;
//   return getRequest(url); 
// }


// export function getEmbryoMaterialsDetails(cycleID : number ): Promise<CryoStorageDetail[]> {
//   const url = `cryo-storage/embryo/materials/cycle/${cycleID}/`;
//   return getRequest(url); 
// }

// export function getSpermMaterialsDetails(cycleID : number ): Promise<spermCryoStorageDetail[]> {
//   const url = `cryo-storage/semen/materials/cycle/${cycleID}/`;
//   return getRequest(url); 
// }


export function getMaterialsDetails(patientID : number ): Promise<CryoStorageDetail[]> {
  const url = `cryo-storage/oocyte/materials/patient/${patientID}/`;
  return getRequest(url); 
}


export function getEmbryoMaterialsDetails(patientID : number ): Promise<CryoStorageDetail[]> {
  const url = `cryo-storage/embryo/materials/patient/${patientID}/`;
  return getRequest(url); 
}


export function getSpermMaterialsDetails(patientID : number ): Promise<spermCryoStorageDetail[]> {
  const url = `cryo-storage/semen/materials/patient/${patientID}/`;
  return getRequest(url); 
}



interface RenewalDatePayload {
  id: number;
  renewal_date: string;
  type: string;
}


export function updateRenewalDate(data: RenewalDatePayload): Promise<void> {
  const url = 'cryo-storage/renewal-date/';
  return putRequest(url, data, { responseType: 'json' });
}

export interface LocationDetails {
id: number;
reference_id: string;
plan: number;
parents: Array<{
  id: number;
  reference_id: string | null;
  plan: number;
  level: number;
  name: string;
  parent: number | null;
  occupancy: number;
  hierarchy: string;
  specimen: string | null;
  is_occupied: boolean;
  is_leaf: boolean;
  color: string | null;
}>;
level: number;
name: string;
parent: number;
occupancy: number;
hierarchy: string;
specimen: string;
is_occupied: boolean;
color: string;
}


export function getCryotechDetails(unit_ID : number ): Promise<CryotechUnit[]> {
  const url = `cryo-storage-plan/unit/${unit_ID}/`;
  return getRequest(url); 
}
