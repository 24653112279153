import React from 'react'; 
import {
  Box,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  LinearProgress,
} from '@mui/material';
import './TankRadio.css';
import { StorageUnit } from '../Embryology/Embryo_Culture/embryoCulture.helpers';
import { toTitleCase } from '../../utils/commonUtils';
import caneNotAvailable from '../../assets/SVG/Cane.png';
import gobletNotAvailable from '../../assets/SVG/Goblet.png';
import vialsNotAvailable from '../../assets/SVG/Vials.png';
import GobletIndicator from './GobletIndicator';

interface StorageUnitRadioProps {
  units: StorageUnit[];
  selectedUnit: number | null;
  onChange: (value: number) => void;
  type: 'canister' | 'cane' | 'goblet' | 'vials';
}

const GobletUnit: React.FC<StorageUnitRadioProps> = ({ units, selectedUnit, onChange, type }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unitId = Number(event.target.value);
    onChange(unitId);
  };


  // ✅ Select the correct image based on the `type`
  const getNoUnitsImage = () => {
    switch (type) {
    case 'cane':
      return caneNotAvailable;
    case 'goblet':
      return gobletNotAvailable;
    case 'vials':
      return vialsNotAvailable;
    default:
      return null;
    }
  };

  return (
    <FormControl component="fieldset" className="tank-radio-container">
      <Typography 
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '16px',
          marginBottom: '8px',
          marginTop: '10px',
          textAlign: 'left',
          color: '#333',
        }}
      >
        {toTitleCase(type)}
      </Typography>
      
      <RadioGroup value={selectedUnit !== null ? String(selectedUnit) : ''} onChange={handleChange}>
        {units.length ? (
          units.map((unit) => {
            const isSelected = selectedUnit === unit.id;
            return (
              <Box 
                key={unit.id} 
                className={`tank-item ${isSelected ? 'selected-tank' : 'unselected-tank'}`} 
                sx={{
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  transform: isSelected ? 'scale(1.05)' : 'scale(1)',
                  boxShadow: isSelected ? '0px 6px 15px rgba(0, 0, 0, 0.15)' : 'none',
                }}
              >
                <Box className="tank-label-container">
                  <Radio 
                    value={String(unit.id)} 
                    className={`radio-button ${isSelected ? 'selected-radio' : ''}`} 
                    sx={{
                      color: isSelected ? '#222' : '#999', // Outer circle color
                      '&.Mui-checked': {
                        color: '#fb6f92', // Inner circle color
                      },
                    }}
                  />
                  <Typography 
                    className="tank-name"   
                    sx={{
                      ml: '0.5rem',
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '17.07px',
                      letterSpacing: '0px',    
                      color: selectedUnit === null ? '#000' : isSelected ? '#000' : '#999',
                      transition: 'color 0.3s ease-in-out',
                      display: 'inline-flex', // Ensures elements stay on the same line
                      alignItems: 'center',   // Aligns text and icon properly
                      gap: '4px'             // Adds spacing between text and indicator
                    }}
                  >
                    {toTitleCase(unit.name)} {unit.color} 
                    <GobletIndicator color={unit.color || null} />
                  </Typography>

                  <Typography 
                    className="tank-percentage"
                    sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      fontSize: '10px',
                      lineHeight: '12.19px',
                      letterSpacing: '0%',
                      textAlign: 'right'
                    }}
                  >
                    {((unit.occupancy ?? 0).toFixed(2))}%
                  </Typography>
                </Box>

                <LinearProgress
                  variant="determinate"
                  value={unit?.occupancy ?? 0}
                  className={`progress-bar ${isSelected ? 'selected-bar' : ''} ${
                    (unit.occupancy ?? 0) >= 100 ? 'red-bar' : 'green-bar'
                  }`}
                  
                  sx={{
                    transition: 'width 0.5s ease-in-out',
                    backgroundColor: unit.occupancy === 100 && !isSelected ? '#F25B5B' : 'transparent', 
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: unit.occupancy === 100 
                        ? (!isSelected ? '#D84040' : '#FF0000') 
                        : '#4caf50',
                    },
                  }}
                />
              </Box>
            );
          })
        ) : (
          <img 
            src={getNoUnitsImage() || ''} 
            alt={`No ${type} Available`} 
            style={{ width: '280px', height: '240px' }} 
          />
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default GobletUnit;
