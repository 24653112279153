/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Button, Chip, Stack, Grid2} from '@mui/material';
import style from './AppointmentTable.module.css';
import { Appointment, AppointmentData, AppointmentStatus, ColumnConfig, DepartmentDetail, GroupConfig } from '../../types';
import EditableTable from '../customTableComponent';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import { getAppointmentsList} from '../../services/apiService';
import AppointmentModal from './../appointmentForm/appointmentForm'; // Make sure to import the modal component
import { useClinic } from '../ClinicProvider';
import ActionsMenu from './actionMenu';
import { convertUTCToDesiredTimezone } from '../../services/time';

const groupConfig: GroupConfig[] = [
  { fields: ['date'], type: 'row' },
  { fields: ['TimeFrom'], type: 'row' },
  { fields: ['TimeTo'], type: 'row' },
  { fields: ['patientName', 'MRN'], type: 'column', defaultHeaderGroupname: 'Patient Name' },
  { fields: ['mobileNumber'], type: 'row' },
  { fields: ['department'], type: 'row' },
  { fields: ['doctor'], type: 'row' },
  { fields: ['Reason'], type: 'row' },
  { fields: ['action'], type: 'row' },
];

const columns: ColumnConfig[] = [
  { field: 'date', headerName: 'Date', type: 'date', editable: false },
  { field: 'TimeFrom', headerName: ' Time From', type: 'text', editable: false },
  { field: 'TimeTo', headerName: 'Time To', type: 'text', editable: false },
  { field: 'patientName', headerName: 'Patient Name', type: 'text', editable: false },
  { field: 'MRN', headerName: 'MRN', type: 'text', editable: false },
  { field: 'mobileNumber', headerName: 'Mobile Number', type: 'text', editable: false },
  { field: 'doctor', headerName: 'Doctor', type: 'text', editable: false },
  { field: 'department', headerName: 'Department', type: 'text', editable: false },
  { field: 'Reason', headerName: 'Reason', type: 'text', editable: false },
  { field: 'action', headerName: 'Action', type: 'text', editable: false },
];

interface AppointmentTableProps {
  departmentID?: number | undefined;
  departmentName?: string | '';
  departmentTabs?:DepartmentDetail[];
  handleTabChange?: Dispatch<SetStateAction<DepartmentDetail | undefined>>;
}

export default function AppointmentTable({ departmentID , departmentName, departmentTabs, handleTabChange }: AppointmentTableProps) {

  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const { ClinicId } = useClinic();
  const [appointmentData, setAppointmentData] = useState<AppointmentData[]>([]);
  const [dataSize, setDataSize] = useState<number | undefined>();
  const [modalOpen, setModalOpen] = useState(false); // State for modal open/close
  const [doctor, setDoctor] = useState(''); // State for patient name
  const [appointments, setAppointments] = useState<Appointment[] | undefined>();
  const [scheduled, setScheduled] = useState(0);
  const [checkedIn, setCheckedIn] = useState(0);
  const [checkedOut, setCheckedOut] = useState(0);
  const [unAttended, setUnAttended] = useState(0);
  const [cancelled, setCancelled] = useState(0);
  const [filterAppointments, setFilterAppointments ] = useState<string | undefined>(undefined);
  const [currentPageoffset, setCurrentPageoffset] = useState(1);
  const [filteredAppointmentData, setFilteredAppointmentData] = useState<AppointmentData[]>([]);
 
  const handleOnChange = (id: number, field: string, value: string) => {

  };

  const handleAction = (id: number) => {

  };

  const handlePageChange = (newPage: number) => {
    setCurrentPageoffset(newPage);
    setFilterAppointments(undefined); 
    setFilteredAppointmentData([]) ;
  };

  const formatTimeTo12Hour = (time: string | undefined) => {
    if (!time || typeof time !== 'string' || !time.includes(':')) {
      console.error('Invalid time format:', time);
      return '';
    }

    const date = new Date(time);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${String(minutes).padStart(2, '0')} ${ampm}`;
  };

  const defaultAppointmentDate = new Date();

  const calculateStatusCounts = (appointments: Appointment[]) => {
    return appointments.reduce(
      (counts, appointment) => {
        switch (appointment.status) {
        case AppointmentStatus.CANCELLED:
          counts.cancelled += 1;
          break;
        case AppointmentStatus.CHECKED_IN:
          counts.checkedIn += 1;
          break;
        case AppointmentStatus.CHECKED_OUT:
          counts.checkedOut += 1;
          break;
        case AppointmentStatus.SCHEDULED:
          counts.scheduled += 1;
          break;
        case AppointmentStatus.UNATTENDED:
          counts.unAttended += 1;
          break;
        }
        return counts;
      },
      { cancelled: 0, checkedIn: 0, checkedOut: 0, scheduled: 0, unAttended: 0 }
    );
  };

  const getOffset = useCallback(() : number =>{
    let offset;
    const itemsPerPagePageoffset = 10;
    if (currentPageoffset === 1) {
      offset = (itemsPerPagePageoffset * (currentPageoffset - 1));
    } else {
      offset = (itemsPerPagePageoffset * (currentPageoffset - 1)) + 1;
    }
    return offset;
  },[ currentPageoffset ]);

  useEffect(() => {
    if (appointments && filterAppointments === undefined ) {
      const statusCounts = calculateStatusCounts(appointments);
      setCancelled(statusCounts.cancelled);
      setCheckedIn(statusCounts.checkedIn);
      setCheckedOut(statusCounts.checkedOut);
      setScheduled(statusCounts.scheduled);
      setUnAttended(statusCounts.unAttended);
    }
  }, [appointments, filterAppointments]);

  useEffect(()=>{
    setFilterAppointments( undefined );
    setCurrentPageoffset(1); 
    setAppointmentData([]);
  },[departmentID]);
  
  useEffect(() => {
    showLoader();
  
    // Fetch appointments without async/await
    const fetchAppointments = () => {
      // Make sure to handle the case when departmentID is not defined
      if (departmentID && filterAppointments === undefined) {
        getAppointmentsList({ tenantId: ClinicId, departmentID: departmentID, status: filterAppointments, offset: filterAppointments ? undefined : getOffset(), limit:10 })
          .then((appointmentDataResponse) => {
            setDataSize(appointmentDataResponse.meta.total_count);
            setAppointments(appointmentDataResponse.objects);
            const transformedAppointments: AppointmentData[] = appointmentDataResponse.objects.map((appointment: Appointment) => {
              const createdDate = new Date(appointment.appointment_date);
              return {
                id: appointment.id,
                gender: appointment.patient?.gender || '-',
                age: appointment.patient?.age || '-',
                reasonOfVisit: appointment.patient?.additional_info?.reason_for_visit || '-',
                date: `${createdDate.getUTCDate().toString().padStart(2, '0')}/${(createdDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${createdDate.getUTCFullYear()}`,
                TimeFrom: convertUTCToDesiredTimezone(appointment.start_datetime),
                TimeTo: convertUTCToDesiredTimezone(appointment.end_datetime),
                patientName: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
                MRN: `${appointment.patient?.medical_record_no || '-'} | ${appointment.patient?.gender || '-'}`,
                mobileNumber: appointment.patient?.mobile_number || '-',
                doctor: appointment.user.username || '',
                firstvisit: appointment.department__name || '-',
                action: (<ActionsMenu appointment={appointment} />),
                appointmentDate: appointment.appointment_date || '-',
                appointmentReason: appointment.appointment_reason__reason?.toString ? appointment.appointment_reason__reason.toString() : '-',
                department: appointment.department__name || '-',
                Reason: appointment.appointment_reason__reason || '-', // Map the reason for visit correctly here
                startTime: appointment.start_datetime || '-',
                endTime: appointment.end_datetime || '-',
                patient: `${appointment.patient?.first_name || ''} ${appointment.patient?.last_name || ''}`.trim() || '-',
                status: appointment.status || '',
              };
            });
  
            setAppointmentData(transformedAppointments);
          })
          .catch((error) => {
            console.error('Error fetching appointments:', error);
            addAlert('Error fetching appointments. Please try again later.', 'error');
          })
          .finally(() => {
            hideLoader();
          });
      } else {
        hideLoader();
      }
    };
  
    fetchAppointments();
  }, [addAlert, showLoader, hideLoader, ClinicId, departmentID, filterAppointments, currentPageoffset, filteredAppointmentData, getOffset]);
  

  const handleOpenModal = () => {
    setModalOpen(true); // Open the modal
    setDoctor(''); // Optionally reset patient name or set it to a specific value
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  const handleSetFilterAppointments = (status: string) => {
    setFilteredAppointmentData(appointmentData.filter((appointment: AppointmentData) => appointment?.status === status));
    setFilterAppointments(status);
  };
  
  return (
    <div className={style.container}>
      <Grid2 container spacing={3} className={style.container} alignItems='center' sx={{ mt: 2, px: 2 }}>
        <Grid2 size={{xs: 6}}>
          <h2 className={style.title}>Patients List</h2>
        </Grid2>
        <Grid2 size={{xs: 6}} style={{ textAlign: 'right' }}>
          <Button className={style.button} variant="contained" onClick={handleOpenModal}> {/* Open modal on click */}
            New Appointment
          </Button>
        </Grid2>
      </Grid2>
      <div className={style.status}>
        <Stack direction="row" spacing={1}>
          <Chip onClick={() => handleSetFilterAppointments('SCHEDULED')} label={`Scheduled ${scheduled}`} variant={filterAppointments === 'SCHEDULED' ? 'filled' : 'outlined'} color='primary' sx={{ opacity: 0.8 }} disabled={scheduled === 0}/>
          <Chip onClick={() => handleSetFilterAppointments('CHECKED_IN') } label={`Checked-In ${checkedIn}`} variant={filterAppointments === 'CHECKED_IN' ? 'filled' : 'outlined'} color='warning' sx={{ opacity: 0.8 }} disabled={checkedIn === 0}/>
          <Chip onClick={() => handleSetFilterAppointments('CHECKED_OUT') } label={`Checked-Out ${checkedOut}`} variant={filterAppointments === 'CHECKED_OUT' ? 'filled' : 'outlined'} color='warning'sx={{ opacity: 0.8 }} disabled={checkedOut === 0}/>
          <Chip onClick={() => handleSetFilterAppointments('UNATTENDED') } label={`Un-Attended ${unAttended}`} variant={filterAppointments === 'UNATTENDED' ? 'filled' : 'outlined'} color='success'sx={{ opacity: 0.8 }} disabled={unAttended === 0}/>
          <Chip onClick={() => handleSetFilterAppointments('CANCELLED') } label={`Canceled ${cancelled}`} variant={filterAppointments === 'CANCELLED' ? 'filled' : 'outlined'} color='error'sx={{ opacity: 0.8 }} disabled={cancelled === 0}/>
        </Stack>
      </div>
      <div className='mt-4'>
        <EditableTable
          columns={columns}
          data={filteredAppointmentData?.length > 0 ? filteredAppointmentData : appointmentData}
          actionType='none'
          onAction={handleAction}
          onChange={handleOnChange}
          dataSize={dataSize}
          groupConfig={groupConfig}
          onPageChange={handlePageChange}
        />
      </div>
    
      {/* Render the AppointmentModal conditionally */}
      <div>
        <AppointmentModal
          open={modalOpen}
          onClose={handleCloseModal}
          slotDate={defaultAppointmentDate}
          slotDepartment= {departmentID}
          departmentName={departmentName}
          departmentTabs={departmentTabs}
          handleTabChange={handleTabChange}
        />
      </div>

    </div>
  );
}
