import * as Yup from 'yup';
import { getRequest, putRequest } from '../../../services/httpService';


export type BlankEnum = '';
export type NullEnum = 'NULL';


export type DenudedTechniqueEnum = 'MECHANICAL_DENUDATION' | 'ENZYMATIC_DENUDATION' | 'COMBINED_MECHANICAL';
export type IncubatorEnum = 'HERA_CELL' | 'MINC_K_SYSTEMS' | 'MEDGYN' | 'OOSAFE' | 'VITROLIFE' | 'IVF_TECH';
export type GradeEnum = 'GV' | 'M I' | 'M II' | 'DEGENERATED';
export type DispositionEnum = 'MATURATION' | 'FREEZE' | 'DISCARD';
// Enum for Cryo Purpose
export type CryoPurposeEnum = 'PRESERVATION_OF_EXCESS_EMBRYOS'| 'TIMED_EMBRYO_TRANSFER'| 'FERTILITY_PRESERVATION'| 'GENETIC_TESTING'| 'FLEXIBLE_FAMILY_PLANNING'| 'REDUCING_MULTIPLE_PREGNANCIES';
// Enum for Media Choices
export type MediaEnum = 'KITAZATO' | 'SWEMED' | 'IRVINE_SCIENTIFIC';

// Dropdown Items for disposition
export const dispositionItems: { label: string; value: DispositionEnum }[] = [
  { label: 'Maturation', value: 'MATURATION' },
  { label: 'Freeze', value: 'FREEZE' },
  { label: 'Discard', value: 'DISCARD' },
];

// Dropdown Items for Grade
export const gradeItems: { label: string; value: GradeEnum }[] = [
  { label: 'GV', value: 'GV' },
  { label: 'M I', value: 'M I' },
  { label: 'M II', value: 'M II' },
  { label: 'Degenerated', value: 'DEGENERATED' },
];

// Dropdown Items for Incubators
export const incubatorItems: { label: string; value: IncubatorEnum }[] = [
  { label: 'Hera Cell', value: 'HERA_CELL' },
  { label: 'MINC/K Systems', value: 'MINC_K_SYSTEMS' },
  { label: 'MedGyn', value: 'MEDGYN' },
  { label: 'Oosafe', value: 'OOSAFE' },
  { label: 'Vitrolife', value: 'VITROLIFE' },
  { label: 'IVF Tech', value: 'IVF_TECH' },
];


// Dropdown Items for Denuded Techniques
export const denudedTechniqueItems: { label: string; value: DenudedTechniqueEnum | BlankEnum}[] = [
  { label: 'Mechanical Denudation', value: 'MECHANICAL_DENUDATION' },
  { label: 'Enzymatic Denudation', value: 'ENZYMATIC_DENUDATION' },
  { label: 'Combined Mechanical Enzymatic Denudation', value: 'COMBINED_MECHANICAL' },
];

export interface OocyteDetailsType {
    oocyte_count: number;
    denuded_date: string;
    denuded_start_time: string;
    denuded_end_time: string;
    denuded_by: number | null;
    denuded_technique: string | null; 
    embryologist: number | null;
    witness: number | null;
    remarks?: string | null;
    incubator: string | null;  
}

export const initialValuesOD:OocyteDetailsType = {
  oocyte_count: 0,
  denuded_date: '',
  denuded_start_time: '',
  denuded_end_time: '',
  denuded_by: null,
  denuded_technique: '',
  embryologist: null,
  witness: null,
  remarks: '',
  incubator: '',
};

export interface OocyteCycleSummaryType {
  cycle: number | null;
  remarks?: string | '';
  oocytes: number[] | null; 
  grade?: string | ''; 
  disposition?: string | '';
  cryo_date?: string | null;
  cryo_preserved_by?: number | null;
  cryo_purpose?: string | null;
  media?: string | null;
  screening_status?: string | null;
  status?: string | null;
  tank?: number | null;
  canister?: number | null;
  cane?: number | null;
  goblet?: number | null;
  goblet_color?: string | null;
  cryotech?: number | null;
  cryotech_color?: string | null;
  locations?:number | null;  
  files?: { file: string }[] | null;
}

export const initialCycleSummary: OocyteCycleSummaryType = {
  cycle: null,
  remarks: '',
  oocytes: [],
  grade: '',
  disposition: '',
  cryo_date: null,
  cryo_preserved_by:null,
  cryo_purpose: null,
  media: null,
  screening_status: null,
  status:'DRAFT',

  tank:null,
  canister:null,
  cane:null,
  goblet:null,  
  goblet_color: '', 
  cryotech: null,
  cryotech_color: null,
  locations: null
};

export const OocyteCycleSummaryValidationSchema = Yup.object().shape({
  OocyteCycleSummary: Yup.array().of(
    Yup.object().shape({
      cycle: Yup
        .number()
        .nullable(),
  
      remarks: Yup
        .string()
        .nullable()
        .max(1000, 'Remarks cannot exceed 1000 characters'),

      oocytes: Yup
        .array(),

      grade: Yup
        .string()
        .required('Grade is required')
        .oneOf(['GV', 'M I', 'M II', 'DEGENERATED'], 'Grade must be one of GV, M1, M2, MI'), 
  
      disposition: Yup
        .string()
        .required('Disposition is required') 
        .oneOf(['MATURATION', 'DISCARD', 'FREEZE', 'TRANSFER'], 'Disposition must be one of MATURATION, DISCARD, FREEZE, TRANSFER'),
                

      cryo_date: Yup.string()
        .nullable()
        .test('is-required-if-freeze', 'Cryo Preserved Date is required when disposition is FREEZE', function (value) {
          const { disposition } = this.parent; // Access sibling fields
          if (disposition === 'FREEZE') {
            return !!value; // Ensure cryo_date is not null or empty
          }
          return true; // Pass validation if not 'FREEZE'
        }),


      cryo_preserved_by: Yup
        .string()
        .nullable()
        .test('is-required-if-freeze', 'Cryo Preserved Date is required when disposition is FREEZE', function (value) {
          const { disposition } = this.parent; // Access sibling fields
          if (disposition === 'FREEZE') {
            return !!value; // Ensure cryo_date is not null or empty
          }
          return true; // Pass validation if not 'FREEZE'
        }),
      
      cryo_purpose: Yup
        .string()
        .nullable(),
      
      media: Yup
        .string()
        .nullable(),        
      
      screening_status: Yup
        .string()
        .nullable(),

      tank: Yup.number()
        .nullable()
        .test(
          'tank-required-if-freeze',
          'Tank is required when disposition is FREEZE',
          function (value) {
            const { disposition } = this.parent; 
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),
      canister: Yup.number()
        .nullable()
        .test(
          'canister-required-if-freeze',
          'Canister is required when a freeze is selected',
          function (value) {
            const { disposition } = this.parent; 
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),
      
      cane: Yup.number()
        .nullable()
        .test(
          'cane-required-if-freeze',
          'Cane is required when a freeze is selected',
          function (value) {
            const { disposition } = this.parent; 
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),
      
      goblet: Yup.number()
        .nullable()
        .test(
          'goblet-required-if-freeze',
          'Goblet is required when a freeze is selected',
          function (value) {
            const { disposition } = this.parent; 
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),

      goblet_color: Yup.string()
        .nullable()
        .test(
          'goblet-color-required-if-goblet',
          'Goblet color is required when a goblet is selected',
          function (value) {
            const { disposition } = this.parent;
            
            // Remove validation if goblet_color already exists
            if (value) {
              return true;
            }
      
            // Apply validation only if disposition is 'FREEZE' and no goblet color is selected
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),

      cryotech: Yup.number()
        .nullable()
        .test(
          'cryotech-required-if-goblet',
          'Cryotech is required when a goblet is selected',          
          function (value) {
            const { disposition } = this.parent; 
            if (disposition === 'FREEZE') {
              return value !== null && value !== undefined;
            }
            return true;
          }
        ),
      cryotech_color: Yup.string()
        .nullable()
        .test(
          'cryotech-color-required-if-cryotech',
          'Cryotech color is required when a cryotech is selected',
          function (value) {
            const { cryotech } = this.parent;
            if (cryotech !== null && cryotech !== undefined) {
              return value !== null && value !== undefined && value.trim() !== '';
            }
            return true;
          }
        ),
    
        
    })
  )
});
  
export const oocyteDetailsValidationSchema = Yup.object().shape({
  OocyteDetails: Yup.array().of(
    Yup.object().shape({
      oocyte_count: Yup.number()
        .min(1, 'Oocyte Retrieved must be at least 1'),
      denuded_date: Yup.date()
        .required('Denuding Date is required'),
      denuded_start_time: Yup.string()
        .required('Start Time is required'),
      denuded_end_time: Yup.string()
        .required('End Time is required')
        .test(
          'is-after-start-time',
          'End Time must be after Start Time',
          function (value) {
            const { denuded_start_time } = this.parent;
            return value && denuded_start_time ? value > denuded_start_time : true;
          }
        ),
      denuded_by: Yup.number()
        .required('Embryologist name is required'),

      denuded_technique: Yup.mixed<DenudedTechniqueEnum | BlankEnum >()
        .oneOf(['MECHANICAL_DENUDATION', 'ENZYMATIC_DENUDATION', 'COMBINED_MECHANICAL', '',])
        .required('denuded_technique is required'),
  
      embryologist: Yup.number()
        .required('Embryologist name is required'),

      witness: Yup.number()
        .required('Witness Embryologist name is required')
        .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
          const { embryologist } = this.parent; // Accessing the sibling field
          return value !== embryologist; // Validate that they are not the same
        }),

      remark: Yup.string()
        .nullable()
        .max(200, 'Remark must not exceed 200 characters'),
      incubator: Yup.mixed<IncubatorEnum>()
        .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', ])
        .required('Incubator is required'),
    })
  )
});

 

export const CombinedValidationSchema = oocyteDetailsValidationSchema.concat(OocyteCycleSummaryValidationSchema);


// Function to update oocyte details
export function updateOocyteDetails(id: number, data: OocyteDetailsType): Promise<OocyteDetailsType> {
  const url = `/oocyte-details/${id}/details/`;
  return putRequest(url, data, { responseType: 'json' });
}


// Function to update oocyte details
export function updateOocyteSummary(id: number, data: OocyteCycleSummaryType): Promise<OocyteCycleSummaryType> {
  const url = `/oocyte/cycle/${id}/`;
  return putRequest(url, data, { responseType: 'json' });
}

 
// Function to get oocyte retrieval
export function getOocyteRetrieval(id: number): Promise<OocyteDetailsType> {
  const url =`oocyte-details/${id}`;
  return getRequest(url, { responseType: 'json' });
}


export function getOocyteSummaryIDwise(id: number): Promise<OocyteCycleSummaryType> {
  const url =`oocyte-details/${id}`;
  return getRequest(url, { responseType: 'json' });
}


export interface OocyteRow {
  id: number;
  cycle: number;
  remarks: string | null;
  oocyte_number: number;
  grade: string | null;
  location: string | null;
  disposition: string | null; 
  cryo_preserved_date: string | null;
  cryo_preserved_by: number | null;
  cryo_purpose: string | null;
  is_screened: boolean;
}
export function getOocyteSummaryTable(id: number): Promise<OocyteRow[]> { // Change to return an array of OocyteRow
  const url = `cycle/${id}/oocytes`;
  return getRequest(url, { responseType: 'json' });
}

export function deleteOocytes(id: number ): Promise<void> {
  const url = `/oocyte/${id}/deactivate/`;
  return putRequest(url, { responseType: 'json' });
}

export interface OocyteDetails {
  oocyte_number: number;
  isFilled: boolean;
}


export const oocytes = [
  {
    id: 'S_1',
    image: 'P',
    grade: 'MI',
    incubator: 'MINC/K Systems',
    disposition: 'Maturation',
    remark: 'NA',
    location: '-',
  },
  {
    id: 'S_2',
    image: 'P',
    grade: 'MII',
    incubator: 'MINC/K Systems',
    disposition: 'Freeze',
    remark: 'NA',
    location: 'Tank 1 | Canister 2 | Cane 1 | Goblet | Cryolock',
  },
  {
    id: 'S_3',
    image: 'P',
    grade: 'GV',
    incubator: 'MINC/K Systems',
    disposition: 'Discard',
    remark: 'NA',
    location: '-',
  },
];

const generateRadialGradient = (progress: number) => {
  const startColor = `rgba(255, ${Math.max(0, 255 - progress * 2)}, ${Math.max(0, 255 - progress * 3)}, 1)`; // Start color varies with progress
  const endColor = `rgba(${Math.min(255, progress * 3)}, 174, 202, 1)`; // End color varies with progress
  return `radial-gradient(circle, ${startColor} 0%, ${endColor} 100%)`;
};


// Define a constant for roles to avoid magic strings
export const ROLES = ['DOCTOR', 'ANESTHETIST', 'EMBRYOLOGIST', 'NURSE'] as const;

export interface Clinician {
  id: number; 
  first_name: string;
  last_name: string;
  // Add other relevant fields as per your API response
}

// Create a type that represents the role strings
export type Role = typeof ROLES[number]; // 'DOCTOR' | 'ANESTHETIST' | 'EMBRYOLOGIST' | 'NURSE'



export const tanks = [
  { 
    name: 'Tank 1', 
    progress: 10, 
    canisters: [
      { 
        name: 'Canister 1', 
        progress: 30, 
        canes: [
          {
            name: 'Cane 1',
            progress: 30,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(40), 
                progress: 40,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 1', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 3', filled: false },

                  { color: generateRadialGradient(40), name: 'Cryotech 3', filled: false },

                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 3', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(40), 
                progress: 50,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 4', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 5', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 6', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(40), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 7', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 8', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 9', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 2',
            progress: 40,
            goblets: [
              { 
                name: 'Goblet 1',
                progress: 50,
                color: generateRadialGradient(50), 
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 10', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 11', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 12', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(50), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 13', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 14', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 15', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(50), 
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 16', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 17', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 18', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 3',
            progress: 50,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(60), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 19', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 20', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 21', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(60), 
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 22', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 23', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 24', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(60), 
                progress: 80,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 25', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 26', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 27', filled: false },
                ],
              },
            ],
          },
        ],
      },
      { 
        name: 'Canister 2', 
        progress: 40,
        canes: [
          {
            name: 'Cane 1',
            progress: 30,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(40), 
                progress: 40,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 1', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 3', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(40), 
                progress: 50,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 4', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 5', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 6', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(40), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 7', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 8', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 9', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 2',
            progress: 40,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(40), 
                progress: 50,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 10', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 11', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 12', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(40), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 13', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 14', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 15', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(40), 
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 16', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 17', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 18', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 3',
            progress: 50,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(50), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 19', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 20', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 21', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 22', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 23', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 24', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(50), 
                progress: 80,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 25', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 26', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 27', filled: false },
                ],
              },
            ],
          },
        ],
      },
      { 
        name: 'Canister 3', 
        progress: 50,
        canes: [
          {
            name: 'Cane 1',
            progress: 30,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(60), 
                progress: 40,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 1', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 2', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 3', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(60), 
                progress: 50,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 4', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 5', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 6', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(60), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 7', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 8', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 9', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 2',
            color: generateRadialGradient(60), 
            progress: 40,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(60), 
                progress: 50,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 10', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 11', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 12', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(60), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 13', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 14', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 15', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(60), 
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 16', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 17', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 18', filled: false },
                ],
              },
            ],
          },
          {
            name: 'Cane 3',
            progress: 50,
            goblets: [
              { 
                name: 'Goblet 1',
                color: generateRadialGradient(60), 
                progress: 60,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 19', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 20', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 21', filled: false },
                ],
              },
              { 
                name: 'Goblet 2',
                color: generateRadialGradient(60), 
                progress: 70,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 22', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 23', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 24', filled: false },
                ],
              },
              { 
                name: 'Goblet 3',
                color: generateRadialGradient(60), 
                progress: 80,
                cryotech: [
                  { color: generateRadialGradient(40), name: 'Cryotech 25', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 26', filled: false },
                  { color: generateRadialGradient(40), name: 'Cryotech 27', filled: false },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
  


// Dropdown Items for Cryo Purpose
export const cryoPurposeItems: { label: string; value: CryoPurposeEnum }[] = [
  { label: 'Preservation of Excess Embryos', value: 'PRESERVATION_OF_EXCESS_EMBRYOS' },
  { label: 'Timed Embryo Transfer', value: 'TIMED_EMBRYO_TRANSFER' },
  { label: 'Fertility Preservation', value: 'FERTILITY_PRESERVATION' },
  { label: 'Genetic Testing', value: 'GENETIC_TESTING' },
  { label: 'Flexible Family Planning', value: 'FLEXIBLE_FAMILY_PLANNING' },
  { label: 'Reducing Multiple Pregnancies', value: 'REDUCING_MULTIPLE_PREGNANCIES' },
];


// Dropdown Items for Media Choices
export const mediaItems: { label: string; value: MediaEnum }[] = [
  { label: 'Kitazato', value: 'KITAZATO' },
  { label: 'Swemed', value: 'SWEMED' },
  { label: 'Irvine Scientific', value: 'IRVINE_SCIENTIFIC' },
];

export interface OocyteFile {
  id: number;
  file: string; // URL of the file
}

export interface OocyteInfo {
  id: number;
  cycle: number;
  remarks: string | null;
  oocyte_number: number;
  grade: string | null;
  location: string | null;
  disposition: string | null; 
  cryo_preserved_date: string | null;
  cryo_preserved_by: number | null;
  cryo_purpose: string | null;
  status?:string,
  is_screened: boolean;
  image?: string | null; // Add image property (if applicable)
  cryo_media?: string | null;
  files_url ?: string | null;
  location_details?: LocationDetails; 
  files?: OocyteFile[];
}

export interface LocationDetails {
  id: number;
  reference_id: string;
  plan: number;
  parents: Array<{
    id: number;
    reference_id: string | null;
    plan: number;
    level: number;
    name: string;
    parent: number | null;
    occupancy: number;
    hierarchy: string;
    specimen: string | null;
    is_occupied: boolean;
    is_leaf: boolean;
    color: string | null;
  }>;
  level: number;
  name: string;
  parent: number;
  occupancy: number;
  hierarchy: string;
  specimen: string;
  is_occupied: boolean;
  color: string;
}

export interface StorageResponse {
  meta: PaginationInfo;
  objects: StorageUnit[];
}

export interface PaginationInfo {
  limit: number;
  next: string | null;
  offset: number;
  previous: string | null;
  total_count: number;
}

export interface StorageUnit {
  id: number;
  created_on: string;
  updated_on: string;
  deleted_on: string | null;
  root_name: string;
  depth: number;
  root_capacity: number;
  specimens: string;
  is_finalized: boolean;
  tenant: number;
  clinic: number;
}


