/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {  getThawed, mapThawedData, ThawedData, updatedBillingLabels, updatedEmbryoMaterialLabels, updatedMaterialLabels } from './ThawedComponentHelper';
import { ReactComponent as INFO } from '../../assets/SVG/MaterialInfoCircle.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from './CryoThawingComponent.module.css';
import { ReactComponent as More } from '../../assets/SVG/close-circle-greay.svg';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import PlanInformation from './PlanInformation';
import { ReactComponent as Edit } from '../../assets/SVG/editcryo.svg';
import { useEffect, useState } from 'react';
import { ReactComponent as CryobackgroundImg } from '../../assets/SVG/Cryobackgroundimg.svg';
import { useLoader } from '../loaderProvider/loader';
import { usePatientContext } from '../SubPatinetProvider';
interface ThawedComponentProps {
  label: string;
}

const ThawedComponent: React.FC<ThawedComponentProps> = ({ label }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState<HTMLElement | SVGSVGElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<'material' | 'billing' | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); 
  const [data, setData] = useState<ThawedData[]>([]);
  const [selectedPlanInfo, setSelectedPlanInfo] = React.useState<string | null>(null);
  const [dynamicLabels, setDynamicLabels] = useState<any[]>([]);
  const [billingData, setBillingData] = useState<any[]>([]);
  const { showLoader, hideLoader } = useLoader();
  const { patientId } = usePatientContext();
  const [selectedRowData, setSelectedRowData] = useState<ThawedData | null>(null);

  
  const handlePopoverOpen = (
    event: React.MouseEvent<SVGSVGElement | HTMLElement>,
    content: 'material' | 'billing',
    index: number 
  ) => {
    setPopoverAnchorEl(event.currentTarget as HTMLElement | SVGSVGElement);
    setPopoverContent(content);
    const item = data[index];
    if (content === 'material') {
      const labels = label?.toLowerCase() === 'embryo' ? updatedEmbryoMaterialLabels(item) : updatedMaterialLabels(item); 
      setDynamicLabels(labels); 
    } else if (content === 'billing') {
      const billing = updatedBillingLabels(item); 
      setBillingData(billing); 
    }

  };

  const handleClosePopover = () => {
    setPopoverAnchorEl(null);
    setPopoverContent(null);
  };
  const handleOpenDialog = (id: string, rowData: ThawedData) => {
    setSelectedPlanInfo(id);
    setOpenDialog(true);
    setSelectedRowData(rowData);

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    showLoader();
    getThawed(patientId, label)
      .then((fetchedData) => {
        const mappedData = Array.isArray(fetchedData) ? mapThawedData(fetchedData) : [];
        setData(mappedData);
        setErrorMessage(null);
      })
      .catch((error) => {
        const errorDetail = error?.errors?.[0]?.detail || 'An unknown error occurred while fetching the data.';
        console.error('Error fetching thawed data:', error);
        setErrorMessage(errorDetail);
      })
      .finally(() => {
        hideLoader();
      });
  
  }, [label, patientId, showLoader, hideLoader]);
  
  

  return (
    <div className={styles.oocyteContainer}>
      {data.length === 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <CryobackgroundImg />
        </div>
      ) : (
        <table className={styles.fullWidthTable}>
          <thead>
            <tr>
              <th>{label} ID</th>
              <th>Cycle No</th>
              <th>Thaw ID</th>
              <th>Thaw Date</th>
              <th>Material</th>
              <th>Plan Information</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.embryo_number || '-'}</td>
                <td>{item.cycleNo || '-'}</td>
                <td>{item.cryoId || '-'}</td>
                <td>{item.cryoDate || '-'}</td>
                <td
                  onClick={(e) => handlePopoverOpen(e, 'material',index)}
                  style={{ cursor: 'pointer' }}
                >
                  <INFO /> {item.material || '-'}
                </td>
                <td style={{ cursor: 'pointer' }}>
                  <INFO
                    data-action="add"
                    onClick={(e) => handlePopoverOpen(e, 'billing',index)}
                    style={{ cursor: 'pointer' }}
                  />
                  <span style={{ margin: '0 8px' }}></span>
                  {item.planInfo || '-'}
                  <span style={{ margin: '0 8px' }}></span>
                  <Edit
                    onClick={() => handleOpenDialog(String(item.id || ''), item)} 
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Dynamic Popover for Disposition Information */}
      <Popover
        id="dynamic-popover"
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Typography sx={{
            flexGrow: 1,
            fontFamily: 'Montserrat',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: '#232323',
          }}>
            {popoverContent === 'material' ? 'Material Information' : 'Disposition Information'}
          </Typography>
          <More onClick={handleClosePopover} style={{ cursor: 'pointer', marginLeft: '25px' }} />
        </Box>

        <hr className={styles.divider} />

        {popoverContent === 'material' && dynamicLabels.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography sx={{
              flexGrow: 1,
              fontFamily: 'Montserrat', 
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19.5px',
              textAlign: 'left',
              color: '#737373',
            }}>
              {item.label}
            </Typography>
            <Typography sx={{
              ml: 2,
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '21.82px',
              textAlign: 'left',
              color: '#232323',
            }}>
              {item.value}
            </Typography>
          </Box>
        ))}

        {popoverContent === 'billing' && billingData.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography sx={{
              flexGrow: 1,
              fontFamily: 'Montserrat',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19.5px',
              textAlign: 'left',
              color: '#737373',
            }}>
              {item.label}
            </Typography>
            <Typography sx={{
              ml: 2,
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '21.82px',
              textAlign: 'left',
              color: '#232323',
            }}>
              {item.value}
            </Typography>
          </Box>
        ))}
      </Popover>

      {/* Plan Information Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth={true}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
          },
        }}
      >
        <DialogContent>
          <PlanInformation handleCloseDialog={handleCloseDialog} planInfo={selectedPlanInfo ? Number(selectedPlanInfo) : null}   rowData={selectedRowData} 
          />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
};



export default ThawedComponent;
