import React from 'react'; 
import {
  Box,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  LinearProgress,
} from '@mui/material';
import './TankRadio.css';
import { StorageUnit } from '../Embryology/Embryo_Culture/embryoCulture.helpers';

interface TankRadioProps {
  tanks: StorageUnit[];
  selectedTank: number | null;
  onChange: (value: string) => void;
}

const TankRadio: React.FC<TankRadioProps> = ({ tanks, selectedTank, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tankId = event.target.value;
    onChange(tankId);
  };

  return (
    <FormControl component="fieldset" className="tank-radio-container">
      <Typography 
        sx={{
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontSize: '16px',
          marginBottom: '8px',
          textAlign: 'left',
          color: '#333',
        }}
      >
      Tank
      </Typography>
      <RadioGroup value={selectedTank !== null ? String(selectedTank) : ''} onChange={handleChange}>
        <Box 
          sx={{
            maxHeight: '320px',
            overflowY: tanks.length > 1 ? 'auto' : 'visible',
            paddingRight: '8px' // Optional: Prevents horizontal scrollbar
          }}
        >
          {tanks.length ? (
            tanks.map((tank) => {
              const isSelected = selectedTank === tank.id;
              return (
                <Box 
                  key={tank.id} 
                  className={`tank-item ${isSelected ? 'selected-tank' : 'unselected-tank'}`} 
                  sx={{
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, width 0.3s ease-in-out',
                    transform: isSelected ? 'scale(1.05)' : 'scale(1)',
                    boxShadow: isSelected ? '0px 6px 15px rgba(0, 0, 0, 0.15)' : 'none',
                    width: isSelected ? '92%' : '100%',
                    margin: isSelected ? '0.5rem auto' : '0',
                  }}
                >
                  <Box className="tank-label-container">
                    <Radio 
                      value={String(tank.id)} 
                      className={`radio-button ${isSelected ? 'selected-radio' : ''}`} 
                      sx={{
                        color: isSelected ? '#222' : '#999',
                        '&.Mui-checked': {
                          color: '#fb6f92',
                        },
                      }}
                    />
                    <Typography 
                      className="tank-name"
                      sx={{
                        ml: '0.5rem',
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        fontSize: '14px',
                        color: selectedTank === null ? '#000' : isSelected ? '#000' : '#999',
                        transition: 'color 0.3s ease-in-out',
                      }}
                    >
                      {tank.root_name}
                    </Typography>
                    <Typography 
                      className="tank-percentage"
                      sx={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        fontSize: '10px',
                        textAlign: 'right'
                      }}
                    >
                      {tank.occupancy ?? 0}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={tank?.occupancy ?? 0}
                    className={`progress-bar ${isSelected ? 'selected-bar' : ''} ${
                      (tank.occupancy ?? 0) >= 100 ? 'red-bar' : 'green-bar'
                    }`}
                    sx={{
                      transition: 'width 0.5s ease-in-out',
                      backgroundColor: tank.occupancy === 100 && !isSelected ? '#F25B5B' : 'transparent', 
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: tank.occupancy === 100 
                          ? (!isSelected ? '#D84040' : '#FF0000') 
                          : '#4caf50',
                      },
                    }}
                  />
                </Box>
              );
            })
          ) : (
            <Typography>No tanks available</Typography>
          )}
        </Box>
      </RadioGroup>

    </FormControl>
  );
};

export default TankRadio;