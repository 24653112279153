import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Typography, Box,  Avatar,  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, AvatarGroup, Tooltip, ThemeProvider, TextField, debounce, Grid2, Paper } from '@mui/material';
import { ReactComponent as More } from '../../../../src/assets/SVG/close-circle-greay.svg';
import Warning from '../../../../src/assets/SVG/Warning-red.svg';
import { proceduresListType, procedureColumns, procedureGroupConfig, ServiceType, deleteCycle, Patient, getTreatmentServices, deleteService, handleAddMoreServices } from './proceduresHelper';
import Grid from '@mui/material/Grid2';
import EditableTable from '../../customTableComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { theme } from '../../Medical_History_Forms/themeprovider';
import styles from './procedureComponent.module.css';
import { useLoader } from '../../loaderProvider/loader';
import { useAlert } from '../../alertProvider';
import { putLinkOocyteDonor } from '../createCycle/LinkOocyteDonorHelper';
import { usePatientContext } from '../../SubPatinetProvider';
import closeCircleRed from '../../../assets/SVG/close-circle.svg';
import cart from '../../../assets/SVG/shopping-cart.svg';
import  CloseCircle  from '../../../assets/SVG/closecircle.svg';
import PriscriptionSVG from '../../../assets/SVG/Prescription.svg';
import addCircle from '../../../assets/SVG/add-black.svg';
import deleteBtn from '../../../assets/SVG/trash.svg';
import dayjs from 'dayjs';
import {  ServiceMaster } from '../CycleList/cyclelist-helper';
import { cycleRelatedMandatoryProcedures } from '../createCycle/createCycle-helper';
interface ProcedureDialogProps {
  open: boolean;
  handleClose: () => void;
  procedureData: proceduresListType;
  onSave: (updatedData: proceduresListType) => void;
}

const ProcedureDialog: React.FC<ProcedureDialogProps> = ({ open, handleClose, procedureData, onSave }) => {
  const [tableData, setTableData] = useState<ServiceType[]>(procedureData?.services || []);
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [openModal, setOpenModal] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [donors, setDonors] =useState<Patient[]>([]);
  const titles = ['Remove Procedure?', 'Remove Donor?', 'Delete Procedure?'];
  const [donorsIds, setDonorsIds] =useState<number[]>([]);
  const [selectedDonors, setSelectedDonors] = useState<number[]>([]);
  const { patientId } = usePatientContext();
  const [isDeletable, setisdeletable] =useState(false);
  const [showAddServices, setShowAddServices] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProcedures , SetSelectedProcedures] = useState<ServiceMaster[]>([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [CycleRelatedProcedures, setCycleRelatedProcedures]= useState<ServiceMaster[]>([]); 
  const [procedureid, setProcedureId]= useState<string |undefined>('');
  useEffect(() => {  
    setActiveStep(0);
    setCycleRelatedProcedures([]);
    setSelectedDonors([]);
    SetSelectedProcedures([]);
    setShowOverlay(false);
    setShowAddServices(false);
    setSearchTerm('');
    // eslint-disable-next-line no-console
    console.log('procedureDataprocedureDataprocedureData',procedureData);

    const combinedSources =(procedureData && procedureData.donor_info && procedureData.donor_info.recipient !== null)? [...procedureData.donor_info.sperm_source, ...procedureData.donor_info.oocyte_source].filter(
      (item) => item.type !== 'self'
    ): [];
    setDonors(combinedSources);
    const donorIdArray = combinedSources.map(donor => donor.id);
    
    setDonorsIds(donorIdArray);
    if (donorIdArray.length === 0) {
      setActiveStep(2);
    }

    if (procedureData && procedureData.created_on) {
      const createdOn = dayjs(procedureData.created_on);

      const isDeletable = dayjs().diff(createdOn, 'hour') <= 24;
      setisdeletable(isDeletable);
    }
    setProcedureId(procedureData?.procedure_id);

  }, [procedureData]);
  // eslint-disable-next-line no-console
  useEffect(()=>{ console.log(procedureid);},[procedureid]);

  const handleNext = () => {

    if (activeStep === 1) {
      const donorsNotSelected = donorsIds.filter(id => !selectedDonors.includes(id));
      const donorData = {
        donor_ids: donorsNotSelected,
      };
      putLinkOocyteDonor(patientId, donorData)
        .then(() => {
          addAlert('Donor Delinked successfully.', 'success');
          setActiveStep((prevStep) => prevStep + 1);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          
          addAlert('Error Delinked donors. Please try again.', 'error'); 
          setActiveStep(0);
          setOpenModal(false);
          return;
        });
      
    }else if (activeStep === 2) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleDelete(procedureData.id);
    }else{
      setActiveStep((prevStep) => prevStep + 1);

    }
  };

  const handleToggle = (donorId: number) => {
    setSelectedDonors((prevSelected) =>
      prevSelected.includes(donorId)
        ? prevSelected.filter((id) => id !== donorId) // Deselect if already selected
        : [...prevSelected, donorId] // Add to selected if not already present
    );
  };
  
  const handleDeleteService = useCallback(
    (id: number) => {
      deleteService(procedureData.id, id)
        .then(() => {
          addAlert('Service deleted successfully', 'success');
          onSave({ ...procedureData });
          setActiveStep(0);
          setOpenModal(false);
          handleClose();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('An error occurred:', error);
          addAlert('An error occurred:', 'error');
        })
        .finally(() => {
          hideLoader();
        });
    },
    [procedureData, onSave, setActiveStep, setOpenModal, handleClose, hideLoader, addAlert]
  );

  useEffect(() => {

    
    if (open) {

      const updatedTableData = (procedureData?.services || []).map((service) => {
        const isPredefined = cycleRelatedMandatoryProcedures
          .find((p) => p.procedureName === procedureData?.procedures_type)
          ?.services.some((s) => s.code === service.code);
      
        return {
          ...service,
          deletable: !isPredefined, // true if it's not predefined, false otherwise
          action: !isPredefined ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                cursor: 'pointer',
              }}
            >
              {/* Delete Button */}
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    onClick={() => handleDeleteService(service.id)}
                    style={{
                      color: '#FF0000',
                      cursor: 'pointer',
                    }}
                    size="small"
                  >
                    <img src={deleteBtn} alt="delete" />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          ) : (
            ''
          ),
          
        };
      });
      setTableData(updatedTableData || []);
      
    }
  }, [open, procedureData?.services, procedureData?.procedures_type, handleDeleteService]);


  const handleDelete=(id:number)=>{
    showLoader();
    deleteCycle(id).then((res)=>{
      // eslint-disable-next-line no-console
      console.log(res);
      addAlert('Procedure deletes successfully', 'success');
      onSave({ ...procedureData });
      setActiveStep(0);
      setOpenModal(false);
      SetSelectedProcedures([]);
      setCycleRelatedProcedures([]);  
      handleClose();
      
    })
      .catch((error) => {
      // Handle errors
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
        addAlert('An error occurred:', 'error');
      }).finally(() => {
        hideLoader();
          
      });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // Select all donors
      setSelectedDonors(donors.map(donor => donor.id));
    } else {
      // Deselect all donors
      setSelectedDonors([]);
    }
  };

  const handleAddServices = () => {
    setShowAddServices(prev => !prev);
  };

  const debouncedSearch = useRef(
    debounce((query: string, procedureId?:string ) => {

      getTreatmentServices(`?procedure=${procedureId}&search=${query}&limit=20&service_type=CYCLE_RELATED`)
        .then((data) => {
          // Ensure data.objects is valid, otherwise use an empty array
          const services = data?.objects || [];
  
          // Ensure procedureData.services exists, otherwise use an empty array
          const selectedServices = procedureData?.services || [];
  
          // Filter out services that are already selected
          const filteredServices = services.filter(
            (service) => !selectedServices.some((selected) => selected.id === service.id)
          );
  
          // eslint-disable-next-line no-console
          console.log(filteredServices); // Debugging output
  
          // Set only the top 5 services
          setCycleRelatedProcedures(filteredServices.slice(0, 5));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        });
    }, 200)
  ).current;
  
  const handleSearch = useCallback(
    (query: string) => {
 
      if (query.length === 0) {
        setCycleRelatedProcedures([]);
        return;};
    
      if (!procedureid) {
        return;
      }
      if (procedureid) {

        debouncedSearch(query, procedureid);
      }
    },
    [debouncedSearch, procedureid] // Dependencies
  );
  
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    handleSearch(event.target.value);
  };
  const handleToggleOverlay = () => {
    setShowOverlay((prev) => !prev);
  };
  const handleRemoveService = (index:number) => {
    SetSelectedProcedures((prev) => {
      const updatedServices = prev.filter((_, i) => i !== index);
      return updatedServices;
    });
  };


  const handleAddProcedure = (order: ServiceMaster) =>{
    const isOrderAlreadyAdded = selectedProcedures.some((service) => service.id === order.id);
  
    if (isOrderAlreadyAdded) {
      addAlert('This service is already added.', 'info');
    } else {
      SetSelectedProcedures((prev) => [...prev, order]);
    }
  };

  const handleSubmitProcedures=()=>{
  
    // const data :PatientProcedureCreate ={
    //   patient:patientId,
    //   procedures:1,
    //   services: selectedProcedures
    //     .map((procedure) => procedure.id) // Extract IDs
    //     .filter((id): id is number => id !== undefined),
    //   doctor:procedureData?.doctor || 0,

    // };
    // // eslint-disable-next-line no-console
    // console.log(selectedProcedures);
    // return;
    const promises = selectedProcedures.map((service) =>
      handleAddMoreServices(procedureData.id,service.id)
    );
  
    Promise.all(promises)
      .then(() => {
        SetSelectedProcedures([]);
        setCycleRelatedProcedures([]);
        addAlert('Procedures Added Successfully', 'success');
        handleClose();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error);
        addAlert('An error occurred while adding procedures', 'error');
        handleClose();
      });
  };

  const [isGlowing, setIsGlowing] = useState(false);

  // Effect to trigger glow when selectedProcedures changes
  useEffect(() => {
    if (selectedProcedures.length > 0) {
      setIsGlowing(true);
      setTimeout(() => setIsGlowing(false), 1000); // Glow effect lasts for 1 sec
    }
  }, [selectedProcedures.length]);
  return (

    <ThemeProvider theme={theme}>
      <Dialog 
        open={open} 
        onClose={handleClose } 
        maxWidth="lg" fullWidth
        PaperProps={{  sx: {  borderRadius: '12px', width: '60%',  minWidth: '700px',}  }}
      >
        <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '1px' }}>
            <span style={{ marginLeft: '12px' }}>Procedure Details</span>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Button 
                onClick={handleAddServices}
                className={styles.delete_btn}
                style={{
                  fontFamily: 'Montserrat',
                  height: '40px',         // Smaller height
                  borderRadius: '12px',   
                  padding: '6px 12px',    // Compact padding
                  gap: '4px',             
                  backgroundColor: '#444',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px'        // Smaller font size for label
                }}
              >
              Add Services
              </Button>
            
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <More />
              </IconButton>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContent>

            {/* Procedure Info Section */}
            <Grid container spacing={2} mb={3}>
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Procedure Type</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {procedureData?.procedures_type}
                </Typography>
              </Grid>
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Advised Date</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {procedureData?.advised_date}
                </Typography>
              </Grid>
            
              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Indication</Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{procedureData?.indication}</Typography>
              </Grid>

              <Grid size={3} sx={{ textAlign: 'left' }}>
                <Typography variant="body1">Doctor Name </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{procedureData?.doctor_name}</Typography>
              </Grid>

            </Grid>


            {/* Cycle Info Section donor_info  */}
            {procedureData?.donor_info && procedureData.donor_info.recipient && (
              <Grid container spacing={2} mb={3}>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Oocyte Source</Typography>
                  <Box>
                    {(() => {
                      const sources = procedureData.donor_info.oocyte_source;

                      if (sources.length === 1 && sources[0].type === 'self') {
                        return (
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Patient
                          </Typography>
                        );
                      } else {
                        return (
                          <Box display="flex" alignItems="center" gap={1}>
                            {/* Show Patient if 'self' exists */}
                            {sources.some((source) => source.type === 'self') && (
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Patient, 
                              </Typography>
                            )}
                            {sources.some((source) => source.type === 'partner') && (
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Partner
                              </Typography>
                            )}

                            {/* Show Donor Avatars */}
                            {/* Show Donor Avatars using AvatarGroup */}
                            {sources.some((source) => source.type !== 'self' && source.type !== 'partner') && (
                              <AvatarGroup >
                                {sources
                                  .filter((source) => source.type !== 'self')
                                  .map((donor, index) => (
                                    <Tooltip
                                      key={index}
                                      title={`${donor.first_name || 'Unknown'} ${donor.last_name || ''} - ${donor.medical_record_no || ''}`}
                                      arrow
                                    >
                                      <Avatar
                                        alt={donor.first_name || 'Donor'}
                                        src={donor.photo_url || ''}
                                        sx={{ width: 30, height: 30 }}
                                      />
                                    </Tooltip>
                                  ))}
                              </AvatarGroup>
                            )}
                          </Box>
                        );
                      }
                    })()}
                  </Box>
                </Grid>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Sperm Source</Typography>
                  <Box>
                    {(() => {
                      const sources = procedureData.donor_info.sperm_source;

                      if (sources.length === 1 && sources[0].type === 'self') {
                        return (
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Partner
                          </Typography>
                        );
                      } else {
                        return (
                          <Box display="flex" alignItems="center" gap={1}>
                            {/* Show Partner if 'self' exists */}
                            {sources.some((source) => source.type === 'self') && (
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Partner, 
                              </Typography>
                            )}
                            {/* Show Donor Avatars */}
                            {/* Show Donor Avatars using AvatarGroup */}
                            {sources.some((source) => source.type !== 'self') && (
                              <AvatarGroup >
                                {sources
                                  .filter((source) => source.type !== 'self')
                                  .map((donor, index) => (
                                    <Tooltip
                                      key={index}
                                      title={`${donor.first_name || 'Unknown'} ${donor.last_name || ''} - ${donor.medical_record_no || ''}`}
                                      arrow
                                    >
                                      <Avatar
                                        alt={donor.first_name || 'Donor'}
                                        src={donor.photo_url || ''}
                                        sx={{ width: 30, height: 30 }}
                                      />
                                    </Tooltip>
                                  ))}
                              </AvatarGroup>
                            )}
                          </Box>
                        );
                      }
                    })()}
                  </Box>
                </Grid>
                <Grid size={3} sx={{ textAlign: 'left' }}>
                  <Typography variant="body1">Recipient</Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {(() => {
                      const sources = procedureData.donor_info.recipient;
                      if (sources.type === 'self') {
                        return 'Patient';
                      } else {
                        return (
                          <Tooltip title={`${sources.first_name || 'Unknown'} ${sources.last_name || ''} - ${sources.medical_record_no || ''}` } arrow>
                            <Avatar
                              alt={sources.first_name || 'Donor'}
                              src={sources.photo_url || ''}
                              sx={{ width: 30, height: 30 }}
                            />
                          </Tooltip>
                        );
                      }
                    })()}
                  </Typography>
                </Grid>
              </Grid>
            )}
          
            {showAddServices && (
              <Grid  size={12} bgcolor={'#f9f2f291'} padding={2} sx={{ borderRadius: '20px' }} marginBottom={2}>
                <h5>
                  Add Services
                </h5>
                <Grid2 container sx={{ width: '100%' }}>
  
                  {/* Search Field */}
                  <Grid2  size={10} justifyContent="flex-end"  sx={{
                    alignSelf: 'flex-end', 
                  }} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Search Services"
                      placeholder="Search by Services, code"
                      size="small"
                      value={searchTerm}
                      onChange={onSearchChange}
                      sx={{
      
                        '& .MuiInputBase-root': {
                          height: 46, 
                        },
                      }}
                    /> 
                  </Grid2>

                  {/* Cart Button */}
                  <Grid2 size={2}>
                    <div   style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', marginRight:'15px' }}>
                     
                      <div>
                        <span
                          style={{
                            position: 'relative',
                            top: '25px',
                            left: '35px',
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#f00',
                            color: '#fff',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            zIndex: 1111,
                          }}
                        >
                          {selectedProcedures.length}
                        </span>

                        <Button
                          onClick={handleToggleOverlay}
                          style={{
                            width: '55px',
                            height: '53px',
                            border: 'solid 1px #E2E3E5',
                            borderRadius: '15px',
                            padding: '0px',
                            zIndex: 1000,
                            backgroundColor: '#FFF',
                            boxShadow: isGlowing
                              ? '0 0 15px rgba(255, 166, 0, 0.93)' // Orange glow effect
                              : '0 4px 12px rgba(0, 0, 0, 0.5)',
                            transition: 'box-shadow 0.3s ease-in-out',
                          }}
                        >
                          <img src={cart} alt="Cart Icon" />
                        </Button>
                      </div>

                      {/* Overlay */}
                      {showOverlay && (
                        <Paper
                          className={styles.overlayFadeIn}
                          elevation={4}
                          style={{
                            position: 'absolute',
                            top: '85px',
                            right: '0',
                            width: '400px',
                            backgroundColor: '#E2E3E5',
                            borderRadius: '10px',
                            padding: '16px',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
                            zIndex: 2000,
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              right: '20px',
                              width: '0',
                              height: '0',
                              borderLeft: '10px solid transparent',
                              borderRight: '10px solid transparent',
                              borderBottom: '10px solid #E2E3E5',
                              zIndex: 2001,
                            }}
                          ></div>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              cursor: 'pointer',
                              padding: '10px',
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '18px',
                                fontWeight: 700,
                              }}
                            >
      Orders ({selectedProcedures.length})
                            </Typography>
                            <img src={CloseCircle} alt="close"  onClick={handleToggleOverlay}/>
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: '#FFF',
                              borderRadius: '10px',
                              padding: '10px',
                              maxHeight: '250px',
                              overflow:'auto'
                            }}
                          >

                            {/* {selectedProcedures.length > 0 && (
                              <Grid2 size={12}  sx={{
                                padding:'16px'
                              }}  >
                                <Autocomplete
                                  options={doctors}
                                  getOptionLabel={(option) => `Dr. ${option.first_name} ${option.last_name}`}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      handleDoctorChange(newValue.id); // Passes the doctor ID
                                    } else {
                                      handleDoctorChange(-1); // Pass a default ID or handle null as needed
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Doctor"
                                      variant="outlined"
                                      error={doctorError} // Show error state
                                      helperText={doctorError ? 'Doctor is required' : ''} // Error message
                                      fullWidth
                                    />
                                  )}
                                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                
                                />
                              </Grid2>
                            )} */}
                            {selectedProcedures.length > 0 ? (
                              <Box>
                                {selectedProcedures.map((procedure, index) => (
                                  <Box
                                    key={index}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    p={2}
                                    borderRadius="8px"
                                    border="1px solid grey"
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    bgcolor="#fff"
                                    m={2}
                                  >
                                    <Grid2 container spacing={2}>
                                      <Grid2  size={10} display="flex" justifyContent="start">
                                        <p>
                                          {procedure.name} ({procedure.code})
                                        </p>
                                      </Grid2>
                                      <Grid2  size={2} display="flex" justifyContent="end">
                                        <Button onClick={() => handleRemoveService(index)}>
                                          <img
                                            src={closeCircleRed}
                                            alt="Close Icon"
                                            style={{ width: '20px', height: '20px' }}
                                          />
                                        </Button>
                                      </Grid2>
                                    </Grid2>
                                  </Box>
                                ))}
                              </Box>
                            ) : (
                              <Typography style={{ fontSize: '14px', color: '#888' }}>
        No procedures selected.
                              </Typography>
                            )}
                          </Box>
                          <Box>
                            <div className="d-flex justify-content-end">
                              <button
                                onClick={handleSubmitProcedures}
                                style={{
                                  padding: '5px 10px',
                                  border: 'none',
                                  borderRadius: '7px',
                                  fontSize: 'larger',
                                  fontWeight: '700',
                                  marginTop: '15px',
                                  backgroundColor: '#505050',
                                  color: '#FFF',
                                }}
                                disabled={selectedProcedures.length === 0}
                              >
Proceed
                              </button>
                            </div>
                          </Box>
                        </Paper>
                      )}
                    </div>
                  </Grid2>

                  {CycleRelatedProcedures.map((cycle, index) => {
                    const isOrderAdded = selectedProcedures.some((procedure) => procedure.id === cycle.id);
                    const delay = `${index * 0.06}s`; 
                    return(
                      <Grid2
                        key={index}
                        size={{ xs: 12, sm: 6, md: 2.4 }}
                        gap={2}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '12px',
                          padding: '10px',
                        }}
                      >
                        <Paper
                          elevation={3}
                          style={{ animationDelay: delay }}
                          className={`${styles.card} fadein`}
                          sx={{
                            padding: 2,
                            textAlign: 'center',
                            borderRadius: 2,
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                        >
                          <Grid2 container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <Grid2 size={2}>
                              <img src={PriscriptionSVG} alt="Cycle" />
                            </Grid2>
                            <Grid2 size={10}>
                              <Box pl={1}>
                                <p
                                  style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    lineHeight: '24.55px',
                                    textAlign: 'left',
                                    textUnderlinePosition: 'from-font',
                                    textDecorationSkipInk: 'none',
                                  }}
                                >
            ({cycle.code}) {cycle.name}
                                </p>
                              </Box>
                            </Grid2>
                            <Grid2 sx={{ flexGrow: 1 }} />
                            <Grid2 size={12}>
                              <hr style={{ width: '100%' }} />
                            </Grid2>
                            <Grid2 size={12} display="flex" justifyContent="center">
                              <button
                                onClick={() => handleAddProcedure(cycle)}
                                style={{
                                  padding: '5px 10px',
                                  border: 'none',
                                  borderRadius: '7px',
                                  fontSize: '12px',
                                  fontWeight: '700',
                                  backgroundColor: isOrderAdded ? '#DEEFE1' : '#E0E0E0',
                                  color: isOrderAdded ? '#47B35F' : '#232323',
                                  cursor: isOrderAdded ? 'not-allowed' : 'pointer',
                                  width: 'auto',
                                  height: '30px',
                                }}
                                disabled={isOrderAdded}
                              >
                                {!isOrderAdded && (
                                  <img src={addCircle} alt="Add Icon" style={{ width: '22px', height: '22px' }} />
                                )}
                                {isOrderAdded ? 'Added' : ' Add'}
                              </button>
                            </Grid2>
                          </Grid2>
                        </Paper>
                      </Grid2>
                    );
                  }
             
              
                  )}

                </Grid2>

              </Grid>
            )}
            <Grid size={12}>
              {tableData?.length === 0 ? (
                <div>No procedures found.</div>
              ) : (
                <EditableTable
                  columns={procedureColumns}
                  data={tableData}
                  actionType="none"
                  groupConfig={procedureGroupConfig}
                />
              )}
            </Grid>
          </DialogContent>      
        </DialogContent>

        <DialogActions sx={{ mx: '24px', mb:'16px' }}>
          {/* Left Button */}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} color="secondary" variant='outlined'
              className={`${styles.btn_css}`}
              style={{
                fontFamily: 'Montserrat',
                height: '40px',         // Smaller height
                borderRadius: '8px',   
                padding: '6px 12px',    // Compact padding
                gap: '4px',             
                backgroundColor: '#23323',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px'        // Smaller font size for label
              }}
            >
              <ArrowBackIcon style={{ marginRight: '8px' }} /> {/* Add back arrow icon */}
        Back
            </Button>
          </div>



          {/* Right Button */}
          {
            isDeletable&&
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              
              {/* Edit Button */}
              {/* <Button 
                onClick={handleAddServices}
                className={styles.delete_btn}
                style={{
                  fontFamily: 'Montserrat',
                  height: '40px',         
                  borderRadius: '12px',   
                  padding: '6px 12px',    
                  gap: '4px',
                  backgroundColor: '#444', 
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px',
                  marginRight: '10px'
                }}
              >
                <EditIcon style={{ marginRight: '4px', fontSize: '15px' }} />
        Edit Services
              </Button> */}

              <Button 
                onClick={() => setOpenModal(true)}
                className={styles.delete_btn}
                style={{
                  fontFamily: 'Montserrat',
                  height: '40px',         // Smaller height
                  borderRadius: '12px',   
                  padding: '6px 12px',    // Compact padding
                  gap: '4px',             
                  backgroundColor: '#444',
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '12px'        // Smaller font size for label
                }}
              >
                <DeleteIcon style={{ marginRight: '4px', fontSize: '15px' }} /> 
              Delete Procedure
              </Button>
            </div>
          
          }
        </DialogActions>

        {/* <ConfirmationModal
        open={openModal}
        handleClose={() => setOpenModal(false)} 
        onConfirm={()=>handleDelete(procedureData.id)} 
        title="Link Donor"
        contentMessage="Are you sure you want to delete Cycle?"
        type={'warning'}
      /> */}

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="remove-cycle-dialog-title"
          aria-describedby="remove-cycle-dialog-description"
          maxWidth="xs"
          PaperProps={{  sx: {  borderRadius: '12px', minWidth: '500px',}  }}
          fullWidth
        >
          <DialogTitle id="remove-cycle-dialog-title" sx={{ textAlign: 'center' }}>
            <Box display="flex" justifyContent="center" mb={2}>
              <img src={Warning} alt="warning" />
            </Box>
            <Typography variant="h6" fontWeight="700">
              {titles[activeStep]}
            </Typography>
          </DialogTitle>

          <DialogContent>

            {activeStep === 0 && (
              <>
                <Typography variant="body1" textAlign="center">
          You will need to remove the Donor first
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight="600"
                  textAlign="center"
                  sx={{ marginTop: 2 }}
                >
          &ldquo;&nbsp;Cycle Related Procedure&nbsp;&ldquo;
                </Typography>
              </>
            )}

            {activeStep === 1 && (
              <>
                <Typography  textAlign="center">Select the donor(s) to remove:</Typography>
                <TableContainer sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ padding: '0px' }} align="center">
                          <Checkbox
                            checked={donors.length > 0 && donors.every(donor => selectedDonors.includes(donor.id))}
                            onChange={handleSelectAll}
                            inputProps={{ 'aria-label': 'select all donors' }}
                          />
                        </TableCell>
                        <TableCell>Select All</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {donors.map((donor) => (
                        <TableRow key={donor.id}>
                          <TableCell sx={{ padding: '0px' }} align="center">
                            <Checkbox
                              checked={selectedDonors.includes(donor.id)}
                              onChange={() => handleToggle(donor.id)}
                            />
                          </TableCell>
                          <TableCell sx={{ padding: '0px', paddingY: '10px', display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ marginRight: '10px', width: '25px', height: '25px' }} src={donor.photo_url} alt={donor.first_name} />
                            {donor.first_name} {donor.last_name}
                          </TableCell>
                          <TableCell sx={{ padding: '0px' }}>{donor.medical_record_no || '-'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
         
            {activeStep === 2 && (
              <>
                <Typography variant="body1" textAlign="center">
              Are you sure want to delete procedure
                </Typography>
              </>
            )}
          </DialogContent>

          <DialogActions sx={{ justifyContent: 'center', gap: 2, padding: 2 }}>
            <Button
              onClick={() => {setOpenModal(false);setActiveStep(0);}}
              variant="outlined"
              color="inherit"
              sx={{
                textTransform: 'none',
                fontWeight: '600',
                padding: '8px 20px',
                borderRadius: '6px',
              }}
            >
          Cancel
            </Button>
            <Button
              onClick={()=>handleNext()}
              variant="contained"
 
              sx={{
                textTransform: 'none',
                fontWeight: '600',
                padding: '8px 20px',
                borderRadius: '6px',
                bgcolor:'#505050',
                color:'#FFF'
              }}
            >
              {activeStep === 0 && 'Proceed'}
              {activeStep === 1 && 'Remove'}
              {activeStep === 2 && 'Delete'}
            </Button>
          </DialogActions>
        </Dialog>

      
      </Dialog>
    </ThemeProvider>
  );
};

export default ProcedureDialog;
