/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import Styles from './Stimpage.module.css';
import { StimulationSchedular } from '../../components/stimSchedular';
import HeaderProfile from '../../components/header_Profile/header_Profile.index';
import { usePatientContext } from '../../components/SubPatinetProvider';
import { PatientData } from '../../types';
import CycleSelector from './cycleSelector.stim';
import { OptionType } from './helper.stim';
import {ReactComponent as CycleSVG} from '../../assets/SVG/In vitro fertilization-bro 1.svg';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Hormonalgraph from './hormonalgraph';

const StimPage: React.FC = () => {

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const [selectedPatient, setselectedPatient] = useState<PatientData | null>(null);
  const [selectedStimChart, setSelectedStimChart] = useState<number | null>(null);
  const {patientId} = usePatientContext();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDatafromHeader = (data: PatientData) => {
    setselectedPatient(data);
  };

  const handleChangeCycle = (event: React.SyntheticEvent | null, value: number | null) => {
    setSelectedStimChart(value);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

  return (
    <div className={Styles.stimpage}>
      <div className={Styles.header}>
        <HeaderProfile onData={handleDatafromHeader}/>
      </div>
      <div className={Styles.cycleSelector}>
        {selectedPatient && <CycleSelector patient={patientId} onCycleChange={handleChangeCycle}/>}
      </div>
      <div>
        <div>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Chart" {...a11yProps(0)} />
            <Tab label="Graph" {...a11yProps(1)} />
          </Tabs>
        </div>
        <div>
          <CustomTabPanel value={value} index={0}>
            <Suspense fallback={<Typography>Loading chart</Typography>}>
              <div className={Styles.scheduler}>
                {selectedStimChart ? <StimulationSchedular id={selectedStimChart} height={'75vh'}/>: <div className={Styles.logo}><CycleSVG/><Typography variant='h6'>No active stim cycle found.</Typography></div>}
              </div>
            </Suspense>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Suspense fallback={<Typography>Loading graph</Typography>}>
              <div className={Styles.scheduler}>
                {selectedStimChart ? <Hormonalgraph patientId={selectedPatient?.id || patientId} stim={selectedStimChart}/>: <div className={Styles.logo}><CycleSVG/><Typography variant='h6'>No active stim cycle found.</Typography></div>}
              </div>
            </Suspense>
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

export default StimPage;