import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, TextField, MenuItem, Button, Autocomplete, Grid2 } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modals.module.css';
import { convertDateToISOWithOffset, convertUTCToLocalTimeDate, formatDateToISOWithOffsetFilter, formatDateToLocalYYYYMMDD } from '../../services/time';
import { createEvent, deleteEvents, EventAppointment, EventCycleRequest, EventData, getEvent, getRooms, getTables, getUsersByClinicOnly, OptionType, Room, Table, updateEvent } from './helper.stim';
import { PaginatedResponse, UserBasic } from '../../types';
import { useClinic } from '../../components/ClinicProvider';
import { showConfirmationModal } from '../../components/ConfirmationModel';
import EditIcon from './../../assets/SVG/edit-2.svg';
import deleteIcon from './../../assets/SVG//trash.svg';
import { getUsersByClinicAndTime } from '../../services/apiService';
import { useAlert } from '../../components/alertProvider';

interface EventModelProps {
  scheduleEventModalOpen: boolean;
  handleScheduleModalClose: () => void;
  eventDate?: Date;
  patient: number;
  partner?: number;
  stim: number;
  id?: string;
  isNewEvent?: boolean;
}

const EventModel: React.FC<EventModelProps> = ({ scheduleEventModalOpen, handleScheduleModalClose, eventDate, patient, partner, stim, id, isNewEvent }) => {
  const [doctors, setDoctors] = useState<OptionType[]>([]);
  const [tables, setTables] = useState<OptionType[]>([]);
  const [rooms, setRooms] = useState<OptionType[]>([]);
  const [event, setEvent] = useState<EventData | null>();
  const [viewMode, setViewMode] = useState<boolean>(true);
  
  
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();

  useEffect(() => {
    if (id && !id.toString().includes('mbsc')) {
      getEvent(parseInt(id)).then((res: EventData) => {
        setEvent(res);
      });
    }
  }, [id]);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    getUsersByClinicOnly(ClinicId)
      .then((paginated: PaginatedResponse<UserBasic[]>) => paginated.objects)
      .then((users: UserBasic[]) => {
        const userOptions: OptionType[] = users.map((user: UserBasic) => ({
          label: `${user.first_name} ${user.last_name}`,
          value: user.id.toString(),
        }));
        setDoctors(userOptions);
      });

    getRooms()
      .then((paginated: PaginatedResponse<Room[]>) => paginated.objects)
      .then((rooms: Room[]) => {
        const roomOptions: OptionType[] = rooms.map((room: Room) => ({
          label: room.name,
          value: room.id.toString(),
        }));
        setRooms(roomOptions);
      });

    getTables()
      .then((paginated: PaginatedResponse<Table[]>) => paginated.objects)
      .then((tables: Table[]) => {
        const tableOptions: OptionType[] = tables.map((table: Table) => ({
          label: table.name,
          value: table.id.toString(),
        }));
        setTables(tableOptions);
      });
  }, [ClinicId]);

  const date = event?.date ? new Date(event.date) : eventDate || new Date();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      event: event?.event_type || '',
      date: formatDateToLocalYYYYMMDD(date),
      fromTime: event?.appointment?.start_datetime
        ? convertUTCToLocalTimeDate(new Date(event?.appointment.start_datetime ?? ''))
        : '',
      toTime: event?.appointment?.end_datetime
        ? convertUTCToLocalTimeDate(new Date(event?.appointment.end_datetime ?? ''))
        : '',
      doctor: event?.appointment?.user.toString() ?? '',
      embryologist: event?.appointment?.embryologist.toString() || '',
      operationTheater: event?.appointment?.room.toString() || '',
      OTtable: event?.appointment?.table.toString() || '',
      remark: event?.appointment?.remark || '',
    },
    validationSchema: Yup.object({
      event: Yup.string().required('This field is required'),
      date: Yup.string().required('This field is required'),
      fromTime: Yup.string().required('This field is required'),
      toTime: Yup.string()
        .required('This field is required')
        .test('is-later', 'To Time cannot be less than From Time', function (value) {
          const { fromTime } = this.parent;
          if (!fromTime || !value) return true;
          const parseTime = (time: string) => {
            const [h, m] = time.split(':').map(Number);
            return h * 60 + m;
          };
          return parseTime(value) > parseTime(fromTime);
        }),
      doctor: Yup.string().required('This field is required'),
      embryologist: Yup.string().required('This field is required'),
      operationTheater: Yup.string().required('This field is required'),
      OTtable: Yup.string().required('This field is required'),
      // remark: Yup.string().required('This field is required'),
    }),
    onSubmit: async (values) => {
      const start = new Date(values.date);
      start.setHours(parseInt(values.fromTime.split(':')[0]), parseInt(values.fromTime.split(':')[1]));

      const end = new Date(values.date);
      end.setHours(parseInt(values.toTime.split(':')[0]), parseInt(values.toTime.split(':')[1]));

      const stimApp: EventAppointment = {
        user: parseInt(values.doctor),
        appointment_date: formatDateToLocalYYYYMMDD(new Date(values.date)),
        start_datetime: convertDateToISOWithOffset(start),
        end_datetime: convertDateToISOWithOffset(end),
        patient: patient,
        partner: partner,
        room: parseInt(values.operationTheater),
        table: parseInt(values.OTtable),
        status: 'SCHEDULED',
        remark: values?.remark ?? null,
        embryologist: parseInt(values.embryologist),
        type: 'APPOINTMENT',
      };

      const eventBody: EventCycleRequest = {
        event_type: values.event as 'OPU' | 'ET' | 'LP' | 'FA',
        date: formatDateToLocalYYYYMMDD(new Date(values.date)),
        stim_appointment: stimApp,
        stim_chart: stim,
      };

      if (event && id) {
        const stimApp = {
          user: parseInt(values.doctor),
          appointment_date: formatDateToLocalYYYYMMDD(new Date(values.date)),
          start_datetime: convertDateToISOWithOffset(start),
          end_datetime: convertDateToISOWithOffset(end),
          appointment_reason: null, // Nullable field
          patient: patient || null, // Nullable field
          partner: partner || null, // Nullable field
          room: values.operationTheater ? parseInt(values.operationTheater) : null, // Nullable field
          table: values.OTtable ? parseInt(values.OTtable) : null, // Nullable field
          remark: values?.remark || null, // Nullable field
          status: 'SCHEDULED', // Ensure this matches the status Enum
          type: 'APPOINTMENT', // Ensure this matches the type Enum
          department: null, // Nullable field
          embryologist: values.embryologist ? parseInt(values.embryologist) : null, // Nullable field
        };
        
        const eventBody = {
          event_type: values.event as 'OPU' | 'ET' | 'LP' | 'FA', // Matches EventTypeEnum
          date: formatDateToLocalYYYYMMDD(new Date(values.date)),
          stim_appointment: stimApp,
          stim_chart: stim
        };
        updateEvent(eventBody, id)
          .then(() => {
            handleScheduleModalClose();
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            if (error.response?.data?.type === 'validation_error') {
              const serverErrors = error.response.data.errors;
              const formikErrors: Record<string, string> = {};
          
              // Define a mapping between server keys and Formik field names
              const errorKeyMap: Record<string, string> = {
                appointment_date: 'date',
                start_datetime: 'fromTime',
                end_datetime: 'toTime',
                user: 'doctor',
                embryologist: 'embryologist',
                table: 'OTtable',
                room: 'operationTheater',
                remark: 'remark',
              };
          
              serverErrors.forEach((err: { attr: string; detail: string }) => {
                // Extract the key from the server error
                const serverKey = err.attr.split('.')[1];
          
                // Map the server key to the Formik field name if it exists
                const formikField = errorKeyMap[serverKey];
                if (formikField) {
                  formikErrors[formikField] = err.detail;
                }
              });
          
              formik.setErrors(formikErrors);
            }
          });
        
      } else {
        createEvent(eventBody)
          .then(() => {
            handleScheduleModalClose();
          })
          .catch((error) => {
            if (error.response?.data?.type === 'validation_error') {
              const serverErrors = error.response.data.errors;
              const formikErrors: Record<string, string> = {};

              serverErrors.forEach((err: { attr: string; detail: string }) => {
                const formikField = err.attr.split('.')[1];
                if (formikField) formikErrors[formikField] = err.detail;
              });

              formik.setErrors(formikErrors);                          
              error.response.data.errors.forEach((err: { attr: string; detail: string }) => {
                addAlert(err.detail ?? '', 'error');
              });
            }
          });
      }

      
    },
  });

  const [formikValues, setFormikValues] = useState(formik.values);
  const [availableRoleList, setAvailableRoleList] = useState<UserBasic[]>([]);

  useEffect(() => {
    setFormikValues(formik.values);
  }, [formik.values]);

  const fetchPersonnelByRoleAndTime = useCallback(() => {
    const startDateTime = formatDateToISOWithOffsetFilter( new Date(`${formatDate(new Date(formikValues.date))} ${formikValues.fromTime}:00`));
    const endDateTime = formatDateToISOWithOffsetFilter( new Date(`${formatDate(new Date(formikValues.date))} ${formikValues.toTime}:00`));
    getUsersByClinicAndTime(ClinicId, null, startDateTime, endDateTime)
      .then((res: PaginatedResponse<UserBasic[]>) => {
        const personnels = res?.objects ?? [];
        setAvailableRoleList(personnels);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching working hours:', error);
      });
    // eslint-disable-next-line
    }, [ClinicId, formikValues.date, formikValues.fromTime, formikValues.toTime]);
  
  useEffect(() => {
    if (formikValues.fromTime && formikValues.toTime) {
      fetchPersonnelByRoleAndTime();
    }
    // eslint-disable-next-line
    }, [formikValues.fromTime, formikValues.toTime]);



  const handleDelete = () => {
    if (event && event.id) {
      showConfirmationModal({
        message: 'Are you sure you want to delete this event?',
        onConfirm: () => {
          deleteEvents(event?.id).finally(() =>  handleScheduleModalClose());
        },
        onCancel: () => {
          handleScheduleModalClose();
        },
      });
    }
  };

  const getLabelText = (list: { label: string; value: string }[], key: keyof typeof formik.values) => 
    list.find(item => item.value === formik.values[key])?.label.toString() ?? '';
  
  return (
    <Modal open={scheduleEventModalOpen} onClose={handleScheduleModalClose}>
      <Box className={styles.modal}>
        <Box className={styles.modalHeader}>
          <Typography variant="h3" gutterBottom>
            Schedule Event
          </Typography>
          {isNewEvent && viewMode && event?.event_type !== 'LP' ? <Box>
            <IconButton size="medium" sx={{mr: 1}} onClick={handleDelete}>
              <img src={deleteIcon} alt="delete" /> 
            </IconButton>
            <IconButton size="medium" sx={{mr: 1}} onClick={() => setViewMode(false)}>
              <img src={EditIcon} alt="edit" /> 
            </IconButton> 
            <IconButton aria-label="close" onClick={handleScheduleModalClose}>
              <CloseIcon />
            </IconButton>         
          </Box>  : <IconButton aria-label="close" onClick={handleScheduleModalClose}>
            <CloseIcon />
          </IconButton>}
          
        </Box>
        {isNewEvent && viewMode ? 
          <Box p={3}>
            <Grid2 container spacing={2} size={{xs:12}}>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Event</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{formik.values.event}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Date</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{formik.values.date}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>To Time</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{formik.values.toTime}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Doctor</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{getLabelText(doctors, 'doctor')}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Embryologist</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{getLabelText(doctors, 'embryologist')}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Operation Theatre</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{getLabelText(rooms, 'operationTheater')}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>OT Table</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{getLabelText(tables, 'OTtable')}</Typography>
              </Grid2>
              <Grid2 size={{xs:12, sm:6}}>
                <Typography>Remark</Typography>
                <Typography variant='body1' fontWeight={'medium'}>{formik.values.remark}</Typography>
              </Grid2>
            </Grid2>
          </Box> : 
          <form onSubmit={formik.handleSubmit}>
            <Grid2 container spacing={2} className={styles.modalBody}>
              <Grid2 size={{xs:12,md:6}}>
                <TextField
                  fullWidth
                  select
                  label="Schedule *"
                  name="event"
                  value={formik.values.event}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="normal"
                  error={formik.touched.event && Boolean(formik.errors.event)}
                  helperText={formik.touched.event && formik.errors.event}
                >
                  <MenuItem key="opu" value="OPU">
                  OPU
                  </MenuItem>
                  <MenuItem key="et" value="ET">
                  ET
                  </MenuItem>
                  <MenuItem key="fa" value="FA">
                  FA
                  </MenuItem>
                </TextField>
              </Grid2>
              <Grid2 size={{xs: 12,md:6}}>
                <TextField
                  fullWidth
                  label="Date *"
                  name="date"
                  type="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="normal"
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  slotProps={{inputLabel:{shrink: true}}}
                />
              </Grid2>
              <Grid2 size={{xs: 12,md:6}}>
                <TextField
                  fullWidth
                  label="From Time *"
                  name="fromTime"
                  type="time"
                  value={formik.values.fromTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="normal"
                  error={formik.touched.fromTime && Boolean(formik.errors.fromTime)}
                  helperText={formik.touched.fromTime && formik.errors.fromTime}
                  slotProps={{inputLabel:{shrink: true}}}
                />
              </Grid2>
              <Grid2 size={{xs: 12,md:6}}>
                <TextField
                  fullWidth
                  label="To Time *"
                  name="toTime"
                  type="time"
                  value={formik.values.toTime}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="normal"
                  error={formik.touched.toTime && Boolean(formik.errors.toTime)}
                  helperText={formik.touched.toTime && formik.errors.toTime}
                  slotProps={{inputLabel:{shrink: true}}}
                />
              </Grid2>
              <Grid2 size={{xs: 12,md:6}}>
                <Autocomplete
                  value={doctors.find((doc) => doc.value === formik.values.doctor) || null}
                  options={doctors}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => formik.setFieldValue('doctor', value?.value || '')}
                  renderOption={(props, option) => {
                    const isAvailable = availableRoleList.some((user) => user.id.toString() === option.value);
                      
                    return (
                      <li {...props} key={option.value}>
                        <Typography
                          style={{
                            color: isAvailable ? 'green' : 'red',
                            fontWeight: isAvailable ? 'bold' : 'normal',
                          }}
                        >
                          {option.label}
                        </Typography>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Doctor *"
                      variant="outlined"
                      error={formik.touched.doctor && Boolean(formik.errors.doctor)}
                      helperText={formik.touched.doctor && formik.errors.doctor}
                      margin="normal"
                    />
                  )}
                  disabled={!formik.values.fromTime || !formik.values.toTime}
                />
              </Grid2>
              <Grid2 size={{xs: 12, md: 6}}>
                <Autocomplete
                  value={doctors.find((doc) => doc.value === formik.values.embryologist) || null}
                  options={doctors}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => formik.setFieldValue('embryologist', value?.value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Embryologist *"
                      margin="normal"
                      error={formik.touched.embryologist && Boolean(formik.errors.embryologist)}
                      helperText={formik.touched.embryologist && formik.errors.embryologist}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{xs: 12, md: 6}}>
                <Autocomplete
                  value={rooms.find((room) => room.value === formik.values.operationTheater) || null}
                  options={rooms}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => formik.setFieldValue('operationTheater', value?.value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Operation Theater *"
                      margin="normal"
                      error={formik.touched.operationTheater && Boolean(formik.errors.operationTheater)}
                      helperText={formik.touched.operationTheater && formik.errors.operationTheater}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{xs: 12, md: 6}}>
                <Autocomplete
                  value={tables.find((table) => table.value === formik.values.OTtable) || null}
                  options={tables}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, value) => formik.setFieldValue('OTtable', value?.value || '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="OT Table *"
                      margin="normal"
                      error={formik.touched.OTtable && Boolean(formik.errors.OTtable)}
                      helperText={formik.touched.OTtable && formik.errors.OTtable}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{xs: 12}}>
                <TextField
                  fullWidth
                  label="Remark"
                  name="remark"
                  multiline
                  rows={3}
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  margin="normal"
                  error={formik.touched.remark && Boolean(formik.errors.remark)}
                  helperText={formik.touched.remark && formik.errors.remark}
                />
              </Grid2>
            </Grid2>
            <Box className={styles.modalActions}>
              <div className={styles.primary}>
                <Button variant="outlined" onClick={handleScheduleModalClose}>
              Cancel
                </Button>
                <Button type="submit" variant="contained">
                  {availableRoleList.some((user) => user.id.toString() === formikValues.doctor) && !formikValues.doctor ? 'Save': 'Force save'}
                </Button>
              </div>
              {event && <Button variant="outlined" color="error" onClick={handleDelete}>
              Delete
              </Button>}
            </Box>
          </form>}
      </Box>
    </Modal>
  );
};

export default EventModel;