/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { CryoStorageDetail, getMaterialsDetails } from '../cryoStorage.helpers';
import { capitalizeTitle, getDateInDDMMYYYY } from '../../../utils/commonUtils';
import EditableDateCell from '../editableDateCell';

interface MaterialDetailsProps {
    selectedCycleID: number | null;
  }
  
const MaterialDetails: React.FC<MaterialDetailsProps> = ({ selectedCycleID }) => {
  const [selectedCycleDetails, setSelectedCycleDetails] = useState<CryoStorageDetail[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  

  const fetchData = (cycleID: number) => {
    setLoading(true);
    setError(null);
    getMaterialsDetails(cycleID)
      .then((data) => {
        setSelectedCycleDetails(data || null);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching material details:', err);
        setError('Failed to fetch material details');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedCycleID !== null) {
      fetchData(selectedCycleID);
    }
  }, [selectedCycleID]);

  const handleDateChange = (newDate: string) => {
    // eslint-disable-next-line no-console
    console.log('New Date:', newDate);
    fetchData(selectedCycleID!); 
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>{error}</p>;
  }
  
  
  
  // Gradient color mapping for circles
  const gradientMap: Record<string, string> = {
    White: 'radial-gradient(circle, #FFFFFF 0%, #DCDCDC 100%)',
    Blue: 'radial-gradient(circle, #5C8CDB 0%, #BDD6FF 80%)',
    Brown: 'radial-gradient(circle, #8B5A2B 0%, #F5E6DA 100%)',
    Green: 'radial-gradient(circle, #4CAF50 0%, #DFF5E0 100%)',
    Orange: 'radial-gradient(circle, #E78457 0%, #FFDCCC 100%)',
    Red: 'radial-gradient(circle, #E74C3C 0%, #FFE5E0 100%)',
    Yellow: 'radial-gradient(circle, #DEC122 0%, #FFFCE9 100%)',
  };
  
  // Function to render a colored circle based on gradient map
  const GradientCircle: React.FC<{ color?: string }> = ({ color }) => {
    if (!color || !gradientMap[color]) return null;
  
    return (
      <span
        style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          background: gradientMap[color],
          marginLeft: '2px',
          marginRight: '6px',
          boxShadow: 'inset 1px 1px 3px rgba(0,0,0,0.2), 0 3px 6px rgba(0,0,0,0.1)',
          border: '1px solid rgba(255,255,255,0.7)',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      ></span>
    );
  };

   
  const formatLocationDetails = (locationDetails: any): JSX.Element => {
    if (!locationDetails || !locationDetails.parents) {
      return <span>N/A</span>;
    }
  
    // Extract hierarchy excluding the final cryo storage location
    const hierarchy = locationDetails.parents  
      .filter((p: any) => !/gob|goblet|globlet/i.test(p.name)) 
      .map((p: any) => p.name)
      .join(' | ');
  
    // Get Goblet
    // const goblet = locationDetails.parents.find((p: any) => p.name.includes('Globl'));
    const goblet = locationDetails.parents.find((p: any) => /gob|goblet|globlet/i.test(p.name));

    
    // Get Cryotec (current storage location)
    const cryotec = locationDetails;
  
    return (
      <span>
        {hierarchy}
        {goblet && (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', ml: 1 }}>
            <Typography variant="body2" component="span" sx={{ fontSize: '14px', mr: 1 }}>
              | {capitalizeTitle(goblet.name)}
            </Typography>
            <GradientCircle color={goblet.color || ''} />
          </Box>
        )}
        {cryotec && (
          <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap', ml: 1 }}>
            <Typography variant="body2" component="span" sx={{ fontSize: '14px', mr: 1 }}>
              | {capitalizeTitle(cryotec.name)}
            </Typography>
            <GradientCircle color={cryotec.color || ''} />
          </Box>
        )}
      </span>
    );
  };
    
  
  return (
    <div >
      <TableContainer sx={{ border: '1px solid rgb(240, 237, 237)', borderRadius: '10px' ,  overflowX: 'auto',}} >
        <Table >
          <TableHead>
            <TableRow
              sx={{
                height: '2rem',
                background: 'rgba(250, 250, 250, 1)',
                border: 'none',
                '& .MuiTableCell-root': {
                  padding: '0.6rem 0.6rem',
                  minWidth: '50%',
                },
              }}
            >
              {['Oocyte No.', 'Grade',  'Freezing Details', 'Age', 'Renewal Date', 'Location'].map((title, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontFamily: 'Nunito, sans-serif',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.45',
                    textAlign: 'left',
                    color: 'rgba(35, 35, 35, 1)',
                    padding: '0.3rem 0.6rem',
                    verticalAlign: 'top'
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {selectedCycleDetails ? (
              selectedCycleDetails.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="h6" color="textSecondary">
                        No data found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                selectedCycleDetails.map((storage, index) => (
                  <TableRow key={index}>
                    <TableCell>{storage.oocyte_number ? storage.oocyte_number : storage.embryo_number || '-'}</TableCell>
                    <TableCell>{storage.grade || '-'}</TableCell>
                    <TableCell>
                      <div> No : {storage.id}</div>
                      <div>{storage.created_on ? getDateInDDMMYYYY(storage.created_on) : '-'}</div>
                    </TableCell>                    
                    <TableCell style={{ whiteSpace: 'nowrap' }}>Day 0</TableCell>             
                    <TableCell>
                      <EditableDateCell cryo_date={storage.renewal_date} itemId={storage.id} type={storage.oocyte_number ? 'oocyte' : 'embryo'}  onDateChange={handleDateChange} />
                    </TableCell>                    
                    <TableCell>{formatLocationDetails(storage.location_details)  || '-'}</TableCell>
                  </TableRow>
                ))
              )
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h6" color="textSecondary">
                        Loading...
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> 
    </div>
  );
};

export default MaterialDetails;
