import React from 'react';
import { ReactComponent as CycleTerminate } from '../../../assets/SVG/EmbryoUnavailable.svg';
import Grid2 from '@mui/material/Grid2';

interface CultureUnavailableText {
  noContentMsg?: string;
}

const EmbryoCycleUnavailable: React.FC<CultureUnavailableText> = ({ noContentMsg }) => {
  return (
    <Grid2 container justifyContent="center" alignItems="center">
      <Grid2>
        <CycleTerminate style={{ width: '600px', height: '400px', marginTop: '20%' }} />
        <p style={{ textAlign: 'center', fontWeight: 600 }}>
          {noContentMsg ?? 'No cycle has been created yet'}
        </p>
      </Grid2>
    </Grid2>
  );
};

export default EmbryoCycleUnavailable;
