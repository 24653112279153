/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TextField, ThemeProvider, MenuItem, Box, Autocomplete } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import {ConfirmationModal} from '../../confirmationModal';
import FormObserver from '../../FormikFormObserver';
import { getMaxDateForMonthInput, RequiredLabel } from '../../../utils/commonUtils';
import styles from '../oocyteRetrieval/oocyteRetrival.module.css';
import theme from '../../../utils/theme';
import { addHysteroscopy, cavityItems, cervicalOsItems, endometriumItems, getHysteroscopy, HysteroscopyType, HysteroscopyValidationSchema, initialHysteroscopy, procedureDoneItems, updateHysteroscopy } from './hysteroscopy.helper';
import {  getUsersByClinicId } from '../../../services/apiService';
import { useClinic } from '../../ClinicProvider';
import { Clinician, Role, ROLES } from '../oocyteRetrieval/OocyteRetrival.helper';

interface HysteroscopyFormProp {
  record: number; 
  onFormChange?: (hasChanged: boolean) => void;
  onBack: () => void;
  isEditMode: boolean; 
}


const Hysteroscopy: React.FC<HysteroscopyFormProp> = ({ record, onFormChange , onBack, isEditMode = false})  => {
  const handleFormChange = onFormChange || (() => {});
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [initialValues, setInitialValues] = useState<HysteroscopyType>(initialHysteroscopy);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const maxMonthDate = useMemo(() => getMaxDateForMonthInput(), []);
  const [openResetConfirmDialog, setOpenResetConfirmDialog] = useState(false); 
  const { ClinicId } = useClinic();
  const isSelectingRef = useRef(false); 

  useEffect(() => {
    showLoader();
    getHysteroscopy(record)
      .then((data) => {
        setInitialValues({ ...initialHysteroscopy, ...data });
      })
      .catch(() => console.error('Error fetching Hysteroscopy', 'error'))
      .finally(() => hideLoader());
  }, [record, addAlert, showLoader, hideLoader]);

  
  const sanitizeHysteroscopy = (values: HysteroscopyType): HysteroscopyType => {
    const sanitizedValues = { ...values, patient_procedure: record };
    return sanitizedValues;
  };
  
  const handleSubmit = async (
    values: HysteroscopyType,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<HysteroscopyType>
  ) => {
    showLoader();

    // Use the utility function to sanitize values
    const sanitizedValues = sanitizeHysteroscopy(values);

    let hysteroscopyApiCall; // Renamed for clarity
    if (isEditMode) {
      // Ensure that sanitizedValues.id is a valid number
      const id = sanitizedValues?.id;
    
      if (id !== undefined && id !== null) { // Check if ID is not undefined or null
        hysteroscopyApiCall = updateHysteroscopy(id, sanitizedValues);
      } else {
        console.error('ID is required for updating laparoscopy');
        hideLoader();
        setSubmitting(false);
        return; // Exit early if ID is not valid
      }
    } else {
      // Otherwise, call the add API
      hysteroscopyApiCall = addHysteroscopy(sanitizedValues);
    }

    hysteroscopyApiCall
      .then((updatedData) => {
        addAlert('Hysteroscopy saved successfully', 'success');
        setInitialValues(updatedData);
        resetForm({ values: updatedData });
        
        onBack();
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<HysteroscopyType> = {};

          apiErrors.forEach((error: { attr: keyof HysteroscopyType; detail: string }) => {
            formikErrors[error.attr] = error.detail;
          });

          setErrors(formikErrors);
        } else {
          console.error('An error occurred while saving Hysteroscopy.');
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleReset = (
    resetForm: FormikHelpers<HysteroscopyType>['resetForm'],
    setFieldValue: FormikHelpers<HysteroscopyType>['setFieldValue']
  ) => {
    setOpenResetConfirmDialog(false); 
    resetForm(); 
    
    Object.keys(initialHysteroscopy).forEach((field) => {
      const initialValue = initialHysteroscopy[field as keyof HysteroscopyType];
      if (typeof initialValue === 'number' || initialValue === null) {
        setFieldValue(field, '');
      } else {
        setFieldValue(field, initialValue);
      }
    });
  };

  // Initialize state for doctor lists
  const [doctorList, setDoctorList] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setDoctorList(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); 

  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize
      
  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={HysteroscopyValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >      
      {({         
        values,
        setFieldValue,
        submitForm,
        validateForm,
        errors,
        dirty,  handleBlur, handleChange, handleSubmit, isSubmitting, touched, resetForm, setFieldTouched }) => (
        <>        
          <FormObserver dirty={dirty} onFormChange={handleFormChange} />          
          <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 , marginRight:2}}>
                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }}>
                  <TextField
                    fullWidth
                    size="small" 
                    label={RequiredLabel('Date', true)}
                    name="date"
                    type="date"
                    InputLabelProps={{shrink: true}}
                    value={values.date}
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue('date', e.target.value || null)}
                    error={touched.date && Boolean(errors.date)}
                    // helperText={touched.date && errors.date}
                    inputProps={{ max: maxMonthDate  }}
                    aria-label="Date Input Field"
                  />
                </Grid2>
              </Grid2>

              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 , marginRight:2}}>
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>                 
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="surgeon"
                    options={doctorList['DOCTOR'] || []}
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList['DOCTOR'].find((clinician) => clinician.id === values.surgeon) || null} // Bind to the form value
                    onChange={(_, value) => {
                      setFieldValue('surgeon', value?.id || ''); // Update form value with selected option's username
                      isSelectingRef.current = true; // Set the flag to true when an option is selected
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={RequiredLabel('Name of Surgeon', true)}
                        error={touched.surgeon && Boolean(errors.surgeon)}
                        // helperText={touched.surgeon && errors.surgeon}
                      />
                    )}
                  />                
                </Grid2>

                {/* Autocomplete input for assistant_surgeon */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="assistant_surgeon"
                    options={doctorList['DOCTOR'] || []}
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList['DOCTOR'].find((clinician) => clinician.id === values.assistant_surgeon) || null} // Bind to the form value
                    onChange={(_, value) => {
                      setFieldValue('assistant_surgeon', value?.id || ''); // Update form value with selected option's ID
                      isSelectingRef.current = true; // Set the flag to true when an option is selected
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assistant Surgeon"
                        error={touched.assistant_surgeon && Boolean(errors.assistant_surgeon)}
                      />
                    )}
                  />
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="anesthetist"
                    options={doctorList['ANESTHETIST'] || []}
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList['ANESTHETIST'].find((anesthetist) => anesthetist.id === values.anesthetist) || null} // Bind to the form value
                    onChange={(_, value) => {
                      setFieldValue('anesthetist', value?.id || ''); // Update form value with selected option's ID
                      isSelectingRef.current = true; // Set the flag to true when an option is selected
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={RequiredLabel('Anesthetist', false)}
                        error={touched.anesthetist && Boolean(errors.anesthetist)}
                        // helperText={touched.anesthetist && errors.anesthetist}
                      />
                    )}
                  />
                </Grid2>
                
                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Procedure Done', true)}
                    name="procedure_done"
                    select
                    value={values.procedure_done || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.procedure_done && Boolean(errors.procedure_done)}
                    // helperText={touched.procedure_done && errors.procedure_done}
                    // InputLabelProps={{ shrink: Boolean(values.procedure_done) }}
                  >
                    {procedureDoneItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Duration of Procedure (min)"
                    name="procedure_duration"
                    type='number'
                    value={values.procedure_duration || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.procedure_duration && Boolean(errors.procedure_duration)}
                    // helperText={touched.procedure_duration && errors.procedure_duration}
                  />
                </Grid2>


                {/* Cervical OS */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Cervical OS"
                    name="cervical_os"
                    select
                    value={values.cervical_os || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.cervical_os && Boolean(errors.cervical_os)}
                    helperText={touched.cervical_os && errors.cervical_os}
                  >
                    {cervicalOsItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Endocervical Conal */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Endocervical Conal"
                    name="endocervical_conal"
                    value={values.endocervical_conal || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.endocervical_conal && Boolean(errors.endocervical_conal)}
                    helperText={touched.endocervical_conal && errors.endocervical_conal}
                  />
                </Grid2>

                {/* Uterus Cervical Length */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Uterus Cervical Length (in mm)"
                    name="uterus_cervical_length"
                    type='number'
                    value={values.uterus_cervical_length || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.uterus_cervical_length && Boolean(errors.uterus_cervical_length)}
                    helperText={touched.uterus_cervical_length && errors.uterus_cervical_length}
                  />
                </Grid2>

                {/* Cavity Dropdown */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Cavity"
                    name="cavity"
                    select
                    value={values.cavity || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.cavity && Boolean(errors.cavity)}
                    helperText={touched.cavity && errors.cavity}
                  >
                    {cavityItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Endometrium Dropdown */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Endometrium"
                    name="endometrium"
                    select
                    value={values.endometrium || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.endometrium && Boolean(errors.endometrium)}
                    helperText={touched.endometrium && errors.endometrium}
                  >
                    {endometriumItems.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid2>

                {/* Right Ostia Text Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Right Ostia"
                    name="right_ostia"
                    value={values.right_ostia || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.right_ostia && Boolean(errors.right_ostia)}
                    helperText={touched.right_ostia && errors.right_ostia}
                  />
                </Grid2>

                {/* Left Ostia Text Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Left Ostia"
                    name="left_ostia"
                    value={values.left_ostia || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.left_ostia && Boolean(errors.left_ostia)}
                    helperText={touched.left_ostia && errors.left_ostia}
                  />
                </Grid2>

                {/* EB-HPE Text Field */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="EB-HPE"
                    name="eb_hpe"
                    value={values.eb_hpe || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.eb_hpe && Boolean(errors.eb_hpe)}
                    helperText={touched.eb_hpe && errors.eb_hpe}
                  />
                </Grid2>

                {/* Other Details Text Area */}
                <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Other Details"
                    name="other_details"
                    value={values.other_details || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.other_details && Boolean(errors.other_details)}
                    helperText={touched.other_details && errors.other_details}
                    multiline
                    minRows={1} 
                    maxRows={3} 
                  />
                </Grid2>

                {/* Remarks Text Area */}
                <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Remarks"
                    name="remarks"
                    value={values.remarks || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={touched.remarks && Boolean(errors.remarks)}
                    helperText={touched.remarks && errors.remarks}
                    multiline
                    minRows={1} 
                    maxRows={3} 
                  />
                </Grid2>

                {/* Autocomplete input for done_by */}
                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="done_by"
                    options={doctorList['DOCTOR'] || []}
                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                    value={doctorList['DOCTOR'].find((clinician) => clinician.id === values.done_by) || null} // Bind to the form value
                    onChange={(_, value) => {
                      setFieldValue('done_by', value?.id || ''); // Update form value with selected option's ID
                      isSelectingRef.current = true; // Set the flag to true when an option is selected
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Done By"
                        error={touched.done_by && Boolean(errors.done_by)}
                      />
                    )}
                  />
                </Grid2>

                
              </Grid2>
              <Box sx={{flexGrow: 1}}>
                <Box className={styles.customButtonDiv}>                  
                  <button
                    type="button"
                    className={styles.resetButton}
                    onClick={() => setOpenResetConfirmDialog(true)} 
                  >
                  Reset
                  </button>
                  <button
                    type="button"                    
                    className={`${styles.customButton} ${isSubmitting || !dirty ? styles.disabled : ''}`}
                    disabled={isSubmitting || !dirty} 
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                          // submitForm();
                          setOpenConfirmDialog(true);
                        } else {
                          const errorKeys = Object.keys(errors);                          
                          errorKeys.forEach((key) =>{
                            setFieldTouched(key, true, true);
                          });
                        }
                      });
                    }}                  
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {isSubmitting ? 'Saving...' : 'Save'}
                    </span>
                  </button>                  
                </Box>

                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    submitForm();
                    setOpenConfirmDialog(false);
                  }}
                  type="save"
                  title="Hysteroscopy"
                  contentMessage="Are you sure you want to save hysteroscopy?"
                />
                <ConfirmationModal
                  open={openResetConfirmDialog}
                  handleClose={() => setOpenResetConfirmDialog(false)} 
                  onConfirm={() => handleReset(resetForm, setFieldValue)} 
                  title="Hysteroscopy"
                  contentMessage="Are you sure you want to reset hysteroscopy ?"
                  type={'delete'}
                />
              </Box>
            </form>
          </ThemeProvider>
        </>
      )}
    </Formik>
  );
};

export default Hysteroscopy;