import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { RouterProvider } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import routes from './AppRouter'; // Import the routes
import { AlertProvider } from './components/alertProvider';
import { LoaderProvider } from './components/loaderProvider/loader';
import { ClinicProvider } from './components/ClinicProvider';
import { PatientProvider } from './components/patinetProvider';
import { SubPatientProvider } from './components/SubPatinetProvider';
import { CycleProvider } from './components/cycleContext/CycleContext';
import { MultilingualProvider } from './components/multilingualProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n';

const App: React.FC = () => {

  useEffect(() => {
    // Apply the linear gradient to the body dynamically
    document.body.style.background = 
      'linear-gradient(72deg, #FFF 0%, #FFF 9.67%, #F6F6F6 32.14%, #ECEEF0 100%), ' +
      'linear-gradient(72deg, #FFF 0%, #FFF 9.67%, #F6F6F6 32.14%, #ECEEF0 100%)';
    
    document.body.style.backgroundSize = 'cover';  // Ensure the gradient covers the full screen

    // Optional cleanup function to reset background on component unmount
    return () => {
      document.body.style.background = '';
    };
  }, []);  // O
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <MultilingualProvider language="en"> {/* Set default language here */}
            <ClinicProvider>
              <PatientProvider>
                <SubPatientProvider>
                  <CycleProvider>
                    <AlertProvider>
                      <LoaderProvider>
                        <RouterProvider router={routes} /> {/* Routes are rendered here */}
                      </LoaderProvider>
                    </AlertProvider>
                  </CycleProvider>
                </SubPatientProvider>
              </PatientProvider>
            </ClinicProvider>
          </MultilingualProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;