import * as Yup from 'yup';
import { ActionsEnum, BlankEnum, CultureDishConfigEnum, FertilizationEnum, IncubatorEnum, MultiNucleiEnum, NullEnum, PgtFacilityEnum, PgtOrderBiopsyCellsEnum, PgtResultEmbryoSexEnum, PgtResultMoistureEnum, PgtResultsEnum, PgtTypeEnum, StatusDa2Enum } from './embryoCulture.helpers';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getRequest, postRequest, putRequest } from '../../../services/httpService';

// Define the initial embryo culture and update details types
export interface EmbryoCultureType {
    time: string; 
    embryologist?: number | null; 
    witness?: number | null; 
    culture_dish_config?: CultureDishConfigEnum | BlankEnum | NullEnum | null; 
    incubator?: IncubatorEnum | BlankEnum | NullEnum | null
    remarks?: string | null; 
    day: number; 
    cycle: number;
    id?: number | null | undefined;
    is_assisted_hatching: boolean;
  }
  

export const initialEmbryoCultureValues: EmbryoCultureType = {
  time: '', 
  embryologist: null, 
  witness: null, 
  culture_dish_config: null, 
  incubator: null, 
  remarks: null, 
  day: 3, 
  cycle:0,
  is_assisted_hatching: false, 
};


export interface OocyteFile {
  embryo_no: number;
  file: string; // URL of the file
}

export interface EmbryoUpdateDetailsType {    
    cycle: number;
    embryos: number[] | null;
    action: ActionsEnum | BlankEnum;
    // files?: string[] | null;
    day: number;
    status?: StatusDa2Enum | BlankEnum;
   
    grade?: string | null;
    symmetry?: string | null;
    number_of_cells?: string | null;
    multi_nuclei?: MultiNucleiEnum | null;

    pgt_order_date?: string | null;
    pgt_order_time?: string | null;
    pgt_order_biopsy_cells?: PgtOrderBiopsyCellsEnum | null;
    pgt_facility?: PgtFacilityEnum | null;
    pgt_result_moisture?: PgtResultMoistureEnum | null;
    pgt_result_notes?: string;
    pgt_order_details?:string
    pgt_result?:string
    pgt_type?: PgtTypeEnum | null;
    pgt_result_embryo_sex?: PgtResultEmbryoSexEnum | null;
    pgt_results?: PgtResultsEnum | null;

    
    cryo_preserved_date?: string | null;
    cryo_preserved_by?: number | null;
    cryo_purpose?: string | null;
    cryo_media?: string | null;
    is_screened?: boolean | null;
    
    source?: number | null;
    thaw_cycle?: number | null;
    locations: number | null;
        files?: OocyteFile[];
  }

export const initialEmbryoUpdateDetailsValues: EmbryoUpdateDetailsType = {
  cycle:0, 
  embryos: [], 
  action: '',
  day: 3, 
  status: 'DRAFT',

  grade: null,
  symmetry:null,
  number_of_cells:null,
  multi_nuclei:null,

  pgt_order_date: null,
  pgt_order_details:'',
  pgt_result:'',
  pgt_order_time: null, 
  pgt_order_biopsy_cells: null, 
  pgt_facility: null, 
  pgt_result_moisture: null, 
  pgt_result_notes: '',
  pgt_type: null, 
  pgt_result_embryo_sex: null, 
  pgt_results: null, 

  cryo_preserved_date: null,         
  cryo_preserved_by: null,          
  cryo_purpose: null,             
  cryo_media: null,             
  is_screened: null,  
  locations:  null
};
  


// Validation Schema for single embryo culture
export const EmbryoCultureValidationSchema = Yup.object().shape({

  embryologist: Yup.number()
    .required('Embryologist name is required'),
  witness: Yup.number()
    .required('Witness Embryologist name is required')
    .test('not-same', 'The witness embryologist cannot be the same as the embryologist.', function (value) {
      const { embryologist } = this.parent; // Accessing the sibling field
      return value !== embryologist; // Validate that they are not the same
    }),
  
  cycle: Yup.number().integer(),
  remarks: Yup.string().nullable(),
  culture_dish_config: Yup.mixed<CultureDishConfigEnum | BlankEnum | NullEnum>()
    .oneOf([
      'NORMAL_SINGLE_CULTURE', 'EMBRYO_SLIDE_TIMELAPSE_SINGLE_CULTURE', 
      'CULTURE_PRO_TIMELAPSE_SINGLE_CULTURE', 'EMBRYO_SLIDE_IC8_DISH_TIMELAPSE_SINGLE_CULTURE', 
      '38_SPECIAL_GPS_SINGLE_CULTURE', 'EMBRYO_GPS_SINGLE_CULTURE', 
      'MINI_GPS_GPS_SINGLE_CULTURE', '4_WELL_GPS_SINGLE_CULTURE', 
      'U_DROP_GPS_SINGLE_CULTURE', 'EMBRYO_CORRAL_GPS_SINGLE_CULTURE', 
      'NORMAL_GROUP_CULTURE', 'MICROWELL_GROUP_CULTURE', '', 'NULL'
    ])
    .nullable(),
  day: Yup.number().min(0).max(30).integer(),
  time: Yup.string().required(),
  incubator: Yup.mixed<IncubatorEnum | BlankEnum | NullEnum>()
    .oneOf(['HERA_CELL', 'MINC_K_SYSTEMS', 'MEDGYN', 'OOSAFE', 'VITROLIFE', 'IVF_TECH', '', 'NULL'])
    .nullable(),
  fertilization:Yup.mixed<FertilizationEnum | BlankEnum | NullEnum>()
    .oneOf(['NORMAL', 'ABNORMAL', 'UNFERTILIZED', '', 'NULL'])
    .nullable(),
  is_assisted_hatching: Yup.boolean(), // Required boolean
    
});
    
// Validation Schema for single embryo update details
export const EmbryoValidationSchema = Yup.object().shape({
  embryos: Yup.array()
    .min(1, 'Embryo number is required')
    .required('Embryo number is required'), 
  action: Yup.mixed<ActionsEnum | BlankEnum>()
    .oneOf(['CULTURE', 'FREEZE', 'DISCARD', 'TRANSFER'], 'Invalid action')
    .required(),
  status: Yup.mixed<StatusDa2Enum>()
    .oneOf(['DRAFT', 'FINALIZED'], 'Invalid status').nullable(),
  grade: Yup.string().nullable().oneOf(['1', '2', '3', '4', '1AA', '1AB', '1AC', '1BA', '1BB', '1BC', '1CA', '1CB', '1CC', '2AA', '2AB', '2AC', '2BA', '2BB', '2BC', '2CA', '2CB', '2CC', '3AA', '3AB', '3AC', '3BA', '3BB', '3BC', '3CA', '3CB', '3CC', '4AA', '4AB', '4AC', '4BA', '4BB', '4BC', '4CA', '4CB', '4CC', '5AA', '5AB', '5AC', '5BA', '5BB', '5BC', '5CA', '5CB', '5CC', '6AA', '6AB', '6AC', '6BA', '6BB', '6BC', '6CA', '6CB', '6CC'], 'Invalid grade'),

  pgt_order_date: Yup.string().nullable(),
  pgt_order_time: Yup.string().nullable(),
  pgt_order_biopsy_cells: Yup.mixed<PgtOrderBiopsyCellsEnum>().nullable().oneOf(['5', '6', '7', '8', '9', '10'], 'Invalid biopsy cell value'),
  pgt_facility: Yup.mixed<PgtFacilityEnum>().nullable().oneOf(['PROGENESIS'], 'Invalid facility'),
  pgt_result_moisture: Yup.mixed<PgtResultMoistureEnum>().nullable().oneOf(['HIGH_MITOCHONDRIAL_SCORE', 'MODERATE_MITOCHONDRIAL_SCORE', 'LOW_MITOCHONDRIAL_SCORE', 'NOT_TESTED'], 'Invalid moisture result'),
  pgt_result_notes: Yup.string().nullable(),
  pgt_type: Yup.mixed<PgtTypeEnum>().nullable().oneOf(['PGT-A', 'PGT-M', 'PGT-SR'], 'Invalid PGT type'),
  pgt_result_embryo_sex: Yup.mixed<PgtResultEmbryoSexEnum>().nullable().oneOf(['MALE', 'FEMALE'], 'Invalid embryo sex'),
  pgt_results: Yup.mixed<PgtResultsEnum>().nullable().oneOf(['EUPLOID', 'ANEUPLOID', 'MOSAIC', 'INCONCLUSIVE', 'NOT_TESTED'], 'Invalid PGT results'),
  multi_nuclei: Yup.mixed<MultiNucleiEnum>().nullable().oneOf(['MONONUCLEI', 'BINUCLEI', 'MULTINUCLEI'], 'Invalid multi nuclei value'),
});
    
// Combined Validation Schema for single objects
export const CombinedValidationSchema = Yup.object().shape({
  embryoCulture: EmbryoCultureValidationSchema,
  embryoDetails: EmbryoValidationSchema,
});


export interface EmbryoFinilizeUpdateType {
  embryos: number[];             
  cycle: number;  
  day: number; 
  status: StatusDa2Enum | BlankEnum;
  source?:number |null,
  thaw_cycle?: number |null
}

export function updateEmbryoCulture(id: number, data: EmbryoCultureType): Promise<EmbryoCultureType> {
  const url =`embryo-culture/${id}/details`;

  return putRequest(url, data, { responseType: 'json' });
}

export function updateEmbryoSummary(id: number, data: EmbryoUpdateDetailsType | EmbryoFinilizeUpdateType): Promise<EmbryoUpdateDetailsType | EmbryoFinilizeUpdateType> {
  const url = `/embryo/cycle/${id}/`;
  return putRequest(url, data, { responseType: 'json' });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEmbryoCultureDetails(id: number, source?: number, thawId?:number | null): Promise<any> {
  let url = `cycle/${id}/embryos/?day=3`;

  // Only append the source parameter if it is provided
  if (source !== undefined) {
    url += `&source=${source}`;
  }

  if (thawId !== undefined && thawId !== null) {
    url += `&thaw_cycle=${thawId}`;
  }
  return getRequest(url, { responseType: 'json' });
}



export const columns = [
  { label: 'Embryo No.', field: 'embryoNo', editable: false },
  { label: 'Image', field: 'image', editable: false },
  { label: 'Grade', field: 'grade', editable: true },
  { label: 'Symmetry', field: 'symmetry', editable: true },
  { label: '# Cells', field: 'no_of_Cells', editable: true },
  { label: 'Multinuclei', field: 'multinuclei', editable: true },
  { label: 'PGT Order Details', field: 'pgtOrderDetails', editable: true },
  { label: 'PGT Result', field: 'pgtResult', editable: true },
  { label: 'Action', field: 'action', editable: false }, // Action column is special
  { label: 'Freeze Location', field: 'location_details', editable: false },
];