/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Button, Typography, Grid2 } from '@mui/material';
import styles from './StimModals.module.css';
import FollicularImageIcon from '../../assets/SVG/upload-img.svg';
import { createFSDetailResultsImages, FileFollicularReq, FileFollicularReqV2, FollicularStudyResultsList, getFSDetailResults, uploadFile, UploadFileResponse, UploadFileResponseV2 } from '../../pages/Stim/helper.stim';
import { FileTypes } from '../../types';
import { useAlert } from '../alertProvider';
import { formatDateToLocalYYYYMMDD } from '../../services/time';
import { uploadFileURLV3 } from '../../services/apiService';

interface FollicularImageModalProps {
  open: boolean;
  onClose: () => void;
  id: number;
  patient: number,
  cycleId: number,
  date: Date,
  stimId: number
}

const FollicularImageModal: React.FC<FollicularImageModalProps> = ({
  open,
  onClose,
  id,
  patient,
  cycleId,
  date,
  stimId
}) => {

  const [leftOvaryImage, setLeftOvaryImage] = useState<string | null | undefined>();
  const [rightOvaryImage, setRightOvaryImage] = useState<string | null | undefined>();
  const [details, setDetails] = useState<FollicularStudyResultsList | null>();
  const { addAlert } = useAlert();
  const leftImg = useRef<HTMLInputElement | null>(null);
  const rightImg = useRef<HTMLInputElement | null>(null);

  const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/webp'];

  const ErrorMessages = {
    invalidFileType: 'The Uploaded File Type is Invalid. Kindly Uplaoad an Image File',
    invalidFileSize: 'The Uploaded File Size is Invalid. Kindly Uplaoad an Image File less than 500KB',
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, ovary: 'left' | 'right') => {
    const file = event.target.files?.[0];
    if (file && allowedTypes.includes(file.type)  && file.size < 500000) {
      const reader = new FileReader();
      reader.onload = () => {
        if (ovary === 'left') setLeftOvaryImage(reader.result as string);
        else setRightOvaryImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }else{
      if(file && !allowedTypes.includes(file.type)){
        addAlert(ErrorMessages.invalidFileType, 'error');
      }else if(file && file.size > 500000){
        addAlert(ErrorMessages.invalidFileSize, 'error'); 
      }
      leftImg.current!.value = '';
      rightImg.current!.value = '';
    }
  };

  useEffect(() => {
    if (!id.toString().includes('mbsc')) {
      getFSDetailResults(id).then((response: FollicularStudyResultsList) => {
        setDetails(response);
        setLeftOvaryImage(response.left_ovary_images[0].image ?? '');
        setRightOvaryImage(response.right_ovary_images[0].image ?? '');
      });
    }
  }, [id]);

  function dataURLToFile(dataURL: string, fileName: string): File {
    const arr = dataURL.split(',');
    const mimeMatch = /:(.*?);/.exec(arr[0]);
    const mime = mimeMatch ? mimeMatch[1] : '';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
  
    return new File([u8arr], fileName, { type: mime });
  }
  
  async function handleSubmit(): Promise<void> {
  
    const reqBody: FileFollicularReqV2 = {
      stimulation_chart: stimId,
      date: details? formatDateToLocalYYYYMMDD(new Date(details.date)): formatDateToLocalYYYYMMDD(new Date(date)),
      time: details?.time?.toString() ?? '',
      left_ovary_images: [{image: leftOvaryImage ?? ''}],
      right_ovary_images: [{image: rightOvaryImage ?? ''}]
    };

    uploadFileURLV3(reqBody)
      .then((response: UploadFileResponseV2) => {
        response.left_ovary_images.forEach((image) => {
          if(image.image){
            setLeftOvaryImage(image.image);
          }});
        response.right_ovary_images.forEach((image) => {
          if(image.image){
            setRightOvaryImage(image.image);
          }});
        addAlert('Follicular Images uploaded', 'success');      
      })
      .catch((error) => 
        addAlert(error?.response?.data?.errors[0].detail ?? ' Follicular Image upload Failed' , 'error'))
      .finally(() => onClose());

    // const promises = [];
    
    // if (rightOvaryImage && (details? details.right_ovary_path_url !== rightOvaryImage: true)) {
    //   const rightFile = dataURLToFile(rightOvaryImage, `right-ovary-${id}.png`);
    //   const body: FileFollicularReq = {
    //     file_name: `right-ovary-${id}`,
    //     source: FileTypes.FS_RIGHT_IMAGE,
    //     ref_id: patient,
    //     stim_id: stimId,
    //     date:  details? formatDateToLocalYYYYMMDD(new Date(details.date)): formatDateToLocalYYYYMMDD(new Date(date))
    //   };
    //   const rightUploadPromise = uploadFile(body, rightFile);
    //   promises.push(rightUploadPromise);
    // }
  
    // if (leftOvaryImage && (details? details.left_ovary_path_url !== leftOvaryImage: true)) {
    //   const leftFile = dataURLToFile(leftOvaryImage, `left-ovary-${id}.png`);
    //   const body: FileFollicularReq = {
    //     file_name: `left-ovary-${id}`,
    //     source: FileTypes.FS_LEFT_IMAGE,
    //     ref_id: patient,
    //     stim_id: stimId,
    //     date: details? formatDateToLocalYYYYMMDD(new Date(details.date)): formatDateToLocalYYYYMMDD(new Date(date))
    //   };
    //   const leftUploadPromise = uploadFile(body, leftFile);
    //   promises.push(leftUploadPromise);
    // }
  
    // Promise.all(promises).then(() => addAlert('Images uploaded', 'success')).then(() => onClose()).catch(() => addAlert('Images uploaded Failed', 'error'));
    // createFSDetailResultsImages(details).then(() => addAlert('Images uploaded', 'success')).
    // then(() => onClose()).catch(() => addAlert('Images uploaded Failed', 'error'));
  }
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={`${styles.modal} ${styles.imageUploadModal}`}>
        {/* Modal Header */}
        <Typography variant="h5" className={styles.modalHeader}>
          Add Follicular Image
        </Typography>
        {/* Modal Body */}
        <Grid2 container spacing={2} className={styles.modalBody}>
          {/* Left Ovary Section */}
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="h6">Left Ovary</Typography>
            <div className={styles.uploadBox}>
              {leftOvaryImage ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${leftOvaryImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    className={styles.reUploadBtn}
                  >
                    Re-upload <input
                      type="file"
                      ref={leftImg}
                      accept={'image/jpeg, image/png, image/jpg, image/gif, image/webp'}
                      hidden
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(event, 'left')}
                    />
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  className={styles.uploadBtn}
                >
                  <img
                    src={FollicularImageIcon}
                    alt="Upload Icon"
                    className={styles.uploadIcon}
                  />
                  <input
                    type="file"
                    ref={leftImg}
                    accept={'image/jpeg, image/png, image/jpg, image/gif, image/webp'}
                    hidden
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(event, 'left')}
                  />
                </Button>
              )}
            </div>
          </Grid2>

          {/* Right Ovary Section */}
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="h6">Right Ovary</Typography>
            <div className={styles.uploadBox}>
              {rightOvaryImage ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${rightOvaryImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    className={styles.reUploadBtn}
                    component="label"
                  >
                    Re-upload <input
                      type="file"
                      ref={rightImg}
                      accept={'image/jpeg, image/png, image/jpg, image/gif, image/webp'}
                      hidden
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(event, 'right')}
                    />
                  </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  component="label"
                  className={styles.uploadBtn}
                >
                  <img
                    src={FollicularImageIcon}
                    alt="Upload Icon"
                    className={styles.uploadIcon}
                  />
                  <input
                    type="file"
                    ref={rightImg}
                    accept={'image/jpeg, image/png, image/jpg, image/gif, image/webp'}
                    hidden
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(event, 'right')}
                  />
                </Button>
              )}
            </div>
          </Grid2>
        </Grid2>

        {/* Modal Actions */}
        <Box
          className={styles.modalActions}
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            className={styles.buttonsec}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            className={styles.buttonSmall}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FollicularImageModal;
