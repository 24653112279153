import React from 'react';
import { Box } from '@mui/material';
import { getGradient } from '../../utils/commonUtils';

const GobletIndicator: React.FC<{ color: string | null }> = ({ color }) => {
  if (!color) return null; // Don't render if color is null

  return (
    <Box
      sx={{
        width: 16,
        height: 16,
        borderRadius: '50%',
        background: getGradient(color),
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.15)',
        ml: '10px',
      }}
      aria-hidden="true"
    />
  );
};

export default GobletIndicator;
