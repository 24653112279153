import * as React from 'react';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMultilingualContext } from '../components/multilingualProvider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


export const MultilingualDropdown = () => {
  const { i18n } = useTranslation(); // Get the i18n object from react-i18next

  const { currentLanguage, setLanguage } = useMultilingualContext(); // Get the current language from context

  const [languageValue, setLanguageValue] = useState(currentLanguage ?? 'en'); // Initialize the language value state
  
  const localStorageLanguage = localStorage.getItem('language'); // Get the language from local storage
  // Define the language options
  const languageOption = [
    { title: 'English', value: 'en' },
    { title: 'Hindi', value: 'hi' },
  ]; 

  interface LanguageChangeEvent {
    target: {
      value: string;
    };
  }

  const handleChange = (event: LanguageChangeEvent): void => {
    const {
      target: { value },
    } = event; // Destructure the value from the event target

    const isSelectedlanguage = value ?? languageValue; // Get the selected language or fallback to the current language
   
    i18n.changeLanguage(isSelectedlanguage); // Change the language using react-i18next
    
    setLanguageValue(isSelectedlanguage); // Update the language value state
    setLanguage(isSelectedlanguage); // Update the context with the selected language
    
    localStorage.setItem('language', isSelectedlanguage); // Store the selected language in local storage
  };

  useEffect(() => {
    if (!currentLanguage) {      
      localStorage.setItem('language', 'en'); // If no language is selected, store 'en' in local storage
    }; 
    i18n.changeLanguage(localStorageLanguage ?? currentLanguage ?? languageValue ?? 'en'); // Change the language from local storage using react-i18next if page is refreshed
    setLanguage(localStorageLanguage ?? currentLanguage ?? languageValue ?? 'en'); // Update the context with the selected language
  }, [currentLanguage,languageValue, i18n, localStorageLanguage, setLanguage]); // Run this effect when currentLanguage or languageValue changes

  return  (<Box sx={{ minWidth: 120 }} mb={1}>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Language</InputLabel>
      <Select
        autoWidth
        labelId="multilingual-select-label"
        id="multilingual-select"
        value={localStorageLanguage ?? currentLanguage}
        label={<Typography color='primary.main'>Language</Typography>}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        sx={{        
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E17E61', // Remove focus border
          },
        }}
      >
        {languageOption.map((item, index) => (
          <MenuItem           
            key={item.title+index}
            value={item.value}
          >
            <Typography
              variant={'body2'}
              color={localStorageLanguage  === item?.value ? 'vidaiOrange' : 'secondary.main'}
              fontWeight={'600'}
            >
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>);
  
};
export default MultilingualDropdown;
