import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import './CryotechComponent.css';
import { CryotechUnit } from '../Embryology/Embryo_Culture/embryoCulture.helpers';
import { toTitleCase } from '../../utils/commonUtils';

interface Gradient {
  name: string;
  background: string;
}

interface CryotechProps {
  cryotechs: CryotechUnit[];
  gradientData: { gradients: Gradient[] };
}

const CryotechComponent: React.FC<CryotechProps> = ({ cryotechs, gradientData }) => {
  const gradientMap = useMemo(
    () =>
      gradientData.gradients.reduce((acc, gradient) => {
        acc[gradient.name] = gradient.background;
        return acc;
      }, {} as Record<string, string>),
    [gradientData]
  );

  const getGradientStyle = (color: string | null) =>
    gradientMap[color || ''] || 'radial-gradient(circle, #E0E0E0 0%, #A0A0A0 100%)';

  const renderTooltipContent = (item: CryotechUnit) => {
    if (!item.is_occupied) return '';

    return (
      <div className="tooltip-content">
        <p>
          <span className="tooltip-label">Name:</span>{' '}
          <span className="tooltip-value">{item.patient_name || 'N/A'}</span>
        </p>
        <p>
          <span className="tooltip-label">Sample No:</span>{' '}
          <span className="tooltip-value">{item.sample_number || 'N/A'}</span>
        </p>
        <p>
          <span className="tooltip-label">Embryo:</span>{' '}
          <span className="tooltip-value">{item.day ? `${item?.number}EMB ${item?.day}Day` : 'N/A'}</span>
        </p>
      </div>
    );
  };

  return (
    <>
      {cryotechs.length > 1 && (
        <div className="cryotech-title">
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: '16px',
              marginBottom: '8px',
              textAlign: 'left',
              color: '#333',
            }}
          >
            Cryotech
          </Typography>
        </div>
      )}

      
      <div className="cryotech-container">
        {cryotechs.map((item) => (
          <div key={item.id} className="cryotech-wrapper">
            <Tooltip
              title={renderTooltipContent(item)}
              arrow
              sx={{
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#222',
                  borderRadius: '6px',
                  padding: '8px',
                  maxWidth: '220px',
                },
              }}
            >
              <div
                className={`cryotech-box ${item.is_occupied ? 'occupied' : ''}`}
                style={{ background: getGradientStyle(item.color) }} />
            </Tooltip>
            <div className="cryotech-name">{item.is_occupied ? toTitleCase(item.name) : 'Empty'}</div>
          </div>
        ))}
      </div></>
  );
};

export default CryotechComponent;
