/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styles from './Follicular_Scan.module.css';
import { ThemeProvider } from '@emotion/react';
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Grid2,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { theme } from '../../theme';
import { RequiredLabel } from '../../utils/commonUtils';
import { formatDateToLocalYYYYMMDD } from '../../services/time';
import {
  createFSDetailResults,
  FollicularStudyDetails,
  FollicularStudyResultsList,
  getFSDetailResults,
  RequestBodyFS,
} from './helper.stim';
import { useAlert } from '../../components/alertProvider';
import { isAxiosError } from 'axios';
import { useLocation } from 'react-router-dom';

type FollicularDetails = {
  date: string;
  time: string;
  lmpDate: string;
  cycleDay: string;
  endometriumThickness?: string;
  ri?: string;
  psv?: string;
  endometriumMorphology?: string;
  cystLeftOvary?: string;
  cystRightOvary?: string;
  remark?: string;
};

interface FolliculerProps {
  handleHormoneModalClose: () => void;
  date: Date;
  stim: number;
  lmp?: Date;
  cycleDay: number;
  id?: number;
}

const FollicularScan: React.FC<FolliculerProps> = ({
  handleHormoneModalClose,
  date,
  stim,
  lmp,
  cycleDay,
  id,
}) => {
  const { state } = useLocation();
  const [record, setRecord] = useState<FollicularStudyResultsList | null>();
  const [mode, setMode] = useState<'view' | 'edit'>('edit');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { addAlert } = useAlert();

  const [rows, setRows] = useState<number[]>([]);
  const [folliclesData, setFolliclesData] = useState<{
    [key: number]: {
      leftSize: string;
      rightSize: string;
      leftFollicles: string;
      rightFollicles: string;
    };
  }>({});
  const [follicularDetails, setFollicularDetails] = useState<FollicularDetails>(
    {
      date: record?.date
        ? formatDateToLocalYYYYMMDD(new Date(record.date))
        : formatDateToLocalYYYYMMDD(date),
      time: record?.time || '',
      lmpDate: record?.lmp_date
        ? formatDateToLocalYYYYMMDD(new Date(record.lmp_date))
        : lmp
          ? formatDateToLocalYYYYMMDD(lmp)
          : formatDateToLocalYYYYMMDD(new Date()),
      cycleDay: record?.cycle_day || cycleDay.toString(),
      endometriumThickness: record?.endometrium_thickness || '',
      ri: record?.ri || '',
      psv: record?.psv || '',
      endometriumMorphology: record?.endometrium_morphology || '',
      cystLeftOvary: record?.cyst_left_ovary || '',
      cystRightOvary: record?.cyst_right_ovary || '',
      remark: record?.remark || '',
    }
  );

  useEffect(() => {
    if (record?.details && record.details.length > 0) {
      setRows(
        Array.from({ length: record.details.length }, (_, index) => index + 1)
      );
    } else {
      const savedRows = localStorage.getItem('follicularRows');
      if (savedRows) {
        setRows(JSON.parse(savedRows));
      } else {
        setRows([1, 2, 3, 4, 5]); // Default 5 rows if no saved rows or record
      }
    }
  }, [record]);

  useEffect(() => {
    if (id && !id.toString().includes('mbsc')) {
      getFSDetailResults(id).then((result: FollicularStudyResultsList) =>
        setRecord(result)
      );
    }
  }, [id]);

  useEffect(() => {
    if (record?.id) {
      setMode('view');
      setFollicularDetails({
        date: record?.date
          ? formatDateToLocalYYYYMMDD(new Date(record.date))
          : formatDateToLocalYYYYMMDD(date),
        time: record?.time || '',
        lmpDate: record?.lmp_date
          ? formatDateToLocalYYYYMMDD(new Date(record.lmp_date))
          : lmp
            ? formatDateToLocalYYYYMMDD(lmp)
            : formatDateToLocalYYYYMMDD(new Date()),
        cycleDay: record?.cycle_day || cycleDay.toString(),
        endometriumThickness: record?.endometrium_thickness || '',
        ri: record?.ri || '',
        psv: record?.psv || '',
        endometriumMorphology: record?.endometrium_morphology || '',
        cystLeftOvary: record?.cyst_left_ovary || '',
        cystRightOvary: record?.cyst_right_ovary || '',
        remark: record?.remark || '',
      });
      const initialFolliclesData = record?.details?.reduce((acc: Record<number, {
        leftSize: string;
        rightSize: string;
        leftFollicles: string;
        rightFollicles: string;
      }>, detail: FollicularStudyDetails, index: number) => {
        acc[index + 1] = {
          leftSize: detail.left_follicle_size ?? '',
          rightSize: detail.right_follicle_size ?? '',
          leftFollicles: detail.left_no_of_follicles ?? '',
          rightFollicles: detail.right_no_of_follicles ?? ''
        };
        return acc;
      }, {});
      setFolliclesData(initialFolliclesData || {});
    }

    // eslint-disable-next-line
  }, [record]);
  const toggleMode = () => {
    setMode((prev) => (prev === 'view' ? 'edit' : 'view'));
  };


  const addMoreRows = () => {
    const newRows = [
      ...rows,
      ...Array.from({ length: 5 }, (_, index) => rows.length + index + 1),
    ];
    setRows(newRows);
  };

  const deleteRow = (rowId: number) => {
    const updatedRows = rows.filter((row) => row !== rowId);
    setRows(updatedRows);

    const updatedFolliclesData = { ...folliclesData };
    delete updatedFolliclesData[rowId];
    setFolliclesData(updatedFolliclesData);
  };


  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!follicularDetails.date) newErrors.date = 'Date is required.';
    if (!follicularDetails.time) newErrors.time = 'Time is required.';
    if (!follicularDetails.lmpDate) newErrors.lmpDate = 'LMP Date is required.';
    if (!follicularDetails.cycleDay)
      newErrors.cycleDay = 'Cycle Day is required.';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    rowId: number,
    field: 'leftSize' | 'rightSize' | 'leftFollicles' | 'rightFollicles',
    value: string
  ) => {
    setFolliclesData((prev) => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: value.replace(/[^0-9]/g, ''), // Allow only numeric values
      },
    }));
    setErrors((prev) => {
      const { [`row-${rowId}`]: _, ...rest } = prev;
      return rest;
    });
  };

  const submitFollicularData = async () => {
    if (!validateForm()) {
      addAlert('Please fill the required fields', 'error');
      return;
    }

    const requestBody: RequestBodyFS = {
      stimulation_chart: stim.toString(),
      endometrium_thickness: follicularDetails.endometriumThickness || '0',
      date: follicularDetails.date,
      time: follicularDetails.time,
      ri: follicularDetails.ri,
      psv: follicularDetails.psv?.replace(/[^0-9.]/g, ''),
      endometrium_morphology: follicularDetails.endometriumMorphology || '',
      cyst_left_ovary: follicularDetails.cystLeftOvary,
      cyst_right_ovary: follicularDetails.cystRightOvary,
      remark: follicularDetails.remark || '',
      details: rows
        .filter((rowId) => {
          const row = folliclesData[rowId];
          return (
            row?.leftSize ||
            row?.rightSize ||
            row?.leftFollicles ||
            row?.rightFollicles
          );
        })
        .map((rowId) => ({
          id: rowId,
          left_follicle_size: folliclesData[rowId]?.leftSize || '',
          right_follicle_size: folliclesData[rowId]?.rightSize || '',
          left_no_of_follicles: folliclesData[rowId]?.leftFollicles || '',
          right_no_of_follicles: folliclesData[rowId]?.rightFollicles || '',
        })),
    };

    try {
      await createFSDetailResults(requestBody);
      addAlert('Form has been updated successfully','success');
      setMode('view');
      setTimeout(() => handleHormoneModalClose(), 300); // CLose Modal on Successful Save of Details
    } catch (error) {
      if (isAxiosError(error)) {
        error.response?.data.errors.forEach((err: any) => {
          addAlert(`${err.attr} : ${err.detail}`, 'error');
        });
      }
      console.error('Error saving follicular scan data:', error);
    }
  };

  return (
    <div className="mt-4">
      <ThemeProvider theme={theme}>
        <div className={styles.container}>
          <span
            className={styles.headerText}
            style={{ display: 'block', marginBottom: '20px' }}
          >
              Follicular Scan
          </span>
          <form>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Date</label>
                    <span>{follicularDetails.date}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Date', true)}
                    name="date"
                    type="date"
                    value={follicularDetails.date || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({
                        ...prev,
                        date: e.target.value,
                      }))
                    }
                    slotProps={{ inputLabel: { shrink: true } }}
                    error={!!errors.date}
                    helperText={errors.date}
                    disabled={true}
                  />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Time</label>
                    <span>{follicularDetails.time}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Time', true)}
                    name="time"
                    type="time"
                    value={follicularDetails.time || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({
                        ...prev,
                        time: e.target.value,
                      }))
                    }
                    slotProps={{ inputLabel: { shrink: true } }}
                    error={!!errors.time}
                    helperText={errors.time}
                  />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>LMP Date</label>
                    <span>{follicularDetails.lmpDate}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('LMP Date', true)}
                    name="lmpDate"
                    type="date"
                    value={follicularDetails.lmpDate || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({
                        ...prev,
                        lmpDate: e.target.value,
                      }))
                    }
                    slotProps={{ inputLabel: { shrink: true } }}
                    disabled={true}
                    error={!!errors.lmpDate}
                    helperText={errors.lmpDate}
                  />
                )}
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Cycle Day</label>
                    <span>{follicularDetails.cycleDay}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Cycle Day', true)}
                    name="cycleDay"
                    type="number"
                    value={follicularDetails.cycleDay || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({
                        ...prev,
                        cycleDay: e.target.value.replace(/[^0-9]/g, ''),
                      }))
                    }
                    slotProps={{ inputLabel: { shrink: true } }}
                    disabled={true}
                    error={!!errors.cycleDay}
                    helperText={errors.cycleDay}
                  />
                )}
              </Grid2>
            </Grid2>
          </form>

          <hr />
          {/* Follicular Scan Table */}
          <Grid2 container className={styles.tableEnteries}>
            <Grid2 size={{ xs: 6 }}>
              <Typography variant="h4" className={styles.tableHeader}>
                  Left Ovary
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Typography variant="h4" className={styles.tableHeader}>
                  Right Ovary
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container className={styles.tableEnteries}>
            <Grid2 size={{ xs: 2 }}>
              <Typography variant="h6">Follicular Size (mm)</Typography>
            </Grid2>
            <Grid2 size={{ xs: mode==='view' ?  5 : 4 }}>
              <Typography variant="h6">No. of Follicles </Typography>
            </Grid2>
            <Grid2 size={{ xs:  2 }}>
              <Typography variant="h6">Follicular Size (mm)</Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography variant="h6">No. of Follicles</Typography>
            </Grid2>
            {mode !== 'view' && (
              <Grid2 size={{ xs: 1 }}>
                <Typography variant="h6">Action</Typography>
              </Grid2>
            )}
            <div className={styles.frows}>
              {rows.map((rowId) => (
                <Grid2
                  container
                  spacing={2}
                  key={rowId}
                  className={styles.inputRow}
                >
                  <Grid2 size={{ xs:  2 }}>
                    {mode === 'view' ? (
                      <Typography variant="body1" pl={1}>
                        <span>{folliclesData[rowId]?.leftSize}</span>
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        placeholder="Left Size"
                        value={folliclesData[rowId]?.leftSize || ''}
                        onChange={(e) =>
                          handleInputChange(rowId, 'leftSize', e.target.value)
                        }
                        error={!!errors[`row-${rowId}`]}
                        helperText={errors[`row-${rowId}`] || ''}
                      />
                    )}
                  </Grid2>
                  <Grid2 size={{ xs: mode === 'view' ? 5 : 4 }}>
                    {mode === 'view' ? (
                      <Typography variant="body1" pl={1}>
                        <span>{folliclesData[rowId]?.leftFollicles}</span>
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        placeholder="Left Follicles"
                        value={folliclesData[rowId]?.leftFollicles || ''}
                        onChange={(e) =>
                          handleInputChange(
                            rowId,
                            'leftFollicles',
                            e.target.value
                          )
                        }
                        error={!!errors[`row-${rowId}`]}
                        helperText={errors[`row-${rowId}`] || ''}
                      />
                    )}
                  </Grid2>
                  <Grid2 size={{ xs: 2 }}>
                    {mode === 'view' ? (
                      <Typography variant="body1">
                        <span>{folliclesData[rowId]?.rightSize}</span>
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        placeholder="Right Size"
                        value={folliclesData[rowId]?.rightSize || ''}
                        onChange={(e) =>
                          handleInputChange(
                            rowId,
                            'rightSize',
                            e.target.value
                          )
                        }
                      />
                    )}
                  </Grid2>
                  <Grid2 size={{ xs: 3 }}>
                    {mode === 'view' ? (
                      <Typography variant="body1">
                        <span>{folliclesData[rowId]?.rightFollicles}</span>
                      </Typography>
                    ) : (
                      <TextField
                        size="small"
                        placeholder="Right Follicles"
                        value={folliclesData[rowId]?.rightFollicles || ''}
                        onChange={(e) =>
                          handleInputChange(
                            rowId,
                            'rightFollicles',
                            e.target.value
                          )
                        }
                      />
                    )}
                  </Grid2>
                  {mode === 'edit' && (
                    <Grid2 size={{ xs: 1 }}>
                      <IconButton
                        color="error"
                        onClick={() => deleteRow(rowId)}
                        disabled={rows.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid2>
                  )}
                </Grid2>
              ))}
            </div>
          </Grid2>

          <div className={styles.addMoreRows}>
            {mode === 'edit' && (
              <Button onClick={addMoreRows} style={{ textTransform: 'none' }}>
                  Add 5 Rows
              </Button>
            )}
          </div>

          <hr />

          <Grid2 container spacing={2} mt={2}>
            {(mode === 'edit' || follicularDetails.endometriumThickness) && (
              <Grid2  size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Endometrium Thickness (mm)</label>
                    <span>{follicularDetails.endometriumThickness}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Endometrium Thickness (mm)', false)}
                    value={follicularDetails.endometriumThickness || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, endometriumThickness: e.target.value }))
                    }
                  />
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.ri) && (
              <Grid2  size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>RI</label>
                    <span>{follicularDetails.ri}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('RI', false)}
                    value={follicularDetails.ri || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, ri: e.target.value }))
                    }
                  />
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.psv) && (
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>PSV</label>
                    <span>{follicularDetails.psv}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('PSV', false)}
                    value={follicularDetails.psv || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, psv: e.target.value }))
                    }
                  />
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.endometriumMorphology) && (
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Endometrium Morphology</label>
                    <span>{follicularDetails.endometriumMorphology}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label={RequiredLabel('Endometrium Morphology', false)}
                    value={follicularDetails.endometriumMorphology || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, endometriumMorphology: e.target.value }))
                    }
                  >
                    <MenuItem value="HYPERECHOEIC">HYPERECHOEIC</MenuItem>
                    <MenuItem value="ISOECHOEIC">ISOECHOEIC</MenuItem>
                    <MenuItem value="TRIPLE_LAYER">TRIPLE LAYER</MenuItem>
                    <MenuItem value="III DEFINED">III DEFINED</MenuItem>
                    <MenuItem value="DOUBLE_LAYER">DOUBLE LAYER</MenuItem>
                  </TextField>
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.cystLeftOvary) && (
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Cyst in Left Ovary</label>
                    <span>{follicularDetails.cystLeftOvary}</span>
                  </Typography>
                ) : (
                  <TextField fullWidth size="small"
                    label={RequiredLabel('Cyst in Left Ovary', false)}
                    value={follicularDetails.cystLeftOvary || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, cystLeftOvary: e.target.value }))
                    } />
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.cystRightOvary) && (
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Cyst in Right Ovary</label>
                    <span>{follicularDetails.cystRightOvary}</span>
                  </Typography>
                ) : (
                  <TextField fullWidth size="small"
                    label={RequiredLabel('Cyst in Right Ovary', false)}
                    value={follicularDetails.cystRightOvary || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, cystRightOvary: e.target.value }))
                    } />
                )}
              </Grid2>
            )}
            {(mode === 'edit' || follicularDetails.remark) && (
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                {mode === 'view' ? (
                  <Typography variant="body1" className={styles.label}>
                    <label>Remark</label>
                    <span>{follicularDetails.remark}</span>
                  </Typography>
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    label={RequiredLabel('Remark', false)}
                    value={follicularDetails.remark || ''}
                    onChange={(e) =>
                      setFollicularDetails((prev) => ({ ...prev, remark: e.target.value }))
                    } />
                )}
              </Grid2>
            )}
          </Grid2>

          <div
            className={styles.customButtonDiv}
            style={{ marginTop: '20px' }}
          >
            <Button
              variant="contained"
              className={styles.cancelButton}
              onClick={handleHormoneModalClose}
            >
                Close
            </Button>
            <span className={styles.saveBtnsDiv}>
              {(record || mode === 'view') && (
                <Button
                  variant="contained"
                  className={
                    mode === 'view'
                      ? styles.customButton
                      : styles.cancelButton
                  }
                  onClick={toggleMode}
                >
                  {mode === 'view' ? 'Edit' : 'View'}
                </Button>
              )}
              {mode === 'edit' && (
                <Button
                  variant="contained"
                  className={styles.customButton}
                  onClick={submitFollicularData}
                >
                    Save
                </Button>
              )}
            </span>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default FollicularScan;
