import React, { useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from 'lodash';

interface SearchFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchData: (query: string, clinicId?: string) => Promise<{ objects: any[] }>;
  clinicId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResults: (data: any[], close?: boolean) => void; // Added close flag
}

const SearchFilterUnits: React.FC<SearchFilterProps> = ({ fetchData, clinicId, onResults }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const debouncedSearch = useMemo(() => 
    debounce((query: string) => {
      fetchData(`limit=50&search=${query}`, clinicId ?? '')
        .then((response) => onResults(response.objects))
        // eslint-disable-next-line no-console
        .catch((error) => console.error('Error fetching data:', error))
        .finally(() => setLoading(false));
    }, 300), 
  [fetchData, clinicId, onResults]
  );
  
  const handleSearch = () => {
    if (searchTerm.length > 2) {
      setLoading(true);
      debouncedSearch(searchTerm);
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    onResults([], true); // Send empty data + close flag
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <TextField
        label="Search by Patient, Sample ID"
        placeholder="Search by Patient, Sample ID"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
          }
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size={20} />
                ) : searchTerm ? (
                  <IconButton onClick={handleClear} size="small">
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
          },
        }}
      />
    </div>
  );
};

export default SearchFilterUnits;
