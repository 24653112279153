/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextField, ThemeProvider,  MenuItem,  Button, Box, FormControlLabel, Checkbox, Link, Autocomplete, Typography } from '@mui/material';
import Grid2 from '@mui/material/Grid2';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import {Clinician, CycleData, CycleItem, OocyteRetrievalType, OocyteRetrievalValidationSchema,  ROLES,  Role,  addOocyteRetrieval,  getARTCycleList,  getOocyteRetrieval,  getcycleList,  initialOocyteRetrieval,  updateOocyteRetrieval} from './OocyteRetrival.helper';
import { theme } from '../../Medical_History_Forms/themeprovider';
import { useAlert } from '../../alertProvider';
import { useLoader } from '../../loaderProvider/loader';
import {ConfirmationModal} from '../../confirmationModal';
import FormObserver from '../../FormikFormObserver';
import { capitalizeTitle, extractTime, getMaxDate, getMaxDateForMonthInput, isWithin24Hours, RequiredLabel, sanitizeTime } from '../../../utils/commonUtils';
import { usePatientContext } from '../../SubPatinetProvider';
import { anesthesiaItems, cycleItems, difficultyLevelItems, subtypeOfNeedleItems, typeOfNeedleItems } from './oocyteRetrieval.enums';
import styles from './oocyteRetrival.module.css';
import { useClinic } from '../../ClinicProvider';
import { getUsersByClinicId } from '../../../services/apiService';
import StatusChip from '../../customTableComponent/statusChip';
import OocyteRetrievalDetails from './oocyteRetrievalDetails';
import { useNavigate } from 'react-router-dom';
import EmbryoCycleUnavailable from '../../../pages/Embryology/Embryo_Culture/embryoCycleUnavailable';
import { useCycle } from '../../cycleContext/CycleContext';

interface OocyteRetrievalFormProp {
  onFormChange?: (hasChanged: boolean) => void;
}

const unavailableProcedures = ['(OI) Cycle', 'IUI', 'Surrogacy Cycle', 'FET'];

const OocyteRetrieval: React.FC<OocyteRetrievalFormProp> = ({ onFormChange}) => {
  const [retrievalData, setRetrievalData] = useState<OocyteRetrievalType>(initialOocyteRetrieval);

  const handleFormChange = onFormChange || (() => {});
  const { patientId } = usePatientContext();
  const { ClinicId } = useClinic();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<OocyteRetrievalType>(initialOocyteRetrieval);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const maxMonthDate = useMemo(() => getMaxDateForMonthInput(), []);
  const maxDate = useMemo(() => getMaxDate(), []);  
  const [hasReset, setHasReset] = useState(false); 
  const [openResetConfirmDialog, setOpenResetConfirmDialog] = useState(false); 

  const [cycleList, setCycleList] = useState<CycleItem[]>([]);
  
  const [selectedCycle, setSelectedCycle] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCycleDetails, setSelectedCycleDetails] = useState<any | null>(null);
  const [cycleStatus, setCycleStatus] = useState<string | null>(null);
  const [oocyteRetrievalDataAvailable, setOocyteRetrievalDataAvailable] = useState<boolean>(false); 
  const [isEditing, setIsEditing] = useState(false);
  const { setCycleId } = useCycle();
  
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedCycleItem, setSelectedCycleItem] = useState<any | null>(null);

  const [showActionButtons, setShowActionButtons] = useState<boolean>(false);

  const { selectedCycle: selectedCycleData } = useCycle(); // Alias applied

  useEffect(() => {
    if (!selectedCycleData) {
      return;
    }

    if (selectedCycleData?.opu_event?.appointment) {
      setRetrievalData((prevData) => ({
        ...prevData,
        opu_date: selectedCycleData?.opu_event?.appointment?.appointment_date ?? '',
        embryologist: selectedCycleData?.opu_event?.appointment.embryologist
          ? Number(selectedCycleData.opu_event.appointment.embryologist)
          : null,
        start_time: extractTime(selectedCycleData?.opu_event?.appointment.start_datetime) ?? '',
        end_time: extractTime(selectedCycleData?.opu_event?.appointment.end_datetime) ?? '',
      }));
  
      setInitialValues((prevValues) => ({
        ...prevValues,
        opu_date: selectedCycleData?.opu_event?.appointment?.appointment_date ?? '',
        embryologist: selectedCycleData?.opu_event?.appointment.embryologist
          ? Number(selectedCycleData?.opu_event?.appointment.embryologist)
          : null,
        start_time: extractTime(selectedCycleData?.opu_event?.appointment.start_datetime) ?? '',
        end_time: extractTime(selectedCycleData?.opu_event?.appointment.end_datetime) ?? '',
      }));
    }
  }, [selectedCycleData]);
  
  
  const fetchOocyteRetrieval = useCallback(
    (cycleId: number ) => {
      showLoader();   

      getcycleList(cycleId.toString())
        .then((data) => {          
          // Extract left and right follicles from the response
          const leftFollicles = data?.optimum_follicles?.left_follicles ?? null;
          const rightFollicles = data?.optimum_follicles?.right_follicles ?? null;

          // Update the form values with the fetched data
          setInitialValues((prevValues) => ({
            ...prevValues,
            optimum_follicles_left: leftFollicles,
            optimum_follicles_right: rightFollicles,
          }));

          setInitialValues((prevValues) => ({
            ...prevValues,
            opu_date: data?.opu_event?.appointment?.appointment_date 
              ? data.opu_event.appointment.appointment_date 
              : new Date().toISOString().split('T')[0], // Defaults to current date (YYYY-MM-DD)
            
            embryologist: data?.opu_event?.appointment?.embryologist
              ? Number(data.opu_event.appointment.embryologist)
              : null,

            start_time: extractTime(data?.opu_event?.appointment?.start_datetime) 
              ?? new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }), // Defaults to current time with AM/PM
          
            end_time: extractTime(data?.opu_event?.appointment?.end_datetime) 
              ?? new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }), // Defaults to current time with AM/PM
          
          }));          

        })
        .catch((error) => {         
          console.error('Error fetching cycle details:', error);
        });

      if (cycleId) {
        getOocyteRetrieval(cycleId)
          .then((data) => {
            setInitialValues((prevValues) => ({
              ...prevValues, // ✅ Using previous state instead of retrievalData
              ...data,
            }));
            
            setOocyteRetrievalDataAvailable(true); 
          })
          .catch(() => {
            setOocyteRetrievalDataAvailable(false); 
            console.error('Error fetching Oocyte Retrieval');
          })
          .finally(() => {
            hideLoader();
            setInitialValues((prevValues) => ({
              ...prevValues,
              cycle: cycleId
            }));
          });
      }
    },
    [showLoader, hideLoader] 
  );  

  useEffect(() => {
    showLoader();
    getARTCycleList(patientId)
      .then((data) => {        
        const formattedData = data.map((cycle: CycleData) => ({
          id: cycle.id,
          value: cycle.cycle_number,
          label: cycle.cycle_number,
          status: cycle.status,
          procedure:cycle?.procedure?.procedures,
          procedure_list:cycle?.procedure
        }));
        setCycleList(formattedData); 


        // Set default cycle if the list is not empty
        if (formattedData.length > 0) {
          const procedures = data[0].procedure?.procedures;
          // If procedures include "FET", set the last cycle data
          if (procedures === 'FET') {
            setSelectedCycle(formattedData[0].id);
            setCycleStatus(formattedData[0].status);
            fetchOocyteRetrieval(formattedData[0].id);
            setSelectedCycleDetails(data[0]);
            setSelectedCycleItem(formattedData[0].procedure_list);
            setShowActionButtons(!unavailableProcedures.includes(formattedData[0].procedure));
          } else {
          // If not "FET", use the first cycle by default
            setSelectedCycle(formattedData[0].id);
            setCycleStatus(formattedData[0].status);
            fetchOocyteRetrieval(formattedData[0].id);
            setSelectedCycleDetails(data[0]);
            setSelectedCycleItem(formattedData[0].procedure_list);
            setShowActionButtons(!unavailableProcedures.includes(formattedData[0].procedure));

          }
          
          setInitialValues((prevValues) => ({
            ...prevValues,
            cycle_start_date: data[0]?.cycle_start_date
          }));
        }
      })
      .catch(() => {
        console.error('Error fetching cycle list');
      })
      .finally(() => {
        hideLoader();
        setIsEditing(false);
      });
  }, [patientId, showLoader, hideLoader, fetchOocyteRetrieval]);


  const sanitizeOocyteRetrieval = (values: OocyteRetrievalType): OocyteRetrievalType => {
    const sanitizedValues = { ...values };
    if (sanitizedValues.start_time !== null && sanitizedValues.start_time.trim().length === 5) {
      sanitizedValues.start_time = `${sanitizedValues.start_time}:00`;
    }
    if (sanitizedValues.end_time !== null && sanitizedValues.end_time.trim().length === 5) {
      sanitizedValues.end_time = `${sanitizedValues.end_time}:00`;
    }

  
    if(sanitizedValues?.oocytes_retrieved_left === null || sanitizedValues?.oocytes_retrieved_left === undefined)
    {
      sanitizedValues.oocytes_retrieved_left = 0;
    }
    
    if(sanitizedValues?.oocytes_retrieved_right === null || sanitizedValues?.oocytes_retrieved_right === undefined)
    {
      sanitizedValues.oocytes_retrieved_right = 0;
    }

    // Set `pre_anesthesia_checkup` to false if empty
    if (!sanitizedValues?.pre_anesthesia_checkup) {
      sanitizedValues.pre_anesthesia_checkup = false;
    }
    return sanitizedValues;
  };
  
  const handleSubmit = async (
    values: OocyteRetrievalType,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<OocyteRetrievalType>
  ) => {
    showLoader();
    const sanitizedValues = sanitizeOocyteRetrieval(values);

    const cycleId = sanitizedValues.cycle as number; 

    const operation = isEditing
      ? updateOocyteRetrieval(sanitizedValues?.id as number, sanitizedValues)
      : addOocyteRetrieval(sanitizedValues);
    operation
      .then((updatedData) => {
        addAlert('Oocyte retrieval saved successfully', 'success');
        setInitialValues(updatedData);
        resetForm({ values: updatedData });
        fetchOocyteRetrieval(cycleId);
        setIsEditing(false);
      })
      .catch((error) => {
        if (error.response && error.response.data?.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<OocyteRetrievalType> = {};
      
          // Map API errors to formik errors
          apiErrors.forEach(({ attr, detail }: { attr: keyof OocyteRetrievalType; detail: string }) => {
            formikErrors[attr] = detail;
          });
      
          setErrors(formikErrors);
      
          // Show cycle error if it exists
          if (formikErrors.cycle) {
            addAlert(formikErrors.cycle, 'error');
          }
      
          // Show the first error from the API response as an alert
          if (apiErrors.length > 0) {
            addAlert(apiErrors[0].detail, 'error');
          }
        } else {           
          console.error('An error occurred while saving oocyteRetrieval.');
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
      });
  };
  
  const handleReset = (
    resetForm: FormikHelpers<OocyteRetrievalType>['resetForm'],
    setFieldValue: FormikHelpers<OocyteRetrievalType>['setFieldValue']
  ) => {
    setOpenResetConfirmDialog(false); 
    resetForm(); 
    setHasReset(true); 
    
    Object.keys(retrievalData).forEach((field) => {
      const initialValue = retrievalData[field as keyof OocyteRetrievalType];
      if (typeof initialValue === 'number' || initialValue === null) {
        setFieldValue(field, '');
      } else {
        setFieldValue(field, initialValue);
      }
    });
  };

  useEffect(() => {
    return () => {
      setCycleId(null); 
    };
  }, [setCycleId]);

  const handleCycleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = Number(event.target.value);
    setCycleId(selectedValue);
    const foundCycleItem = cycleList.find((item) => item.id === selectedValue);     
    setSelectedCycleItem(foundCycleItem || null);

    setShowActionButtons(!unavailableProcedures.includes(foundCycleItem?.procedure || ''));

    // if (
    //   (foundCycleItem?.procedure && !foundCycleItem.procedure.includes('FET')) ||
    // !foundCycleItem?.procedure
    // ) {
    setSelectedCycle(selectedValue);
    setCycleStatus(foundCycleItem?.status || null);
    fetchOocyteRetrieval(selectedValue);
    // }
  };
  

  const handleCombinedChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formikHandleChange: (event: React.ChangeEvent<any>) => void
  ) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    formikHandleChange(event);
    handleCycleChange(event as React.ChangeEvent<HTMLInputElement>);
    setIsEditing(false);
  };

  // Initialize state for clinician lists
  const [clinicianLists, setClinicianLists] = useState<Record<Role, Clinician[]>>({
    DOCTOR: [],
    ANESTHETIST: [],
    EMBRYOLOGIST: [],
    NURSE: [],
  });

  // Wrap fetchCliniciansByRole in useCallback
  const fetchCliniciansByRole = useCallback((role: Role) => {
    showLoader();
    const query = `clinic=${ClinicId}&role=${role}`;
    getUsersByClinicId(query)
      .then((res) => {
        setClinicianLists(prev => ({
          ...prev,
          [role]: res?.objects ?? [],
        }));
      })
      .catch((error) => {
        console.error(`Error fetching ${role.toLowerCase()}s:`, error);
      })
      .finally(() => {
        hideLoader();
      });
  }, [ClinicId, showLoader, hideLoader]); // Add dependencies here

  // Fetch clinicians for each role on component mount or when ClinicId changes
  useEffect(() => {
    ROLES.forEach(fetchCliniciansByRole);
  }, [fetchCliniciansByRole]); // Use the memoize

  const handleEditClick = () => {
    setIsEditing(true);
  };


  const navigateToCycleOverview = (cycleId:number|null) => {
    if (cycleId) {
      navigate(`/clinical/patients/cycle-overview/${patientId}`, { state: { cycleId: cycleId } });
      
    } else {
      
      navigate(`/clinical/patients/cycle-overview/${patientId}`);
    }

  };
  return (
    <Formik 
      initialValues={initialValues}
      validationSchema={OocyteRetrievalValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >      
      {({         
        values,
        setFieldValue,
        submitForm,
        validateForm,
        errors,
        dirty,  handleBlur, handleChange, handleSubmit, isSubmitting, touched, resetForm, setFieldTouched }) => (
        <>        
          <FormObserver dirty={dirty} onFormChange={handleFormChange} />          
          <ThemeProvider theme={theme}>
            <form onSubmit={handleSubmit}>
              <Box sx={{flexGrow: 1, marginRight:2}}>
                <Grid2 container spacing={2}  sx={{ marginTop: 1 }}>
                  {/* Cycle No. */}
                  <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 3 }}>
                    <TextField
                      fullWidth
                      size="small"
                      label={RequiredLabel('Cycle No', true)}
                      name="cycle"
                      select
                      value={values.cycle || ''}
                      onBlur={handleBlur}
                      onChange={handleCombinedChange(handleChange)}
                      error={touched.cycle && Boolean(errors.cycle)}
                      // helperText={touched.cycle && errors.cycle}
                    >
                      {cycleList.map((item) => ( 
                        <MenuItem key={item.value} value={item.id}>
                          {item.label} &nbsp;|&nbsp; &nbsp; 
                          <StatusChip 
                            value={item.status === 'TERMINATED' ? 'Inactive' : capitalizeTitle(item.status)} 
                            height="22px" 
                          />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid2>

                  <Grid2 size={{ xs: 4, sm:4, md: 4, lg:3 }} 
                    sx={{ textAlign: 'left' }}>
                    <Link
                      href="#"
                      onClick={()=>navigateToCycleOverview(values.cycle)}
                      sx={{ fontFamily: 'Montserrat', fontSize: '16px', fontWeight: 600, lineHeight: '23.2px', textAlign: 'left', marginLeft:2}}
                    >
                      Cycle Overview
                    </Link>
                  </Grid2>


                  {/* Terminate Cycle (Button) */}                  
                  {cycleStatus !== 'TERMINATED' ? (
                    <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                      sx={{ textAlign: 'right', }}
                    >
                      <Button variant='outlined'  color='error' onClick={()=>navigateToCycleOverview(null)}    
                        sx={{
                          textTransform: 'capitalize',
                          borderRadius: '12px',
                        }}>
                        Terminate Cycle
                      </Button>
                    </Grid2>
                  ) : (
                    <Grid2 size={{ xs: 6, sm: 6, md: 6, lg: 6 }}
                      sx={{ textAlign: 'right'}}
                    >
                      <button className={styles.terminateButton}  onClick={()=>navigateToCycleOverview(null)}>
                        Create New Cycle
                      </button>
                    </Grid2>
                  )} 
                </Grid2>

                {
                  selectedCycleItem && (
                    (unavailableProcedures.includes(selectedCycleItem?.procedures) || unavailableProcedures.includes(selectedCycleItem?.procedure))
                      ? (        
                        <EmbryoCycleUnavailable noContentMsg="Oocyte Retrival is not prescribed for selected cycle" />
                      )
                      : (
                        cycleStatus === 'Inactive' ||
                        cycleStatus === 'TERMINATED' ||
                        (oocyteRetrievalDataAvailable && !isEditing)
                          ? (
                            <OocyteRetrievalDetails cycleId={selectedCycle} />
                          )
                          : (
                            <>
                              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 }}>
                                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small" 
                                    label={RequiredLabel('Cycle Start Date', true)}
                                    name="cycle_start_date"
                                    type="date"
                                    InputLabelProps={{shrink: true}}
                                    value={values.cycle_start_date}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue('cycle_start_date', e.target.value || null)}
                                    error={touched.cycle_start_date && Boolean(errors.cycle_start_date)}
                                    // helperText={touched.cycle_start_date && errors.cycle_start_date}
                                    inputProps={{ max: maxMonthDate  }}
                                    disabled={true}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label={RequiredLabel('OPU Date', true)}
                                    name="opu_date"
                                    type="date"
                                    InputLabelProps={{shrink: true}}
                                    value={values.opu_date}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue('opu_date', e.target.value || null)}
                                    error={touched.opu_date && Boolean(errors.opu_date)}
                                    // helperText={touched.opu_date && errors.opu_date}
                                    inputProps={{ max: maxMonthDate  }}
                                  />
                                </Grid2>

                                {/* Start Time Field */}
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label={RequiredLabel('Start Time', true)}
                                    name="start_time"
                                    type="time"
                                    InputLabelProps={{ shrink: true  }}
                                    value={values.start_time}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue('start_time', e.target.value || null)}
                                    error={touched.start_time && Boolean(errors.start_time)}
                                    // helperText={touched.start_time && errors.start_time}
                                  />
                                </Grid2>

                                {/* End Time Field */}
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label={RequiredLabel('End Time', true)}
                                    name="end_time"
                                    type="time"
                                    InputLabelProps={{ shrink:  true  }}
                                    value={values.end_time}
                                    onBlur={handleBlur}
                                    onChange={(e) => setFieldValue('end_time', e.target.value || null)}
                                    error={touched.end_time && Boolean(errors.end_time)}
                                    helperText={
                                      touched.end_time &&
                            (errors.end_time === 'End Time should be after Start Time'
                              ? errors.end_time
                              : '')
                                    }
                                  />
                                </Grid2>
                              </Grid2>

                              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 }}>   
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="clinician"
                                    options={clinicianLists['DOCTOR'] || []} 
                                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                                    value={clinicianLists['DOCTOR'].find((doctor) => doctor.id === values.clinician) || null}
                                    onChange={(_, value) => {
                                      setFieldValue('clinician', value?.id || '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={RequiredLabel('Clinician', true)}
                                        error={touched.clinician && Boolean(errors.clinician)}
                                        // helperText={touched.clinician && errors.clinician}
                                      />
                                    )}
                                  />
                                </Grid2>
                
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="embryologist"
                                    options={clinicianLists['EMBRYOLOGIST'] || [] }
                                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                                    value={clinicianLists['EMBRYOLOGIST'].find((doctor) => doctor.id === values.embryologist) || null}
                                    onChange={(_, value) => {
                                      setFieldValue('embryologist', value?.id || '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={RequiredLabel('Embryologist', true)}
                                        error={touched.embryologist && Boolean(errors.embryologist)}
                                        // helperText={touched.embryologist && errors.embryologist}
                                      />
                                    )}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="witness_embryologist"
                                    options={clinicianLists['EMBRYOLOGIST']  || [] }
                                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                                    value={clinicianLists['EMBRYOLOGIST'].find((doctor) => doctor.id === values.witness_embryologist) || null}
                                    onChange={(_, value) => {
                                      setFieldValue('witness_embryologist', value?.id || '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={RequiredLabel('Witness Embryologist', true)}
                                        error={touched.witness_embryologist && Boolean(errors.witness_embryologist)}
                                        helperText={
                                          touched.witness_embryologist &&
                                (errors.witness_embryologist === 'The witness embryologist cannot be the same as the embryologist.'
                                  ? errors.witness_embryologist
                                  : '')
                                        }
                                      />
                                    )}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="anesthetist"
                                    options={clinicianLists['ANESTHETIST']  || []}
                                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                                    value={clinicianLists['ANESTHETIST'].find((doctor) => doctor.id === values.anesthetist) || null}
                                    onChange={(_, value) => {
                                      setFieldValue('anesthetist', value?.id || '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label={RequiredLabel('Anesthetist', true)}
                                        error={touched.anesthetist && Boolean(errors.anesthetist)}
                                        // helperText={touched.anesthetist && errors.anesthetist}
                                      />
                                    )}
                                  />
                                </Grid2>
                              </Grid2>

                              <Grid2 container spacing={2} justifyContent="start" sx={{ marginTop: 2 }}>
                                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label={RequiredLabel('Type of Anesthesia', true)}
                                    name="type_of_anesthesia"
                                    select
                                    value={values.type_of_anesthesia || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.type_of_anesthesia && Boolean(errors.type_of_anesthesia)}
                                    // helperText={touched.type_of_anesthesia && errors.type_of_anesthesia}
                                  >
                                    {anesthesiaItems.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    select
                                    label={RequiredLabel('No. of Needle Used', true)}
                                    name="no_of_needles_used"
                                    value={values.no_of_needles_used !== undefined ? values.no_of_needles_used : ''}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      setFieldValue('no_of_needles_used', e.target.value ? e.target.value : '');
                                    }}
                                    error={touched.no_of_needles_used && Boolean(errors.no_of_needles_used)}
                                    // helperText={touched.no_of_needles_used && errors.no_of_needles_used}
                                    InputLabelProps={{
                                      shrink: !!values.no_of_needles_used, 
                                    }}
                                  >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                  </TextField>
                                </Grid2>
                  
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <Autocomplete
                                    fullWidth
                                    size="small"
                                    id="nurse"
                                    options={clinicianLists['NURSE'] || []}
                                    getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
                                    value={clinicianLists['NURSE'].find((doctor) => doctor.id === values.nurse) || null}
                                    onChange={(_, value) => {
                                      setFieldValue('nurse', value?.id || '');
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label='Nurse'
                                        error={touched.nurse && Boolean(errors.nurse)}
                                        // helperText={touched.nurse && errors.nurse}
                                      />
                                    )}
                                  />
                                </Grid2>                

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label='Tube ID'
                                    name="tube_id"
                                    value={values.tube_id || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.tube_id && Boolean(errors.tube_id)}
                                    // helperText={touched.tube_id && errors.tube_id}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Type of Needle"
                                    name="type_of_needle"
                                    select
                                    value={values.type_of_needle || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.type_of_needle && Boolean(errors.type_of_needle)}
                                    // helperText={touched.type_of_needle && errors.type_of_needle}
                                  >
                                    {typeOfNeedleItems.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Subtype of Needle"
                                    name="sub_type_of_needle"
                                    select
                                    value={values.sub_type_of_needle || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.sub_type_of_needle && Boolean(errors.sub_type_of_needle)}
                                    // helperText={touched.sub_type_of_needle && errors.sub_type_of_needle}
                                  >
                                    {subtypeOfNeedleItems.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Level of Difficulty"
                                    name="difficulty_level"
                                    select
                                    value={values.difficulty_level || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.difficulty_level && Boolean(errors.difficulty_level)}
                                    // helperText={touched.difficulty_level && errors.difficulty_level}
                                  >
                                    {difficultyLevelItems.map((item) => (
                                      <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid2>
                  
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  {/* Procedure Findings */}
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Procedure Findings"
                                    name="procedure_findings"
                                    value={values.procedure_findings || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.procedure_findings && Boolean(errors.procedure_findings)}
                                    helperText={touched.procedure_findings && errors.procedure_findings}
                                    multiline
                                    minRows={1}  
                                    maxRows={1}  
                                  />
                                </Grid2>

                                {/* Optimum Follicles */}
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Optimum Follicles (Left)"
                                    name="optimum_follicles_left"
                                    value={values.optimum_follicles_left || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.optimum_follicles_left && Boolean(errors.optimum_follicles_left)}
                                    // helperText={touched.optimum_follicles_left && errors.optimum_follicles_left}
                                    type="number"
                                    disabled={true}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Optimum Follicles (Right)"
                                    name="optimum_follicles_right"
                                    value={values.optimum_follicles_right || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.optimum_follicles_right && Boolean(errors.optimum_follicles_right)}
                                    // helperText={touched.optimum_follicles_right && errors.optimum_follicles_right}
                                    type="number"
                                    disabled={true}
                                  />
                                </Grid2>

                                {/* Oocytes Retrieved */}
                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Oocyte Retrieved (Left Ovary)"
                                    name="oocytes_retrieved_left"
                                    value={values.oocytes_retrieved_left || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.oocytes_retrieved_left && Boolean(errors.oocytes_retrieved_left)}
                                    helperText={touched.oocytes_retrieved_left && errors.oocytes_retrieved_left}
                                    type="number"
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Oocyte Retrieved (Right Ovary)"
                                    name="oocytes_retrieved_right"
                                    value={values.oocytes_retrieved_right || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.oocytes_retrieved_right && Boolean(errors.oocytes_retrieved_right)}
                                    helperText={touched.oocytes_retrieved_right && errors.oocytes_retrieved_right}
                                    type="number"
                                  />
                                </Grid2>

                                {/* General Remark */}
                                <Grid2 size={{ xs: 6, sm:4, md: 4, lg:3 }} sx={{ marginTop: 1 }}>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    label="Notes"
                                    name="notes"
                                    value={ values.notes || ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.notes && Boolean(errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                    multiline
                                    minRows={1}  
                                    maxRows={3}
                                  />
                                </Grid2>

                                <Grid2 size={{ xs: 6, sm: 4, md: 4, lg: 3 }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.pre_anesthesia_checkup === true}
                                        onChange={(e) => handleChange({
                                          target: {
                                            name: 'pre_anesthesia_checkup',
                                            value: e.target.checked ? true : false, 
                                          },
                                        })}
                                        name="pre_anesthesia_checkup"
                                      />
                                    }
                                    label="Pre-Anesthesia Check-up"
                                  />
                                </Grid2>
                              </Grid2>
                            </>
                          )
                      )
                  )
                }
                {showActionButtons && (
                  <>
                    {cycleStatus !== 'TERMINATED' && (!oocyteRetrievalDataAvailable || isEditing) && (
                      <Box className={styles.customButtonDiv}>
                        <button
                          type="button"
                          className={styles.resetButton}
                          onClick={() => setOpenResetConfirmDialog(true)}
                        >
                        Reset
                        </button>
                        <button
                          type="button"
                          className={`${styles.customButton} ${isSubmitting || !dirty ? styles.disabled : ''}`}
                          disabled={isSubmitting || !dirty}
                          onClick={() => {
                            validateForm()
                              .then((formErrors) => {
                                if (Object.keys(formErrors || {}).length === 0 && !isSubmitting) {
                                  setOpenConfirmDialog(true);
                                } else {
                                  Object.keys(values).forEach((field) => {
                                    setFieldTouched(field, true, false);
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error('Error during form validation:', err);
                              });
                          }}
                        >
                          <span style={{ fontWeight: 'bold' }}>
                            {isSubmitting ? 'Saving...' : 'Save'}
                          </span>
                        </button>
                      </Box>
                    )}

                    {cycleStatus !== 'TERMINATED' &&
                      oocyteRetrievalDataAvailable &&
                      !isEditing &&
                      isWithin24Hours(initialValues?.created_on) && (
                      <Box className={styles.customButtonDiv}>
                        <button
                          type="button"
                          className={styles.customButton}
                          onClick={handleEditClick}
                        >
                          <span style={{ fontWeight: 'bold' }}>Edit</span>
                        </button>
                      </Box>
                    )}
                  </>
                )}

                <ConfirmationModal
                  open={openConfirmDialog}
                  handleClose={() => setOpenConfirmDialog(false)}
                  onConfirm={() => {
                    setOpenConfirmDialog(false);
                    submitForm(); 
                  }} 
                  type="save"
                  title="Oocyte Retrieval"
                  contentMessage="Are you sure you want to save oocyte retrieval?"
                />
                <ConfirmationModal
                  open={openResetConfirmDialog}
                  handleClose={() => setOpenResetConfirmDialog(false)} 
                  onConfirm={() => handleReset(resetForm, setFieldValue)} 
                  title="OocyteRetrieval"
                  contentMessage="Are you sure you want to reset oocyte retrieval ?"
                  type={'delete'}
                />
              </Box>
            </form>
          </ThemeProvider>
        </>
      )}
    </Formik>
  );
};

export default OocyteRetrieval;